<template>
  <form @submit.prevent="handleSubmit">
    <div class="md:flex">
      <div class="md:w-2/4">
        <BaseInput
          label="provider.name"
          name="name"
          v-model="formData.name"
          :errors="v$.name.$errors"
          :required="true"
        />
      </div>

      <div class="md:w-2/4 md:ml-6">
        <BaseInput
          label="provider.alias"
          name="alias"
          v-model="formData.alias"
          :errors="v$.alias.$errors"
          :required="true"
        />
      </div>
    </div>

    <hr class="mt-5" />
    <VariablesMap v-model="formData.variables_map" :title="t('provider.variablesMap')" />

    <div class="py-3 px-4 border border-blue-200 rounded-md">
      <div class="flex flex-col">
        <span class="font-bold text-gray-700 tesx-sm">Наши ip-адреса:</span>
        <span class="ml-2 mt-1 text-gray-700 flex flex-row items-center">
          <spna>• 95.216.206.177</spna>
          <Tippy @click="copy('95.216.206.177')" :content="t('common.copy')">
            <CopyIcon class="w-4 h-4 ml-2 text-blue-600 hover:text-blue-700 cursor-pointer" />
          </Tippy>
        </span>
        <span class="ml-2 mt-2 text-gray-700 flex flex-row items-center">
          <span>• 95.216.205.236</span>
          <Tippy @click="copy('95.216.205.236')" :content="t('common.copy')">
            <CopyIcon class="w-4 h-4 ml-2 text-blue-600 hover:text-blue-700 cursor-pointer" />
          </Tippy>
        </span>
      </div>

      <div class="w-full p-4 border mt-3 border-blue-200 rounded-md flex flex-row items-center gap-x-6">
        <span class="text-gray-700 break-all">
          {{ url }}
        </span>
        <Tippy @click="copy(url)" class="ml-auto" :content="t('common.copy')">
          <CopyIcon class="w-5 h-5 text-blue-600 hover:text-blue-700 cursor-pointer" />
        </Tippy>
      </div>
    </div>

    <div class="flex justify-between my-4">
      <h4 class="text-lg font-medium">{{ t('provider.requiredParameters') }}</h4>

      <button
        type="button"
        class="btn btn-success ml-3"
        @click="formData.mandatory_variables.push('')"
        :disabled="disabledMandatory"
        :title="disabledMandatory ? t('provider.diasbledAddVariableTitle') : t('provider.addVariableTitle')"
      >
        <PlusIcon class="w-4 h-4 mr-1" />
        {{ t('provider.addVariable') }}
      </button>
    </div>

    <div class="flex flex-row flex-wrap gap-y-3 gap-x-3">
      <div v-for="(variable, index) in formData.mandatory_variables" :key="index">
        <div class="input-group mt-2">
          <input type="text" class="form-control w-52" v-model="formData.mandatory_variables[index]" />

          <div
            class="input-group-text cursor-pointer hover:bg-gray-200 active:bg-gray-400"
            @click="formData.mandatory_variables.splice(index, 1)"
            :title="t('provider.deleteVariable')"
          >
            <Trash2Icon class="w-5 h-5 text-theme-6" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
        {{ t('provider.cancel') }}
      </button>
      <button type="submit" class="btn btn-primary">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('provider.save') }}
      </button>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useStore } from 'vuex'
import VariablesMap from '@/components/VariablesMap.vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'EditProviderForm',
  components: { VariablesMap },
  props: {
    provider: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()
    const toast = useToast()

    const formData = ref({
      id: props.provider.id || '',
      name: props.provider.name || '',
      alias: props.provider.alias || '',
      variables_map: props.provider.variables_map || [],
      mandatory_variables: props.provider.mandatory_variables || [],
    })

    const url = ref('')

    const generateProviderUrl = () => {
      const domain = `https://cd.sigmamobi.com/callbacks/${props.provider.id || '{provider_id}'}`
      let params = []

      formData.value.variables_map.forEach(variable => {
        const urlParam = `${variable.value}={${variable.value}}`

        if (variable.value && !params.includes(urlParam)) {
          params.push(urlParam)
        }
      })

      url.value = `${domain}${params.length ? '?' : ''}${params.join('&')}`
    }

    const copy = async text => {
      try {
        await navigator.clipboard.writeText(text)
        toast.success(t('vTable.copiedText'), {
          timeout: 1500,
          closeOnClick: false,
        })
      } catch (error) {
        toast.error(t('vTable.copyErrorText'), {
          timeout: 1500,
          closeOnClick: false,
        })
      }
    }

    const disabledMandatory = computed(() => {
      return formData.value.mandatory_variables.some(variable => !variable?.replace(/\s/g, ''))
    })

    const disabledMap = computed(() => {
      return formData.value.variables_map.some(
        variable => !variable.name.replace(/\s/g, '') || !variable?.value.replace(/\s/g, '')
      )
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        alias: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      if (disabledMandatory.value || disabledMap.value) {
        toast.error(t('provider.parameterNotFilled'), {
          closeOnClick: false,
        })

        return
      }

      props.provider.id ? updateProvider() : createProvider()
    }

    const createProvider = () => {
      store.dispatch('providers/createProvider', formData.value).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const updateProvider = () => {
      store.dispatch('providers/updateProvider', formData.value).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    watch(
      () => formData.value.variables_map,
      () => generateProviderUrl(),
      { deep: true }
    )

    generateProviderUrl()

    return {
      t,
      v$,
      formData,
      url,
      copy,
      disabledMandatory,
      handleSubmit,
    }
  },
})
</script>
