<template>
  <div class="mt-5">
    <label class="input-file flex items-center">
      <input type="file" name="file" accept="image/jpeg, image/png, image/gif" @change="onFileChange" />

      <span type="button" class="btn btn-twitter h-10 w-[130px]">
        <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 mr-1" />
        <UploadIcon v-else class="w-4 h-4 mr-1" />
        {{ loading ? t('vFileUploader.loadingUploadImage') : t('vFileUploader.uploadImage') }}
      </span>
    </label>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'

const IMAGE_FORMAT = ['image/jpeg', 'image/png', 'image/gif']

export default defineComponent({
  name: 'VFileUploader',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()
    const toast = useToast()

    const loading = ref(false)
    const image = ref(null)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const onFileChange = e => {
      loading.value = true

      if (errorHandler(e)) {
        loading.value = false
        return
      }

      image.value = e.target.files[0]
      upload()
    }

    const upload = async () => {
      const data = new FormData()
      data.append('image', image.value)

      store
        .dispatch('images/uploadImage', data)
        .then(result => {
          if (result.data?.success) {
            context.emit('update:modelValue', result.data.url)

            toast.success(t('vFileUploader.successUploadImage'), {
              closeOnClick: false,
            })
          } else {
            toast.error(t('vFileUploader.errorUploadImage'), {
              closeOnClick: false,
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const errorHandler = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return true
      }

      const fileType = e.target.files[0]?.type
      const notValidType = !IMAGE_FORMAT.includes(fileType)
      if (notValidType) {
        toast.error(t('vFileUploader.invalidImageFormat'), {
          closeOnClick: false,
        })
        return true
      }

      const moreTwoMb = e.target.files[0]?.size > 2097152
      if (moreTwoMb) {
        toast.error(t('vFileUploader.invalidImageSize'), {
          closeOnClick: false,
        })
        return true
      }

      return false
    }

    const formatSize = bytes => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (!bytes) {
        return ''
      }

      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    }

    return {
      image,
      loading,
      onFileChange,
      formatSize,
      t,
    }
  },
})
</script>

<style scoped lang="scss">
.input-file input[type='file'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
</style>
