<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="openAddRoleDialog">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('role.addRoleButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getRoles"
        @applyFilter=";(table.pagination.pageData.page = 1), getRoles()"
      >
        <BaseInput type="number" class="w-24" label="role.id" name="id" v-model="table.filter.id" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getRoles"
        @edit="editRole"
        @delete="deleteRole"
        @updateSorting="sort => ((table.sorting = sort), getRoles())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="openReportsDialog(slotProps.id)">
            <Tippy :content="t('role.displayingReports')">
              <PieChartIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :size="role.id ? 'lg' : 'md'"
      :showDialog="dialog.form"
      @close="dialog.form = false"
      :textHeader="role.id ? t('role.editRole') : t('role.addRole')"
      :recordId="role.id"
    >
      <RolesForm
        @close="dialog.form = false"
        @addRole="addRole"
        @updateRole="updateRole"
        :role="role"
        :serverError="serverError"
      />
    </VDialog>

    <VDialog
      size="sm"
      :showDialog="dialog.reports"
      @close="dialog.reports = false"
      :textHeader="t('role.displayingReports')"
      :recordId="role.id"
    >
      <RolesReports :role="role" @close-modal="dialog.reports = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import RolesForm from './RolesForm.vue'
import RolesReports from './RolesReports.vue'

export default defineComponent({
  name: 'Roles',
  components: {
    RolesForm,
    RolesReports,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const confirmDialog = ref(null)
    const serverError = ref({})

    const dialog = ref({
      form: false,
      reports: false,
    })

    const role = ref({})
    const roles = ref([])

    const table = ref({
      filter: {
        id: route.query.id || '',
      },
      sorting: {
        order: 'DESC',
        order_by: 'id',
      },
      columns: {
        id: { label: t('role.id'), sortable: true, sort_name: 'id' },
        name: { label: t('role.name'), sortable: true, sort_name: 'name' },
        description: t('role.description'),
        author: t('role.author'),
      },
      data: computed(() => {
        return roles.value.map(role => {
          return {
            id: role.id,
            name: role.name,
            description: role.description,
            author: role.author?.name,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getRoles = async () => {
      const response = await store.dispatch('roles/getRoles', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response?.data) {
        roles.value = response.data.data
        table.value.pagination.meta = response.data.meta
      }
    }

    const addRole = roleInfo => {
      store.dispatch('roles/addRole', roleInfo).then(result => {
        if (result.response?.data?.errors) {
          serverError.value = result.response.data.errors
          return
        }
        getRoles()
        dialog.value.form = false
      })
    }

    const openAddRoleDialog = () => {
      role.value = {}
      dialog.value.form = true
    }

    const editRole = id => {
      store
        .dispatch(`roles/getRoleById`, id)
        .then(result => {
          if (result.data?.data) {
            role.value = result.data.data
          }
        })
        .finally(() => {
          dialog.value.form = true
        })
    }

    const updateRole = async roleInfo => {
      store
        .dispatch('roles/updateRoleInfo', {
          id: roleInfo.id,
          roleInfo: { name: roleInfo.name, description: roleInfo.description },
        })
        .then(() => {
          getRoles()
          dialog.value.form = false
        })
    }

    const deleteRole = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'role.confirmDialog.title',
        message: 'role.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('roles/deleteRole', id).then(() => {
          getRoles()
        })
      }
    }

    const openReportsDialog = async id => {
      const response = await store.dispatch('roles/getRoleById', id)

      if (response?.data?.data) {
        role.value = response.data.data
        dialog.value.reports = true
      }
    }

    getRoles()

    return {
      table,
      dialog,
      roles,
      role,
      confirmDialog,
      serverError,

      getRoles,
      addRole,
      openAddRoleDialog,
      openReportsDialog,
      deleteRole,
      editRole,
      updateRole,

      t,
    }
  },
})
</script>
