import { useToast } from 'vue-toastification'

export function useErrorNotification(errors) {
  if (typeof errors === 'string') {
    pushNotification(errors)
    return
  }

  Object.values(errors).forEach(error => {
    error.forEach(message => {
      pushNotification(message)
    })
  })
}

const pushNotification = message => {
  const toast = useToast()

  if (message) {
    toast.error(message, {
      closeOnClick: false,
    })

    console.warn(message)
  }
}
