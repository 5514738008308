import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'

// https://vue-toastification.maronato.dev/?ref=madewithvuejs.com
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// import Axios from 'axios'

// Vue.prototype.$http = Axios;
//
// const token = localStorage.getItem('token')
//
// if (token) {
//   Vue.prototype.$http.defaults.headers.common['Authorization'] = token
// }

// SASS Theme
import './assets/sass/app.scss'

import i18n from './i18n'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)

globalComponents(app)
utils(app)

app.mount('#app')

app.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    const isDuplicateToast = toasts.filter(t => t.content === toast.content && toast.type === 'error').length !== 0
    if (isDuplicateToast) {
      return false
    }
    return toast
  },
})
