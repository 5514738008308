<template>
  <div>
    <VTable :columns="table.headers" :data="table.data" :hideScroll="true" :hideButton="['edit']" class="mt-6">
      <template #rows>
        <tr
          v-for="(alias, index) of table.data"
          :key="index"
          class="bg-white border-b border-opacity-50 border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100"
          :class="{
            'bg-theme-10 bg-opacity-5 hover:bg-theme-10 hover:bg-opacity-5':
              !locking(index) && editableRow.index !== null,
          }"
        >
          <td scope="row" class="px-4 py-4 text-gray-900 dark:text-white w-2/4">
            <BaseInput
              label="baseInput.label"
              v-model="table.data[index].provider_alias"
              @input="changeHandler(index)"
              :disabled="locking(index)"
            />
          </td>

          <td scope="row" class="px-4 py-4 text-gray-900 dark:text-white w-2/4">
            <VFieldSelect
              v-model="table.data[index].operator.id"
              :options="options.operators"
              label=""
              @change="changeHandler(index)"
              :disabledStyle="locking(index)"
            />
          </td>

          <td class="whitespace-nowrap" align="right">
            <button
              type="button"
              class="mr-2"
              :title="t('vTable.delete')"
              @click="deleteOperatorAlias(alias.id)"
              :class="{ 'opacity-30 cursor-not-allowed': editableRow.index !== null }"
              :disabled="editableRow.index !== null"
            >
              <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-6" />
            </button>
          </td>
        </tr>
      </template>
    </VTable>

    <div class="modal-footer mt-10">
      <div v-if="!showSaveBtn" class="flex items-center">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('provider.close') }}
        </button>

        <button class="btn btn-success" @click="addAlias()">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('provider.addOperatorAlias') }}
        </button>
      </div>

      <div v-if="showSaveBtn" class="flex items-center">
        <button class="btn btn-outline-secondary mr-2" @click="cancelСhanges()">
          <XIcon class="w-4 h-4 mr-1" />
          {{ t('provider.cancel') }}
        </button>

        <button class="btn btn-primary" @click="handleSubmit()">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('provider.save') }}
        </button>
      </div>
    </div>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'OperatorAlias',
  props: {
    provider: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()
    const confirmDialog = ref(null)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const showSaveBtn = ref(false)
    const locking = index => editableRow.value.index !== null && editableRow.value.index !== index

    const editableRow = ref({
      record: null,
      index: null,
    })

    const table = ref({
      headers: {
        alias: t('provider.alias'),
        operator: t('provider.operator'),
      },
      data: [],
    })

    const options = ref({
      operators: [],
    })

    const getOperatorAlias = async () => {
      store.dispatch('providers/getOperatorAlias', props.provider.id).then(aliases => {
        table.value.data = aliases
      })
    }

    const handleSubmit = async () => {
      const record = table.value.data[editableRow.value.index]

      record.is_new ? createOperatorAlias(record) : updateOperatorAlias(record)
    }

    const createOperatorAlias = async record => {
      await store
        .dispatch('providers/createOperatorAlias', {
          provider_id: props.provider.id,
          provider_alias: record.provider_alias,
          operator_id: record.operator.id,
        })
        .then(result => {
          const errors = result.response?.data?.errors
          if (errors) {
            useErrorNotification(errors)
            return
          }

          resetEditableRow()
          getOperatorAlias()
        })
    }

    const updateOperatorAlias = async record => {
      store
        .dispatch('providers/updateOperatorAlias', {
          id: record.id,
          provider_alias: record.provider_alias,
          operator_id: record.operator.id,
        })
        .then(result => {
          const errors = result.response?.data?.errors
          if (errors) {
            useErrorNotification(errors)
            return
          }

          resetEditableRow()
          getOperatorAlias()
        })
    }

    const deleteOperatorAlias = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'provider.confirmDialogOperatorAlias.title',
        message: 'provider.confirmDialogOperatorAlias.message',
      })

      if (dialog) {
        await store.dispatch('providers/deleteOperatorAlias', id).then(() => {
          resetEditableRow()
          getOperatorAlias()
        })
      }
    }

    const addAlias = () => {
      showSaveBtn.value = true

      table.value.data.push({
        is_new: true,
        provider_alias: '',
        operator: { id: '' },
      })

      const lastIndex = table.value.data.length - 1
      editableRow.value = { record: cloneDeep(table.value.data[lastIndex]), index: lastIndex }
    }

    const changeHandler = index => {
      if (editableRow.value.index !== null) {
        return
      }

      editableRow.value = {
        record: cloneDeep(table.value.data[index]),
        index: index,
      }

      showSaveBtn.value = true
    }

    const cancelСhanges = () => {
      const editableIndex = editableRow.value.index

      const isNewRecord = editableRow.value.record.is_new

      isNewRecord
        ? table.value.data.splice(editableIndex, 1)
        : (table.value.data[editableIndex] = editableRow.value.record)

      resetEditableRow()
      showSaveBtn.value = false
    }

    const resetEditableRow = () => {
      showSaveBtn.value = false
      editableRow.value = { record: null, index: null }
    }

    const getOptionsOperators = async () => {
      const operators = await store.dispatch('operators/getOperators', { full: false })
      options.value.operators = operators.data ? operators.data : []
    }

    getOperatorAlias()
    getOptionsOperators()

    return {
      table,
      editableRow,
      showSaveBtn,
      options,
      confirmDialog,
      deleteOperatorAlias,
      handleSubmit,
      addAlias,
      changeHandler,
      cancelСhanges,
      locking,
      t,
    }
  },
})
</script>
