export const enTrafficSource = {
  addSourceButton: 'Setup traffic source',
  addSource: 'Setup traffic source',
  editSource: 'Edit traffic source',
  copySource: 'Copy traffic source',

  id: 'ID',
  name: 'Name',
  author: 'Author',
  created_at: 'Setup date',
  status: 'Status',

  variablesMap: 'Field matching map',

  confirmDialog: {
    title: 'Delete traffic source',
    message: 'Are you sure you want to delete traffic source?',
  },

  confirmRestoreDialog: {
    title: 'Restore a traffic source',
    message: 'Do you really want to restore the traffic source?',
  },

  save: 'Save',
  cancel: 'Cancel',
}

export const ruTrafficSource = {
  addSourceButton: 'Добавить источник',
  addSource: 'Добавление источника трафика',
  editSource: 'Редактирование источника трафика',
  copySource: 'Скопировать источник трафика',

  id: 'ID',
  name: 'Название',
  author: 'Автор',
  created_at: 'Дата создания',
  status: 'Статус',

  variablesMap: 'Карта соответствия полей',

  confirmDialog: {
    title: 'Удаление источника трафика',
    message: 'Вы действительно хотите удалить источник трафика?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление источника трафика',
    message: 'Вы действительно хотите восстановить источник трафика?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',
}
