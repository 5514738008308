<template>
  <div class="v-my-traffic-source-form">
    <form @submit.prevent="handleSubmit">
      <BaseInput label="trafficSource.name" v-model="formData.name" :required="true" :errors="v$.name.$errors" />

      <div class="flex justify-between my-4">
        <h4 class="text-lg font-medium">{{ t('trafficSource.variablesMap') }}</h4>

        <button
          type="button"
          class="btn btn-success ml-3"
          @click="formData.variables_map.push({ name: '', value: '' })"
          :disabled="disabledAddButton"
          :title="disabledAddButton ? t('variablesMap.diasbledAddVariableTitle') : t('variablesMap.addVariableTitle')"
        >
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('variablesMap.addVariable') }}
        </button>
      </div>

      <div v-for="(variable, index) in formData.variables_map" :key="index">
        <div class="flex flex-row mb-3 items-center">
          <DatabaseIcon class="w-5 h-5 mr-2" />

          <div class="w-2/4">
            <BaseInput label="baseInput.label" v-model="formData.variables_map[index].name" />
          </div>

          <GitCommitIcon class="w-7 h-6" />

          <div class="w-2/4">
            <BaseInput label="baseInput.label" v-model="formData.variables_map[index].value" />
          </div>

          <button
            type="button"
            class="ml-3"
            :title="t('variablesMap.deleteVariable')"
            @click="formData.variables_map.splice(index, 1)"
          >
            <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-6" />
          </button>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('trafficSource.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('trafficSource.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, defineComponent, inject } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'MyTrafficSourceForm',
  props: {
    source: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const toast = useToast()
    const getCountUread = inject('injectGetCountUread')

    const formData = ref({
      id: props.source.id || '',
      name: props.source.name || '',
      variables_map: props.source.variables_map || [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const result = await v$.value.$validate()
      if (!result) {
        return
      }

      if (disabledAddButton.value) {
        toast.error(t('variablesMap.parameterNotFilled'), {
          closeOnClick: false,
        })

        return
      }

      props.source.id ? updateSources() : createSources()
    }

    const updateSources = async () => {
      const result = await store.dispatch('myTrafficSource/updateTrafficSource', formData.value)
      const errors = result.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      context.emit('refresh')
      context.emit('close')
    }

    const createSources = async () => {
      const result = await store.dispatch('myTrafficSource/createTrafficSource', formData.value)
      const errors = result.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      getCountUread()

      context.emit('refresh')
      context.emit('close')
    }

    const disabledAddButton = computed(() => {
      return formData.value.variables_map.some(
        variable => !variable.name.replace(/\s/g, '') || !variable?.value.replace(/\s/g, '')
      )
    })

    return {
      disabledAddButton,
      formData,
      handleSubmit,
      v$,
      t,
    }
  },
})
</script>
