<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addOperator">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('operators.addButtonOperator') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getOperators"
        @applyFilter=";(table.pagination.pageData.page = 1), getOperators()"
      >
        <BaseInput type="number" class="w-24" label="operators.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-52" label="operators.name" name="name" v-model="table.filter.name" />

        <VFieldSelect
          class="w-52"
          label="operators.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          v-model="table.filter.country"
          :clearable="true"
        />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getOperators"
        @edit="getOperator"
        @delete="deleteOperator"
        @updateSorting="sort => ((table.sorting = sort), getOperators())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="copyOperator(slotProps.id)">
            <Tippy :content="t('operators.copyOperator')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showModal"
      :hideScroll="operator.id ? false : true"
      @close="showModal = false"
      :textHeader="operator.id ? t('operators.editOperator') : t('operators.addOperator')"
      :recordId="operator.id"
    >
      <EditOperatorForm :operator="operator" @close="showModal = false" @refresh="getOperators" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import EditOperatorForm from './EditOperatorForm.vue'

export default defineComponent({
  name: 'Operators',
  components: {
    EditOperatorForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const route = useRoute()
    const store = useStore()
    const translation = useTranslation()

    const operators = ref([])
    const operator = ref({})

    const showModal = ref(false)
    const confirmDialog = ref(null)

    const options = ref({
      country: [],
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        country: route.query.country || '',
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('operators.id'), sortable: true, sort_name: 'id' },
        name: { label: t('operators.name'), sortable: true, sort_name: 'name' },
        alias: t('operators.alias'),
        created_at: { label: t('operators.created_at'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return operators.value.map(operator => {
          return {
            id: operator.id,
            name: `${operator.name} (${translation.getCountry(operator.country)})`,
            alias: `${operator.alias}.${operator.country.iso_name}`,
            created_at: new Date(operator.created_at).toLocaleString() || '',
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getOperators = () => {
      store
        .dispatch('operators/getOperators', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(result => {
          operators.value = result.data
          table.value.pagination.meta = result.meta
        })
    }

    const addOperator = () => {
      operator.value = {}
      showModal.value = true
    }

    const getOperator = id => {
      store.dispatch('operators/getOperator', id).then(result => {
        if (result.data) {
          operator.value = result.data
          showModal.value = true
        }
      })
    }

    const copyOperator = async id => {
      const response = await store.dispatch('operators/getOperator', id)

      if (response.data) {
        response.data = {
          ...response.data,
          id: '',
          name: `${response.data.name} | COPY`,
        }

        operator.value = response.data
        showModal.value = true
      }
    }

    const deleteOperator = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'operators.confirmDialog.title',
        message: 'operators.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('operators/deleteOperator', id).then(() => {
          getOperators()
        })
      }
    }

    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }

    getOperators()
    getCountriesOptions()

    return {
      t,
      table,
      translation,
      addOperator,
      getOperator,
      getOperators,
      copyOperator,
      deleteOperator,
      confirmDialog,
      options,
      operators,
      operator,
      showModal,
    }
  },
})
</script>
