import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getHeaders({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/blacklist/header', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getHeaderById(_, id) {
    try {
      const response = await http.get(`/blacklist/header/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addHeader(_, params) {
    try {
      return await http.post('/blacklist/header', params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateHeader(_, params) {
    try {
      return await http.put(`/blacklist/header/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteHeader(_, id) {
    try {
      return await http.delete(`/blacklist/header/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async importHeaders(_, file) {
    try {
      return await http.post('/blacklist/header/upload', file)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
