<template>
  <li class="mr-2">
    <a
      @click="setActiveTab"
      :class="{
        'text-theme-3 border-theme-3 active dark:text-theme-11 dark:border-theme-11 rounded-sm': modelValue === name,
        'hover:text-gray-900 hover:border-gray-400 dark:hover:text-gray-300 rounded-sm': modelValue !== name,
      }"
      class="cursor-pointer inline-flex p-4 rounded-t-lg border-b-2 border-transparent group"
      :key="modelValue"
    >
      <component
        v-if="icon"
        class="mr-2 w-5 h-5"
        :class="{
          'text-theme-3 group-hover:text-theme-3 dark:text-theme-11': modelValue === name,
          'text-gray-700 group-hover:text-gray-900 dark:text-gray-500 dark:group-hover:text-gray-300':
            modelValue !== name,
        }"
        :is="icon"
      />
      {{ label }}
    </a>
  </li>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VTab',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },

  setup(porps, context) {
    const setActiveTab = () => {
      context.emit('update:modelValue', porps.name)
    }

    return {
      setActiveTab,
    }
  },
})
</script>
