export const enConfirmDialog = {
  agree: 'Agree',
  approve: 'Approve',
  unApproved: 'Un approved',
  cancel: 'Cancel',
  title: 'Deleting',
  message: 'Do you really want to remove',
}

export const ruConfirmDialog = {
  agree: 'Подтвердить',
  approve: 'Разрешить',
  unApproved: 'Запретить',
  cancel: 'Отмена',
  title: 'Удаление',
  message: 'Вы действительно хотите удалить?',
}
