<template>
  <form @submit.prevent="submitRequisite" class="col-span-12 lg:col-span-4">
    <div class="mt-3">
      <label for="post-form-requisite" class="form-label">{{ t('paymentDetails.method') }}</label>
      <select
        id="post-form-requisite"
        v-model="formData.requisite"
        class="form-select p-3 w-full box border-gray-300"
        :error="v$.requisite.$errors.length > 0"
      >
        <option value="1">Webmoney-WEBMONEY</option>
        <option value="2">Наличные rub</option>
      </select>
    </div>
    <BaseInput label="paymentDetails.requisite" v-model="formData.method" :errors="v$.method.$errors" />

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
        {{ t('paymentDetails.cancel') }}
      </button>
      <button type="submit" class="btn btn-primary">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('paymentDetails.save') }}
      </button>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup(props, { emit }) {
    const formData = ref({
      requisite: '1',
      method: '',
    })

    const rules = computed(() => {
      return {
        requisite: {
          required,
        },
        method: {
          required,
        },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const submitRequisite = async () => {
      const result = await v$.value.$validate()
      if (!result) {
        return
      }
      emit('close-modal')
      formData.value = { requisite: '1', method: '' }
    }

    return {
      formData,
      v$,
      submitRequisite,
      t,
    }
  },
})
</script>
