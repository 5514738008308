import { createI18n } from 'vue-i18n'
import messages from './locales/locales.js'

const getUserLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage
  switch (userLang) {
    case 'ru-RU':
    case 'ru':
      return 'ru'
    default:
      return 'en'
  }
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: getUserLanguage(),
  fallbackLocale: 'en',
  messages: messages,
})
