<template>
  <div>
    <h4 class="text-lg font-medium my-4">{{ t('services.variablesMap') }}</h4>

    <div v-for="(variable, index) in variableList" :key="index">
      <div class="flex flex-row mb-3 items-center sm:px-1">
        <DatabaseIcon class="w-5 h-5 mr-2" />

        <div class="w-2/4">
          <BaseInput label="baseInput.label" v-model="variableList[index].name" :disabled="true" />
        </div>

        <GitCommitIcon class="w-7 h-6" />

        <div class="w-2/4">
          <BaseInput label="baseInput.label" v-model="variableList[index].value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ServiceVariableMap',
  props: {
    service: {
      type: Object,
      required: true,
    },
    providerId: {
      type: [Number, String],
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, context) {
    const store = useStore()
    const variableList = ref([])

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const generateVariablesMap = () => {
      if (!props.service.provider) {
        return
      }

      variableList.value = props.service.provider?.mandatory_variables.map(variable => {
        const serviceVariable = props.service.provider_variables.find(value => variable == value.name)

        return {
          name: variable,
          value: serviceVariable?.value || '',
        }
      })
    }

    const updateProviderVariable = id => {
      if (!id) {
        return
      }

      store.dispatch('providers/getProvider', id).then(result => {
        if (!result.data) {
          variableList.value = []
          return
        }

        variableList.value = result.data.mandatory_variables.map(variable => {
          return {
            name: variable,
            value: '',
          }
        })
      })
    }

    generateVariablesMap()

    watch(
      () => variableList.value,
      () => context.emit('update:modelValue', variableList.value),
      { deep: true }
    )

    watch(
      () => props.providerId,
      () => updateProviderVariable(props.providerId)
    )
    return {
      variableList,
      t,
    }
  },
})
</script>
