<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="openAddUserDialog">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('users.addUserButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getUsers"
        @applyFilter=";(table.pagination.pageData.page = 1), getUsers()"
      >
        <BaseInput type="number" class="w-24" label="users.id" name="id" v-model="table.filter.id" />

        <BaseInput label="users.email" name="name" iconLeft="MailIcon" v-model="table.filter.email" />

        <VFieldSelect
          :options="options.status"
          class="min-w-[180px]"
          label="users.status"
          optionName="description"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.status"
        />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getUsers"
        @edit="editUser"
        @delete="deleteUser"
        @restore="restoreUser"
        @updateSorting="sort => ((table.sorting = sort), getUsers())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="showRoleForm(slotProps.id)">
            <Tippy :content="t('users.roleSettings')">
              <SlidersIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-600" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showForm.user"
      @close="showForm.user = false"
      :textHeader="user.id ? t('users.editUser') : t('users.addUser')"
      :size="user.id ? 'sm' : 'md'"
      :recordId="user.id"
    >
      <EditUserDialog
        :user="user"
        @close="showForm.user = false"
        @updateUser="updateUser"
        @addUser="addUser"
        :serverError="serverError"
      />
    </VDialog>

    <VDialog
      size="sm"
      :showDialog="showForm.role"
      @close="showForm.role = false"
      :textHeader="t('users.roleSettings')"
      :recordId="user.id"
    >
      <UsersRoleForm :user="user" @close-modal="showForm.role = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import EditUserDialog from './EditUserDialog.vue'
import UsersRoleForm from './UsersRoleForm.vue'

export default defineComponent({
  name: 'Users',
  components: {
    EditUserDialog,
    UsersRoleForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()

    const confirmDialog = ref(null)
    const serverError = ref({})
    const getMonitoring = inject('injectMonitoring')
    const getMonitoringCount = inject('injectMonitoringCount')

    const users = ref([])
    const user = ref({})

    const showForm = ref({
      user: false,
      role: false,
    })

    const options = ref({
      status: [],
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        email: decodeURIComponent(route.query.email || '') || '',
        status: route.query.status || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: getMonitoringCount.value.users > 0 ? 'ASC' : 'DESC',
        order_by: getMonitoringCount.value.users > 0 ? 'status_id' : 'id',
      },
      columns: {
        id: { label: t('users.id'), sortable: true, sort_name: 'id' },
        name: { label: t('users.name'), sortable: true, sort_name: 'name' },
        email: { label: t('users.email'), type: 'email' },
        phone: t('account.phone'),
        telegram: { label: t('account.telegram'), type: 'telegram' },
        skype: { label: t('account.skype'), type: 'skype' },
        status: { label: t('users.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return users.value.map(user => {
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            phone: user.profile?.phone,
            telegram: user.profile?.telegram,
            skype: user.profile?.skype,
            status: user.status?.id,
            is_deleted: !!user.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getUsers = async () => {
      const response = await store.dispatch('users/getUsers', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response?.data.data) {
        users.value = response.data.data
        table.value.pagination.meta = response.data.meta
      }
    }

    const openAddUserDialog = () => {
      user.value = {}
      showForm.value.user = true
    }

    const addUser = formData => {
      store.dispatch('users/addUser', formData).then(result => {
        if (result.response?.data?.errors) {
          serverError.value = result.response.data.errors
          return
        }
        getUsers()
        showForm.value.user = false
        getMonitoring()
      })
    }

    const editUser = async id => {
      const result = await store.dispatch('users/getUserById', id)

      if (result.data.data) {
        user.value = result.data.data
        showForm.value.user = true
      }
    }

    const showRoleForm = async id => {
      const result = await store.dispatch('users/getUserById', id)

      if (result.data.data) {
        user.value = result.data.data
        showForm.value.role = true
      }
    }

    const deleteUser = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'users.confirmDialog.title',
        message: 'users.confirmDialog.message',
        propName: users.value.find(el => el.id == id).name,
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('users/deleteUser', id).then(() => {
          getUsers()
          getMonitoring()
        })
      }
    }

    const updateUser = async userInfo => {
      store
        .dispatch('users/updateUserInfo', {
          id: userInfo.id,
          userInfo: userInfo,
        })
        .then(() => {
          getUsers()
          showForm.value.user = false
          getMonitoring()
        })
    }

    const restoreUser = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'users.confirmRestoreDialog.title',
        message: 'users.confirmRestoreDialog.message',
      })

      if (dialog) {
        const restore = await store.dispatch('users/restoreUser', id)

        if (restore) {
          getUsers()
          getMonitoring()
        }
      }
    }

    const getStatusesOptions = async () => {
      const statuses = await store.dispatch('users/getUserStatuses')
      options.value.status = statuses ? statuses : []
    }

    getStatusesOptions()

    getUsers()

    return {
      users,
      user,
      table,
      showForm,
      confirmDialog,
      options,
      serverError,

      getUsers,
      editUser,
      showRoleForm,
      openAddUserDialog,
      addUser,
      deleteUser,
      updateUser,
      restoreUser,
      t,
    }
  },
})
</script>
