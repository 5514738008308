<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addEnrollmentRule">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('enrollmentRules.addEnrollmentRuleButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getEnrollmentRules"
        @applyFilter=";(table.pagination.pageData.page = 1), getEnrollmentRules()"
      >
        <BaseInput type="number" class="w-24" label="enrollmentRules.id" name="id" v-model="table.filter.id" />

        <BaseInput label="enrollmentRules.name" name="name" v-model="table.filter.name" />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :columns="table.headers"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :sort="table.sorting"
        :clickableRow="true"
        @getData="getEnrollmentRules"
        @edit="editEnrollmentRule"
        @delete="deleteEnrollmentRule"
        @restore="restoreEnrollmentRule"
        @updateSorting="sort => ((table.sorting = sort), getEnrollmentRules())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="copyEnrollmentRule(slotProps.id)">
            <Tippy :content="t('enrollmentRules.copyEnrollmentRule')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      size="sm"
      :showDialog="showModal"
      @close="showModal = false"
      :textHeader="enrollmentRule.id ? t('enrollmentRules.editEnrollmentRule') : t('enrollmentRules.addEnrollmentRule')"
      :recordId="enrollmentRule.id"
    >
      <EnrollmentRuleForm
        @close-modal="showModal = false"
        @refresh="getEnrollmentRules"
        :enrollmentRule="enrollmentRule"
      />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import EnrollmentRuleForm from './EnrollmentRuleForm.vue'

export default defineComponent({
  name: 'EnrollmentRules',
  components: {
    EnrollmentRuleForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()

    const showModal = ref(false)
    const confirmDialog = ref(null)

    const enrollmentRules = ref([])
    const enrollmentRule = ref({})

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      headers: {
        id: { label: t('enrollmentRules.id'), sortable: true, sort_name: 'id' },
        name: { label: t('enrollmentRules.name'), sortable: true, sort_name: 'name' },
        is_on_rebill: { label: t('enrollmentRules.isOnRebill'), sortable: true, sort_name: 'is_on_rebill' },
        percent: { label: t('enrollmentRules.percent'), sortable: true, sort_name: 'percent' },
        unsubscription_timeout: {
          label: t('enrollmentRules.unsubscriptionTimeout'),
          sortable: true,
          sort_name: 'unsubscription_timeout',
        },
        subscriber_unique_period: {
          label: t('enrollmentRules.subscriberUniquePeriod'),
          sortable: true,
          sort_name: 'subscriber_unique_period',
        },
        created_at: { label: t('enrollmentRules.createdAt'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return enrollmentRules.value.map(enrollmentRule => {
          return {
            id: enrollmentRule.id,
            name: enrollmentRule.name,
            is_on_rebill: enrollmentRule.is_on_rebill ? t('enrollmentRules.yes') : t('enrollmentRules.no'),
            percent: `${enrollmentRule.percent}%`,
            unsubscription_timeout: enrollmentRule.unsubscription_timeout,
            subscriber_unique_period: enrollmentRule.subscriber_unique_period,
            created_at: new Date(enrollmentRule.created_at).toLocaleString() || '',
            is_deleted: !!enrollmentRule.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getEnrollmentRules = async () => {
      const enrollmentRuleList = await store.dispatch('enrollmentRules/getEnrollmentRules', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (enrollmentRuleList) {
        enrollmentRules.value = enrollmentRuleList.data
        table.value.pagination.meta = enrollmentRuleList.meta
      }
    }

    const addEnrollmentRule = () => {
      enrollmentRule.value = {}
      showModal.value = true
    }

    const editEnrollmentRule = async id => {
      const record = await store.dispatch('enrollmentRules/getEnrollmentRuleById', id)

      if (record) {
        enrollmentRule.value = record?.data
        showModal.value = true
      }
    }

    const copyEnrollmentRule = async id => {
      const response = await store.dispatch('enrollmentRules/getEnrollmentRuleById', id)

      if (response.data) {
        response.data = {
          ...response.data,
          id: '',
          name: `${response.data.name} | COPY`,
        }

        enrollmentRule.value = response.data
        showModal.value = true
      }
    }

    const deleteEnrollmentRule = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'enrollmentRules.confirmDialog.title',
        message: 'enrollmentRules.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('enrollmentRules/deleteEnrollmentRule', id).then(() => {
          getEnrollmentRules()
        })
      }
    }

    const restoreEnrollmentRule = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'enrollmentRules.confirmRestoreDialog.title',
        message: 'enrollmentRules.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('enrollmentRules/restoreEnrollmentRule', id).then(() => {
          getEnrollmentRules()
        })
      }
    }

    getEnrollmentRules()

    return {
      table,
      enrollmentRules,
      enrollmentRule,
      showModal,
      confirmDialog,
      getEnrollmentRules,
      addEnrollmentRule,
      copyEnrollmentRule,
      editEnrollmentRule,
      deleteEnrollmentRule,
      restoreEnrollmentRule,
      t,
    }
  },
})
</script>
