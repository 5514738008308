// import Chart from './chart/Main.vue'
// import GoogleMapLoader from './google-map-loader/Main.vue'
// import Highlight from './highlight/Main.vue'
// import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
// import ClassicEditor from './ckeditor/ClassicEditor.vue'
import VAccordion from '@/global-components/VAccordion'
import BaseInput from '@/global-components/base-input/index.vue'
import GroupRadio from '@/global-components/group-radio'
import VFieldSelect from '@/global-components/VFieldSelect.vue'
import VFieldMultiSelect from '@/global-components/VFieldMultiSelect.vue'
import BaseCheckbox from '@/global-components/base-checkbox'
import GroupCheckbox from '@/global-components/group-checkbox/index.vue'
import VTable from '@/global-components/Table/VTable.vue'
import VTableFilter from '@/global-components/Table/VTableFilter.vue'
import VConfirmDialog from '@/global-components/VConfirmDialog.vue'
import VDialog from '@/global-components/VDialog.vue'
import VFieldTextarea from '@/global-components/VFieldTextarea.vue'
import VFieldCheckbox from '@/global-components/VFieldCheckbox.vue'
import VFieldDatepicker from '@/global-components/VFieldDatepicker.vue'
import VFileUploader from '@/global-components/VFileUploader.vue'
import VTabs from '@/global-components/VTabs/VTabs.vue'
import VTab from '@/global-components/VTabs/VTab.vue'
import VQuillEditor from '@/global-components/VQuillEditor.vue'
// import VButton from '@/global-components/VButton.vue'
// import Dropzone from './dropzone/Main.vue'
// import FullCalendar from './calendar/Main.vue'
// import FullCalendarDraggable from './calendar/Draggable.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'

export default app => {
  // app.component('Chart', Chart)
  // app.component('GoogleMapLoader', GoogleMapLoader)
  // app.component('Highlight', Highlight)
  // app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  // app.component('Dropzone', Dropzone)
  // app.component('ClassicEditor', ClassicEditor)
  // app.component('FullCalendar', FullCalendar)
  // app.component('FullCalendarDraggable', FullCalendarDraggable)
  app.component('VAccordion', VAccordion)
  app.component('BaseInput', BaseInput)
  app.component('GroupRadio', GroupRadio)
  app.component('VFieldSelect', VFieldSelect)
  app.component('VFieldMultiSelect', VFieldMultiSelect)
  app.component('BaseCheckbox', BaseCheckbox)
  app.component('GroupCheckbox', GroupCheckbox)
  app.component('VTable', VTable)
  app.component('VTableFilter', VTableFilter)
  app.component('VConfirmDialog', VConfirmDialog)
  app.component('VDialog', VDialog)
  app.component('VFieldTextarea', VFieldTextarea)
  app.component('VFieldCheckbox', VFieldCheckbox)
  app.component('VFieldDatepicker', VFieldDatepicker)
  app.component('VFileUploader', VFileUploader)
  app.component('VTabs', VTabs)
  app.component('VTab', VTab)
  app.component('VQuillEditor', VQuillEditor)
  // app.component('VButton', VButton)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
