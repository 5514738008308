import { formatNumber } from '@/utils/number.ts'
import { isJSON } from '@/utils/json'
import { QUERY_IDS } from './queryIds.js'

const QUERYS = {
  statistics: {
    id: QUERY_IDS.statistics,
    columns: {
      number: ['request_count', 'traf_count', 'subs', 'cancels', 'bills', 'leads'],
      object: ['income_partners', 'income'],
    },
  },
  finance: {
    id: QUERY_IDS.finance,
    columns: {
      number: ['request_count', 'traf_count', 'subs', 'cancels', 'bills', 'leads'],
      object: ['income_partners', 'income'],
    },
  },
  cohorts: {
    id: QUERY_IDS.cohorts,
    columns: {
      number: [
        'Подписки',
        'Оплаты',
        'Дубликаты',
        'Лиды',
        'Отписки',
        'Оплаты (1d)',
        'Оплаты (3d)',
        'Оплаты (7d)',
        'Оплаты (14d)',
        'Оплаты (30d)',
        'Оплаты (60d)',
      ],
      object: [
        'CPA',
        'REVENUE',
        'REVENUE (1d)',
        'REVENUE (3d)',
        'REVENUE (7d)',
        'REVENUE (14d)',
        'REVENUE (30d)',
        'REVENUE (60d)',
        'REVENUE (90d)',
        'REVENUE (120d)',
        'REVENUE (150d)',
        'REVENUE (180d)',
        'REVENUE (1y)',
      ],
    },
  },
}

let total = {}

export const generateTotalRow = (rows, query) => {
  total = {}

  for (const queryData of Object.values(QUERYS)) {
    if (queryData.id === query.value.id && rows.length) {
      preparationTotalRow(rows[0])

      rows.forEach(row => {
        processingColumn(row, queryData.columns)
      })

      formattingСurrency()

      return total
    }
  }

  return total
}

const preparationTotalRow = firstRow => {
  Object.keys(firstRow).forEach(key => (total[key] = ''))

  if ('group_by' in total) {
    total.group_by = 'TOTAL'
    return
  }

  if ('#' in total) {
    total['#'] = 'TOTAL'
    return
  }
}

const processingColumn = (row, columns) => {
  Object.entries(row).forEach(([key, value]) => {
    // Number column
    if (columns.number.includes(key)) {
      total[key] = Number(total[key]) + Number(value)
    }

    // Object column
    if (columns.object.includes(key)) {
      if (isJSON(value) || typeof value === 'object') {
        const incomes = isJSON(value) ? JSON.parse(value) : value

        switch (typeof incomes) {
          case 'object':
            Object.entries(incomes).forEach(([currency, sum]) => {
              if (typeof total[key] === 'string') {
                total[key] = {}
              }

              const existCurrency = !!total[key][currency]
              total[key][currency] = existCurrency ? Number(total[key][currency]) + Number(sum) : Number(sum)
            })
            break
          case 'number':
            total[key] = Number(total[key]) + Number(incomes)
            break
        }
      }
    }
  })
}

const formattingСurrency = () => {
  Object.entries(total).forEach(([key, value]) => {
    switch (typeof value) {
      case 'number':
        total[key] = formatNumber(total[key])
        break
      case 'object':
        Object.keys(total[key]).forEach(currency => {
          total[key][currency] = formatNumber(total[key][currency])
        })

        total[key] = JSON.stringify(total[key])
        break
    }
  })
}
