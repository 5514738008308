import { http } from '@/api/http'

const state = {}

const actions = {
  async getMonitoring() {
    try {
      const response = await http.get(`/monitoring`)
      return response.data.data
    } catch (error) {
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
