<template>
  <div class="v-offer-wall">
    <div class="box p-4 mt-3">
      <div class="flex flex-row justify-end items-center">
        <Tippy :content="t('offerWall.grid')">
          <button class="btn mr-2" :class="{ 'btn-secondary': showGrid }" @click="onChangeGrid(true)">
            <GridIcon class="w-5 h-5" />
          </button>
        </Tippy>

        <Tippy :content="t('offerWall.list')">
          <button class="btn" :class="{ 'btn-secondary': !showGrid }" @click="onChangeGrid(false)">
            <ListIcon class="w-5 h-5" />
          </button>
        </Tippy>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getOffers"
        @applyFilter=";(table.pagination.pageData.page = 1), getOffers()"
      >
        <BaseInput type="number" class="w-24" label="offer.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-50" label="offer.name" name="name" v-model="table.filter.name" />

        <VFieldSelect
          v-model="table.filter.country"
          :options="options.country"
          label="offer.country"
          class="w-48"
          :optionName="translation.getCountryOptionName()"
          :clearable="true"
        />

        <VFieldSelect
          v-model="table.filter.flow_type"
          :options="options.flowTypes"
          label="offer.flowType"
          class="w-36"
          :clearable="true"
          :hideSearch="true"
        />

        <VFieldSelect
          v-model="table.filter.category"
          :options="options.categorys"
          optionName="en_name"
          label="offer.category"
          :clearable="true"
          class="w-52"
        />

        <VFieldSelect
          v-model="table.filter.operator"
          :options="options.operators"
          label="offer.operator"
          class="w-52"
          :clearable="true"
        />
      </VTableFilter>
    </div>

    <div v-if="loading" class="flex justify-center mt-16">
      <LoadingIcon icon="tail-spin" class="w-8 h-8" />
    </div>
    <div v-else>
      <div v-if="showGrid">
        <div v-if="!offers.length">
          <div class="alert bg-theme-12 bg-opacity-40 show flex items-center mt-5">
            <AlertCircleIcon class="w-6 h-6 mr-2" />
            {{ t('landingWall.noDataMessage') }}
          </div>
        </div>
        <div v-else class="v-offer-wall__card-list gap-5 mt-5">
          <OfferCard
            v-for="offer in offers"
            :key="offer.id"
            :offer="offer"
            :loading="loading"
            @clickCard="showOfferDetail"
            @addCampaign="showOfferDetail"
            @requestAccess="requestAccess"
          />
        </div>
        <VPagination
          class="box px-4 py-2 mt-5"
          :pagination="table.pagination.meta"
          @send-page="data => ((table.pagination.pageData = data), getOffers())"
        />
      </div>

      <div v-if="!showGrid" class="box p-4 mt-3">
        <VTable
          :sort="table.sorting"
          :columns="table.columns"
          :data="table.data"
          :paginationOptions="table.pagination.meta"
          :hideButtons="['edit', 'delete']"
          :clickableRow="true"
          @getData="getOffers"
          @updateSorting="sort => ((table.sorting = sort), getOffers())"
          @updatePageData="data => (table.pagination.pageData = data)"
        >
          <template v-slot:buttons="slotProps">
            <span v-if="isOnDemandOffer(slotProps.id) && slotProps.record.access_status !== 2">
              <span
                v-if="slotProps.record.access_status === 1"
                class="bg-orange-300 text-orange-800 text-xs font-medium px-3 py-1 rounded mr-2 select-none"
              >
                {{ t('common.moderation') }}
                <CheckIcon class="w-4 h-4" />
              </span>
              <button v-else class="mr-2" type="button" @click.stop="requestAccess(slotProps.id)">
                <Tippy :content="t('offerWall.requestAccess')">
                  <UnlockIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-orange-700" />
                </Tippy>
              </button>
            </span>
            <span v-else>
              <button class="mr-2" type="button" @click.stop="showOfferDetail(slotProps.id, true)">
                <Tippy :content="t('offerWall.createCompany')">
                  <PlusIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
                </Tippy>
              </button>
            </span>

            <button type="button" @click.stop="showOfferDetail(slotProps.id)">
              <Tippy :content="t('offerWall.info')">
                <InfoIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-3" />
              </Tippy>
            </button>
          </template>
        </VTable>
      </div>
    </div>

    <VDialog :showDialog="showDialog.main" @close="closePopup" :textHeader="offer.name" :recordId="offer.id">
      <DetailOfferDialog
        :offer="offer"
        :addCampaign="addCampaign"
        @requestAccess="requestAccess"
        @close-modal="closePopup"
        @openLandingDialog="campaignId => showLandingsDialog(campaignId)"
      />
    </VDialog>

    <VDialog
      :showDialog="showDialog.landing"
      @close="showDialog.landing = false"
      :textHeader="t('campaign.landingManagement')"
      :recordId="campaign.id"
    >
      <CampaignLandingsDialog :campaign="campaign" :isPartner="true" @close="showDialog.landing = false" />
    </VDialog>
  </div>
</template>

<script>
import { computed, defineComponent, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { convertAmountToDisplay } from '@/utils/amountConverter'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/hooks/useTranslation'
import { OFFER_WALL } from '@/consts/localStorage'
import OfferCard from './OfferCard.vue'
import DetailOfferDialog from './DetailOfferDialog.vue'
import VPagination from '@/global-components/Table/VPagination.vue'
import CampaignLandingsDialog from '../Campaigns/CampaignLandingsDialog.vue'

export default defineComponent({
  name: 'OfferWall',
  components: {
    OfferCard,
    DetailOfferDialog,
    VPagination,
    CampaignLandingsDialog,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const translation = useTranslation()
    const getCountUread = inject('injectGetCountUread')

    const loading = ref(true)

    const offers = ref([])
    const offer = ref({})
    const campaign = ref({})

    const addCampaign = ref(false)
    const showGrid = ref(
      localStorage.getItem(OFFER_WALL.grid) ? JSON.parse(localStorage.getItem(OFFER_WALL.grid)) : true
    )
    const paginationOptions = ref([])

    const showDialog = ref({
      main: false,
      landing: false,
    })

    const options = ref({
      country: [],
      flowTypes: [],
      categorys: [],
      operators: [],
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        country: route.query.country || '',
        model: route.query.model || '',
        flow_type: route.query.flow_type || '',
        category: route.query.category || '',
        operator: route.query.operator || '',
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('offerWall.id'), sortable: true, sort_name: 'id' },
        image: '',
        name: { label: t('offerWall.name'), sortable: true, sort_name: 'name' },
        flowType: { label: t('offerWall.flowType'), sortable: true, sort_name: 'flow_type_id' },
        category: t('offerWall.category'),
        qtyLandings: { label: t('offerWall.qtyLandings'), sortable: true, sort_name: 'landings_count' },
        payout: 'Payout',
        country: { label: t('offerWall.country'), sortable: true, sort_name: 'country_id' },
      },
      data: computed(() => {
        return offers.value.map(offer => {
          return {
            id: offer.id,
            image: { is_image: true, path: offer.image },
            name: offer.name,
            flowType: offer.flow_type.name,
            category: offer.category?.en_name,
            qtyLandings: offer.count_landings,
            payout: `CPA: ${offer.payout_cpa?.count_payouts > 1 ? 'up to:' : ''} ${offer.payout_cpa?.sum || '-'} ${offer
              .payout_cpa?.currency || ''}`,
            country: translation.getCountry(offer.country),
            access_status: offer.access_status || null,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getOffers = async () => {
      loading.value = true
      const response = await store.dispatch('offerWall/getOffers', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
        active: true,
        wall: true,
      })

      if (response.data?.data) {
        offers.value = response.data.data
        table.value.pagination.meta = response.data.meta

        getCountLandings()
        getPayoutOffer()
        await getAccessStatus()
      }

      loading.value = false
    }

    const showOfferDetail = (id, creation = false) => {
      addCampaign.value = creation
      router.push({ params: { id: id } })

      store.dispatch('offerWall/getOfferById', id).then(result => {
        offer.value = result.data?.data ? result.data?.data : {}
        showDialog.value.main = true
      })
    }

    const requestAccess = async offerId => {
      if (!offerId) {
        return
      }

      const access = await store.dispatch('access/addCabinetAccess', {
        entity_type_id: 1, // offer
        entity_id: offerId,
      })

      if (access) {
        const errors = access.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        getAccessStatus()
        getCountUread()
      }
    }

    const getCountLandings = () => {
      offers.value.forEach(async (offer, index) => {
        const landings = await store.dispatch('offerWall/getLandingsToOffer', offer.id)

        if (landings) {
          offers.value[index].count_landings = landings.data.data.length || 0
        }
      })
    }

    const getPayoutOffer = () => {
      offers.value.forEach(async (offer, index) => {
        const operators = await store.dispatch('offerWall/getOperatorsByOffer', offer.id)

        let payout = {
          count: 0,
          upTo: 0,
        }

        if (operators.data) {
          operators.data.operators.forEach(async offerOperator => {
            const servicesOperators = await store.dispatch(
              'services/getCabinetOperatorsByService',
              offerOperator.service.id
            )

            if (servicesOperators?.data) {
              const servicesOperator = servicesOperators.data.operators.find(
                operator => offerOperator.operator.id == operator.operator.id
              )

              if (servicesOperator) {
                const cpaRate = offerOperator.cpa_partner_rate?.sum
                payout.count++

                if (cpaRate > payout.upTo) {
                  payout.upTo = cpaRate

                  const currencyCpa =
                    offerOperator.cpa_partner_rate.currency !== 0
                      ? await store.dispatch('currency/getCurrencyById', offerOperator.cpa_partner_rate.currency)
                      : ''

                  offers.value[index].payout_cpa = {
                    sum: convertAmountToDisplay(cpaRate) || '',
                    currency: currencyCpa.data?.iso_name || '',
                    count_payouts: payout.count,
                  }
                }
              }
            }
          })
        }
      })
    }

    const getAccessStatus = async () => {
      const accessUserOffers = await store.dispatch('access/getAccessUserOffers')

      offers.value.forEach(offer => {
        const key = `offer-${offer.id}`
        if (key in accessUserOffers) {
          offer.access_status = accessUserOffers[key]?.status_id || null
        }
      })
    }

    const showLandingsDialog = id => {
      store.dispatch('myCampaigns/getCampaignById', id).then(result => {
        if (result.data?.data) {
          campaign.value = result.data.data
          showDialog.value.landing = true
        }
      })
    }

    const onChangeGrid = value => {
      showGrid.value = value
      localStorage.setItem(OFFER_WALL.grid, value)
    }

    const closePopup = () => {
      showDialog.value.main = false
      router.push({ params: { id: '' } })
    }

    if (route.params.id) {
      showOfferDetail(route.params.id)
    }

    const isOnDemandOffer = id => {
      return offers.value.some(offer => offer.id === id && offer.privacy_type.name === 'OnDemand')
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getFlowTypesOptions = async () => {
      const flowTypes = await store.dispatch('flowTypes/getFlowTypes')
      options.value.flowTypes = flowTypes.data ? flowTypes.data : []
    }
    const getCategoriesOptions = async () => {
      const categories = await store.dispatch('offers/getCategories')
      options.value.categorys = categories ? categories : []
    }
    const getOptionsOperators = async () => {
      const operators = await store.dispatch('operators/getCabinetOperators', { full: false })
      options.value.operators = operators.data ? operators.data : []
    }

    getOffers()

    // Dropdown options
    getCountriesOptions()
    getFlowTypesOptions()
    getCategoriesOptions()
    getOptionsOperators()

    return {
      table,
      options,
      offers,
      offer,
      loading,
      showGrid,
      addCampaign,
      showLandingsDialog,
      campaign,
      showDialog,
      showOfferDetail,
      requestAccess,
      getOffers,
      isOnDemandOffer,
      closePopup,
      onChangeGrid,
      paginationOptions,
      t,
      translation,
    }
  },
})
</script>

<style lang="scss">
.v-offer-wall {
  &__card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &__image {
    width: 40px;
    height: 40px;
  }
}
</style>
