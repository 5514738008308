export const enLandingWall = {
  list: 'List',
  grid: 'Wall',

  id: 'ID',
  name: 'Name',
  country: 'Country',
  flowType: 'Flow type',
  category: 'Category',
  offer: 'Offer',
  offers: 'Offers',
  qtyOffers: 'Qty offers',

  noDataMessage: 'No data found',
  requestAccess: 'Request access',
  accessIsAllowed: 'Access is allowed',
  accessRequested: 'Access requested',
  accessDenied: 'Access is denied',
  infoLanding: 'Landing Information',
  accessRequestedToast: 'Landing access successfully requested',
  basicInformation: 'Basic information',
  alphaCode: 'Alpha code',
  access: 'Access',
  info: 'Information',
  setupCampaign: 'Setup campaign',

  requested: 'Requested',
  allowed: 'Allowed',
  forbidden: 'Forbidden',
  notRequested: 'Not requested',

  close: 'Close',
}

export const ruLandingWall = {
  list: 'Список',
  grid: 'Сетка',

  id: 'ID',
  name: 'Название',
  country: 'Страна',
  flowType: 'Способ оплаты',
  category: 'Категория',
  offer: 'Оффер',
  offers: 'Офферы',
  qtyOffers: 'Кол-во офферов',

  noDataMessage: 'Данных не обнаружено',
  requestAccess: 'Запросить доступ',
  accessIsAllowed: 'Доступ разрешен',
  accessRequested: 'Доступ запрошен',
  accessDenied: 'Доступ запрещен',
  infoLanding: 'Информация лендинга',
  accessRequestedToast: 'Доступ к лендингу успешно запрошен',
  basicInformation: 'Основная информация',
  alphaCode: 'Код страны',
  access: 'Доступ',
  info: 'Информация',
  setupCampaign: 'Создать кампанию',

  requested: 'Запрошен',
  allowed: 'Разрешен',
  forbidden: 'Запрещен',
  notRequested: 'Не запрошен',

  close: 'Закрыть',
}
