<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addCampaign">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('myCampaign.addCampaignButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getCampaigns"
        @applyFilter=";(table.pagination.pageData.page = 1), getCampaigns()"
      >
        <BaseInput type="number" class="w-24" label="myCampaign.id" name="id" v-model="table.filter.id" />

        <BaseInput label="campaign.name" name="name" v-model="table.filter.name" />

        <BaseInput
          class="w-52"
          iconLeft="HashIcon"
          label="myCampaign.campaignHash"
          name="hash"
          v-model="table.filter.hash"
        />

        <VFieldSelect
          v-model="table.filter.offer"
          :options="options.offers"
          class="w-60"
          label="myCampaign.offer"
          :clearable="true"
          :showId="true"
        />

        <VFieldSelect
          v-model="table.filter.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          class="w-60"
          label="myCampaign.country"
          :clearable="true"
        />

        <VFieldSelect
          v-model="table.filter.model"
          :options="options.paymentModels"
          class="w-32"
          label="myCampaign.paymentModel"
          :clearable="true"
          :hideSearch="true"
        />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getCampaigns"
        @edit="showCampaignForm"
        @delete="deleteCampaign"
        @updateSorting="sort => ((table.sorting = sort), getCampaigns())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="showDeductions(slotProps.id)">
            <Tippy :content="t('myCampaign.deductions')">
              <DollarSignIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showCampaignLandings(slotProps.id)">
            <Tippy :content="t('campaign.landingManagement')">
              <TrelloIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-35" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyCampaignUrl(slotProps.record)">
            <Tippy :content="t('myCampaign.copyUrlCampaign')">
              <LinkIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-34" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog.form"
      @close="closeCampaignForm"
      :textHeader="campaign.id ? t('myCampaign.editCampaign') : t('myCampaign.addCampaign')"
      :recordId="campaign.id"
      :status="campaign.status?.id"
    >
      <EditCampaignForm
        :campaign="campaign"
        @close="closeCampaignForm"
        @refresh="getCampaigns"
        @openLandingDialog="id => showCampaignLandings(id)"
      />
    </VDialog>

    <VDialog
      size="sm"
      :showDialog="showDialog.deduction"
      @close="showDialog.deduction = false"
      :textHeader="t('myCampaign.deductions')"
      :recordId="campaign.id"
    >
      <MyCampaignsDeductions :campaign="campaign" @close="showDialog.deduction = false" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.landing"
      @close="showDialog.landing = false"
      :textHeader="t('campaign.landingManagement')"
      :recordId="campaign.id"
    >
      <CampaignLandingsDialog :campaign="campaign" :isPartner="true" @close="showDialog.landing = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/hooks/useTranslation'
import EditCampaignForm from './EditMyCampaignForm.vue'
import MyCampaignsDeductions from './MyCampaignsDeductions.vue'
import CampaignLandingsDialog from '../Campaigns/CampaignLandingsDialog.vue'

export default defineComponent({
  components: {
    EditCampaignForm,
    MyCampaignsDeductions,
    CampaignLandingsDialog,
  },
  name: 'MyCampaigns',

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const toast = useToast()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const translation = useTranslation()

    const campaigns = ref([])
    const campaign = ref({})

    const showDialog = ref({
      main: false,
      deduction: false,
      landing: false,
    })

    const confirmDialog = ref(null)

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: route.query.name || '',
        hash: decodeURIComponent(route.query.hash || '') || '',
        offer: route.query.offer || '',
        country: route.query.country || '',
        model: route.query.model || '',
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('myCampaign.id'), sortable: true, sort_name: 'id' },
        name: { label: t('myCampaign.title'), sortable: true, sort_name: 'name' },
        offer: t('myCampaign.offer'),
        domain: t('myCampaign.domain'),
        payment_model: t('myCampaign.paymentModel'),
        created_at: { label: t('myCampaign.createdAt'), sortable: true, sort_name: 'created_at' },
        status: { label: t('myCampaign.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return campaigns.value.map(campaign => {
          return {
            id: campaign.id,
            name: campaign.name,
            offer: campaign.offer?.name,
            domain: campaign.domain?.name,
            payment_model: campaign.payment_model?.name,
            created_at: new Date(campaign.created_at).toLocaleString() || '',
            url: campaign.url,
            status: campaign.status?.id,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const options = ref({
      offers: [],
      country: [],
      paymentModels: [],
    })

    const getCampaigns = () => {
      store
        .dispatch('myCampaigns/getCampaigns', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(result => {
          if (result?.data) {
            campaigns.value = result.data
            table.value.pagination.meta = result.meta
          }
        })
    }

    const showCampaignForm = async id => {
      const response = await store.dispatch('myCampaigns/getCampaignById', id)

      if (response) {
        router.push({ params: { id: id } })
        campaign.value = response.data?.data
        showDialog.value.form = true
      }
    }

    const closeCampaignForm = () => {
      showDialog.value.form = false
      router.push({ params: { id: '' } })
    }

    const deleteCampaign = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'myCampaign.confirmDialog.title',
        message: 'myCampaign.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('myCampaigns/deleteCampaign', id).then(() => {
          getCampaigns()
        })
      }
    }

    const addCampaign = () => {
      campaign.value = {}
      showDialog.value.form = true
    }

    const showDeductions = async id => {
      const response = await store.dispatch('myCampaigns/getCampaignById', id)

      if (response.data.data) {
        campaign.value = response.data.data
        showDialog.value.deduction = true
      }
    }

    const showCampaignLandings = async id => {
      const response = await store.dispatch('myCampaigns/getCampaignById', id)

      if (response.data.data) {
        campaign.value = response.data.data
        showDialog.value.landing = true
      }
    }

    const copyCampaignUrl = async campaign => {
      try {
        await navigator.clipboard.writeText(campaign.url || '')

        toast.success(t('myCampaign.campaignUrlMessage'), {
          closeOnClick: false,
        })
      } catch (error) {
        console.error(error)
        toast.error(t('myCampaign.copyUrlErrorMessage'), {
          closeOnClick: false,
        })
      }
    }

    if (route.params.id) {
      showCampaignForm(route.params.id)
    }

    const getOffersOptions = async () => {
      const offers = await store.dispatch('offerWall/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getPaymentModelOptions = async () => {
      const paymentModels = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModels = paymentModels.data ? paymentModels.data : []
    }

    getCampaigns()

    // dropdown options
    getOffersOptions()
    getCountriesOptions()
    getPaymentModelOptions()

    return {
      t,
      translation,
      campaigns,
      campaign,
      showDialog,
      confirmDialog,
      table,
      options,

      getCampaigns,
      addCampaign,
      showCampaignForm,
      closeCampaignForm,
      deleteCampaign,
      copyCampaignUrl,

      showDeductions,
      showCampaignLandings,
    }
  },
})
</script>
