export const enSideMenu = {
  myOfferWall: 'Offerwall',
  landingWall: 'Landing wall',
  notifications: 'Notifications',
  dashboards: 'Dashboards',

  // Moderation
  moderation: 'Moderation',
  settings: 'Settings',
  cabinet: 'Account',
  users: 'Users',
  campaigns: 'Campaigns',
  settingProfile: 'Account settings',
  trafficSource: 'Traffic source',
  trafficCallback: 'Callback',
  trafficBack: 'Trafficback',
  domains: 'Domains',
  accessManagement: 'Access management',

  // Settings
  operators: 'Operators',
  providers: 'Providers',
  offers: 'Offers',
  enrollmentRules: 'Enrollment rules',
  landings: 'Lending pages',
  prelanding: 'Prelandings',
  services: 'Services',
  roles: 'Roles',
  blackLists: 'Blacklist',
  msisdn: 'MSISDN',
  xRequestedWith: 'X-Requested-with',
  referer: 'Referer',
  ipAddress: 'IP address',
  userAgent: 'User Agent',
  source: 'Source',
  countries: 'Regions / Countries',
  payoutsHistory: 'Payouts history',
  archiveOfferRules: 'Offers',
  archiveCampaignRules: 'Campaigns',

  // Cabinet
  myCampaigns: 'Campaigns',
  myTrafficSource: 'Traffic sources',
  myTrafficCallback: 'Callback',
  myTrafficBack: 'Trafficback',
  myDomains: 'Domains',

  // Reports
  myReports: 'My reports',
  report: 'Report',
  joinReports: 'Statistics',

  // Reports
  reports: 'Reports',

  // Statistics
  statistics: 'Statistics',

  // Logs
  logs: 'Logs',

  // Dropdown menu
  myCabinet: 'My account',
  account: 'Account',
  paymentDetails: 'Payment details',
  changePassword: 'Password reset',
  logOut: 'Logout',
  logIn: 'Login',

  // Context menu
  openNewTab: 'Open the link in the new tab',
  openNewWindow: 'Open the link in a new window',
  copyUrl: 'Copy link address',
  copySuccess: 'Copied to clipboard',
}

export const ruSideMenu = {
  myOfferWall: 'Витрина офферов',
  landingWall: 'Витрина лендингов',
  notifications: 'Уведомления',
  dashboards: 'Дашборды',

  // Moderation
  moderation: 'Модерация',
  settings: 'Настройки',
  cabinet: 'Кабинет',
  users: 'Пользователи',
  campaigns: 'Кампании',
  settingProfile: 'Настройки аккаунта',
  trafficSource: 'Источники трафика',
  trafficCallback: 'Коллбэки',
  trafficBack: 'Трафикбэки',
  domains: 'Домены',
  accessManagement: 'Управление доступами',

  // Settings
  operators: 'Операторы',
  providers: 'Провайдеры',
  offers: 'Офферы',
  enrollmentRules: 'Правила выкупа',
  landings: 'Лендинги',
  prelanding: 'Прелендинги',
  services: 'Сервисы',
  roles: 'Роли',
  blackLists: 'Черный список',
  msisdn: 'MSISDN',
  xRequestedWith: 'X-Requested-with',
  referer: 'Referer',
  ipAddress: 'IP address',
  userAgent: 'User Agent',
  source: 'Source',
  countries: 'Регионы / Страны',
  payoutsHistory: 'История отчислений',
  archiveOfferRules: 'По оферам',
  archiveCampaignRules: 'По кампаниям',

  // Cabinet
  myCampaigns: 'Кампании',
  myTrafficSource: 'Источники трафика',
  myTrafficCallback: 'Коллбэки',
  myTrafficBack: 'Трафикбэки',
  myDomains: 'Домены',

  // Reports
  myReports: 'Мои отчеты',
  report: 'Отчет',
  joinReports: 'Статистика',

  // Reports
  reports: 'Отчеты',

  // Statistics
  statistics: 'Статистика',

  // Logs
  logs: 'Логи',

  // Dropdown menu
  myCabinet: 'Мой кабинет',
  account: 'Аккаунт',
  paymentDetails: 'Платежные реквизиты',
  changePassword: 'Смена пароля',
  logOut: 'Выйти',
  logIn: 'Войти',

  // Context menu
  openNewTab: 'Открыть в новой вкладке',
  openNewWindow: 'Открыть в новом окне',
  copyUrl: 'Скопировать ссылку',
  copySuccess: 'Скопировано в буфер обмена',
}
