import { isProd } from '@/utils/stand.js'

export const getRubickRoutes = () => {
  const topMenu = {
    path: '/top-menu',
    component: () => (isProd() ? import('../views/error-page/Main.vue') : import('../layouts/top-menu/Main.vue')),
    children: [
      {
        path: '/',
        name: 'top-menu-dashboard-overview-1',
        component: () => import('../views/RubickViews/dashboard-overview-1/Main.vue'),
      },
      {
        path: 'dashboard-overview-2',
        name: 'top-menu-dashboard-overview-2',
        component: () => import('../views/RubickViews/dashboard-overview-2/Main.vue'),
      },
      {
        path: 'dashboard-overview-3',
        name: 'top-menu-dashboard-overview-3',
        component: () => import('../views/RubickViews/dashboard-overview-3/Main.vue'),
      },
      {
        path: 'inbox',
        name: 'top-menu-inbox',
        component: () => import('../views/RubickViews/inbox/Main.vue'),
      },
      {
        path: 'file-manager',
        name: 'top-menu-file-manager',
        component: () => import('../views/RubickViews/file-manager/Main.vue'),
      },
      {
        path: 'point-of-sale',
        name: 'top-menu-point-of-sale',
        component: () => import('../views/RubickViews/point-of-sale/Main.vue'),
      },
      {
        path: 'chat',
        name: 'top-menu-chat',
        component: () => import('../views/RubickViews/chat/Main.vue'),
      },
      {
        path: 'post',
        name: 'top-menu-post',
        component: () => import('../views/RubickViews/post/Main.vue'),
      },
      {
        path: 'calendar',
        name: 'top-menu-calendar',
        component: () => import('../views/RubickViews/calendar/Main.vue'),
      },
      {
        path: 'crud-data-list',
        name: 'top-menu-crud-data-list',
        component: () => import('../views/RubickViews/crud-data-list/Main.vue'),
      },
      {
        path: 'crud-form',
        name: 'top-menu-crud-form',
        component: () => import('../views/RubickViews/crud-form/Main.vue'),
      },
      {
        path: 'users-layout-1',
        name: 'top-menu-users-layout-1',
        component: () => import('../views/RubickViews/users-layout-1/Main.vue'),
      },
      {
        path: 'users-layout-2',
        name: 'top-menu-users-layout-2',
        component: () => import('../views/RubickViews/users-layout-2/Main.vue'),
      },
      {
        path: 'users-layout-3',
        name: 'top-menu-users-layout-3',
        component: () => import('../views/RubickViews/users-layout-3/Main.vue'),
      },
      {
        path: 'profile-overview-1',
        name: 'top-menu-profile-overview-1',
        component: () => import('../views/RubickViews/profile-overview-1/Main.vue'),
      },
      {
        path: 'profile-overview-2',
        name: 'top-menu-profile-overview-2',
        component: () => import('../views/RubickViews/profile-overview-2/Main.vue'),
      },
      {
        path: 'profile-overview-3',
        name: 'top-menu-profile-overview-3',
        component: () => import('../views/RubickViews/profile-overview-3/Main.vue'),
      },
      {
        path: 'wizard-layout-1',
        name: 'top-menu-wizard-layout-1',
        component: () => import('../views/RubickViews/wizard-layout-1/Main.vue'),
      },
      {
        path: 'wizard-layout-2',
        name: 'top-menu-wizard-layout-2',
        component: () => import('../views/RubickViews/wizard-layout-2/Main.vue'),
      },
      {
        path: 'wizard-layout-3',
        name: 'top-menu-wizard-layout-3',
        component: () => import('../views/RubickViews/wizard-layout-3/Main.vue'),
      },
      {
        path: 'blog-layout-1',
        name: 'top-menu-blog-layout-1',
        component: () => import('../views/RubickViews/blog-layout-1/Main.vue'),
      },
      {
        path: 'blog-layout-2',
        name: 'top-menu-blog-layout-2',
        component: () => import('../views/RubickViews/blog-layout-2/Main.vue'),
      },
      {
        path: 'blog-layout-3',
        name: 'top-menu-blog-layout-3',
        component: () => import('../views/RubickViews/blog-layout-3/Main.vue'),
      },
      {
        path: 'pricing-layout-1',
        name: 'top-menu-pricing-layout-1',
        component: () => import('../views/RubickViews/pricing-layout-1/Main.vue'),
      },
      {
        path: 'pricing-layout-2',
        name: 'top-menu-pricing-layout-2',
        component: () => import('../views/RubickViews/pricing-layout-2/Main.vue'),
      },
      {
        path: 'invoice-layout-1',
        name: 'top-menu-invoice-layout-1',
        component: () => import('../views/RubickViews/invoice-layout-1/Main.vue'),
      },
      {
        path: 'invoice-layout-2',
        name: 'top-menu-invoice-layout-2',
        component: () => import('../views/RubickViews/invoice-layout-2/Main.vue'),
      },
      {
        path: 'faq-layout-1',
        name: 'top-menu-faq-layout-1',
        component: () => import('../views/RubickViews/faq-layout-1/Main.vue'),
      },
      {
        path: 'faq-layout-2',
        name: 'top-menu-faq-layout-2',
        component: () => import('../views/RubickViews/faq-layout-2/Main.vue'),
      },
      {
        path: 'faq-layout-3',
        name: 'top-menu-faq-layout-3',
        component: () => import('../views/RubickViews/faq-layout-3/Main.vue'),
      },
      {
        path: 'update-profile',
        name: 'top-menu-update-profile',
        component: () => import('../views/RubickViews/update-profile/Main.vue'),
      },
      {
        path: 'change-password',
        name: 'top-menu-change-password',
        component: () => import('../views/change-password/Main.vue'),
      },
      {
        path: 'regular-table',
        name: 'top-menu-regular-table',
        component: () => import('../views/RubickViews/regular-table/Main.vue'),
      },
      {
        path: 'tabulator',
        name: 'top-menu-tabulator',
        component: () => import('../views/RubickViews/tabulator/Main.vue'),
      },
      {
        path: 'accordion',
        name: 'top-menu-accordion',
        component: () => import('../views/RubickViews/accordion/Main.vue'),
      },
      {
        path: 'button',
        name: 'top-menu-button',
        component: () => import('../views/RubickViews/button/Main.vue'),
      },
      {
        path: 'modal',
        name: 'top-menu-modal',
        component: () => import('../views/RubickViews/modal/Main.vue'),
      },
      {
        path: 'slide-over',
        name: 'top-menu-slide-over',
        component: () => import('../views/RubickViews/slide-over/Main.vue'),
      },
      {
        path: 'notification',
        name: 'top-menu-notification',
        component: () => import('../views/RubickViews/notification/Main.vue'),
      },
      {
        path: 'alert',
        name: 'top-menu-alert',
        component: () => import('../views/RubickViews/alert/Main.vue'),
      },
      {
        path: 'progress-bar',
        name: 'top-menu-progress-bar',
        component: () => import('../views/RubickViews/progress-bar/Main.vue'),
      },
      {
        path: 'tooltip',
        name: 'top-menu-tooltip',
        component: () => import('../views/RubickViews/tooltip/Main.vue'),
      },
      {
        path: 'dropdown',
        name: 'top-menu-dropdown',
        component: () => import('../views/RubickViews/dropdown/Main.vue'),
      },
      {
        path: 'typography',
        name: 'top-menu-typography',
        component: () => import('../views/RubickViews/typography/Main.vue'),
      },
      {
        path: 'icon',
        name: 'top-menu-icon',
        component: () => import('../views/RubickViews/icon/Main.vue'),
      },
      {
        path: 'loading-icon',
        name: 'top-menu-loading-icon',
        component: () => import('../views/RubickViews/loading-icon/Main.vue'),
      },
      {
        path: 'regular-form',
        name: 'top-menu-regular-form',
        component: () => import('../views/RubickViews/regular-form/Main.vue'),
      },
      {
        path: 'datepicker',
        name: 'top-menu-datepicker',
        component: () => import('../views/RubickViews/datepicker/Main.vue'),
      },
      {
        path: 'tom-select',
        name: 'top-menu-tom-select',
        component: () => import('../views/RubickViews/tom-select/Main.vue'),
      },
      {
        path: 'file-upload',
        name: 'top-menu-file-upload',
        component: () => import('../views/RubickViews/file-upload/Main.vue'),
      },
      {
        path: 'wysiwyg-editor',
        name: 'top-menu-wysiwyg-editor',
        component: () => import('../views/RubickViews/wysiwyg-editor/Main.vue'),
      },
      {
        path: 'validation',
        name: 'top-menu-validation',
        component: () => import('../views/RubickViews/validation/Main.vue'),
      },
      {
        path: 'chart',
        name: 'top-menu-chart',
        component: () => import('../views/RubickViews/chart/Main.vue'),
      },
      {
        path: 'slider',
        name: 'top-menu-slider',
        component: () => import('../views/RubickViews/slider/Main.vue'),
      },
      {
        path: 'image-zoom',
        name: 'top-menu-image-zoom',
        component: () => import('../views/RubickViews/image-zoom/Main.vue'),
      },
    ],
  }

  const simpleMenu = {
    path: '/simple-menu',
    component: () => (isProd() ? import('../views/error-page/Main.vue') : import('../layouts/simple-menu/Main.vue')),
    children: [
      {
        path: '/',
        name: 'simple-menu-dashboard-overview-1',
        component: () => import('../views/RubickViews/dashboard-overview-1/Main.vue'),
      },
      {
        path: 'dashboard-overview-2',
        name: 'simple-menu-dashboard-overview-2',
        component: () => import('../views/RubickViews/dashboard-overview-2/Main.vue'),
      },
      {
        path: 'dashboard-overview-3',
        name: 'simple-menu-dashboard-overview-3',
        component: () => import('../views/RubickViews/dashboard-overview-3/Main.vue'),
      },
      {
        path: 'inbox',
        name: 'simple-menu-inbox',
        component: () => import('../views/RubickViews/inbox/Main.vue'),
      },
      {
        path: 'file-manager',
        name: 'simple-menu-file-manager',
        component: () => import('../views/RubickViews/file-manager/Main.vue'),
      },
      {
        path: 'point-of-sale',
        name: 'simple-menu-point-of-sale',
        component: () => import('../views/RubickViews/point-of-sale/Main.vue'),
      },
      {
        path: 'chat',
        name: 'simple-menu-chat',
        component: () => import('../views/RubickViews/chat/Main.vue'),
      },
      {
        path: 'post',
        name: 'simple-menu-post',
        component: () => import('../views/RubickViews/post/Main.vue'),
      },
      {
        path: 'calendar',
        name: 'simple-menu-calendar',
        component: () => import('../views/RubickViews/calendar/Main.vue'),
      },
      {
        path: 'crud-data-list',
        name: 'simple-menu-crud-data-list',
        component: () => import('../views/RubickViews/crud-data-list/Main.vue'),
      },
      {
        path: 'crud-form',
        name: 'simple-menu-crud-form',
        component: () => import('../views/RubickViews/crud-form/Main.vue'),
      },
      {
        path: 'users-layout-1',
        name: 'simple-menu-users-layout-1',
        component: () => import('../views/RubickViews/users-layout-1/Main.vue'),
      },
      {
        path: 'users-layout-2',
        name: 'simple-menu-users-layout-2',
        component: () => import('../views/RubickViews/users-layout-2/Main.vue'),
      },
      {
        path: 'users-layout-3',
        name: 'simple-menu-users-layout-3',
        component: () => import('../views/RubickViews/users-layout-3/Main.vue'),
      },
      {
        path: 'profile-overview-1',
        name: 'simple-menu-profile-overview-1',
        component: () => import('../views/RubickViews/profile-overview-1/Main.vue'),
      },
      {
        path: 'profile-overview-2',
        name: 'simple-menu-profile-overview-2',
        component: () => import('../views/RubickViews/profile-overview-2/Main.vue'),
      },
      {
        path: 'profile-overview-3',
        name: 'simple-menu-profile-overview-3',
        component: () => import('../views/RubickViews/profile-overview-3/Main.vue'),
      },
      {
        path: 'wizard-layout-1',
        name: 'simple-menu-wizard-layout-1',
        component: () => import('../views/RubickViews/wizard-layout-1/Main.vue'),
      },
      {
        path: 'wizard-layout-2',
        name: 'simple-menu-wizard-layout-2',
        component: () => import('../views/RubickViews/wizard-layout-2/Main.vue'),
      },
      {
        path: 'wizard-layout-3',
        name: 'simple-menu-wizard-layout-3',
        component: () => import('../views/RubickViews/wizard-layout-3/Main.vue'),
      },
      {
        path: 'blog-layout-1',
        name: 'simple-menu-blog-layout-1',
        component: () => import('../views/RubickViews/blog-layout-1/Main.vue'),
      },
      {
        path: 'blog-layout-2',
        name: 'simple-menu-blog-layout-2',
        component: () => import('../views/RubickViews/blog-layout-2/Main.vue'),
      },
      {
        path: 'blog-layout-3',
        name: 'simple-menu-blog-layout-3',
        component: () => import('../views/RubickViews/blog-layout-3/Main.vue'),
      },
      {
        path: 'pricing-layout-1',
        name: 'simple-menu-pricing-layout-1',
        component: () => import('../views/RubickViews/pricing-layout-1/Main.vue'),
      },
      {
        path: 'pricing-layout-2',
        name: 'simple-menu-pricing-layout-2',
        component: () => import('../views/RubickViews/pricing-layout-2/Main.vue'),
      },
      {
        path: 'invoice-layout-1',
        name: 'simple-menu-invoice-layout-1',
        component: () => import('../views/RubickViews/invoice-layout-1/Main.vue'),
      },
      {
        path: 'invoice-layout-2',
        name: 'simple-menu-invoice-layout-2',
        component: () => import('../views/RubickViews/invoice-layout-2/Main.vue'),
      },
      {
        path: 'faq-layout-1',
        name: 'simple-menu-faq-layout-1',
        component: () => import('../views/RubickViews/faq-layout-1/Main.vue'),
      },
      {
        path: 'faq-layout-2',
        name: 'simple-menu-faq-layout-2',
        component: () => import('../views/RubickViews/faq-layout-2/Main.vue'),
      },
      {
        path: 'faq-layout-3',
        name: 'simple-menu-faq-layout-3',
        component: () => import('../views/RubickViews/faq-layout-3/Main.vue'),
      },
      {
        path: 'update-profile',
        name: 'simple-menu-update-profile',
        component: () => import('../views/RubickViews/update-profile/Main.vue'),
      },
      {
        path: 'change-password',
        name: 'simple-menu-change-password',
        component: () => import('../views/change-password/Main.vue'),
      },
      {
        path: 'regular-table',
        name: 'simple-menu-regular-table',
        component: () => import('../views/RubickViews/regular-table/Main.vue'),
      },
      {
        path: 'tabulator',
        name: 'simple-menu-tabulator',
        component: () => import('../views/RubickViews/tabulator/Main.vue'),
      },
      {
        path: 'accordion',
        name: 'simple-menu-accordion',
        component: () => import('../views/RubickViews/accordion/Main.vue'),
      },
      {
        path: 'button',
        name: 'simple-menu-button',
        component: () => import('../views/RubickViews/button/Main.vue'),
      },
      {
        path: 'modal',
        name: 'simple-menu-modal',
        component: () => import('../views/RubickViews/modal/Main.vue'),
      },
      {
        path: 'slide-over',
        name: 'simple-menu-slide-over',
        component: () => import('../views/RubickViews/slide-over/Main.vue'),
      },
      {
        path: 'notification',
        name: 'simple-menu-notification',
        component: () => import('../views/RubickViews/notification/Main.vue'),
      },
      {
        path: 'alert',
        name: 'simple-menu-alert',
        component: () => import('../views/RubickViews/alert/Main.vue'),
      },
      {
        path: 'progress-bar',
        name: 'simple-menu-progress-bar',
        component: () => import('../views/RubickViews/progress-bar/Main.vue'),
      },
      {
        path: 'tooltip',
        name: 'simple-menu-tooltip',
        component: () => import('../views/RubickViews/tooltip/Main.vue'),
      },
      {
        path: 'dropdown',
        name: 'simple-menu-dropdown',
        component: () => import('../views/RubickViews/dropdown/Main.vue'),
      },
      {
        path: 'typography',
        name: 'simple-menu-typography',
        component: () => import('../views/RubickViews/typography/Main.vue'),
      },
      {
        path: 'icon',
        name: 'simple-menu-icon',
        component: () => import('../views/RubickViews/icon/Main.vue'),
      },
      {
        path: 'loading-icon',
        name: 'simple-menu-loading-icon',
        component: () => import('../views/RubickViews/loading-icon/Main.vue'),
      },
      {
        path: 'regular-form',
        name: 'simple-menu-regular-form',
        component: () => import('../views/RubickViews/regular-form/Main.vue'),
      },
      {
        path: 'datepicker',
        name: 'simple-menu-datepicker',
        component: () => import('../views/RubickViews/datepicker/Main.vue'),
      },
      {
        path: 'tom-select',
        name: 'simple-menu-tom-select',
        component: () => import('../views/RubickViews/tom-select/Main.vue'),
      },
      {
        path: 'file-upload',
        name: 'simple-menu-file-upload',
        component: () => import('../views/RubickViews/file-upload/Main.vue'),
      },
      {
        path: 'wysiwyg-editor',
        name: 'simple-menu-wysiwyg-editor',
        component: () => import('../views/RubickViews/wysiwyg-editor/Main.vue'),
      },
      {
        path: 'validation',
        name: 'simple-menu-validation',
        component: () => import('../views/RubickViews/validation/Main.vue'),
      },
      {
        path: 'chart',
        name: 'simple-menu-chart',
        component: () => import('../views/RubickViews/chart/Main.vue'),
      },
      {
        path: 'slider',
        name: 'simple-menu-slider',
        component: () => import('../views/RubickViews/slider/Main.vue'),
      },
      {
        path: 'image-zoom',
        name: 'simple-menu-image-zoom',
        component: () => import('../views/RubickViews/image-zoom/Main.vue'),
      },
    ],
  }

  return { topMenu, simpleMenu }
}
