export const enProvider = {
  addProviderButton: 'Add provider',
  addProvider: 'Add provider',
  editProvider: 'Edit provider',
  copyProvider: 'Copy provider',

  id: 'ID',
  name: 'Name',
  alias: 'Alias',
  created_at: 'Created at',

  confirmDialog: {
    title: 'Deleting a Provider',
    message: 'Do you really want to remove the Provider?',
  },

  confirmRestoreDialog: {
    title: 'Restore a Provider',
    message: 'Do you really want to restore the Provider?',
  },

  requiredParameters: 'Required parameters',

  addVariable: 'Add',
  variablesMap: 'Variables map',
  deleteVariable: 'Delete variable',
  parameterNotFilled: 'Parameter not filled',

  addVariableTitle: 'Add parameter',
  diasbledAddVariableTitle: 'You cannot add a new parameter because previous parameters are not filled',

  //Operator Alias
  operatorAlias: 'Operator aliases',
  operator: 'Operator',
  addOperatorAlias: 'Add alias',
  deleteOperatorAlias: 'Delete alias',

  confirmDialogOperatorAlias: {
    title: 'Removing an alias',
    message: 'Are you sure you want to remove the alias?',
  },

  // CallbackEvents
  callbackEvents: 'Callback events',
  event: 'Provider event',
  callback: 'event',
  addCallbackEvents: 'Add event',

  confirmDialogCallbackEvents: {
    title: 'Removing an event',
    message: 'Are you sure you want to remove the event?',
  },

  // TrafficbackReasons
  trafficbackReasons: 'Trafficback reasons',
  providerReason: 'Provider reason',
  trafficbackReason: 'Reason',
  addTrafficbackReasons: 'Add reasons',

  confirmDialogTrafficbackReasons: {
    title: 'Removing an reason',
    message: 'Are you sure you want to remove the reason?',
  },

  // CallbackResponce
  callbackResponce: 'Callback responce',
  responseBody: 'Response body',
  responseCode: 'Response code',
  addResponse: 'Add response',

  confirmDialogCallbackResponce: {
    title: 'Removing an responce',
    message: 'Are you sure you want to remove the responce?',
  },

  save: 'Save',
  close: 'Close',
  cancel: 'Cancel',
}

export const ruProvider = {
  addProviderButton: 'Добавить провайдера',
  addProvider: 'Добавление провайдера',
  editProvider: 'Редактирование провайдера',
  copyProvider: 'Скопировать провайдера',

  id: 'ID',
  name: 'Название',
  alias: 'Псевдоним',
  created_at: 'Дата создания',

  confirmDialog: {
    title: 'Удаление провайдера',
    message: 'Вы действительно хотите удалить провайдера?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление провайдера',
    message: 'Вы действительно хотите восстановить провайдера?',
  },

  requiredParameters: 'Обязательные параметры',

  addVariable: 'Добавить',
  variablesMap: 'Приведение параметров',
  deleteVariable: 'Удалить параметр',
  parameterNotFilled: 'Не заполнен параметр',

  addVariableTitle: 'Добавить параметр',
  diasbledAddVariableTitle: 'Нельзя добавить новый параметр т.к. не заполнены предыдущие параметры',

  // Operator Alias
  operatorAlias: 'Псевдонимы операторов',
  operator: 'Оператор',
  addOperatorAlias: 'Добавить псевдоним',
  deleteOperatorAlias: 'Удалить псевдоним',

  confirmDialogOperatorAlias: {
    title: 'Удаление псевдонима',
    message: 'Вы действительно хотите удалить псевдоним?',
  },

  // Callback Events
  callbackEvents: 'События уведомлений',
  event: 'Событие провайдера',
  callback: 'Событие',
  addCallbackEvents: 'Добавить событие',

  confirmDialogCallbackEvents: {
    title: 'Удаление события',
    message: 'Вы действительно хотите удалить событие?',
  },

  // TrafficbackReasons
  trafficbackReasons: 'Причины возврата',
  providerReason: 'Причина провайдера',
  trafficbackReason: 'Причина',
  addTrafficbackReasons: 'Добавить причину',

  confirmDialogTrafficbackReasons: {
    title: 'Удаление причины',
    message: 'Вы действительно хотите удалить причину?',
  },

  // CallbackResponce
  callbackResponce: 'Ответы уведомлений',
  responseBody: 'Тело ответа',
  responseCode: 'Код ответа',
  addResponse: 'Добавить ответ',

  confirmDialogCallbackResponce: {
    title: 'Удаление ответа',
    message: 'Вы действительно хотите удалить ответ?',
  },

  save: 'Сохранить',
  close: 'Закрыть',
  cancel: 'Отменить',
}
