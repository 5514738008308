// BaseInput
export const enBaseInput = {
  placeholder: '',
  label: '',
}

export const ruBaseInput = {
  placeholder: '',
  label: '',
}

// BaseCheckbox
export const enBaseCheckbox = {
  showDeleted: 'Deleted',
}

export const ruBaseCheckbox = {
  showDeleted: 'Удаленные',
}
