<template>
  <div class="v-add-role-dialog">
    <form @submit.prevent="handleSubmit">
      <BaseInput label="role.name" name="name" v-model="roleInfo.name" :errors="v$.name.$errors" :required="true" />

      <VFieldTextarea
        label="role.description"
        name="description"
        v-model="roleInfo.description"
        :errors="v$.description.$errors"
        :required="true"
      />

      <div v-if="role.id">
        <label class="block mt-4">{{ t('role.access') }}</label>
        <PermissionsTable :role="role" />
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('role.cancel') }}
        </button>
        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('role.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import PermissionsTable from './PermissionsTable'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useServerErrorHandler } from '@/hooks/useServerErrorHandler'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'RolesForm',
  components: { PermissionsTable },
  props: {
    role: {
      type: Object,
      required: true,
    },
    serverError: {
      type: Object,
    },
  },

  setup(props, context) {
    const roleInfo = ref({
      id: props.role.id || '',
      name: props.role.name || '',
      description: props.role.description || '',
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        description: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
      }
    })

    const v$ = useVuelidate(rules, roleInfo)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      if (props.role.id) {
        context.emit('updateRole', roleInfo.value)
      } else {
        context.emit('addRole', roleInfo.value)
      }
    }

    watch(
      () => props.serverError,
      errors => useServerErrorHandler(rules.value, errors, v$),
      { deep: true }
    )

    return {
      v$,

      roleInfo,
      handleSubmit,
      t,
    }
  },
})
</script>
