<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <BaseInput label="services.name" name="name" v-model="formData.name" :required="true" :errors="v$.name.$errors" />

      <VFieldTextarea label="services.description" name="description" v-model="formData.description" />

      <div class="sm:flex">
        <div class="sm:w-2/4">
          <VFieldSelect
            label="services.country"
            v-model="formData.country_id"
            :options="options.country"
            :optionName="translation.getCountryOptionName()"
            :required="true"
            :errors="v$.country_id.$errors"
          />
        </div>

        <div class="sm:w-2/4">
          <VFieldSelect
            class="sm:ml-6"
            label="services.paymentModel"
            :hideSearch="true"
            v-model="formData.payment_model_id"
            :options="options.paymentModel"
            :required="true"
            :errors="v$.payment_model_id.$errors"
          />
        </div>
      </div>

      <VFieldSelect
        label="services.provider"
        v-model="formData.provider_id"
        :options="options.providers"
        :required="true"
        :errors="v$.provider_id.$errors"
      />

      <ServiceVariableMap :service="service" :providerId="formData.provider_id" v-model="formData.provider_variables" />

      <hr class="mt-4" />

      <div class="sm:flex">
        <div class="sm:w-2/4">
          <BaseInput
            class="sm:mr-8"
            label="services.dayLimit"
            name="day_limit"
            v-model="formData.day_limit"
            :required="true"
            :errors="v$.day_limit.$errors"
          />
        </div>

        <VFieldSelect
          class="sm:w-2/4"
          label="services.redirectLimit"
          v-model="formData.redirect_on_limit_service_id"
          :options="options.redirectServices"
        />
      </div>

      <BaseCheckbox class="mt-4" label="services.subIsActive" v-model="formData.is_subscription_active_by_payment" />

      <div class="sm:flex items-end">
        <div class="sm:w-2/4">
          <BaseInput :disabled="true" class="sm:mr-8" label="services.uuid" name="uuid" v-model="formData.uuid" />
        </div>

        <button
          :disabled="!!formData.uuid"
          type="button"
          class="btn btn-linkedin h-10 sm:w-2/4 w-full mt-3"
          @click="generateUuid"
        >
          <RepeatIcon class="w-4 h-4 mr-1" />
          {{ t('services.generateUuid') }}
        </button>
      </div>

      <BaseInput label="services.successUrl" name="success_url" iconLeft="Link2Icon" v-model="formData.success_url" />

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
          {{ t('services.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('services.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { generateUuidv4 } from '@/utils/UniqueID'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useTranslation } from '@/hooks/useTranslation'
import useVuelidate from '@vuelidate/core'
import ServiceVariableMap from './ServiceVariableMap.vue'

export default defineComponent({
  name: 'EditServiceForm',
  components: {
    ServiceVariableMap,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const translation = useTranslation()

    const formData = ref({
      id: props.service.id || '',
      name: props.service.name || '',
      description: props.service.description || '',
      country_id: props.service.country?.id || '',
      payment_model_id: props.service.payment_model?.id || '',
      provider_id: props.service.provider?.id || '',
      provider_variables: props.service?.provider_variables || [],
      day_limit: props.service.day_limit || 0,
      redirect_on_limit_service_id: props.service.redirect_on_limit_service_id || '',
      is_subscription_active_by_payment: props.service.is_subscription_active_by_payment || 0,
      success_url: props.service.success_url || '',
      uuid: props.service.uuid || '',
    })

    const options = ref({
      country: [],
      paymentModel: [],
      providers: [],
      redirectServices: [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        country_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        payment_model_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        provider_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        day_limit: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      const isChangedUuid = props.service.uuid !== formData.value.uuid

      if (isChangedUuid) {
        if (!formData.value.uuid?.replace(/\s/g, '')) {
          formData.value.uuid = null
        }
      } else {
        delete formData.value.uuid
      }

      if (props.service.id) {
        updateService()
      } else {
        createService()
      }
    }

    const updateService = () => {
      store.dispatch('services/updateService', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('close-modal')
        context.emit('refresh')
      })
    }

    const createService = () => {
      store.dispatch('services/createService', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('close-modal')
        context.emit('refresh')
      })
    }

    const generateUuid = () => {
      formData.value.uuid = generateUuidv4()
    }

    const getCountriesList = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }

    const getPaymentModel = async () => {
      const paymentModels = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModel = paymentModels.data ? paymentModels.data : []
    }

    const getProviders = async () => {
      const providers = await store.dispatch('providers/getProviders')
      options.value.providers = providers.data ? providers.data : []
    }

    const getOptionsServices = async () => {
      const countryId = formData.value.country_id

      if (!countryId) {
        options.value.redirectServices = []
        return
      }

      //TODO: Когда бэк будет готов, добавить фильтр по активным записям
      const services = await store.dispatch('services/getServices', {
        country: countryId,
        full: false,
      })

      Object.entries(services.data).forEach(([key, value]) => {
        if (props.service?.id == value.id) {
          services.data[key] = {
            ...services.data[key],
            disabled: true,
          }

          return
        }
      })

      options.value.redirectServices = services.data ? services.data : []
    }

    getCountriesList()
    getPaymentModel()
    getProviders()
    getOptionsServices()

    watch(
      () => formData.value.country_id,
      () => {
        formData.value.redirect_on_limit_service_id = ''
        getOptionsServices()
      }
    )

    return {
      options,
      v$,
      formData,
      handleSubmit,
      generateUuid,
      translation,
      t,
    }
  },
})
</script>
