<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="font-medium uppercase mt-4 mb-1 text-gray-800">
        <ServerIcon class="w-5 h-5 mr-1" />
        {{ t('offer.tableTitle_1') }}
      </div>

      <VTable :columns="columns.editable" :data="table.editable" :hideScroll="true">
        <template #rows>
          <tr
            v-for="(service, index) of table.editable"
            :key="index"
            class="bg-white border-b border-opacity-50 border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100"
            v-show="service.type !== TYPES.delete"
          >
            <td scope="row" class="pl-2 pr-4 py-4 text-gray-900 dark:text-white">
              <VFieldSelect
                v-model="service.operator.id"
                :options="options.operators"
                class="w-60"
                :class="{ 'v-field-search-select__error': service.errors?.operator }"
                @change="changeHandler(index, TYPES.update)"
              />
              <div
                v-if="service.errors?.operator"
                class="text-xs absolute text-theme-6 ml-1 v-field-search-select__intro-error"
              >
                {{ service.errors.operator }}
              </div>
            </td>

            <td scope="row" class="pl-2 pr-4 py-4 text-gray-900 dark:text-white">
              <VFieldSelect
                v-model="service.service.id"
                :options="options.services"
                :showId="true"
                class="w-80"
                :class="{ 'v-field-search-select__error': service.errors?.service }"
                @change="changeHandler(index, TYPES.update)"
              />
              <div
                v-if="service.errors?.service"
                class="text-xs absolute text-theme-6 ml-1 v-field-search-select__intro-error"
              >
                {{ service.errors.service }}
              </div>
            </td>

            <td scope="row" class="pl-2 pr-4 py-4 text-gray-900 dark:text-white">
              <BaseInput
                v-model="service.rsh_partner_rate"
                :inputGroup="true"
                class="min-w-[50px]"
                :class="{ 'border-theme-6': service.errors?.rsh_rate }"
                @input="changeHandler(index, TYPES.update)"
              >
                <template #inputGroup>
                  <div class="input-group-text font-medium select-none">%</div>
                </template>
              </BaseInput>
              <div
                v-if="service.errors?.rsh_rate"
                class="text-xs absolute text-theme-6 ml-1 v-field-search-select__intro-error"
              >
                {{ service.errors.rsh_rate }}
              </div>
            </td>

            <td scope="row" class="pl-2 py-4 text-gray-900 dark:text-white">
              <BaseInput
                v-model="service.cpa_partner_rate.sum"
                class="min-w-[80px]"
                :class="{ 'border-theme-6': service.errors?.cpa_rate }"
                @input="changeHandler(index, TYPES.update)"
              />
              <div
                v-if="service.errors?.cpa_rate"
                class="text-xs absolute text-theme-6 ml-1 v-field-search-select__intro-error"
              >
                {{ service.errors.cpa_rate }}
              </div>
            </td>

            <td scope="row" class="pr-4 py-4 text-gray-900 dark:text-white">
              <VFieldSelect
                v-model="service.cpa_partner_rate.currency"
                :options="options.currency"
                optionName="iso_name"
                class="w-[90px] ml-2"
                :class="{ 'v-field-search-select__error': service.errors?.cpa_currency }"
                @change="changeHandler(index, TYPES.update)"
              />
              <div
                v-if="service.errors?.cpa_currency"
                class="text-xs absolute text-theme-6 ml-1 v-field-search-select__intro-error"
              >
                {{ service.errors.cpa_currency }}
              </div>
            </td>

            <td class="whitespace-nowrap" align="right">
              <Tippy :content="t('vTable.delete')">
                <button type="button" class="mr-2" @click="changeHandler(index, TYPES.delete)">
                  <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-6" />
                </button>
              </Tippy>
            </td>
          </tr>
        </template>
      </VTable>

      <div class="flex justify-end mt-2">
        <button type="button" class="btn btn-success px-6" @click="changeHandler(index, TYPES.new)">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('offer.add') }}
        </button>
      </div>

      <div class="flex items-center font-medium uppercase mt-4 mb-1 text-gray-800">
        <DollarSignIcon class="w-5 h-5 mr-1" />
        {{ t('offer.tableTitle_2') }}
      </div>

      <VTable :columns="columns.info" :data="data" :hideButtons="['edit', 'delete']" :hideScroll="true" />

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          <XIcon class="w-4 h-4 mr-2" />
          {{ t('offer.close') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('offer.save') }}
        </button>
      </div>
    </form>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { parseNumber } from '@/utils/number.ts'
import { convertAmountToDisplay, convertAmountToSend } from '@/utils/amountConverter'
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'RevShareForm',
  props: {
    offer: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()

    const successValidation = ref(true)

    const TYPES = {
      new: 'new',
      update: 'update',
      delete: 'delete',
    }

    const confirmDialog = ref(null)

    const table = ref({
      editable: [],
      info: [],
    })

    const columns = {
      editable: {
        operator: t('offer.operator'),
        service: t('offer.service'),
        rsh_partner_rate: t('offer.revShare'),
        cpa_partner_rate: t('offer.CPA'),
        cpa_partner_currency: t('offer.currency'),
      },
      info: {
        operator: t('offer.operator'),
        service: t('offer.service'),
        sigma_rate: t('offer.sigmaRate'),
        rsh_partner_rate: t('offer.partnerRateRs'),
        cpa_partner_rate: t('offer.partnerRateCPA'),
      },
    }

    const data = computed(() => {
      return table.value.info.map(record => {
        const currency = props.options.currency.find(currency => currency.id == record.cpa_partner_rate?.currency) || {}

        const sigmaRate = parseNumber(record.sigma_rate)
        const percentRs = parseNumber(record.rsh_partner_rate)
        const rateRs = sigmaRate && percentRs ? (sigmaRate / 100) * percentRs : ''
        const truncRateRs = Math.trunc(rateRs * 10000) / 10000 || ''

        return {
          operator: record.operator.name,
          service: `(${record.service.id}) ${record.service.name}`,
          sigma_rate: `${record.sigma_rate || ''} ${record.sigma_rate_currency || ''}`,
          rsh_partner_rate: `${truncRateRs || ''} ${record.sigma_rate_currency || ''}`,
          cpa_partner_rate: `${record.cpa_partner_rate?.sum || ''} ${currency.iso_name || ''}`,
        }
      })
    })

    const handleSubmit = async () => {
      await validation()

      if (!successValidation.value) {
        return
      }

      table.value.editable.forEach(async service => {
        switch (service.type) {
          case TYPES.new:
            await createService(service)
            break
          case TYPES.update:
            await updateService(service)
            break
          case TYPES.delete:
            await deleteService(service)
            break
        }
      })

      if (successValidation.value) {
        context.emit('close')
      }
    }

    const validation = async () => {
      successValidation.value = true

      table.value.editable.forEach(service => {
        service.errors = {
          operator: null,
          service: null,
          rsh_rate: null,
          cpa_rate: null,
          cpa_currency: null,
        }

        if (service.type === TYPES.delete) {
          return
        }

        const isEmptyOperator = !service.operator.id
        if (isEmptyOperator) {
          service.errors.operator = t('offer.emptyField')
          successValidation.value = false
        }

        const isEmptyService = !service.service.id
        if (isEmptyService) {
          service.errors.service = t('offer.emptyField')
          successValidation.value = false
        }

        const isEmptyRshRate = !service.rsh_partner_rate
        if (isEmptyRshRate) {
          service.errors.rsh_rate = t('offer.emptyField')
          successValidation.value = false
        }

        const isEmptyCpaRate = !service.cpa_partner_rate.sum
        if (isEmptyCpaRate) {
          service.errors.cpa_rate = t('offer.emptyField')
          successValidation.value = false
        }

        const isEmptyCpaCurrency = service.cpa_partner_rate.currency === ''
        if (isEmptyCpaCurrency) {
          service.errors.cpa_currency = t('offer.emptyField')
          successValidation.value = false
        }
      })

      return successValidation.value
    }

    const createService = async service => {
      const response = await store.dispatch('offers/createOperatorToOffer', {
        offer_id: props.offer.id,
        operator_id: service.operator.id,
        data: {
          service_id: Number(service.service.id),
          cpa_partner_rate: {
            sum: convertAmountToSend(service.cpa_partner_rate.sum),
            currency: Number(service.cpa_partner_rate.currency),
          },
          rsh_partner_rate: service.rsh_partner_rate,
        },
      })

      if (response) {
        const errors = response.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          successValidation.value = false
          return
        }
      }
    }

    const updateService = async service => {
      const response = await store.dispatch('offers/updateOperatorToOffer', {
        offer_id: props.offer.id,
        operator_id: service.operator.id,
        data: {
          service_id: Number(service.service.id),
          cpa_partner_rate: {
            sum: convertAmountToSend(service.cpa_partner_rate.sum),
            currency: Number(service.cpa_partner_rate.currency),
          },
          rsh_partner_rate: service.rsh_partner_rate,
        },
      })

      if (response) {
        const errors = response.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          successValidation.value = false
          return
        }
      }
    }

    const deleteService = async service => {
      const response = await store.dispatch('offers/deleteOperatorFromOffer', {
        offer_id: props.offer.id,
        operator_id: service.operator.id,
      })

      if (response) {
        const errors = response.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          successValidation.value = false
          return
        }
      }
    }

    const getOperators = async () => {
      const response = await store.dispatch('offers/getOperatorsByOffer', props.offer.id)

      if (response.data) {
        table.value.editable = await response.data.operators

        /* conver cpa rate */
        table.value.editable.forEach((operator, key) => {
          const cpaSum = table.value.editable[key].cpa_partner_rate?.sum
          if (cpaSum) {
            table.value.editable[key].cpa_partner_rate.sum = convertAmountToDisplay(cpaSum)
          }
        })

        generateInfoTable()
      }
    }

    const generateInfoTable = async () => {
      table.value.info = cloneDeep(table.value.editable)

      table.value.editable.forEach(async (row, index) => {
        const operators = await store.dispatch('services/getOperatorsByService', row.service.id)

        if (operators.data) {
          const operator = operators.data.operators.find(operator => row.operator.id == operator.operator.id)

          if (operator) {
            const currencyRsh =
              operator.rsh_rate.currency > 0
                ? await store.dispatch('currency/getCurrencyById', operator.rsh_rate.currency)
                : {}

            table.value.info[index] = {
              ...table.value.info[index],
              sigma_rate: convertAmountToDisplay(operator.rsh_rate.sum),
              sigma_rate_currency: currencyRsh?.data?.iso_name || '',
            }
          }
        }
      })
    }

    const changeHandler = async (index, type) => {
      const record = table.value.editable[index] || {}
      const isDeleteType = type === TYPES.delete

      if (record.type && !isDeleteType) {
        return
      }

      switch (type) {
        case TYPES.new: {
          table.value.editable.push({
            type: TYPES.new,
            operator: { id: '' },
            service: { id: '' },
            rsh_partner_rate: '',
            cpa_partner_rate: { sum: '', currency: '' },
          })
          break
        }
        case TYPES.delete: {
          const isNew = () => record.type === TYPES.new

          if (isNew()) {
            table.value.editable.splice(index, 1)
            break
          }

          record.type = type
          break
        }
        default:
          record.type = type
      }
    }

    getOperators()

    return {
      t,
      TYPES,
      columns,
      data,
      table,
      confirmDialog,
      handleSubmit,
      changeHandler,
      deleteService,
    }
  },
})
</script>
