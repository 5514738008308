import { http } from '@/api/http'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getCallbacks({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/callbacks`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getCallbackById(_, id) {
    try {
      const response = await http.get(`/callbacks/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateCallback(_, params) {
    try {
      const response = await http.put(`/callbacks/${params.id}`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createCallback(_, params) {
    try {
      const response = await http.post(`/callbacks`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteCallback(_, id) {
    try {
      await http.delete(`/callbacks/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreCallback(_, id) {
    try {
      await http.put(`/callbacks/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Callback Events List
  async getCallbackEvents() {
    try {
      const response = await http.get('/callback_events')
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addCallbackEvent(_, params) {
    try {
      const response = await http.post(`/callbacks/${params.callback_id}/callback_event/${params.callback_event_id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteCallbackEvent(_, params) {
    try {
      await http.delete(`/callbacks/${params.callback_id}/callback_event/${params.callback_event_id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
