export const enDashboards = {
  dataFor: 'Data for:',
  today: 'Today',
  yesterday: 'Yesterday',
  last7Days: 'Last 7 days',
  last14Days: 'Last 14 days',
  last30Days: 'Last 30 days',

  clicks: 'Clicks',
  subscriptions: 'Subscriptions',
  leads: 'Leads',
  income: 'Income',

  topOffers: 'Top offers',
  newOffers: 'New offers',
  name: 'Name',
  flowType: 'Flow type',
  country: 'Country',
  qtyLandings: 'Qty landings',

  contactUs1: 'Please feel free to',
  contactUs2: 'contact us',
}

export const ruDashboards = {
  dataFor: 'Данные за:',
  today: 'Cегодня',
  yesterday: 'Вчера',
  last7Days: 'Последние 7 дней',
  last14Days: 'Последние 14 дней',
  last30Days: 'Последние 30 дней',

  clicks: 'Клики',
  subscriptions: 'Подписки',
  leads: 'Лиды',
  income: 'Доход',

  topOffers: 'Топ офферов',
  newOffers: 'Новые офферы',
  name: 'Название',
  flowType: 'Сп. оплаты',
  country: 'Страна',
  qtyLandings: 'Кол-во лендингов',

  contactUs1: 'Пожалуйста, не стесняйтесь',
  contactUs2: 'связаться с нами',
}
