export const enAuth = {
  bigTextUp: 'Logging into the system is',
  bigTextDown: 'not difficult',
  smallText: 'But you better know why',
  fullText: 'Logging into the system is not difficult. But you better know why',

  logIn: 'Login',
  registration: 'Sign up',
  register: 'Registration',
  email: 'E-mail',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  passwordConfirmation: 'Password check',
  firstName: 'Name',
  lastName: 'Surname',
  chooseMessenger: 'We need to contact you, how shall we?',
  writeYourNickname: 'Write your username/id',

  enterPassword: 'Enter password',
  enterEmail: 'Enter email',
  invalidEmailFormat: 'Invalid email format',
  invalidUsernameOrPassword: 'Invalid username/password',

  recoverPassword: 'Reset password',
  authorization: 'Login',

  mailHasAlreadyBeenSent: 'E-mail address already exists',

  back: 'Return to login page',
  verifyEmail: 'Confirm mail',
  verifyEmailInstruction: 'We have sent an email to you. Follow the link to activate your account',
  verifySuccess: 'Thank you! Your email has been verified',
  verifySuccessInstruction:
    'If you have provided correct Skype/telegram contact details, we will contact you as soon as possible to discuss your experience and our possibilities for you',
}

export const ruAuth = {
  bigTextUp: 'Войти в систему',
  bigTextDown: 'несложно',
  smallText: 'Главное знать, зачем вы это делаете.',
  fullText: 'Войти в систему несложно. Главное знать, зачем вы это делаете.',

  logIn: 'Войти',
  registration: 'Регистрация',
  register: 'Регистрация',
  email: 'E-mail',
  password: 'Пароль',
  forgotPassword: 'Забыли пароль?',
  passwordConfirmation: 'Пароль еще раз',
  firstName: 'Имя',
  lastName: 'Фамилия',
  chooseMessenger: 'Нам необходимо связаться с вами, как вам удобнее?',
  writeYourNickname: 'Напишите Ваш username/id',

  enterPassword: 'Введите пароль',
  enterEmail: 'Введите Email',
  invalidEmailFormat: 'Неверный формат почты',
  invalidUsernameOrPassword: 'Неверный логин/пароль',

  recoverPassword: 'Восстановить пароль',
  authorization: 'Авторизация',

  mailHasAlreadyBeenSent: 'E-mail уже используется в системе',

  back: 'Вернуться на страницу авторизации',
  verifyEmail: 'Подтвердите почту',
  verifyEmailInstruction: 'Мы отправили письмо вам на почту. Перейдите по ссылке чтобы активировать аккаунт',
  verifySuccess: 'Спасибо! Ваша электронная почта подтверждена',
  verifySuccessInstruction:
    'Если вы указали корректные контактные данные Skype/telegram, мы свяжемся с вами в ближайшее время для обсуждения вашего опыта и наших возможностей для вас',
}
