export const isJSON = str => {
  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}

export const renderTippyJson = json => {
  const formatedJson = syntaxHighlight(json)
  const replaces = formatedJson
    .replace(/>,</g, '>,</br><')
    .replace(/{</g, '{</br><')
    .replace(/>}/g, '></br>}')

  return replaces
}

export const syntaxHighlight = json => {
  if (typeof json != 'string') {
    json = JSON.stringify(json, undefined, 2)
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '</br>')
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
    function(match) {
      var cls = 'number'
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }

      if (cls === 'key') {
        return '<span class="' + cls + ' ml-2 font-medium">' + match + '</span>'
      }

      return '<span class="' + cls + ' ml-1.5">' + match + '</span>'
    }
  )
}
