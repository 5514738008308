export const enArchiveCampaignRules = {
  addHistory: 'Setup payout history',
  editHostory: 'Editing payout history',
  id: 'ID',
  campaign: 'Campaign',
  operator: 'Operator',
  service: 'Service',
  partnerRateRs: 'Payout to partners (RevShare)',
  partnerRateCPA: 'Payout to partners (CPA)',
  createdAt: 'Setup date',
  updatedAt: 'Update date',
  currency: 'Currency',
  save: 'Save',
  cancel: 'Cancel',
}

export const ruArchiveCampaignRules = {
  addHistory: 'Добавить историю отчисления',
  editHostory: 'Редактирование истории отчисления',
  id: 'ID',
  campaign: 'Кампания',
  operator: 'Оператор',
  service: 'Сервис',
  partnerRateRs: 'Отчисл. партнерам (RevShare)',
  partnerRateCPA: 'Отчисл. партнерам (CPA)',
  createdAt: 'Дата создания',
  updatedAt: 'Дата изменения',
  currency: 'Валюта',
  save: 'Сохранить',
  cancel: 'Отменить',
}
