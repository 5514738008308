import { watch } from 'vue'

export const useCustomFilters = (queryOptions = null, parameters = null) => {
  const checkFilter = param => {
    try {
      switch (param.name) {
        case 'company_filter':
          watch(
            () => param.value,
            value => changeCampaignSelect(value)
          )
          break
      }
    } catch (e) {
      console.error(e)
    }
  }

  const cloneOption = (filterName, options, rows) => {
    switch (filterName) {
      case 'request_param_filter':
        options.value[`${filterName}_all`] = rows ? rows : []
        break
    }
  }

  const changeCampaignSelect = value => {
    const existRequstParamFilter = () => queryOptions.value['request_param_filter'] || null
    const showAllParams = () => value.length === 0 || (value.length === 1 && value[0] === '0')

    if (!existRequstParamFilter() || showAllParams()) {
      queryOptions.value['request_param_filter'] = queryOptions.value['request_param_filter_all']
      return
    }

    const campaingOptioms = queryOptions.value['company_filter'] || []
    const campaignParams = []
    const returnParams = []

    for (const option of campaingOptioms) {
      if (campaignParams.length === value.length) {
        break
      }

      if (value.includes(String(option.id))) {
        campaignParams.push(option.params)
      }
    }

    campaignParams.forEach(param => {
      const parseParam = JSON.parse(param) || null
      if (!Array.isArray(parseParam)) {
        return
      }

      parseParam.forEach(val => {
        returnParams.push({
          id: val.name,
          name: val.name,
        })
      })
    })

    for (const param of Object.values(parameters.value)) {
      if (param.name === 'request_param_filter') {
        let existParam = false
        returnParams.forEach(returnParam => {
          if (returnParam.id === param.value) {
            existParam = true
          }
        })

        if (existParam) {
          break
        }

        param.value = param.multiValuesOptions ? ['0'] : '0'
      }
    }

    queryOptions.value['request_param_filter'] = returnParams
  }

  const resetRequestParams = () => {
    if (
      Object.hasOwn(queryOptions.value, 'request_param_filter') &&
      Object.hasOwn(queryOptions.value, 'request_param_filter_all')
    ) {
      queryOptions.value['request_param_filter'] = queryOptions.value['request_param_filter_all']
      Object.values(parameters.value).forEach(param => checkFilter(param))
    }
  }

  return {
    checkFilter,
    cloneOption,
    resetRequestParams,
  }
}
