<template>
  <div>
    <div class="mb-1.5 sm:flex sm:flex-row flex-wrap">
      <div v-for="(field, index) in parameters" :key="index" class="sm:mr-4">
        <VFieldSelect
          v-if="field.type === 'enum'"
          v-model="field.value"
          :label="getTitleField(field)"
          :options="getEnumOptions(field)"
          returnValue="name"
          class="min-w-[11rem]"
        />

        <VFieldSelect
          v-if="field.type === 'query' && !field.multiValuesOptions"
          v-model="field.value"
          :label="getTitleField(field)"
          :options="queryOptions[field.name]"
          class="min-w-[11rem]"
        />

        <VFieldMultiSelect
          v-if="field.type === 'query' && field.multiValuesOptions"
          v-model="field.value"
          :label="getTitleField(field)"
          :options="queryOptions[field.name]"
          class="min-w-[11rem]"
          :key="field.value?.join('-')"
        />

        <VFieldDatepicker
          v-if="field.type === 'date'"
          :range="false"
          :label="getTitleField(field)"
          :clearable="false"
          :time="false"
          v-model="field.value"
        />

        <VFieldDatepicker
          v-if="field.type === 'datetime-range-with-seconds'"
          :range="true"
          :label="getTitleField(field)"
          :clearable="false"
          v-model="field.value"
          class="sm:w-[21rem]"
        />

        <BaseInput
          v-if="field.type === 'text' && !HIDDEN_FIELDS.includes(field.name)"
          :label="getTitleField(field)"
          v-model="field.value"
        />

        <BaseInput
          v-if="field.type === 'number' && !HIDDEN_FIELDS.includes(field.name)"
          type="number"
          :label="getTitleField(field)"
          v-model="field.value"
        />
      </div>
    </div>

    <div class="flex justify-end sm:flex-row flex-col pt-2 gap-y-3">
      <button class="btn btn-primary sm:mr-2 mr-0 px-4" @click="applyFilter">
        <SearchIcon class="w-4 h-4 mr-2" />
        {{ t('tableFilter.search') }}
      </button>

      <button class="btn px-4" @click="resetFilter">
        <RotateCcwIcon class="w-4 h-4 mr-2 " />
        {{ t('tableFilter.reset') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUnmounted, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useQuery } from './utils/query'
import { useCustomFilters } from './utils/customFilters'
import { ruQuery } from '@/locales/query'

const HIDDEN_FIELDS = ['page', 'per_page']

export default defineComponent({
  name: 'QueryFilter',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const query = useQuery()

    const parameters = ref(cloneDeep(props.query.options.parameters))
    const queryOptions = ref({})
    const customFilters = useCustomFilters(queryOptions, parameters)

    const applyFilter = () => {
      Object.entries(parameters.value).forEach(([index, param]) => {
        const isEmptyValue = () => ['query', 'enum'].includes(param.type) && !String(param.value).length

        if (isEmptyValue()) {
          param.value = props.query.options.parameters[index].value
        }
      })

      context.emit('applyFilter', parameters.value)
    }

    const resetFilter = () => {
      parameters.value = cloneDeep(props.query.options.parameters)
      onListenerMultiSelect()
      customFilters.resetRequestParams()
      context.emit('resetFilter')
    }

    const getTitleField = field => {
      if (field.name in ruQuery) {
        return `query.${field.name}`
      }

      return field.title
    }

    const getEnumOptions = field => {
      if (!field.enumOptions) {
        return []
      }

      const removeLineBreak = field.enumOptions.replace(/\n/gm, '/')
      const splitEnum = removeLineBreak.split('/')

      return splitEnum.map((value, index) => {
        return {
          id: index,
          name: value,
        }
      })
    }

    const generateDropwondOptions = () => {
      Object.values(parameters.value).forEach(param => {
        customFilters.checkFilter(param)

        if (param.type === 'query') {
          query.run(param)
        }
      })

      queryOptions.value = query.options.value
    }

    const onListenerMultiSelect = () => {
      Object.values(parameters.value).forEach(param => {
        if (!['query'].includes(param.type) && !param.multiValuesOptions) {
          return
        }

        watch(
          () => param.value,
          field => {
            const isEmptyValue = () => field.length === 0
            if (isEmptyValue()) {
              field.push('0')
              return
            }

            const isDeletedParamAll = () => field.length > 1 && field.includes('0') && field[0] === '0'
            if (isDeletedParamAll()) {
              field.forEach((value, index) => {
                if (value === '0') {
                  field.splice(index, 1)
                }
              })
              return
            }

            const isAddParamAll = () => field.length > 1 && field.includes('0') && field[0] !== '0'
            if (isAddParamAll()) {
              field.splice(0, field.length)
              field.push('0')
              return
            }
          }
        )
      })
    }

    onListenerMultiSelect()
    addEventListener('keyup', onEnter)

    function onEnter(e) {
      if (e?.key === 'Enter') {
        applyFilter()
      }
    }

    onUnmounted(() => {
      removeEventListener('keyup', onEnter)
    })

    generateDropwondOptions()

    return {
      t,
      parameters,
      queryOptions,
      HIDDEN_FIELDS,

      applyFilter,
      resetFilter,
      getTitleField,
      getEnumOptions,
    }
  },
})
</script>
