export const enVFileUploader = {
  uploadImage: 'Upload',
  loadingUploadImage: 'Loading...',
  successUploadImage: 'Image uploaded successfully',
  errorUploadImage: 'An error occurred while loading the image',

  invalidImageFormat: 'Invalid image format',
  invalidImageSize: 'File size exceeds 2 MB',
}

export const ruVFileUploader = {
  uploadImage: 'Загрузить',
  loadingUploadImage: 'Загрузка...',
  successUploadImage: 'Изображение успешно загружено',
  errorUploadImage: 'При загрузке изображения произошла ошибка',

  invalidImageFormat: 'Неверный формат изображения',
  invalidImageSize: 'Размер изображения превышает 2 MB',
}
