export const enAccount = {
  login: 'Username',
  fio: 'Name surname',
  email: 'Email',
  phone: 'Phone',
  telegram: 'Telegram',
  skype: 'Skype',
  token: 'Your token for external integrations',
  copyToken: 'Copy token',
  copyTokenSuccess: 'Token copied to clipboard',
  language: 'Language',
  saveButton: 'Confirm',
  successSave: 'Account settings successfully saved',
  apiConnected: 'API is connected',
  apiDisabled: 'API is not connected',
  apiDisabledMessage: 'Please, contact your manager to enable an API for you.',
}

export const ruAccount = {
  login: 'Логин',
  fio: 'ФИО',
  email: 'Почта',
  phone: 'Телефон',
  telegram: 'Телеграм',
  skype: 'Скайп',
  token: 'Ваш токен для внешних интеграций',
  copyToken: 'Скопировать токен',
  copyTokenSuccess: 'Токен скопирован в буфер обмена',
  language: 'Язык интерфейса',
  saveButton: 'Сохранить изменения',
  successSave: 'Настройки аккаунта успешно сохранены',
  apiConnected: 'API включен',
  apiDisabled: 'API выключен',
  apiDisabledMessage: 'Пожалуйста, обратитесь к менеджеру, чтобы включить API.',
}
