import { errorHandler } from '@/api/utils/ErrorHandler'
import { authApi } from './index'

const state = {}

const actions = {
  async getPermissions({ dispatch }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await authApi.get(`/permissions`, config)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
