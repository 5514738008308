import { format } from 'date-fns'

const DATE_FORMAT = 'yyyy-MM-dd'

const DATE = {
  d_now: {
    dateFormat: () => format(new Date(), DATE_FORMAT),
  },
  d_yesterday: {
    dateFormat: () => format(getLastDate(1), DATE_FORMAT),
  },
}

const getLastDate = day => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - day)
}

const isDateString = value => {
  return value in DATE
}

export const getDate = value => {
  if (isDateString(value)) {
    return DATE[value].dateFormat()
  }

  return value
}
