import { provide, ref } from 'vue'
import { isYesterday } from 'date-fns'
import { useToast } from 'vue-toastification'
import i18n from '@/i18n'
import VToastNotification from '@/components/VToastNotification.vue'

export const useNotification = store => {
  let interval = null
  const INTERVAL_TIME = 60000 // 1m.
  const unread = ref(null)
  const pushedToastIds = []

  const getCountUread = async () => {
    const response = await store.dispatch('notifications/getNotificationsCount')

    if (response) {
      const existNewNotification = () => unread.value !== null && response.unread > unread.value

      if (existNewNotification()) {
        pushToastNotification(response.unread)
      }

      unread.value = response.unread
    }
  }

  const pushToastNotification = async currentUnread => {
    const toast = useToast()
    const response = await store.dispatch('notifications/getNotifications', {
      read: 0,
      limit: currentUnread - unread.value || 1,
    })

    if (response) {
      response.data.forEach(notification => {
        const isAlreadySent = pushedToastIds.includes(notification.id)
        if (isAlreadySent) {
          return
        }

        pushedToastIds.push(notification.id)

        toast.warning(
          { component: VToastNotification, props: { record: notification } },
          {
            closeOnClick: false,
            timeout: 10000,
            icon: false,
          }
        )
      })
    }
  }

  const toggleRead = async notification => {
    switch (!!notification.read) {
      case true:
        notification.read = false
        ++unread.value
        await store.dispatch('notifications/setUnread', notification.id)
        break
      case false:
        notification.read = true
        --unread.value
        await store.dispatch('notifications/setRead', notification.id)
        break
    }
  }

  const runInterval = () => {
    if (interval) {
      return
    }

    interval = setInterval(() => getCountUread(), INTERVAL_TIME)
  }

  const run = () => {
    getCountUread()
    runInterval()
  }

  provide('injectGetCountUread', getCountUread)
  provide('injectToggleRead', toggleRead)
  provide('injectCountUnread', unread)

  return {
    run,
  }
}

export const useNotificationUtil = () => {
  const { t } = i18n.global

  const TYPE = {
    moderating: 1,
    entityAccess: 2,
    manager: 3,
  }

  const ENTITY = {
    campaign: 1,
    trafficSource: 2,
    callback: 3,
    trafficBacks: 4,
    offerWall: 5,
    landingWall: 6,
  }

  const STATUS = {
    moderating: 1,
    approved: 2,
    unapproved: 3,
  }

  const notifiForManager = notifi => notifi.type_id === TYPE.manager

  const setDateTime = async notifications => {
    const currentDateTime = new Date().getTime()

    notifications.value.forEach(notifi => {
      const notrifiDateTime = new Date(notifi.created_at).getTime()
      let duration = currentDateTime - notrifiDateTime

      // convert seconds
      const seconds = Math.trunc(duration / 1000)
      if (seconds < 60) {
        notifi.convert_date_time = t('notification.justNow')
        return
      }

      // convert minutes
      const msInMinute = 1000 * 60
      const minutes = Math.trunc(duration / msInMinute)
      if (minutes < 60) {
        notifi.convert_date_time = `${minutes} ${t('notification.minutesAgo')}`
        return
      }

      // convert hours
      const msInHour = 1000 * 60 * 60
      const hours = Math.trunc(duration / msInHour)
      if (hours < 24) {
        notifi.convert_date_time = `${hours} ${t('notification.hoursAgo')}`
        return
      }

      // convert a day ago
      const isDayAgo = isYesterday(new Date(notifi.created_at))
      if (isDayAgo) {
        notifi.convert_date_time = t('notification.yesterday')
        return
      }
    })
  }

  /* GENERATE TITLE */
  const getTitle = notification => {
    const isModerating = () => notification.status_id === STATUS.moderating
    if (isModerating()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.campaign.title')
          }
          return t('notification.messages.campaign.moderating.title')
        case ENTITY.trafficSource:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficSource.title')
          }
          return t('notification.messages.trafficSource.moderating.title')
        case ENTITY.callback:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.callback.title')
          }
          return t('notification.messages.callback.moderating.title')
        case ENTITY.trafficBacks:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficBacks.title')
          }
          return t('notification.messages.trafficBacks.moderating.title')
        case ENTITY.offerWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.offerWall.title')
          }
          return t('notification.messages.offerWall.moderating.title')
        case ENTITY.landingWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.landingWall.title')
          }
          return t('notification.messages.landingWall.moderating.title')
      }
    }

    const isApproved = () => notification.status_id === STATUS.approved
    if (isApproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.approved.title')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.approved.title')
        case ENTITY.callback:
          return t('notification.messages.callback.approved.title')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.approved.title')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.approved.title')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.approved.title')
      }
    }

    const isUnapproved = () => notification.status_id === STATUS.unapproved
    if (isUnapproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.unapproved.title')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.unapproved.title')
        case ENTITY.callback:
          return t('notification.messages.callback.unapproved.title')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.unapproved.title')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.unapproved.title')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.unapproved.title')
      }
    }
  }

  /* GENERATE ENTITI */
  const getDescriptionEntity = notification => {
    const isModerating = () => notification.status_id === STATUS.moderating
    if (isModerating()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.campaign.entity')
          }
          return t('notification.messages.campaign.moderating.entity')
        case ENTITY.trafficSource:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficSource.entity')
          }
          return t('notification.messages.trafficSource.moderating.entity')
        case ENTITY.callback:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.callback.entity')
          }
          return t('notification.messages.callback.moderating.entity')
        case ENTITY.trafficBacks:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficBacks.entity')
          }
          return t('notification.messages.trafficBacks.moderating.entity')
        case ENTITY.offerWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.offerWall.entity')
          }
          return t('notification.messages.offerWall.moderating.entity')
        case ENTITY.landingWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.landingWall.entity')
          }
          return t('notification.messages.landingWall.moderating.entity')
      }
    }

    const isApproved = () => notification.status_id === STATUS.approved
    if (isApproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.approved.entity')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.approved.entity')
        case ENTITY.callback:
          return t('notification.messages.callback.approved.entity')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.approved.entity')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.approved.entity')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.approved.entity')
      }
    }

    const isUnapproved = () => notification.status_id === STATUS.unapproved
    if (isUnapproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.unapproved.entity')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.unapproved.entity')
        case ENTITY.callback:
          return t('notification.messages.callback.unapproved.entity')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.unapproved.entity')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.unapproved.entity')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.unapproved.entity')
      }
    }
  }

  /* GENERATE STATUS */
  const getDescriptionStatus = notification => {
    const isModerating = () => notification.status_id === STATUS.moderating
    if (isModerating()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.campaign.status')
          }
          return t('notification.messages.campaign.moderating.status')
        case ENTITY.trafficSource:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficSource.status')
          }
          return t('notification.messages.trafficSource.moderating.status')
        case ENTITY.callback:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.callback.status')
          }
          return t('notification.messages.callback.moderating.status')
        case ENTITY.trafficBacks:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.trafficBacks.status')
          }
          return t('notification.messages.trafficBacks.moderating.status')
        case ENTITY.offerWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.offerWall.status')
          }
          return t('notification.messages.offerWall.moderating.status')
        case ENTITY.landingWall:
          if (notifiForManager(notification)) {
            return t('notification.messages.forManager.landingWall.status')
          }
          return t('notification.messages.landingWall.moderating.status')
      }
    }

    const isApproved = () => notification.status_id === STATUS.approved
    if (isApproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.approved.status')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.approved.status')
        case ENTITY.callback:
          return t('notification.messages.callback.approved.status')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.approved.status')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.approved.status')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.approved.status')
      }
    }

    const isUnapproved = () => notification.status_id === STATUS.unapproved
    if (isUnapproved()) {
      switch (notification.entity_type_id) {
        case ENTITY.campaign:
          return t('notification.messages.campaign.unapproved.status')
        case ENTITY.trafficSource:
          return t('notification.messages.trafficSource.unapproved.status')
        case ENTITY.callback:
          return t('notification.messages.callback.unapproved.status')
        case ENTITY.trafficBacks:
          return t('notification.messages.trafficBacks.unapproved.status')
        case ENTITY.offerWall:
          return t('notification.messages.offerWall.unapproved.status')
        case ENTITY.landingWall:
          return t('notification.messages.landingWall.unapproved.status')
      }
    }
  }

  const getEntityRouteName = notification => {
    switch (notification.entity_type_id) {
      case ENTITY.campaign:
        if (notifiForManager(notification)) {
          return 'campaigns'
        }
        return 'my-campaigns'
      case ENTITY.trafficSource:
        if (notifiForManager(notification)) {
          return 'traffic-source'
        }
        return 'my-traffic-source'
      case ENTITY.callback:
        if (notifiForManager(notification)) {
          return 'traffic-callback'
        }
        return 'my-traffic-callback'
      case ENTITY.trafficBacks:
        if (notifiForManager(notification)) {
          return 'traffic-back'
        }
        return 'my-traffic-back'
      case ENTITY.offerWall:
        if (notifiForManager(notification)) {
          return 'offers'
        }
        return 'offer-wall'
      case ENTITY.landingWall:
        if (notifiForManager(notification)) {
          return 'landings'
        }
        return 'landing-wall'
    }
  }

  return {
    setDateTime,
    getTitle,
    getDescriptionEntity,
    getDescriptionStatus,
    getEntityRouteName,
  }
}
