<template>
  <div class="v-confirm-dialog" v-if="showDialog">
    <div class="v-confirm-dialog-backdrop">
      <div class="v-confirm-dialog-container">
        <div class="modal-content">
          <div class="flex justify-between px-3 py-3 border-b">
            <span class="text-xl">{{ t(title) }}</span>

            <div class="flex items-center">
              <button type="button" @click="onCancel">
                <XIcon class="w-6 h-6 text-gray-700 hover:text-gray-900" />
              </button>
            </div>
          </div>

          <div class="v-confirm-dialog__modal-body">
            {{ t(message, { propName }) }}
          </div>

          <div class="v-confirm-dialog__modal-footer">
            <button type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal" @click="onCancel">
              {{ t(cancelButton) }}
            </button>
            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': !confirmClassBtn,
                'btn-success': confirmClassBtn === 'btn-success',
                'btn-danger': confirmClassBtn === 'btn-danger',
              }"
              @click="onConfirm"
            >
              {{ t(okButton) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VConfirmDialog',

  setup() {
    const showDialog = ref(false)

    const title = ref('')
    const message = ref('')
    const cancelButton = ref('confirmDialog.cancel')
    const okButton = ref('confirmDialog.agree')
    const propName = ref('')
    const confirmClassBtn = ref('')

    const resolvePromise = ref(undefined)
    const rejectPromise = ref(undefined)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const onShowDialog = (param = {}) => {
      title.value = param.title || 'confirmDialog.title'
      message.value = param.message || 'confirmDialog.message'
      okButton.value = param.okButton || 'confirmDialog.agree'
      cancelButton.value = param.cancelButton || 'confirmDialog.cancel'
      propName.value = param.propName || ''
      confirmClassBtn.value = param.confirmClassBtn || ''

      showDialog.value = true

      return new Promise((resolve, reject) => {
        resolvePromise.value = resolve
        rejectPromise.value = reject
      })
    }

    const onConfirm = () => {
      showDialog.value = false
      resolvePromise.value(true)
    }

    const onCancel = () => {
      showDialog.value = false
      resolvePromise.value(false)
    }

    watch(
      () => showDialog.value,
      value => {
        if (value) {
          document.documentElement.style.overflow = 'hidden'
          return
        }

        document.documentElement.style.overflow = 'auto'
      }
    )

    return {
      onShowDialog,
      onConfirm,
      onCancel,
      showDialog,
      t,

      title,
      message,
      cancelButton,
      okButton,
      propName,
      confirmClassBtn,
    }
  },
})
</script>

<style lang="scss">
.v-confirm-dialog {
  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
  }

  &-container {
    animation: fadeIn ease 0.3s;
    margin: 50px;
    width: 500px;
  }

  &__modal-footer {
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: right;
  }

  &__modal-body {
    padding: 1.25rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
