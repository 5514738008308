import { ref } from 'vue'
import Velocity from 'velocity-animate'

// Toggle mobile menu
const activeMobileMenu = ref(false)
const toggleMobileMenu = () => {
  activeMobileMenu.value = !activeMobileMenu.value
}

// Setup mobile menu
const linkTo = (menu, router) => {
  if (menu.subMenu?.length > 0) {
    menu.activeDropdown = !menu.activeDropdown
  } else {
    activeMobileMenu.value = false

    const isReport = menu.pageName.includes('report/')
    const isQuery = menu.pageName.includes('query/')

    if (isReport || isQuery) {
      const [name, id] = menu.pageName.split('/')

      router.push({
        name: name,
        params: { id: id, transitionFromMenu: true },
      })
    } else {
      router.push({
        name: menu.pageName,
      })
    }

    cash('#mobile-modal-slide-over').modal('hide')
  }
}

const enter = (el, done) => {
  Velocity(el, 'slideDown', { duration: 300 }, { complete: done })
}

const leave = (el, done) => {
  Velocity(el, 'slideUp', { duration: 300 }, { complete: done })
}

export { activeMobileMenu, toggleMobileMenu, linkTo, enter, leave }
