<template>
  <div>
    <label class="block mt-3.5 mb-0.5">{{ t(props.label) }}</label>

    <Datepicker
      @update:modelValue="send"
      :modelValue="date"
      :format="time ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
      :startTime="startTime"
      :enableTimePicker="time"
      :clearable="clearable"
      :range="range"
      :locale="$i18n.locale"
      :selectText="t('common.ok')"
      :cancelText="t('common.cancel')"
      enableSeconds
      modelType="format"
      monthNameFormat="long"
      :presetRanges="range && time && presetRanges"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from 'date-fns'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  time: {
    type: Boolean,
    default: true,
  },
  range: {
    type: Boolean,
    default: true,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

let date = computed(() => {
  return props.modelValue
})

const startTime = ref([
  { hours: 0, minutes: 0, seconds: 0 },
  { hours: 23, minutes: 59, seconds: 59 },
])

function send(event) {
  if (props.range && event && event[1] === null) {
    const [date, time] = event[0].split(' ')

    if (!time) {
      event[1] = event[0]
    } else {
      event[1] = `${date} 23:59:59`
    }
  }

  emit('update:modelValue', event)
}

const getLastDate = day => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - day)
}

const presetRanges = ref([
  {
    label: t('common.today'),
    range: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: t('common.yesterday'),
    range: [startOfYesterday(new Date()), endOfYesterday(new Date())],
  },
  {
    label: t('common.thisWeek'),
    range: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1 })],
  },
  {
    label: t('common.lastWeek'),
    range: [startOfWeek(getLastDate(7), { weekStartsOn: 1 }), endOfWeek(getLastDate(7), { weekStartsOn: 1 })],
  },
  {
    label: t('common.thisMonth'),
    range: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: t('common.lastMonth'),
    range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
  },
  {
    label: t('common.thisYear'),
    range: [startOfYear(new Date()), endOfYear(new Date())],
  },

  {
    label: t('common.last7days'),
    range: [startOfDay(getLastDate(7)), endOfDay(new Date())],
  },
  {
    label: t('common.last14days'),
    range: [startOfDay(getLastDate(14)), endOfDay(new Date())],
  },
  {
    label: t('common.last30days'),
    range: [startOfDay(getLastDate(30)), endOfDay(new Date())],
  },
  {
    label: t('common.last60days'),
    range: [startOfDay(getLastDate(60)), endOfDay(new Date())],
  },
  {
    label: t('common.last90days'),
    range: [startOfDay(getLastDate(90)), endOfDay(new Date())],
  },
])
</script>

<style lang="scss">
.dp__input {
  font-family: Roboto;
  font-size: 14px;
  border-color: rgba(226, 232, 240, 1);
  border-radius: 0.375rem;
  border-width: 1px;
  height: 40px;
}

.dp__select {
  @apply bg-theme-1 border-theme-1;
  color: rgba(255, 255, 255, 1);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.dp__cancel {
  border-color: rgba(226, 232, 240);
  background-color: rgba(0, 0, 0, 0);
  color: rgb(45, 55, 72);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  border-width: 1px;
  margin-right: 0.5rem;
}

.dp__action_buttons {
  width: 100%;
  text-align: right;
}

.dp__menu,
.dp__calendar_wrap,
.dp__time_input {
  font-family: Roboto;
  font-size: 14px;
}

.dp__active_date {
  @apply bg-theme-1;
}

.dp__today {
  @apply border-theme-1;
}

.dp__preset_ranges {
  padding-right: 1rem;
}
</style>
