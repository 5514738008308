<template>
  <div>
    <div v-if="offer.payment_model.name === 'CPA'">
      <CpaForm :offer="offer" :options="options" @close="$emit('close')" />
    </div>
    <div v-else>
      <RevShareForm :offer="offer" :options="options" @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import RevShareForm from './RevShareForm.vue'
import CpaForm from './CpaForm.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    CpaForm,
    RevShareForm,
  },
  name: 'ServiceControlForm',
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()
    const countryId = props.offer.country?.id

    const options = ref({
      operators: [],
      services: [],
      currency: [],
    })

    const getOptionsOperators = async () => {
      const operators = await store.dispatch('operators/getOperators', { country: countryId, full: false })
      options.value.operators = operators.data ? operators.data : []
    }

    const getOptionsServices = async () => {
      const services = await store.dispatch('services/getServices', { country: countryId, full: false })
      options.value.services = services.data ? services.data : []
    }

    const getOptionsCurrency = async () => {
      const currency = await store.dispatch('currency/getCurrencies', { full: false })
      options.value.currency = currency.data ? currency.data : []
    }

    getOptionsOperators()
    getOptionsServices()
    getOptionsCurrency()

    return {
      options,
    }
  },
})
</script>
