<template>
  <div class="variables-map">
    <div class="flex justify-between my-4">
      <h4 class="text-lg font-medium">{{ title }}</h4>

      <button
        type="button"
        class="btn btn-success ml-3"
        @click="variablesList.push({ name: '', value: '' })"
        :disabled="disabledAddButton"
        :title="disabledAddButton ? t('variablesMap.diasbledAddVariableTitle') : t('variablesMap.addVariableTitle')"
      >
        <PlusIcon class="w-4 h-4 mr-1" />
        {{ t('variablesMap.addVariable') }}
      </button>
    </div>

    <div v-for="(variable, index) in variablesList" :key="index">
      <div class="flex flex-row mb-3 items-center">
        <DatabaseIcon class="w-5 h-5 mr-2" />

        <VFieldSelect
          class="w-2/4"
          :options="options.variables"
          v-model="variablesList[index].name"
          label=""
          returnValue="name"
        />

        <GitCommitIcon class="w-7 h-6" />

        <div class="w-2/4">
          <BaseInput label="baseInput.label" v-model="variablesList[index].value" />
        </div>

        <button
          type="button"
          class="ml-3"
          :title="t('variablesMap.deleteVariable')"
          @click="variablesList.splice(index, 1)"
        >
          <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-6" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'VariablesMap',
  props: {
    title: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Object,
      default: () => {
        return { admin: true }
      },
    },
  },

  setup(props, context) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const options = ref({
      variables: [],
    })

    const variablesList = ref(props.modelValue)

    const disabledAddButton = computed(() => {
      return variablesList.value.some(
        variable => !variable.name.replace(/\s/g, '') || !variable?.value.replace(/\s/g, '')
      )
    })

    const getInternalVariables = async () => {
      const variables = await store.dispatch('internalVariables/getInternalVariables', { full: true, ...props.type })
      options.value.variables = variables ? variables : []
    }

    watch(
      () => variablesList.value,
      () => context.emit('update:modelValue', variablesList.value),
      { deep: true }
    )

    getInternalVariables()

    return {
      options,
      variablesList,
      disabledAddButton,

      t,
    }
  },
})
</script>
