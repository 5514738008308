import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getArchiveOfferRules({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/archive_offer_rules', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getArchiveOfferRule(_, id) {
    try {
      const response = await http.get(`/archive_offer_rules/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async createArchiveOfferRule(_, params) {
    try {
      const response = await http.post('/archive_offer_rules', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateArchiveOfferRule(_, params) {
    try {
      const response = await http.put(`/archive_offer_rules/${params.id}`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
