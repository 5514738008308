import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getDomains({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/domains', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getDomainById(_, id) {
    try {
      return await http.get(`/domains/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
