import { api } from './index'

const state = {}

const actions = {
  async getCallbackEvents({ dispatch }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/callback_events`, config)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  async getCallbackEventsById({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/callback_events/${id}`, config)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
