<template>
  <div>
    <div id="programmatically-dropdown" class="intro-x dropdown">
      <div class="dropdown-toggle overflow-hidden select-none" role="button" aria-expanded="false">
        <span class="flex flex-row items-center rounded-lg">
          <span class="flex items-center justify-center text-lg ">
            <div class="flex flex-col items-end">
              <span class="text-sm">{{ user?.name || '' }}</span>
              <span class="text-xs text-gray-600">{{ user?.email || '' }}</span>
            </div>
            <UserIcon class="h-9 w-9 ml-3 text-gray-600 bg-gray-300 hover:text-gray-700 p-1 rounded-full" />
          </span>
        </span>
      </div>

      <div class="dropdown-menu w-max">
        <div class="dropdown-menu__content flex w-full p-2 box mt-1 min-w-[270px]">
          <ul class="flex flex-col w-full">
            <li class="my-px">
              <span class="flex font-medium text-sm text-gray-600 px-4 my-2 uppercase">
                {{ t('sideMenu.cabinet') }}
              </span>
            </li>

            <li class="my-px">
              <router-link
                :to="{ name: 'my-campaigns' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <BriefcaseIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.campaigns') }}</span>
              </router-link>
            </li>

            <!-- <li class="my-px">
                <router-link
                  :to="{ name: 'payment-details' }"
                  type="button"
                  @click="hide"
                  href="#"
                  class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
                >
                  <span class="flex items-center justify-center text-lg">
                    <DollarSignIcon class="h-6 w-6" />
                  </span>
                  <span class="ml-3">{{ t('sideMenu.paymentDetails') }}</span>
                </router-link>
              </li> -->

            <li class="my-px">
              <router-link
                :to="{ name: 'my-traffic-source' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <CropIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.trafficSource') }}</span>
              </router-link>
            </li>

            <li class="my-px">
              <router-link
                :to="{ name: 'my-traffic-callback' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <ActivityIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.trafficCallback') }}</span>
              </router-link>
            </li>

            <!-- <li class="my-px">
                <router-link
                  :to="{ name: 'my-traffic-back' }"
                  type="button"
                  @click="hide"
                  href="#"
                  class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
                >
                  <span class="flex items-center justify-center text-lg">
                    <GitBranchIcon class="h-6 w-6" />
                  </span>
                  <span class="ml-3">{{ t('sideMenu.trafficBack') }}</span>
                </router-link>
              </li> -->

            <li class="my-px">
              <span class="flex font-medium text-sm text-gray-600 px-4 my-2 uppercase">
                {{ t('sideMenu.account') }}
              </span>
            </li>

            <li class="my-px">
              <router-link
                :to="{ name: 'notifications' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <BellIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.notifications') }}</span>

                <span
                  v-if="countUnread"
                  class="ml-auto bg-red-300 text-red-800 text-xs font-medium px-1.5 py-0.5 rounded"
                >
                  {{ countUnread }}
                </span>
              </router-link>
            </li>

            <li class="my-px">
              <router-link
                :to="{ name: 'account-profile' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <SettingsIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.settingProfile') }}</span>
              </router-link>
            </li>

            <li class="my-px">
              <router-link
                :to="{ name: 'change-password' }"
                type="button"
                @click="hide"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg">
                  <RepeatIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.changePassword') }}</span>
              </router-link>
            </li>

            <li class="my-px">
              <a
                v-if="token"
                @click="handleLogOut"
                type="button"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg text-theme-6">
                  <UnlockIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.logOut') }}</span>
              </a>
              <router-link
                v-else
                :to="{ path: 'login' }"
                type="button"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="flex items-center justify-center text-lg text-theme-4">
                  <UnlockIcon class="h-6 w-6" />
                </span>
                <span class="ml-3">{{ t('sideMenu.logIn') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'

export default defineComponent({
  name: 'TopBarTranslation',

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const router = useRouter()

    const countUnread = inject('injectCountUnread')
    const token = computed(() => store.state.auth.accessToken)
    const user = computed(() => store.state.auth.user)

    const handleLogOut = () => {
      store.dispatch('auth/sendLogout')
      router.push({
        name: 'login',
      })
      hide()
    }

    const hide = () => {
      cash('#programmatically-dropdown').dropdown('hide')
    }

    return {
      countUnread,
      token,
      user,
      handleLogOut,
      hide,
      t,
    }
  },
})
</script>
