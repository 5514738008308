export const enRole = {
  addRoleButton: 'Add role',

  addRole: 'Add role',
  editRole: 'Edit role',

  id: 'ID',
  name: 'Name',
  description: 'Description',
  author: 'Author',

  access: 'Access',

  confirmDialog: {
    title: 'Deleting a role',
    message: 'Do you really want to remove the role?',
  },

  save: 'Save',
  cancel: 'Cancel',

  displayingReports: 'Displaying reports',
}

export const ruRole = {
  addRoleButton: 'Добавить роль',

  addRole: 'Добавление роли',
  editRole: 'Редактирование роли',

  id: 'ID',
  name: 'Название',
  description: 'Описание',
  author: 'Автор',

  access: 'Доступы',

  confirmDialog: {
    title: 'Удаление роли',
    message: 'Вы действительно хотите удалить роль?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',

  displayingReports: 'Отображение отчетов',
}
