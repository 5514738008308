<template>
  <div>
    <GroupRadio
      radioGroupClass="flex flex-wrap gap-y-3 gap-x-6 sm:gap-x-5"
      v-model="rotation"
      @change="sortingLandings()"
      :options="[
        { name: t('campaign.rotation.random'), id: 1 },
        { name: t('campaign.rotation.order'), id: 2 },
        { name: t('campaign.rotation.weight'), id: 3 },
      ]"
      :title="t('campaign.rotationType')"
    />

    <div class="mt-4">
      <div v-if="loading" class="flex justify-center my-6">
        <LoadingIcon icon="tail-spin" class="w-8 h-8" />
      </div>
      <div v-else>
        <div v-if="!landings.length" class="alert bg-theme-12 bg-opacity-40 show flex items-center mb-2">
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          {{ t('vTable.noDataMessage') }}
        </div>

        <ul v-else class="flex flex-col w-full">
          <div class="flex flex-row my-1 px-4">
            <span class="font-medium text-sm uppercase ml-24">{{ t('campaign.name') }}</span>
            <div class="ml-auto">
              <span class="font-medium text-sm uppercase mr-28">{{ t('campaign.order') }}</span>
              <span class="font-medium text-sm uppercase mr-10">{{ t('campaign.weight') }}</span>
            </div>
          </div>

          <li
            v-for="landing in landings"
            :key="landing.id"
            class="my-1 cursor-pointer"
            @click="onClickLanding($event, landing)"
          >
            <div
              class="flex flex-row items-center py-2 px-4 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
              :class="{
                'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': landing.selected,
              }"
            >
              <BaseCheckbox class="mr-3" v-model="landing.selected" />

              <span class="image-fit w-[45px] h-[45px] select-none mr-3">
                <img
                  class="rounded"
                  :src="landing.image || require('@/assets/images/no_image.jpg').default"
                  @error="$event.target.src = require('@/assets/images/no_image.jpg').default"
                  data-action="zoom"
                />
              </span>

              <div class="flex flex-col gap-y-1.5 font-medium">
                <span>{{ landing.name }}</span>
                <span class="text-xs">
                  <span class="text-xs font-medium rounded text-gray-600">ID: {{ landing.id }}</span>
                </span>
              </div>

              <div class="flex flex-row items-center ml-auto">
                <div v-if="landing.selected" class="flex flex-row items-center mr-20">
                  <button type="order" @click.stop="onChangeOrder(landing, 'up')">
                    <ChevronUpIcon class="w-5 h-5 cursor-pointer hover:bg-theme-31 hover:bg-opacity-20 rounded-md" />
                  </button>

                  <div class="px-3">{{ landing.order }}</div>

                  <button @click.stop="onChangeOrder(landing, 'down')">
                    <ChevronDownIcon class="w-5 h-5 cursor-pointer hover:bg-theme-6 hover:bg-opacity-20 rounded-md" />
                  </button>
                </div>

                <div v-if="landing.selected" class="w-24">
                  <BaseInput type="number" label="" v-model="landing.weight" />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
        <XIcon class="w-4 h-4 mr-1" />
        {{ t('campaign.close') }}
      </button>

      <button type="submit" class="btn btn-primary" @click="handleSubmit">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('campaign.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { inject, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'CampaignLandingsDialog',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const store = useStore()
    const toast = useToast()
    const getCountUread = inject('injectGetCountUread')

    const loading = ref(true)
    const rotation = ref(null)
    const landings = ref([])

    const handleSubmit = async () => {
      if (checkDuplicateOrder()) {
        return
      }

      let data = []

      landings.value.forEach(landing => {
        if (landing.selected) {
          data.push({
            id: landing.id,
            order: landing.order,
            weight: Number(landing.weight),
          })
        }
      })

      const result = await store.dispatch(
        props.isPartner ? 'myCampaigns/updateLandingsToCampaign' : 'campaigns/updateLandingsToCampaign',
        {
          campaign_id: props.campaign.id,
          rotation_type: rotation.value || 1,
          landings: data,
        }
      )

      const errors = result.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      getCountUread()
      context.emit('close')
    }

    const checkDuplicateOrder = () => {
      for (const landing of landings.value) {
        if (!landing.selected) {
          return
        }

        const isDuplicateOrder = landings.value.find(el => el.order == landing.order && el.id !== landing.id)
        if (isDuplicateOrder) {
          toast.error(t('campaign.duplicateOrderError'), {
            closeOnClick: false,
          })

          return true
        }
      }
      return false
    }

    const sortingLandings = () => {
      switch (rotation.value) {
        case 1: // id
          landings.value.sort((a, b) => a.id - b.id)
          break
        case 2: // order
          landings.value.sort((a, b) => a.order - b.order)
          break
        case 3: // weight
          landings.value.sort((a, b) => b.weight - a.weight)
          break
      }
    }

    const onChangeOrder = (landing, type) => {
      switch (type) {
        case 'up':
          landing.order++
          break
        case 'down':
          landing.order > 1 && landing.order--
          break
      }
    }

    const onClickLanding = (e, landing) => {
      const isImage = e?.target?.getAttribute('data-action') === 'zoom'
      const isInput = e.target.type === 'number'

      if (isImage || isInput) {
        return
      }

      let order = 1
      landings.value.forEach(landing => {
        if (landing.selected && landing.order + 1 > order) {
          order = landing.order + 1
        }
      })

      landing.order = order
      landing.selected = !landing.selected
    }

    const getLandings = async () => {
      const lpOffers = await store.dispatch(
        props.isPartner ? 'offerWall/getLandingsToOffer' : 'offers/getLandingsToOffer',
        props.campaign.offer.id
      )
      const lpCampaigns = await store.dispatch(
        props.isPartner ? 'myCampaigns/getLandingsToCampaign' : 'campaigns/getLandingsToCampaign',
        props.campaign.id
      )

      rotation.value = lpCampaigns.data.rotation_type

      lpOffers.data.data.forEach(offerLp => {
        let selected = false
        let order = 999
        let weight = 0

        for (const campaignLp of lpCampaigns?.data.landings) {
          const isSelected = () => offerLp.id === campaignLp.id

          if (isSelected()) {
            selected = true
            order = campaignLp.order
            weight = campaignLp.weight
            break
          }
        }

        landings.value.push({
          ...offerLp,
          selected: selected,
          order: order,
          weight: weight,
        })
      })

      sortingLandings()
      loading.value = false
    }

    getLandings()

    return {
      landings,
      rotation,
      loading,

      t,
      onClickLanding,
      handleSubmit,
      onChangeOrder,
      sortingLandings,
    }
  },
})
</script>
