import { errorHandler } from '@/api/utils/ErrorHandler'
import { http_redash, http_redash_blob } from '@/api/http'

const actions = {
  /* Queries */
  async getQueries() {
    try {
      const response = await http_redash.get('/query')
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async getQueryById(_, id) {
    try {
      const response = await http_redash.get(`/query/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async getQueryResult(_, params) {
    try {
      const response = await http_redash.post('/query/result', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async getDropdownOptions(_, param) {
    try {
      const response = await http_redash.get(`/query/${param.queryId}/dropdown/${param.dropdownId}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Jobs */
  async getJobResult(_, id) {
    try {
      const response = await http_redash.get(`/job/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Results */
  async getReport(_, id) {
    try {
      const response = await http_redash.get(`/result/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Export */
  async exportTable(_, params) {
    try {
      const response = await http_redash_blob.get(
        `/result/${params.queryId}/download/${params.resultId}.${params.format}`
      )
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Permissions reports */
  async getPermissions() {
    try {
      const response = await http_redash.get('/permissions')
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async getPermissionsById(_, id) {
    try {
      const response = await http_redash.get(`/permissions/${id}`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async addPermissions(_, params) {
    try {
      const response = await http_redash.post(`/permissions/${params.role_id}`, { permissions: params.permissionsIds })
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deletePermissions(_, params) {
    try {
      const response = await http_redash.delete(`/permissions/${params.role_id}`, {
        data: { permissions: params.permissionsIds },
      })
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state: {},
  actions,
}
