export const isLocal = () => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
  if (LOCAL_DOMAINS.includes(window.location.hostname)) {
    return true
  }
  return false
}

export const isDev = () => {
  const DEV_DOMAINS = ['develop.sigmadev.ru']
  if (DEV_DOMAINS.includes(window.location.hostname)) {
    return true
  }
  return false
}

export const isProd = () => {
  const PROD_DOMAINS = ['cabinet.sigmamobi.com']
  if (PROD_DOMAINS.includes(window.location.hostname)) {
    return true
  }
  return false
}
