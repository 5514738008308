<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="text-right">
        <button type="button" @click="createSource" class="btn btn-success">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('trafficSource.addSourceButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getSources"
        @applyFilter=";(table.pagination.pageData.page = 1), getSources()"
      >
        <BaseInput type="number" class="w-24" label="trafficSource.id" name="id" v-model="table.filter.id" />

        <BaseInput label="trafficSource.name" name="name" v-model="table.filter.name" />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getSources"
        @edit="editSource"
        @delete="deleteSource"
        @restore="restoreSource"
        @updateSorting="sort => ((table.sorting = sort), getSources())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="copySource(slotProps.id)">
            <Tippy :content="t('trafficSource.copySource')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showModal"
      @close="closeSourceForm"
      :textHeader="source.id ? t('trafficSource.editSource') : t('trafficSource.addSource')"
      :recordId="source.id"
    >
      <TrafficSourceForm @close="closeSourceForm" @refresh="getSources" :source="source" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, inject } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import TrafficSourceForm from './TrafficSourceForm.vue'

export default defineComponent({
  name: 'TrafficSource',
  components: {
    TrafficSourceForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const confirmDialog = ref(null)
    const getMonitoring = inject('injectMonitoring')
    const getMonitoringCount = inject('injectMonitoringCount')

    const sources = ref([])
    const source = ref({})

    const showModal = ref(false)

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: getMonitoringCount.value.trafficSource > 0 ? 'ASC' : 'DESC',
        order_by: getMonitoringCount.value.trafficSource > 0 ? 'status_id' : 'created_at',
      },
      columns: {
        id: { label: t('trafficSource.id'), sortable: true, sort_name: 'id' },
        name: { label: t('trafficSource.name'), sortable: true, sort_name: 'name' },
        author: t('trafficSource.author'),
        created_at: { label: t('trafficSource.created_at'), sortable: true, sort_name: 'created_at' },
        status: { label: t('trafficSource.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return sources.value.map(source => {
          return {
            id: source.id,
            name: source.name,
            author: source.author,
            created_at: new Date(source.created_at).toLocaleString() || '',
            status: source.status?.id,
            is_deleted: !!source.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const createSource = () => {
      source.value = {
        variables_map: [{ name: 'clickid', value: '' }],
      }
      showModal.value = true
    }

    const editSource = async id => {
      const response = await store.dispatch('trafficSource/getTrafficSourceById', id)

      if (response.data) {
        router.push({ params: { id: id } })
        source.value = response.data
        showModal.value = true
      }
    }

    const copySource = async id => {
      const response = await store.dispatch('trafficSource/getTrafficSourceById', id)

      if (response.data) {
        response.data = {
          ...response.data,
          id: '',
          name: `${response.data.name} | COPY`,
        }

        source.value = response.data
        showModal.value = true
      }
    }

    const deleteSource = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'trafficSource.confirmDialog.title',
        message: 'trafficSource.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        await store.dispatch('trafficSource/deleteTrafficSource', id)
        getSources()
        getMonitoring()
      }
    }

    const restoreSource = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'trafficSource.confirmRestoreDialog.title',
        message: 'trafficSource.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('trafficSource/restoreTrafficSource', id).then(() => {
          getSources()
          getMonitoring()
        })
      }
    }

    const getSources = async () => {
      const response = await store.dispatch('trafficSource/getTrafficSources', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response?.data) {
        response.data = await setAuthorName(response.data)
        sources.value = response.data
        table.value.pagination.meta = response.meta
      }
    }

    const setAuthorName = async sources => {
      const authorIds = Array.from(sources, ({ author_id }) => Number(author_id))
      const uniqAuthorIds = authorIds.filter((id, index) => authorIds.indexOf(id) === index)
      const users = await store.dispatch('users/getUsersName', uniqAuthorIds)

      return sources.map(source => {
        const authorName = users[source.author_id] || ''

        return { ...source, author: authorName }
      })
    }

    const closeSourceForm = () => {
      showModal.value = false
      router.push({ params: { id: '' } })
    }

    if (route.params.id) {
      editSource(route.params.id)
    }

    getSources()

    return {
      t,
      sources,
      source,
      table,
      confirm,
      createSource,
      editSource,
      copySource,
      deleteSource,
      restoreSource,
      getSources,
      closeSourceForm,
      confirmDialog,
      showModal,
    }
  },
})
</script>
