export const enOfferWall = {
  id: 'ID',
  name: 'Name',
  category: 'Category',
  paymentModel: 'Payment method',
  status: 'Status',
  country: 'Country',
  offer: 'Offer',
  trafficSource: 'Traffic source',
  callback: 'Callback',
  trafficBack: 'Trafficback',

  createCompany: 'Setup campaign',
  requestAccess: 'Request access',
  accessRequested: 'Access requested',
  accessDenied: 'Access is denied',
  successAccessMessage: 'Access to the offer was successfully requested',
  list: 'List',
  grid: 'Wall',
  info: 'Information',
  active: 'Active',
  notActive: 'Not active',
  private: 'Private',
  forbiddenSources: 'Permitted sources',
  description: 'Description',
  basicInformation: 'Basic information',
  commercialConditions: 'Commercial terms',
  landings: 'Landings',

  offerId: 'offer ID',
  flowType: 'Flow type',
  qtyLandings: 'Qty landings',

  operator: 'Operator',
  trial: 'Trial',
  trialPrice: 'Activation price',
  schedule: 'Schedule',

  payout_cpa: 'Payout CPA',
  payout_revshare: 'Payout Revshare',

  save: 'Save',
  cancel: 'Cancel',
  close: 'Close',
}

export const ruOfferWall = {
  id: 'ID',
  name: 'Название',
  category: 'Категория',
  paymentModel: 'Тип оплаты',
  status: 'Статус',
  country: 'Страна',
  offer: 'Оффер',
  trafficSource: 'Источник трафика',
  callback: 'Коллбэк',
  trafficBack: 'Трафикбэк',

  createCompany: 'Создать кампанию',
  requestAccess: 'Запросить доступ',
  accessRequested: 'Доступ запрошен',
  accessDenied: 'Доступ запрещен',
  successAccessMessage: 'Доступ к офферу успешно запрошен',
  list: 'Список',
  grid: 'Сетка',
  info: 'Информация',
  active: 'Активен',
  notActive: 'Не активен',
  private: 'Приватность',
  forbiddenSources: 'Разрешенные источники',
  description: 'Описание',
  basicInformation: 'Основная информация',
  commercialConditions: 'Коммерческие условия',
  landings: 'Лендинги',

  offerId: 'ID оффера',
  flowType: 'Способ оплаты',
  qtyLandings: 'Кол-во лендингов',

  operator: 'Оператор',
  trial: 'Триал',
  trialPrice: 'Цена подключения',
  schedule: 'Периодичность',

  payout_cpa: 'Отчисления (CPA)',
  payout_revshare: 'Отчисления (REVSHARE)',

  save: 'Сохранить',
  cancel: 'Отменить',
  close: 'Закрыть',
}
