import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import landings from './landings'
import users from './users'
import currency from './currency'
import providers from './providers'
import operators from './operators'
import services from './services'
import offers from './offers'
import offerWall from './offerWall'
import flowTypes from './flow-types'
import auth from './auth'
import axios from 'axios'
import countries from './countries'
import offerPrivacyTypes from './offer-privacy-types'
import paymentModels from './payment-models'
import forbiddenSources from './forbidden-sources'
import callbackEvents from './callback-events'
import trafficSource from './traffic-source'
import myTrafficSource from './myTrafficSource'
import callbacks from './callbacks'
import myTrafficCallbacks from './myTrafficCallbacks'
import trafficbacks from './trafficbacks'
import myTrafficbacks from './myTrafficbacks'
import roles from './roles'
import permissions from './permissions'
import dashboards from './dashboards'
import campaigns from './campaigns'
import myCampaigns from './myCampaigns'
import domains from './domains'
import internalVariables from './internalVariables'
import enrollmentRules from './enrollmentRules'
import statuses from './statuses'
import images from './images'
import reports from './reports'
import access from './access'
import monitoring from './monitoring'
import landingWall from './landingWall'
import notifications from './notifications'
import blacklistMsisdn from './blacklist-msisdn'
import blacklistHeader from './blacklist-header'
import blacklistReferer from './blacklist-referer'
import blacklistIp from './blacklist-ip'
import blacklistUserAgent from './blacklist-userAgent'
import blacklistSource from './blacklist-sources'
import archiveOfferRules from './archiveOfferRules'
import archiveCampaignRules from './archiveCampaignRules'

export const authApi = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_AUTH_URL}`,
})

export const api = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_BASE_URL}`,
})

const store = createStore({
  actions: {
    async buildAxiosConfigWithCredentials({ state }) {
      return {
        headers: {
          Authorization: `Bearer ${state.auth.accessToken}`,
        },
      }
    },
  },
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    landings,
    auth,
    users,
    currency,
    providers,
    operators,
    flowTypes,
    countries,
    offerPrivacyTypes,
    paymentModels,
    forbiddenSources,
    callbackEvents,
    trafficSource,
    myTrafficSource,
    callbacks,
    myTrafficCallbacks,
    trafficbacks,
    myTrafficbacks,
    services,
    offers,
    offerWall,
    roles,
    permissions,
    dashboards,
    campaigns,
    myCampaigns,
    domains,
    internalVariables,
    enrollmentRules,
    statuses,
    images,
    reports,
    access,
    monitoring,
    landingWall,
    notifications,
    blacklistMsisdn,
    blacklistHeader,
    blacklistReferer,
    blacklistIp,
    blacklistUserAgent,
    blacklistSource,
    archiveOfferRules,
    archiveCampaignRules,
  },
})

export function useStore() {
  return store
}

export default store
