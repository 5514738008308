<template>
  <div
    class="v-offer-card box cursor-pointer zoom-in select-none hover:shadow-xl shadow-md"
    @click="$emit('clickCard', offer.id)"
  >
    <div class="w-full image-fit h-[320px]">
      <img
        class="rounded-t-md"
        :src="offer.image || require(`@/assets/images/no_image.jpg`).default"
        @error="$event.target.src = require(`@/assets/images/no_image.jpg`).default"
      />
    </div>

    <div class="flex items-start px-1.5 mt-1.5">
      <div class="flex items-center flex-nowrap bg-blue-200 text-blue-800 text-xs font-medium px-1.5 py-1 rounded">
        <span class="mr-0.5">ID:</span>
        <span>{{ offer.id }}</span>
      </div>
      <div class="font-medium text-base ml-1.5 break-all">{{ offer.name }}</div>
    </div>

    <div class="px-3 py-2 mb-[44px]">
      <p class="v-offer-card__text-indent">
        <span class="text-gray-600 text-xs uppercase font-medium mr-1.5">{{ t('offerWall.country') }}:</span>
        <span>
          {{ `[${offer.country?.alpha_2_code}] ${translation.getCountry(offer.country)}` }}
        </span>
      </p>

      <p class="mt-1">
        <span class="text-gray-600 text-xs uppercase font-medium mr-1.5">{{ t('offerWall.category') }}:</span>
        <span>{{ offer.category?.en_name }}</span>
      </p>

      <p class="mt-1 flex flex-row items-center">
        <span class="text-gray-600 text-xs uppercase font-medium mr-1.5">{{ t('offerWall.flowType') }}:</span>
        <span class="flex flex-row items-center">
          <spam>{{ offer.flow_type.name }}</spam>
          <Tippy :content="offer.flow_type.description" class="ml-1.5 flex flex-row items-center">
            <InfoIcon class="w-4 h-4 cursor-pointer text-theme-3" />
          </Tippy>
        </span>
      </p>

      <p class="mt-1">
        <span class="text-gray-600 text-xs uppercase font-medium mr-1.5">{{ t('offerWall.qtyLandings') }}:</span>
        <span>
          {{ offer.count_landings ?? '-' }}
        </span>
      </p>

      <p class="mt-1">
        <span class="text-gray-600 text-xs uppercase font-medium mr-1.5">Payout:</span>
        <span>
          {{
            `CPA: ${offer.payout_cpa?.count_payouts > 1 ? 'up to:' : ''} ${offer.payout_cpa?.sum || '-'} ${offer
              .payout_cpa?.currency || ''}`
          }}
        </span>
      </p>
    </div>

    <div class="absolute bottom-0 left-0 w-full">
      <div
        @click.stop="onClickButton"
        class="h-[44px] rounded-b-md border-t flex items-center justify-center font-medium hover:bg-gray-200 active:bg-gray-300"
        :class="{
          'bg-orange-100 hover:bg-orange-100 active:bg-orange-100': isModeratingStatus(),
        }"
      >
        <div v-if="loading">
          <LoadingIcon icon="three-dots" class="w-5 h-5" />
        </div>
        <div v-else>
          <span class="flex flex-row items-center">
            <span class="uppercase font-medium text-xs">{{ getButtonTitle() }}</span>

            <CheckIcon v-if="isModeratingStatus()" class="ml-2 w-4 h-4" />
            <PlusIcon v-else-if="isApprovedStatus()" class="ml-2 w-4 h-4" />
            <LockIcon v-else-if="isUnApprovedStatus()" class="ml-2 w-4 h-4" />
            <LockIcon v-else-if="isOnDemandOffer" class="ml-2 w-4 h-4" />
            <PlusIcon v-else class="ml-2 w-4 h-4" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTranslation } from '@/hooks/useTranslation'

export default defineComponent({
  name: 'OfferCard',

  props: {
    offer: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const translation = useTranslation()

    const isOnDemandOffer = props.offer.privacy_type.name === 'OnDemand'

    const isModeratingStatus = () => {
      return props.offer.access_status === 1 && isOnDemandOffer ? true : false
    }
    const isApprovedStatus = () => {
      return props.offer.access_status === 2 && isOnDemandOffer ? true : false
    }
    const isUnApprovedStatus = () => {
      return props.offer.access_status === 3 && isOnDemandOffer ? true : false
    }

    const onClickButton = () => {
      if (props.loading) {
        return
      }

      switch (true) {
        case isModeratingStatus():
          break
        case isApprovedStatus():
          context.emit('addCampaign', props.offer.id, true)
          break
        case isOnDemandOffer || isUnApprovedStatus():
          context.emit('requestAccess', props.offer.id, true)
          break
        default:
          context.emit('addCampaign', props.offer.id, true)
      }
    }

    const getButtonTitle = () => {
      switch (true) {
        case isModeratingStatus():
          return t('common.moderation')
        case isApprovedStatus():
          return t('offerWall.createCompany')
        case isOnDemandOffer || isUnApprovedStatus():
          return t('offerWall.requestAccess')
        default:
          return t('offerWall.createCompany')
      }
    }

    return {
      isOnDemandOffer,
      isModeratingStatus,
      isApprovedStatus,
      isUnApprovedStatus,
      onClickButton,
      getButtonTitle,
      t,
      translation,
    }
  },
})
</script>

<style lang="scss">
.v-offer-card {
  &__text-indent {
    padding-left: 0.6em;
    text-indent: -0.6em;
  }
}
</style>
