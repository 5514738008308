import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getSources({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/blacklist/source', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getSourceById(_, id) {
    try {
      const response = await http.get(`/blacklist/source/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addSource(_, params) {
    try {
      return await http.post('/blacklist/source', params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateSource(_, params) {
    try {
      return await http.put(`/blacklist/source/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteSource(_, id) {
    try {
      return await http.delete(`/blacklist/source/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async importSources(_, file) {
    try {
      return await http.post('/blacklist/source/upload', file)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
