<template>
  <div class="v-field-checkbox">
    <label class="block mt-3.5 mb-0.5" :for="uuid" v-if="label"> {{ t(label) }}</label>

    <div class="form-check flex items-center" style="height: 40px;">
      <input
        :checked="modelValue"
        @change="$emit('update:modelValue', returnNumber ? Number($event.target.checked) : $event.target.checked)"
        :id="uuid"
        class="form-check-switch"
        type="checkbox"
      />
    </div>
  </div>
</template>

<script>
import UniqueID from '@/utils/UniqueID.js'
import { useI18n } from 'vue-i18n'
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    returnNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid = UniqueID().getID()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    return {
      uuid,
      t,
    }
  },
}
</script>
