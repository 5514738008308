export const enBlackListReferer = {
  // top button
  importBtn: 'Upload file',
  addBtn: 'Add referer',

  // filter
  filterReferer: 'Search by referer',

  // main popup
  editTitle: 'Edit referer',
  addTitle: 'Add referer',
  cancel: 'Cancel',
  save: 'Save',

  // import popup
  importTitle: 'File upload',
  importMessage: 'Upload multiple referers to blacklist at once',
  selectFile: 'Select file',
  importInfo: 'Format of .csv file to upload and max. file size 10MB',
  download: 'Download',
  successUpload: 'You have successfully uploaded the file',
  failUpload: 'An error occurred while uploading the file',
  downloadTemplate: 'Template for importing data',

  errors: {
    notValidFormat: 'The selected file is not in .csv format',
    moreTenMb: 'The selected file exceeds the maximum size of 10MB',
  },

  // table
  id: 'ID',
  referer: 'Referer',
  reason: 'Reason',
  author: 'Author',
  createdAt: 'Date created',

  // confrim dialog
  confrimDialog: {
    title: 'Removing a referer',
    message: 'Are you sure you want to remove the referer?',
    okButton: 'Delete',
  },
}

export const ruBlackListReferer = {
  // top button
  importBtn: 'Загрузить файл',
  addBtn: 'Добавить реферер',

  // filter
  filterReferer: 'Поиск по рефереру',

  // main popup
  editTitle: 'Редактирование реферера',
  addTitle: 'Добавление реферера',
  cancel: 'Отмена',
  save: 'Сохранить',

  // import popup
  importTitle: 'Загрузка файла',
  importMessage: 'Загрузите сразу несколько рефереров в черный список',
  selectFile: 'Выбрать файл',
  importInfo: 'Формат файла .csv для загрузки и макс. размер файла 10MB',
  download: 'Загрузить',
  successUpload: 'Вы успешно загрузили файл',
  failUpload: 'Произошла ошибка при загрузке файла',
  downloadTemplate: 'Скачать пример файла для импорта данных',

  errors: {
    notValidFormat: 'Выбранный файл не .csv формата',
    moreTenMb: 'Выбранный файл превышает максимальный размер 10MB',
  },

  // table
  id: 'ID',
  referer: 'Реферер',
  reason: 'Причина',
  author: 'Автор',
  createdAt: 'Дата создания',

  // confrim dialog
  confrimDialog: {
    title: 'Удаление реферера',
    message: 'Вы действительно хотите удалить реферер?',
    okButton: 'Удалить',
  },
}
