export const enOffer = {
  addOfferButton: 'Add offer',
  serviceManagement: 'Service management',
  landingManagement: 'Landing management',
  addOffer: 'Add offer',
  editOffer: 'Edit offer',
  copyOffer: 'Copy offer',

  id: 'ID',
  name: 'Name',
  landing: 'Landing',
  paymentModel: 'Payment model',
  country: 'Country',
  privacyType: 'Privacy type',
  createdAt: 'Created at',

  description: 'Description',
  privacy: 'Privacy',
  provider: 'Provider',
  isActive: 'Status',
  forbidden: 'Permitted sources',
  flowType: 'Flow type',
  preLanding: 'Prelanding',
  enrollmentsRule: 'Enrollments rule',
  category: 'Category',
  terms1: 'Terms 1',
  terms2: 'Terms 2',
  isCustom: 'Dynamic link to LP by API',

  changeImageMessage: 'Change image',
  image: 'Image',

  confirmDialog: {
    title: 'Deleting a offer',
    message: 'Do you really want to remove the offer?',
  },

  confirmRestoreDialog: {
    title: 'Restore a offer',
    message: 'Do you really want to restore the offer?',
  },

  imgErrorHandlerMessage: 'Invalid image URL',

  save: 'Save',
  cancel: 'Cancel',

  // CPA && RevShare Form
  tableTitle_1: 'Services',
  tableTitle_2: 'Standard commercial terms',
  add: 'Add',
  operator: 'Operator',
  service: 'Service',
  deductionsToPartners: 'Payout to partners',
  delete: 'Removal',
  close: 'Close',
  sigmaRate: 'Payin to platform',
  partnerRateCpa: 'Payout to partners',
  revShare: 'RevShare',
  CPA: 'CPA',
  partnerRateRs: 'Payout to partners (RevShare)',
  partnerRateCPA: 'Payout to partners (CPA)',
  currency: 'Currency',
  emptyField: 'Empty field',
  offerType: 'Offer type',
  allUsers: 'All users',

  confirmDialogCPA: {
    title: 'Deleting a service',
    message: 'Do you really want to remove the service?',
  },

  // Landings dialog
  selectAll: 'Select all',
  availableLandings: 'Available landings',
  selectedLandings: 'Selected landings',
  addLanding: 'Add selected landings',
  removeLanding: 'Delete selected landings',
}

export const ruOffer = {
  addOfferButton: 'Добавить оффер',
  serviceManagement: 'Управление сервисами',
  landingManagement: 'Управление лендингами',
  addOffer: 'Добавление оффера',
  editOffer: 'Редактирование оффера',
  copyOffer: 'Скопировать оффер',

  id: 'ID',
  name: 'Название',
  landing: 'Лендинг',
  paymentModel: 'Тип оплаты',
  country: 'Страна',
  privacyType: 'Приватность',
  provider: 'Провайдер',
  createdAt: 'Дата создания',

  description: 'Описание',
  privacy: 'Приватность',
  isActive: 'Статус',
  forbidden: 'Разрешенные источники',
  flowType: 'Способы оплаты',
  preLanding: 'Прелендинг',
  enrollmentsRule: 'Правило выкупа',
  category: 'Категория',
  terms1: 'Условия 1',
  terms2: 'Условия 2',
  isCustom: 'Динамическая ссылка на LP по API',

  changeImageMessage: 'Изменить изображение',
  image: 'Изображение',

  confirmDialog: {
    title: 'Удаление оффера',
    message: 'Вы действительно хотите удалить оффер?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление оффера',
    message: 'Вы действительно хотите восстановить оффер?',
  },

  imgErrorHandlerMessage: 'Некорректный URL-адрес изображения',

  save: 'Сохранить',
  cancel: 'Отменить',

  // CPA && RevShare Form
  tableTitle_1: 'Сервисы',
  tableTitle_2: 'Стандартные коммерческие условия',
  add: 'Добавить',
  operator: 'Оператор',
  service: 'Сервис',
  deductionsToPartners: 'Отчисления партнерам',
  delete: 'Удаление',
  close: 'Закрыть',
  sigmaRate: 'Отчисления платформе',
  partnerRateCpa: 'Отчисления партнерам',
  revShare: 'RevShare',
  CPA: 'CPA',
  partnerRateRs: 'Отчисл. партнерам (RevShare)',
  partnerRateCPA: 'Отчисл. партнерам (CPA)',
  currency: 'Валюта',
  emptyField: 'Поле не заполнено',
  offerType: 'Тип оффера',
  allUsers: 'Все пользователи',

  confirmDialogCPA: {
    title: 'Удаление сервиса',
    message: 'Вы действительно хотите удалить сервис?',
  },

  // Landings dialog
  selectAll: 'Выбрать все',
  availableLandings: 'Доступные лендинги',
  selectedLandings: 'Выбранные лендинги',
  addLanding: 'Добавить выбранные лендинги',
  removeLanding: 'Удалить выбранные лендинги',
}
