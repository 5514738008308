import { http_stat } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getDashboards(_, days) {
    try {
      const response = await http_stat.get(`/stat/dashboard?days_interval=${days}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
