<template>
  <div v-if="formats.length">
    <div class="dropdown" data-placement="bottom-start">
      <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto">
        <UploadIcon class="w-4 h-4 mr-2" />
        {{ t('vTable.export') }}
        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
      </button>

      <div class="dropdown-menu w-max">
        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
          <a
            v-for="format of formats"
            :key="format"
            @click="$emit('export', format), hide()"
            class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
          >
            <FileTextIcon class="w-4 h-4 mr-2" />
            {{ t('vTable.exportTo') }}
            <p class="uppercase ml-1">{{ format }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VExportTable',
  props: {
    formats: {
      type: Array,
      default: () => [],
      validator: formats => formats.every(format => ['csv', 'xlsx'].includes(format)),
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const hide = () => {
      cash('#programmatically-dropdown').dropdown('hide')
    }

    return {
      t,
      hide,
    }
  },
})
</script>
