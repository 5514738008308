<template>
  <div>
    <DarkModeSwitcher />

    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link :to="{ path: '/' }" class="-intro-x flex items-center pt-5">
            <img alt="Sigma" class="w-40" :src="require(`@/assets/images/logo.png`).default" />
          </router-link>

          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/sing_in.png`).default"
            />
            <div class="intro-x text-white font-medium text-4xl leading-tight mt-10">
              {{ t('auth.bigTextUp') }}
            </div>
            <div class="intro-x text-white font-medium text-4xl leading-tight ">{{ t('auth.bigTextDown') }}</div>
            <div class="intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
              {{ t('auth.smallText') }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->

        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div v-if="loading" class="flex items-center w-full justify-center">
            <LoadingIcon icon="tail-spin" class="w-8 h-8" />
          </div>
          <div
            v-else
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <div>
              <div v-if="isVerified" class="intro-x">
                <div class="flex flex-col">
                  <span class="font-bold text-2xl xl:text-3xl text-center xl:text-left">
                    {{ t('auth.verifySuccess') }}
                  </span>

                  <span class="mt-6 text-gray-600 text-center text-lg xl:text-left">
                    {{ t('auth.verifySuccessInstruction') }}
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="intro-x flex flex-col">
                  <span class="font-bold text-2xl xl:text-3xl text-center xl:text-left">
                    {{ t('auth.verifyEmail') }}
                  </span>

                  <span class="mt-6 text-gray-600 flex text-lg items-center">
                    {{ t('auth.verifyEmailInstruction') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

const route = useRoute()
const store = useStore()
const token = route.params.token
const isVerified = ref(false)
const loading = ref(true)

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const verifyEmail = async () => {
  if (!token) {
    loading.value = false
    return
  }
  const response = await store.dispatch('auth/verifyToken', token)
  isVerified.value = response ? true : false

  loading.value = false
}

verifyEmail()

onMounted(() => {
  cash('body')
    .removeClass('main')
    .removeClass('error-page')
    .addClass('login')
})
</script>
