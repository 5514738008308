import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getMsisdns({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/blacklist/msisdn', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getMsisdnById(_, id) {
    try {
      const response = await http.get(`/blacklist/msisdn/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addMsisdn(_, params) {
    try {
      return await http.post('/blacklist/msisdn', params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateMsisdn(_, params) {
    try {
      return await http.put(`/blacklist/msisdn/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteMsisdn(_, id) {
    try {
      return await http.delete(`/blacklist/msisdn/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async importMsisdns(_, file) {
    try {
      return await http.post('/blacklist/msisdn/upload', file)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
