import { nestedMenu } from '@/layouts/side-menu'

const icons = [
  'FileTextIcon',
  'ServerIcon',
  'StarIcon',
  'FileMinusIcon',
  'FilePlusIcon',
  'TrelloIcon',
  'UsersIcon',
  'BellIcon',
  'CornerUpLeftIcon',
  'PieChartIcon',
  'BarChartIcon',
  'ClipboardIcon',
  'FramerIcon',
  'GitBranchIcon',
  'GitMergeIcon',
  'GitPullRequestIcon',
  'Share2Icon',
  'TrendingUpIcon',
  'ZapIcon',
  'ShuffleIcon',
  'GitCommitIcon',
  'CropIcon',
  'BookmarkIcon',
]

export const useGenerateSideMenu = async (menu, route, store) => {
  let formattedMenu = []

  menu.forEach(menu => {
    const isParent = !menu.parent

    if (isParent) {
      formattedMenu.push({
        id: menu.id,
        icon: menu.icon,
        title: menu.title,
        pageName: menu.pageName,
        subMenu: [],
      })

      return
    }

    const parentIndex = formattedMenu.findIndex(parentMenu => parentMenu.id == menu.parent)

    if (menu.subParent) {
      const subParentIndex = formattedMenu[parentIndex].subMenu.findIndex(
        subParentMenu => subParentMenu.id == menu.subParent
      )

      formattedMenu[parentIndex].subMenu[subParentIndex].subMenu.push({
        icon: menu.icon,
        title: menu.title,
        pageName: menu.pageName,
      })

      return
    }

    formattedMenu[parentIndex].subMenu.push({
      id: menu.id,
      icon: menu.icon,
      title: menu.title,
      pageName: menu.pageName,
      subParent: menu.subParent || null,
      subMenu: [],
    })
  })

  await generateQueriesMenu(formattedMenu, store)

  return nestedMenu(formattedMenu, route)
}

const generateQueriesMenu = async (formattedMenu, store) => {
  const queries = await store.dispatch('reports/getQueries')

  if (queries?.results?.length) {
    const items = {
      reports: [],
      statistics: [],
      logs: [],
    }

    queries.results.forEach((query, index) => {
      const isReports = () => query.tags.includes('reports')
      const isStatistics = () => query.tags.includes('statistics')
      const isLogs = () => query.tags.includes('logs')

      switch (true) {
        case isReports():
          items.reports.push({
            icon: icons[index] || null,
            title: query.name,
            pageName: `reports-query/${query.id}`,
          })

          break
        case isStatistics():
          items.statistics.push({
            icon: icons[index] || null,
            title: query.name,
            pageName: `stat-query/${query.id}`,
          })

          break
        case isLogs():
          items.logs.push({
            icon: icons[index] || null,
            title: query.name,
            pageName: `logs-query/${query.id}`,
          })

          break
        default:
          items.reports.push({
            icon: icons[index] || null,
            title: query.name,
            pageName: `reports-query/${query.id}`,
          })
      }
    })

    if (items.reports.length) {
      formattedMenu.push({
        icon: 'PieChartIcon',
        title: 'sideMenu.reports',
        pageName: 'reports',
        subMenu: items.reports,
      })
    }

    if (items.statistics.length) {
      formattedMenu.push({
        icon: 'BarChart2Icon',
        title: 'sideMenu.statistics',
        pageName: 'statistics',
        subMenu: items.statistics,
      })
    }

    if (items.logs.length) {
      formattedMenu.push({
        icon: 'DatabaseIcon',
        title: 'sideMenu.logs',
        pageName: 'logs',
        subMenu: items.logs,
      })
    }
  }
}
