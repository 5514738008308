<template>
  <div>
    <header class="flex items-center">
      <label class="block mt-3.5 mb-0.5"> {{ t(title) }}</label>
      <base-checkbox v-if="isCheck" class="flex items-center ml-3" v-model="isSelectMode" />
    </header>
    <div v-if="isSelectMode">
      <TomSelect v-model="selected" class="w-full">
        <option
          v-for="option of options"
          :value="option.id"
          :key="option.id"
          class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default"
        >
          {{ t(option.name) }}
        </option>
      </TomSelect>
    </div>

    <div v-else :class="radioGroupClass">
      <div v-for="option of options" :key="option.id" class="form-check">
        <input
          v-model="selected"
          type="radio"
          class="form-check-input border mr-2"
          :value="option.id.toString()"
          :id="option.id + option.name"
        />
        <label class="cursor-pointer select-none" :for="option.id + option.name" v-if="option.name">
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import BaseCheckbox from '@/global-components/base-checkbox/index.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  modelValue: { type: [String, Number], default: () => '' },
  options: { type: Array, default: () => [] },
  title: { type: String, default: () => 'Список' },
  isCheck: { type: Boolean, default: () => false },
  radioGroupClass: { type: String, default: '' },
})
const emit = defineEmits(['update:modelValue'])

const selected = ref('')
const isSelectMode = ref(false)

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

watch(
  () => props.modelValue,
  value => {
    selected.value = value
  },
  { immediate: true }
)

watch(
  () => selected.value,
  value => {
    const isId = !Number.isNaN(Number(value))

    if (isId) {
      emit('update:modelValue', Number(value))
      return
    }

    emit('update:modelValue', value)
  }
)
</script>
