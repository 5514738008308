<template>
  <div class="mt-3">
    <div>
      <div v-if="loader" class="flex justify-center my-6">
        <LoadingIcon icon="tail-spin" class="w-8 h-8" />
      </div>
      <form v-else @submit.prevent="handleSubmit">
        <div v-if="!landings.length" class="alert bg-theme-12 bg-opacity-40 show flex items-center mb-2">
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          {{ t('vTable.noDataMessage') }}
        </div>
        <ul v-else class="flex flex-col w-full">
          <li
            v-for="landing in landings"
            :key="landing.id"
            class="my-0.5 cursor-pointer -intro-x"
            @click="onClick($event, landing)"
          >
            <div
              class="flex flex-row items-center h-16 px-4 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
              :class="{
                'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': landing.selected,
              }"
            >
              <BaseCheckbox class="mr-3" v-model="landing.selected" />

              <span class="image-fit w-[40px] h-[40px] select-none mr-3">
                <img
                  class="rounded"
                  :src="landing.image || require('@/assets/images/no_image.jpg').default"
                  @error="$event.target.src = require('@/assets/images/no_image.jpg').default"
                  data-action="zoom"
                />
              </span>

              <span class="font-medium">{{ landing.name }}</span>

              <div class="flex flex-row items-center ml-auto">
                <span
                  v-if="landing.privacy_type?.id === 4"
                  class="bg-red-300 text-red-800 py-0.5 text-xs font-medium px-2 rounded animate-pulse"
                >
                  PRIVATE
                </span>

                <span class="ml-2 truncate bg-blue-200 text-blue-800 py-0.5 text-xs font-medium px-2 rounded">
                  ID: {{ landing.id }}
                </span>
              </div>
            </div>
          </li>
        </ul>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
            {{ t('offer.cancel') }}
          </button>

          <button type="submit" class="btn btn-primary">
            <SaveIcon class="w-4 h-4 mr-2" />
            {{ t('offer.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'OffersLandings',
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const landings = ref([])
    const loader = ref(true)
    const selectedIds = ref([])

    const getSelectedIds = async () => {
      const selected = await store.dispatch('offers/getLandingsToOffer', props.offer.id)

      if (selected?.data.data.length) {
        selected?.data.data.forEach(landing => selectedIds.value.push(landing.id))
      }
    }

    const getLandings = async () => {
      const list = await store.dispatch('landings/getLandings', {
        country: props.offer.country.id,
        flow_type: props.offer.flow_type.id,
        full: true,
      })

      await getSelectedIds()

      if (list?.data.length) {
        list?.data.forEach(landing => {
          const isSelectedId = () => selectedIds.value.includes(landing.id)
          landings.value.push({ ...landing, selected: isSelectedId() })
        })
      }

      loader.value = false
    }

    const onClick = (e, landing) => {
      if (e?.target?.getAttribute('data-action') === 'zoom') {
        return
      }

      landing.selected = !landing.selected
    }

    const handleSubmit = async () => {
      const actions = {
        deleteIds: [],
        addIds: [],
      }

      landings.value.forEach(landing => {
        const isDeleted = () => selectedIds.value.includes(landing.id) && !landing.selected
        const isAdded = () => !selectedIds.value.includes(landing.id) && landing.selected

        if (isDeleted()) {
          actions.deleteIds.push(landing.id)
        }

        if (isAdded()) {
          actions.addIds.push(landing.id)
        }
      })

      if (actions.deleteIds.length) {
        const response = await store.dispatch('offers/deleteLandingsFromOffer', {
          id: props.offer.id,
          landingsIds: actions.deleteIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      if (actions.addIds.length) {
        const response = await store.dispatch('offers/addLandingsToOffer', {
          id: props.offer.id,
          landingsIds: actions.addIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      context.emit('close-modal')
    }

    getLandings()

    return {
      t,
      landings,
      loader,
      onClick,
      handleSubmit,
    }
  },
})
</script>
