import { http } from '@/api/http'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getTrafficbacks({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/trafficbacks`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getTrafficbacksById(_, id) {
    try {
      const response = await http.get(`/trafficbacks/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateTrafficbacks(_, params) {
    try {
      const response = await http.put(`/trafficbacks/${params.id}`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createTrafficbacks(_, params) {
    try {
      const response = await http.post(`/trafficbacks`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteTrafficbacks(_, id) {
    try {
      await http.delete(`/trafficbacks/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreTrafficbacks(_, id) {
    try {
      await http.put(`/trafficbacks/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Trafficback Reasons
  async getTrafficbackReasons() {
    try {
      const response = await http.get('/trafficback_reasons')
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
