export const enConsts = {
  statusOptions: {
    active: 'Active',
    notActive: 'Not Active',
  },

  validate: {
    requiredMessage: 'Complete required information',
    repeatPasswordMessage: 'Passwords don`t match',
    urlMessage: 'Url is not correct',
    minValueMessage: 'The minimum value is',
    maxValueMessage: 'The maximum value is',
  },

  default_title: 'Sigma Mobi {TITLE_DELIMITER} Cabinet',
}

export const ruConsts = {
  statusOptions: {
    active: 'Активный',
    notActive: 'Не активный',
  },

  validate: {
    requiredMessage: 'Не заполнено обязательное поле',
    repeatPasswordMessage: 'Введенные пароли не совпадают',
    urlMessage: 'Неверный формат адреса',
    minValueMessage: 'Минимальное значение',
    maxValueMessage: 'Максимальное значение',
  },

  default_title: 'Sigma Mobi {TITLE_DELIMITER} Кабинет',
}
