import { enAccount, ruAccount } from './account.js'
import { enSideMenu, ruSideMenu } from './side-menu.js'
import { enAuth, ruAuth } from './auth.js'
import { enChangePassword, ruChangePassword } from './changePassword'
import { enPaymentDetails, ruPaymentDetails } from './paymentDetails'
import { enTrafficSource, ruTrafficSource } from './trafficSource'
import { enTrafficCallback, ruTrafficCallback } from './trafficCallback'
import { enTrafficBack, ruTrafficBack } from './trafficBack'
import { enFieldMatchingMap, ruFieldMatchingMap } from './fieldMatchingMap'
import { enConfirmDialog, ruConfirmDialog } from './confirmDialog'
import { enBaseInput, ruBaseInput, enBaseCheckbox, ruBaseCheckbox } from './fields'
import { enUsers, ruUsers } from './users'
import { enTableFilter, ruTableFilter } from './tableFilter'
import { enConsts, ruConsts } from './consts'
import { enOperators, ruOperators } from './operators'
import { enProvider, ruProvider } from './provider'
import { enLanding, ruLanding } from './landing'
import { enFlowTypes, ruFlowTypes } from './flowTypes'
import { enRole, ruRole } from './role'
import { enServices, ruServices } from './services'
import { enVTable, ruVTable } from './vTable'
import { enCampaign, ruCampaign } from './campaign'
import { enMyCampaign, ruMyCampaign } from './myCampaign'
import { enOffer, ruOffer } from './offer'
import { enVExportTable, ruVExportTable } from './vExportTable'
import { enOfferWall, ruOfferWall } from './offerWall'
import { enDomain, ruDomain } from './domain'
import { enMyReport, ruMyReport } from './myReport'
import { enDarkModeSwitcher, ruDarkModeSwitcher } from './darkModeSwitcher'
import { enVariablesMap, ruVariablesMap } from './variablesMap'
import { enCommon, ruCommon } from './common'
import { enEnrollmentRules, ruEnrollmentRules } from './enrollmentRules'
import { enVFileUploader, ruVFileUploader } from './vFileUploader'
import { enAccess, ruAccess } from './access'
import { enQuery, ruQuery } from './query'
import { enLandingWall, ruLandingWall } from './landingWall'
import { enNotification, ruNotification } from './notification'
import { enBlackListMsisdn, ruBlackListMsisdn } from './blackListMsisdn'
import { enBlackListHeader, ruBlackListHeader } from './blackListHeader'
import { enBlackListReferer, ruBlackListReferer } from './blackListReferer'
import { enBlackListIp, ruBlackListIp } from './blackListIp'
import { enBlackListUserAgent, ruBlackListUserAgent } from './blackListUserAgent'
import { enBlackListSource, ruBlackListSource } from './blackListSource'
import { enCountries, ruCountries } from './countries'
import { enDashboards, ruDashboards } from './dashboards.js'
import { enArchiveOfferRules, ruArchiveOfferRules } from './archiveOfferRules.js'
import { enArchiveCampaignRules, ruArchiveCampaignRules } from './archiveCampaignRules.js'

export default {
  en: {
    account: enAccount,
    sideMenu: enSideMenu,
    auth: enAuth,
    changePassword: enChangePassword,
    paymentDetails: enPaymentDetails,
    trafficSource: enTrafficSource,
    trafficCallback: enTrafficCallback,
    trafficBack: enTrafficBack,
    fieldMatchingMap: enFieldMatchingMap,
    confirmDialog: enConfirmDialog,
    baseInput: enBaseInput,
    baseCheckbox: enBaseCheckbox,
    users: enUsers,
    tableFilter: enTableFilter,
    consts: enConsts,
    operators: enOperators,
    provider: enProvider,
    landing: enLanding,
    flowTypes: enFlowTypes,
    role: enRole,
    services: enServices,
    vTable: enVTable,
    campaign: enCampaign,
    myCampaign: enMyCampaign,
    offer: enOffer,
    vExportTable: enVExportTable,
    offerWall: enOfferWall,
    domain: enDomain,
    myReport: enMyReport,
    darkModeSwitcher: enDarkModeSwitcher,
    variablesMap: enVariablesMap,
    common: enCommon,
    enrollmentRules: enEnrollmentRules,
    vFileUploader: enVFileUploader,
    access: enAccess,
    query: enQuery,
    landingWall: enLandingWall,
    notification: enNotification,
    blackListMsisdn: enBlackListMsisdn,
    blackListHeader: enBlackListHeader,
    blackListReferer: enBlackListReferer,
    blackListIp: enBlackListIp,
    blackListUserAgent: enBlackListUserAgent,
    blackListSource: enBlackListSource,
    countries: enCountries,
    dashboards: enDashboards,
    archiveOfferRules: enArchiveOfferRules,
    archiveCampaignRules: enArchiveCampaignRules,
  },
  ru: {
    account: ruAccount,
    sideMenu: ruSideMenu,
    auth: ruAuth,
    changePassword: ruChangePassword,
    paymentDetails: ruPaymentDetails,
    trafficSource: ruTrafficSource,
    trafficCallback: ruTrafficCallback,
    trafficBack: ruTrafficBack,
    fieldMatchingMap: ruFieldMatchingMap,
    confirmDialog: ruConfirmDialog,
    baseInput: ruBaseInput,
    baseCheckbox: ruBaseCheckbox,
    users: ruUsers,
    tableFilter: ruTableFilter,
    consts: ruConsts,
    operators: ruOperators,
    provider: ruProvider,
    landing: ruLanding,
    flowTypes: ruFlowTypes,
    role: ruRole,
    services: ruServices,
    vTable: ruVTable,
    campaign: ruCampaign,
    myCampaign: ruMyCampaign,
    offer: ruOffer,
    vExportTable: ruVExportTable,
    offerWall: ruOfferWall,
    domain: ruDomain,
    myReport: ruMyReport,
    darkModeSwitcher: ruDarkModeSwitcher,
    variablesMap: ruVariablesMap,
    common: ruCommon,
    enrollmentRules: ruEnrollmentRules,
    vFileUploader: ruVFileUploader,
    access: ruAccess,
    query: ruQuery,
    landingWall: ruLandingWall,
    notification: ruNotification,
    blackListMsisdn: ruBlackListMsisdn,
    blackListHeader: ruBlackListHeader,
    blackListReferer: ruBlackListReferer,
    blackListIp: ruBlackListIp,
    blackListUserAgent: ruBlackListUserAgent,
    blackListSource: ruBlackListSource,
    countries: ruCountries,
    dashboards: ruDashboards,
    archiveOfferRules: ruArchiveOfferRules,
    archiveCampaignRules: ruArchiveCampaignRules,
  },
}
