const NUMBER_FORMATTER_RU = new Intl.NumberFormat('ru-RU', {
  maximumFractionDigits: 4,
})

export const formatNumber = value => {
  return NUMBER_FORMATTER_RU.format(value)
}

// replace all characters except numbers and period
export const parseNumber = value => {
  return String(value)
    .replace(/,/, '.')
    .replace(/[^\d.-]/g, '')
}
