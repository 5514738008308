import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getLandings({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/cabinet/landings', config)
      return response?.data
    } catch (error) {
      console.error(error)
    }
  },

  async getLandingById(_, id) {
    try {
      const response = await http.get(`/cabinet/landings/${id}`)
      return response?.data?.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async getOffersToLanding(_, id) {
    try {
      const response = await http.get(`/cabinet/landings/${id}/offers`)
      return response?.data?.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async getOffersToLandingList(_, ids) {
    if (!ids.length) {
      return
    }

    let strIds = []

    ids.forEach(id => {
      strIds.push(`landing_id%5B%5D=${id}`)
    })

    try {
      const response = await http.get(`/cabinet/landing_offers?${strIds.join('&')}`)
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
