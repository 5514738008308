<template>
  <div>
    <div v-if="query?.id === QUERY_IDS.request_card">
      <div class="box mt-3">
        <div class="flex items-center p-3">
          <h2 class="text-base" v-if="query?.name">{{ t(query?.name) }}</h2>
        </div>

        <hr />

        <QueryFilter
          v-if="!!query?.options.parameters['0']"
          :query="query"
          @applyFilter="parameters => applyFilter(parameters)"
          @resetFilter="resetFilter()"
          class="px-4 pb-4"
        />
      </div>
      <div v-if="!isFirstUpload">
        <QueryRequestCard :headers="table.columns" :rows="table.data" :loading="table.loader" />

        <div v-if="table.loader" class="flex justify-center box p-4 mt-3 -intro-y">
          <LoadingIcon icon="tail-spin" class="w-6 h-6" />
        </div>
        <div v-else-if="!table.loader && !table.data.length" class="flex justify-center box p-4 mt-3">
          <div class="text-gray-600 flex items-center">
            <AlertTriangleIcon class="w-6 h-6 mr-2" />
            {{ t('vTable.message') }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="box mt-3">
        <div class="flex items-center p-3">
          <h2 class="text-base" v-if="query?.name">{{ t(query?.name) }}</h2>
        </div>

        <hr />

        <QueryFilter
          v-if="!!query?.options.parameters['0']"
          :query="query"
          @applyFilter="parameters => applyFilter(parameters)"
          @resetFilter="resetFilter()"
          class="px-4 pb-4"
        />
      </div>

      <div class="box p-4 mt-3 -intro-y" v-if="!isFirstUpload">
        <VTable
          :columns="table.columns"
          :data="table.data"
          :total="table.total"
          :hide-buttons="['edit', 'delete']"
          size="xs"
          :tableLoader="false"
          :exportFormat="['csv', 'xlsx']"
          :sort="table.sorting"
          :paginationOptions="table.quantity"
          @export="format => onExport(format)"
          @updateSorting="sort => updateSorting(sort)"
        />

        <div v-if="table.loader" class="flex justify-center mt-5">
          <LoadingIcon icon="tail-spin" class="w-8 h-8" />
        </div>
        <div v-else-if="!table.loader && !table.data.length" class="flex justify-center mt-6 mb-1">
          <div class="text-gray-600 flex items-center">
            <AlertTriangleIcon class="w-6 h-6 mr-2" />
            {{ t('vTable.message') }}
          </div>
        </div>

        <QueryPagination
          v-model="table.paginate"
          :query="query"
          :countRows="table.data.length"
          @updatePaginate="paginate => updatePaginate(paginate)"
        />
      </div>
    </div>

    <div v-if="isFirstUpload" class="mt-3">
      <div class="alert bg-blue-200 show flex items-center mb-2 intro-x">
        <InfoIcon class="w-6 h-6 mr-2" />
        {{ t('query.selectFilterOptions') }}
      </div>
    </div>

    <span v-else-if="table.runTime && !table.loader" class="mt-3 pl-1 flex items-center text-gray-600">
      <ClockIcon class="w-4 h-4 mr-1" />
      {{ table.runTime }}
    </span>

    <span
      v-if="!table.loader && query?.id !== QUERY_IDS.request_card"
      class="mt-1.5 pl-1 flex items-center text-gray-600"
    >
      <AlignJustifyIcon class="w-4 h-4 mr-1" />
      {{ `${t('query.results')}: ${table.data.length}` }}
    </span>
  </div>
</template>

<script>
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'
import { runTime } from './utils/runTime'
import { convertParamsToDisplay, convertParamsForSend } from './utils/convertParameters'
import { QUERY_IDS } from './utils/queryIds.js'
import { format, startOfDay } from 'date-fns'
import { generateTotalRow } from './utils/generateTotalRow'
import { generateColumns, onSortingColumn } from './utils/columns'
import { cloneDeep } from 'lodash'
import QueryFilter from './QueryFilter.vue'
import QueryRequestCard from './QueryRequestCard.vue'
import QueryPagination from './QueryPagination.vue'

/* Delay in getting the result of the job in ms */
const RESPONCE_DELAY_TIME = 1000

export default defineComponent({
  name: 'Query',
  components: {
    QueryFilter,
    QueryRequestCard,
    QueryPagination,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const toast = useToast()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const user = computed(() => store.state.auth.user)
    const reportId = ref(null)
    const query = ref(null)
    const parameters = ref(null)
    const timeout = ref(null)
    const userRoles = ref([])

    const isFirstUpload = ref(true)

    const table = ref({
      sorting: {
        order: '',
        order_by: '',
      },
      columns: {},
      data: [],
      defaultData: [],
      total: {},
      paginate: {
        page: null,
        per_page: null,
      },
      resultId: null,
      loader: true,
      runTime: null,
      quantity: null,
    })

    watch(
      () => route.params.id,
      id => {
        if (id) {
          clearTimeout(timeout.value)
          query.value = null
          parameters.value = null

          table.value = {
            sorting: {
              order: '',
              order_by: '',
            },
            columns: {},
            data: [],
            defaultData: [],
            total: {},
            paginate: {
              page: null,
              per_page: null,
            },
            resultId: null,
            runTime: null,
            quantity: null,
          }

          runReport(id)
        }
      }
    )

    const runReport = async (id, filter = null) => {
      reportId.value = id

      runTime().start()
      table.value.loader = true
      parameters.value = filter

      isFirstUpload.value = false

      if (!query.value) {
        query.value = await getQuery(id)
      }

      table.value.data = []
      table.value.defaultData = []
      table.value.total = {}

      if (isFirstUpload.value) {
        return
      }

      /* Jobs */
      const jobId = await getJobId(filter)
      await startProcessGetJob(jobId, id)

      // await generateReport('{id}')
    }

    const getQuery = async id => {
      isFirstUpload.value = route.params.transitionFromMenu ? true : false
      const query = await store.dispatch('reports/getQueryById', id)

      if (query) {
        return convertParamsToDisplay(query, route)
      }
    }

    const getJobId = async (filter = null) => {
      const params = filter ? filter : query.value.options.parameters

      setPaginate(params)

      const response = await store.dispatch('reports/getQueryResult', {
        id: query.value.id,
        parameters: convertParamsForSend(params, router),
        apply_auto_limit: query.value.options.apply_auto_limit,
        max_age: 0,
      })

      if (response) {
        const errors = response.response?.data?.errors
        if (errors || response.message) {
          table.value.loader = false
          useErrorNotification(errors || response.message)
          return
        }

        return response.job.id
      }
    }

    const startProcessGetJob = async (jobId, runReportId) => {
      if (!jobId || reportId.value !== runReportId || !query.value) {
        return
      }

      const job = await store.dispatch('reports/getJobResult', jobId)

      if (job) {
        if (job.job.query_result_id) {
          clearTimeout(timeout.value)

          await generateReport(job.job.query_result_id)
          return
        }

        if (job.job.error) {
          clearTimeout(timeout.value)
          table.value.loader = false

          toast.error(t('query.toastiFy.error'), {
            closeOnClick: false,
          })
          return
        }

        timeout.value = setTimeout(() => {
          startProcessGetJob(jobId, runReportId)
        }, RESPONCE_DELAY_TIME)
      } else {
        clearTimeout(timeout.value)
      }
    }

    const generateReport = async id => {
      if (!id) {
        return
      }

      const report = await store.dispatch('reports/getReport', id)

      if (report) {
        table.value = {
          ...table.value,
          sorting: {
            order: '',
            order_by: '',
          },
          columns: {},
          data: [],
          defaultData: [],
          total: {},
          quantity: null,
        }

        table.value.columns = generateColumns(query.value.id, report.query_result.data.columns, userRoles)
        table.value.data = report.query_result.data.rows
        table.value.defaultData = cloneDeep(report.query_result.data.rows)
        table.value.quantity = { from: 1, to: table.value.data.length, total: table.value.data.length }

        table.value.total = generateTotalRow(table.value.data, query)

        table.value.resultId = id
        table.value.loader = false

        runTime().end()
        table.value.runTime = runTime().getTime()

        toast.info(table.value.runTime, {
          timeout: 2000,
          closeOnClick: false,
        })
      }
    }

    const onExport = async fileFormat => {
      const response = await store.dispatch('reports/exportTable', {
        queryId: query.value.id,
        resultId: table.value.resultId,
        format: fileFormat,
      })

      try {
        const href = URL.createObjectURL(response.data)
        const currentDate = format(startOfDay(new Date()), 'yyyy_MM_dd')
        const fileName = `${query.value.name}_${currentDate}.${fileFormat}`
        const link = document.createElement('a')

        link.href = href
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } catch (e) {
        toast.error(t('query.exportError'), {
          closeOnClick: false,
        })
      }
    }

    const applyFilter = parameters => {
      if (table.value.paginate) {
        table.value.paginate.page = 1
      }
      runReport(null, parameters)
    }

    const resetFilter = () => {
      if (table.value.paginate) {
        table.value.paginate.page = 1
      }

      isFirstUpload.value = true
      clearTimeout(timeout.value)
    }

    const updateSorting = sort => {
      if (sort.order) {
        table.value.data = onSortingColumn(table.value.data, sort)
        return
      }

      table.value.data = cloneDeep(table.value.defaultData)
    }

    watch(
      () => query.value?.id,
      queryId => {
        if (queryId) {
          getPaginate(query.value.options.parameters)
        }
      }
    )

    const updatePaginate = paginate => {
      table.value.paginate = paginate
      runReport(null, parameters.value || query.value.options.parameters)
    }

    const getPaginate = parameters => {
      table.value.paginate = {
        page: null,
        per_page: null,
      }

      if (!parameters) {
        return
      }

      Object.values(parameters).forEach(param => {
        if (param.name === 'page') {
          table.value.paginate.page = param.value
        }

        if (param.name === 'per_page') {
          table.value.paginate.per_page = param.value
        }
      })
    }

    const setPaginate = parameters => {
      if (!table.value.paginate?.page || !table.value.paginate?.per_page) {
        return
      }

      Object.values(parameters).forEach(param => {
        if (param.name === 'page') {
          param.value = String(table.value.paginate.page)
        }
        if (param.name === 'per_page') {
          param.value = String(table.value.paginate.per_page)
        }
      })
    }

    const getSelectedRole = async () => {
      const result = await store.dispatch('users/getUserRoles', user.value.id)

      if (result?.data.data.length) {
        result.data.data.forEach(role => userRoles.value.push(role.name))
      }
    }

    runReport(route.params.id)
    getSelectedRole()

    return {
      t,
      isFirstUpload,
      runReport,
      onExport,
      updatePaginate,
      applyFilter,
      resetFilter,
      updateSorting,
      table,
      query,
      QUERY_IDS,
    }
  },
})
</script>
