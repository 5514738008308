<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addService">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('services.addServiceButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getServices"
        @applyFilter=";(table.pagination.pageData.page = 1), getServices()"
      >
        <BaseInput type="number" class="w-24" label="services.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-50" label="services.name" name="name" v-model="table.filter.name" />

        <VFieldSelect
          :options="options.paymentModels"
          class="w-32"
          label="services.paymentModel"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.payment_model"
        />

        <VFieldSelect
          :options="options.providers"
          class="w-56"
          label="services.provider"
          :clearable="true"
          v-model="table.filter.provider"
        />

        <VFieldSelect
          label="services.country"
          class="w-48"
          v-model="table.filter.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          :clearable="true"
        />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getServices"
        @edit="editService"
        @delete="deleteService"
        @restore="restoreService"
        @updateSorting="sort => ((table.sorting = sort), getServices())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="controlOperator(slotProps.id)">
            <Tippy :content="t('services.operatorManagement')">
              <CropIcon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-green-600" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyService(slotProps.id)">
            <Tippy :content="t('services.copyService')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-33" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog"
      @close="showDialog = !showDialog"
      :textHeader="service.id ? t('services.editService') : t('services.addService')"
      :recordId="service.id"
    >
      <EditServiceForm :service="service" @close-modal="showDialog = !showDialog" @refresh="getServices" />
    </VDialog>

    <VDialog
      size="lg"
      :showDialog="showDialogOperators"
      @close="showDialogOperators = !showDialogOperators"
      :textHeader="t('services.operatorManagement')"
      :recordId="service.id"
    >
      <EditControlsOperator :service="service" @close="showDialogOperators = !showDialogOperators" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import EditServiceForm from './EditServiceForm.vue'
import EditControlsOperator from './ControlsOperator/EditControlsOperator.vue'

export default defineComponent({
  name: 'Services',
  components: {
    EditServiceForm,
    EditControlsOperator,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const translation = useTranslation()

    const services = ref([])
    const service = ref({})

    const showDialog = ref(false)
    const confirmDialog = ref(null)
    const showDialogOperators = ref(false)

    const options = ref({
      paymentModels: [],
      providers: [],
      country: [],
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        payment_model: route.query.payment_model || '',
        provider: route.query.provider || '',
        country: route.query.country || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('services.id'), sortable: true, sort_name: 'id' },
        name: { label: t('services.title'), sortable: true, sort_name: 'name' },
        payment_model: t('services.paymentModel'),
        provider: t('services.provider'),
        country: { label: t('services.country'), sortable: true, sort_name: 'country_id' },
        created_at: { label: t('services.createdAt'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return services.value.map(service => {
          return {
            id: service.id,
            name: service.name,
            payment_model: service.payment_model?.name,
            provider: service.provider?.name,
            country: translation.getCountry(service.country),
            created_at: new Date(service.created_at).toLocaleString() || '',
            is_deleted: !!service.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getServices = () => {
      store
        .dispatch('services/getServices', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(res => {
          services.value = res.data
          table.value.pagination.meta = res.meta
        })
    }

    const addService = () => {
      service.value = {}
      showDialog.value = !showDialog.value
    }

    const editService = id => {
      store.dispatch('services/getService', id).then(result => {
        if (result.data) {
          service.value = result.data
          showDialog.value = !showDialog.value
        }
      })
    }

    const controlOperator = id => {
      store
        .dispatch('services/getService', id)
        .then(result => {
          if (result.data) {
            service.value = result.data
          }
        })
        .finally(() => {
          showDialogOperators.value = true
        })
    }

    const copyService = id => {
      store.dispatch('services/getService', id).then(result => {
        if (result.data) {
          result.data = {
            ...result.data,
            id: '',
            name: `${result.data.name} | COPY`,
          }

          service.value = result.data
          showDialog.value = !showDialog.value
        }
      })
    }

    const deleteService = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'services.confirmDialog.title',
        message: 'services.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('services/deleteService', id).then(() => {
          getServices()
        })
      }
    }

    const restoreService = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'services.confirmRestoreDialog.title',
        message: 'services.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('services/restoreService', id).then(() => {
          getServices()
        })
      }
    }

    const getPaymentModelOptions = async () => {
      const paymentModels = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModels = paymentModels.data ? paymentModels.data : []
    }
    const getProvidersOptions = async () => {
      const providers = await store.dispatch('providers/getProviders')
      options.value.providers = providers.data ? providers.data : []
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }

    getServices()

    // Dropdown options
    getPaymentModelOptions()
    getProvidersOptions()
    getCountriesOptions()

    return {
      t,
      table,
      options,
      store,
      showDialog,
      showDialogOperators,
      services,
      service,
      confirmDialog,
      getServices,
      addService,
      editService,
      controlOperator,
      copyService,
      deleteService,
      restoreService,
      translation,
    }
  },
})
</script>
