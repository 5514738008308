import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getIps({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/blacklist/ip', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getIpById(_, id) {
    try {
      const response = await http.get(`/blacklist/ip/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addIp(_, params) {
    try {
      return await http.post('/blacklist/ip', params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateIp(_, params) {
    try {
      return await http.put(`/blacklist/ip/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteIp(_, id) {
    try {
      return await http.delete(`/blacklist/ip/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async importIps(_, file) {
    try {
      return await http.post('/blacklist/ip/upload', file)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
