export const enLanding = {
  addLandingButton: 'Add landing page',
  addLanding: 'Add landing page',
  editLanding: 'Edit landing page',

  id: 'ID',
  name: 'Name',
  base_url: 'Main URL',
  privacyType: 'Privacy type',
  flow_type: 'Payment method',
  country: 'Country',
  created_at: 'Setup date',
  copyLanding: 'Copy landing',
  provider: 'Provider',
  service: 'Service',
  offer: 'Offer',
  qtyOffers: 'Qty offers',

  confirmDialog: {
    title: 'Landing page deleting',
    message: 'Are you sure you want to delete landing page?',
  },

  confirmRestoreDialog: {
    title: 'Restore a landing',
    message: 'Do you really want to restore the landing?',
  },

  requiredParameters: 'Required parameters',
  variablesMap: 'Variables map',
  parameterNotFilled: 'Parameter not filled',
  category: 'Category',
  image: 'Image',

  save: 'Save',
  cancel: 'Cancel',
}

export const ruLanding = {
  addLandingButton: 'Добавить лендинг',
  addLanding: 'Добавление лендинга',
  editLanding: 'Редактирование лендинга',

  id: 'ID',
  name: 'Название',
  base_url: 'Базовый URL',
  privacyType: 'Приватность',
  flow_type: 'Тип оплаты',
  country: 'Страна',
  created_at: 'Дата создания',
  copyLanding: 'Скопировать лендинг',
  provider: 'Провайдер',
  service: 'Сервис',
  offer: 'Оффер',
  qtyOffers: 'Кол-во офферов',

  confirmDialog: {
    title: 'Удаление лендинга',
    message: 'Вы действительно хотите удалить лендинг?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление лендинга',
    message: 'Вы действительно хотите восстановить лендинг?',
  },

  requiredParameters: 'Обязательные параметры',
  variablesMap: 'Приведение параметров',
  parameterNotFilled: 'Не заполнен параметр',
  category: 'Категория',
  image: 'Изображение',

  save: 'Сохранить',
  cancel: 'Отменить',
}
