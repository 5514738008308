export const enAccess = {
  accessManagement: 'Access moderation',
  id: 'ID',
  user: 'User',
  offer: 'Offer',
  landing: 'Landing',
  created_at: 'Setup date',
  updated_at: 'Update date',
  status: 'Status',
  addAccess: 'Add access',
  addingAccess: 'Adding access',
  information: 'Information',
  type: 'Type',

  save: 'Save',
  cancel: 'Cancel',

  moderation: 'Moderation',
  approved: 'Approved',
  unApproved: 'UnApproved',

  approvedBtn: 'Approve',
  approvedAlready: 'Access already allowed',

  unApprovedBtn: 'Un approve',
  unApprovedAlready: 'Access already denied',

  confirmDialog: {
    offer: 'offer',
    landing: 'landing',
    title: 'Access management',
    messageAprove: 'Aprove user {userName} access to the {entityType} {entityName}?',
    messageUnAprove: 'Unaprove user {userName} access to the {entityType} {entityName}?',

    buttonDelete: 'Delete',
    titleDelete: 'Delete Access',
    messageDelete: 'Delete user {userName} access to {entityType} {entityName}?',
  },
}

export const ruAccess = {
  accessManagement: 'Модерация доступа',
  id: 'ID',
  user: 'Пользователь',
  offer: 'Оффер',
  landing: 'Лендинг',
  created_at: 'Дата создания',
  updated_at: 'Дата изменения',
  status: 'Статус',
  addAccess: 'Добавить доступ',
  addingAccess: 'Добавление доступа',
  information: 'Информация',
  type: 'Тип',

  save: 'Сохранить',
  cancel: 'Отмена',

  moderation: 'На модерации',
  approved: 'Разрешен',
  unApproved: 'Запрещен',

  approvedBtn: 'Разрешить',
  approvedAlready: 'Доступ уже разрешен',

  unApprovedBtn: 'Запретить',
  unApprovedAlready: 'Доступ уже запрещен',

  confirmDialog: {
    offer: 'офферу',
    landing: 'лендингу',
    title: 'Модерация доступа',
    messageAprove: 'Разрешить пользователю {userName} доступ к {entityType} {entityName}?',
    messageUnAprove: 'Запретить пользователю {userName} доступ к {entityType} {entityName}?',

    buttonDelete: 'Удалить',
    titleDelete: 'Удаление доступа',
    messageDelete: 'Удалить пользователю {userName} доступ к {entityType} {entityName}?',
  },
}
