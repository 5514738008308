import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getLandings({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/landings', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getLanding(_, id) {
    try {
      const response = await http.get(`/landings/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async createLanding(_, payload) {
    try {
      const response = await http.post(`/landings`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateLanding(_, payload) {
    try {
      const response = await http.put(`/landings/${payload.id}`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteLanding(_, id) {
    try {
      const response = await http.delete(`/landings/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreLanding(_, id) {
    try {
      await http.put(`/landings/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Categories
  async getCategories() {
    try {
      const response = await http.get('/categories')
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async getLandingPrivacyTypes() {
    try {
      const response = await http.get('/landing_privacy_types')
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
