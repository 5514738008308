<template>
  <div class="flex flex-row flex-wrap gap-y-3 gap-x-2">
    <Tippy
      v-for="(param, index) in params"
      :key="index"
      :content="param.param_value"
      class="cursor-pointer bg-gray-100 hover:bg-gray-200 rounded py-1 px-3 border border-gray-400 select-none intro-x"
      :class="{
        'bg-green-200 hover:bg-green-300 border-green-400 text-green-800 font-medium': param.active,
      }"
      @click="handlerParams(param, index)"
    >
      <span class="px-1">{{ param.value }}</span>
    </Tippy>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'VSourceParams',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    sourceId: {
      type: Number,
      required: false,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const store = useStore()

    const params = ref([])

    const handlerParams = (param, index) => {
      const url = props.modelValue
      const existsParam = url.includes(param.display_param)

      existsParam ? deleteParam(param, index) : addParam(param.display_param, index)
    }

    const addParam = (param, index) => {
      const url = props.modelValue
      const noParams = !url.includes('?')
      const delimiter = noParams ? '?' : '&'

      context.emit('update:modelValue', `${url}${delimiter}${param}`)
      params.value[index].active = true
    }

    const deleteParam = (param, index) => {
      const [url, parameters] = props.modelValue.split('?')

      const paramsList = parameters?.split('&')
      const clearedParams = paramsList?.filter(value => value !== param.display_param).join('&')

      const returnUrl = clearedParams ? `${url}?${clearedParams}` : url

      context.emit('update:modelValue', returnUrl)
      params.value[index].active = false
    }

    const generateSourceParams = async () => {
      params.value = []

      if (!props.sourceId) {
        return
      }

      const source = props.isPartner
        ? await store.dispatch('myTrafficSource/getTrafficSourceById', props.sourceId)
        : await store.dispatch('trafficSource/getTrafficSourceById', props.sourceId)

      if (source) {
        let uniquParams = []

        source.data.variables_map?.forEach(param => {
          const url = props.modelValue
          const displayParam = `${param.name}=${param.value}`
          const activeParam = url.includes(displayParam)
          const isUniquParam = !uniquParams.includes(param.name)

          if (isUniquParam) {
            params.value.push({
              value: param.name,
              param_value: param.value,
              display_param: displayParam,
              active: activeParam,
            })

            uniquParams.push(param.name)
          }
        })
      }
    }

    watch(
      () => props.sourceId,
      () => generateSourceParams()
    )

    watch(
      () => props.modelValue,
      url => {
        params.value.forEach((param, index) => {
          const isExistParam = url.includes(param.display_param)

          if (isExistParam) {
            params.value[index].active = true
          } else {
            params.value[index].active = false
          }
        })
      }
    )

    generateSourceParams()

    return {
      params,
      handlerParams,
    }
  },
})
</script>
