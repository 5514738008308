<template>
  <div class="container">
    <audio :src="urlNotificationSound" autoplay="autoplay"></audio>
    <div class="flex flex-row">
      <div>
        <h1 class="font-medium text-gray-800 mb-1">{{ notification.getTitle(record) }}</h1>
        <span class="text-gray-700">
          {{ notification.getDescriptionEntity(record) }}
          <span class="text-blue-700 font-medium hover:underline">{{ record.name }}</span>
          {{ notification.getDescriptionStatus(record) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useNotificationUtil } from '@/hooks/useNotification'
import notificationSound from '@/assets/audio/notificationSound.mp3'

export default defineComponent({
  name: 'VToastNotification',
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const notification = useNotificationUtil()
    const urlNotificationSound = notificationSound

    return {
      notification,
      urlNotificationSound,
    }
  },
})
</script>

<style lang="scss">
.Vue-Toastification__toast--warning {
  background-color: white !important;
  color: #718096 !important;
  max-width: 500px !important;
  font-family: 'Roboto', 'Lato', Helvetica, Arial, sans-serif !important;

  .Vue-Toastification__close-button {
    font-weight: normal !important;
    color: #718096 !important;
    font-size: 27px !important;
  }

  .Vue-Toastification__progress-bar {
    background-color: rgb(74 151 209) !important;
  }
}
</style>
