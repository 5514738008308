<template>
  <div class="v-dialog" v-if="showDialog">
    <div class="v-dialog__backdrop">
      <div
        class="v-dialog__body"
        :class="{
          'v-dialog__body__size--xs': size === 'xs',
          'v-dialog__body__size--sm': size === 'sm',
          'v-dialog__body__size--md': size === 'md',
          'v-dialog__body__size--lg': size === 'lg',
          'v-dialog__body__size--mlg': size === 'mlg',
        }"
      >
        <div class="modal-content">
          <div class="flex justify-between px-3 py-1 border-b">
            <div class="flex flex-col">
              <span class="text-xl" :class="{ 'py-1.5': !recordId }">{{ textHeader }}</span>
              <div class="flex flex-row items-center">
                <span v-if="recordId" class="text-gray-600 text-xs">ID: {{ recordId }}</span>
                <span v-if="status && recordId" class="mx-1.5">•</span>
                <div v-if="status" class="flex flex-row items-center">
                  <span
                    v-if="status === 1"
                    class="bg-orange-300 text-orange-800 text-xs font-medium px-3 py-px rounded"
                  >
                    {{ t('common.moderation') }}
                  </span>
                  <span
                    v-else-if="status === 2"
                    class="bg-green-200 text-green-700 text-xs font-medium px-3 py-px rounded"
                  >
                    {{ t('common.active') }}
                  </span>
                  <span v-else-if="status === 3" class="bg-red-300 text-red-800 text-xs font-medium px-3 py-px rounded">
                    {{ t('common.blocked') }}
                  </span>
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <button type="button" @click="$emit('close')">
                <XIcon class="w-6 h-6 text-gray-700 hover:text-gray-900" />
              </button>
            </div>
          </div>

          <div
            class="modal-body"
            :class="{
              'overflow-y-auto': !hideScroll,
            }"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    textHeader: {
      type: String,
      default: '',
    },
    recordId: {
      type: Number,
    },
    status: {
      type: Number,
    },
    size: {
      type: String,
      default: 'md',
    },
    hideScroll: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    watch(
      () => props.showDialog,
      value => {
        if (value) {
          document.documentElement.style.overflow = 'hidden'
          return
        }

        document.documentElement.style.overflow = 'auto'
      }
    )

    return {
      t,
    }
  },
})
</script>

<style lang="scss">
.v-dialog {
  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s ease;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    min-height: -webkit-fill-available;
    backdrop-filter: blur(4px);
  }

  &__body {
    animation: fadeIn ease 0.3s;
    margin: 5px;

    &__size {
      &--xs {
        width: 550px;
        min-width: 290px;
      }
      &--sm {
        width: 650px;
        min-width: 290px;
      }
      &--md {
        width: 850px;
        min-width: 290px;
      }
      &--lg {
        width: 1100px;
        min-width: 290px;
      }
      &--mlg {
        width: 95%;
        min-width: 290px;
        max-width: 2000px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal-body {
    padding: 0 1.25rem 0 1.25rem;
    max-height: 90vh;

    @media (max-width: 767px) {
      max-height: 77vh;
    }
  }

  .modal-footer {
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: flex-end;
    border-top: none;
  }
}
</style>
