<template>
  <div class="v-field-textarea">
    <label class="block mt-3.5 mb-0.5" v-if="t(label)">
      {{ t(label) }}
      <span v-if="required" class="text-theme-6">*</span>
    </label>

    <textarea
      class="form-control"
      style="height: 60px; min-height: 40px;"
      :placeholder="t(placeholder) || t(label)"
      :disabled="disabled"
      :class="{
        'border-theme-6': errors.length,
      }"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <div v-for="error of errors" :key="error.$uid" class="text-xs text-theme-6 ml-1 v-field-textarea__intro-error">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VFieldTextarea',
  props: {
    label: {
      type: String,
      default: 'baseInput.label',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'baseInput.placeholder',
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
.v-field-textarea {
  &__intro-error {
    z-index: 48;
    position: relative;
    opacity: 0;
    transform: translateY(-9px);
    -webkit-animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}
</style>
