import { useToast } from 'vue-toastification'

export const errorHandler = response => {
  const error = response.response

  const statusCode = error?.status || 0

  if (statusCode > 399 && statusCode !== 422) {
    const toast = useToast()
    const message = getHttpErrorMessage(statusCode)

    toast.error(`${statusCode} - ${message}`, {
      closeOnClick: false,
    })

    return true
  }
}

const getHttpErrorMessage = code => {
  switch (code) {
    // 4xx – ошибки клиента
    case 400:
      return 'Неверный запрос'
    case 401:
      return 'Неавторизованный запрос'
    case 403:
      return 'Доступ к ресурсу запрещен'
    case 404:
      return 'Ресурс не найден'
    case 405:
      return 'Недопустимый метод'
    case 406:
      return 'Неприемлемый запрос'
    case 407:
      return 'Требуется идентификация прокси'
    case 408:
      return 'Время запроса истекло'
    case 409:
      return 'Запрос конфликтует с другим запросом'
    case 429:
      return 'Слишком много запросов'

    //5xx – ошибки сервера
    case 500:
      return 'Внутренняя ошибка сервера'
    case 501:
      return 'Сервер не имеет возможностей для обработки запроса'
    case 502:
      return 'Ошибочный шлюз'
    case 503:
      return 'Сервис недоступен'
    case 504:
      return 'Шлюз не отвечает'
    case 505:
      return 'Версия HTTP не поддерживается'
    default:
      return 'Произошла ошибка, повторите позже'
  }
}
