import { provide, ref } from 'vue'
import { useStore } from 'vuex'

export const useMonitoring = () => {
  const store = useStore()
  let interval = null
  const INTERVAL_TIME = 60000 // 1s.

  const count = ref({
    all: 0,
    users: 0,
    campaigns: 0,
    trafficSource: 0,
    trafficCallback: 0,
    trafficBack: 0,
    offerAccessControl: 0,
    landingAccessControl: 0,
  })

  const getCountAll = object => {
    const all = Object.values(object).reduce((a, b) => a + b, 0)

    return all > 9 ? '9+' : all
  }

  const getMontoring = async () => {
    const monitoring = await store.dispatch('monitoring/getMonitoring')

    if (monitoring) {
      count.value = {
        all: getCountAll(monitoring),
        users: monitoring.users,
        campaigns: monitoring.campaigns,
        trafficSource: monitoring['traffic-source'],
        trafficCallback: monitoring['traffic-callback'],
        trafficBack: monitoring['traffic-back'],
        offerAccessControl: monitoring['offer-access-control'],
        landingAccessControl: monitoring['landing-access-control'],
      }
    }
  }

  const runInterval = () => !interval && setInterval(() => getMontoring(), INTERVAL_TIME)

  const isModeraitonPage = pageName => {
    return pageName === 'moderation' && count.value.all ? true : false
  }

  const accessToModeration = menu => {
    let access = false

    Object.values(menu).forEach(item => {
      if (item.pageName === 'moderation') {
        access = true
      }
    })

    return access
  }

  const getPageCount = pageName => {
    switch (pageName) {
      case 'users':
        return count.value.users
      case 'campaigns':
        return count.value.campaigns
      case 'traffic-source':
        return count.value.trafficSource
      case 'traffic-callback':
        return count.value.trafficCallback
      case 'traffic-back':
        return count.value.trafficBack
      case 'access-management-offers':
        return count.value.offerAccessControl
      case 'access-management-landings':
        return count.value.landingAccessControl
    }

    return false
  }

  provide('injectMonitoring', getMontoring)
  provide('injectMonitoringCount', count)

  return {
    count,
    getMontoring,
    runInterval,
    accessToModeration,
    isModeraitonPage,
    getPageCount,
  }
}
