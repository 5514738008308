import { errorHandler } from '@/api/utils/ErrorHandler'
import { http_auth } from '@/api/http'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getRoles({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http_auth.get('/roles', config)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addRole(_, params) {
    try {
      const response = await http_auth.post(`/roles`, params)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteRole(_, id) {
    try {
      const response = await http_auth.delete(`/roles/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.log(error)
    }
  },

  async getRoleById(_, id) {
    try {
      const response = await http_auth.get(`/roles/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.log(error)
    }
  },

  async updateRoleInfo(_, params) {
    try {
      const response = await http_auth.put(`/roles/${params.id}`, params.roleInfo)
      return response
    } catch (error) {
      errorHandler(error)
      console.log(error)
    }
  },

  // ROLE PERMISSONS
  async getRolePermissions(_, id) {
    try {
      const response = await http_auth.get(`/roles/${id}/permissions`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addRolePermissions(_, params) {
    try {
      const response = await http_auth.post(`/roles/${params.id}/permissions`, { permissions: params.permissionsIds })
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async deleteRolePermissions(_, params) {
    try {
      const response = await http_auth.delete(`/roles/${params.id}/permissions`, {
        data: { permissions: params.permissionsIds },
      })
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
