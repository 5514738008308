<template>
  <div>
    <div class="font-semibold mt-4 uppercase text-gray-700">
      {{ t('campaign.tableTitle_1') }}
    </div>
    <VTable :columns="tables.editable.columns" :data="tables.editable.data" :hideScroll="true">
      <template #rows>
        <tr
          v-for="(service, index) of tables.editable.data"
          :key="index"
          class="bg-white border-b border-opacity-50 border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100"
        >
          <td scope="row" class="px-2 py-2.5 text-gray-900 dark:text-white">
            <div class="flex flex-row items-center">
              <HeadphonesIcon class="w-6 h-6 text-gray-600" />
              <div class="flex flex-col ml-3">
                <span>
                  <span>{{ service.operator.name }}</span>
                </span>
                <span class="flex flex-row text-xs text-gray-600 truncate">
                  <span>{{ translation.getCountry(service.operator.country) || '-' }}</span>
                </span>
              </div>
            </div>
          </td>

          <td scope="row" class="px-2 py-2.5 text-gray-900 dark:text-white">
            <div class="flex flex-row items-center">
              <ServerIcon class="w-6 h-6 text-gray-600" />
              <div class="flex flex-col ml-3">
                <span>
                  <span>{{ service.service.name }}</span>
                </span>
                <span class="flex flex-row text-xs text-gray-600 truncate">
                  <span>ID: {{ service.service.id }}</span>
                </span>
              </div>
            </div>
          </td>

          <td v-if="isRevShare()" scope="row" class="px-2 py-2.5 text-gray-900 dark:text-white">
            <BaseInput v-model="service.rsh_partner_rate" @input="changeHandler(index)" :inputGroup="true">
              <template #inputGroup>
                <div class="input-group-text font-medium select-none">%</div>
              </template>
            </BaseInput>
          </td>

          <td scope="row" class="px-2.5 py-2.5 text-gray-900 dark:text-white">
            <div class="flex flex-row">
              <BaseInput class="w-full" v-model="service.cpa_partner_rate.sum" @input="changeHandler(index)" />

              <div class="w-full">
                <VFieldSelect
                  v-model="service.cpa_partner_rate.currency"
                  @change="changeHandler(index)"
                  :options="options.currency"
                  optionName="iso_name"
                  class="ml-2"
                />
              </div>
            </div>
          </td>

          <td scope="row" class="px-2.5 py-2.5 text-gray-900 dark:text-white">
            <div class="flex flex-row items-center justify-center flex-nowrap truncate uppercase text-xs">
              <span v-if="service.personal_payout === true" class="px-4 py-1 rounded-xl bg-red-500 text-white">
                {{ t('campaign.personal') }}
              </span>
              <span v-else class="px-3 py-1 rounded-xl bg-blue-500 text-white">
                {{ t('campaign.standard') }}
              </span>
            </div>
          </td>

          <td class="whitespace-nowrap" align="right">
            <button
              @click="deleteService(service.operator.id)"
              v-if="service.personal_payout === true"
              type="button"
              class="mr-2"
            >
              <Tippy :content="t('vTable.delete')">
                <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-red-600" />
              </Tippy>
            </button>
          </td>
        </tr>
      </template>
    </VTable>

    <div class="mt-10 font-semibold uppercase text-gray-700">
      {{ t('campaign.tableTitle_2') }}
    </div>
    <VTable :hideButtons="['edit', 'delete']" :columns="tables.info.columns" :data="tables.info.data" />

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
        <XIcon class="w-4 h-4 mr-2" />
        {{ t('campaign.close') }}
      </button>

      <button class="btn btn-primary" @click="handleSubmit()">
        <SaveIcon class="w-4 h-4 mr-2" />
        {{ t('campaign.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { convertAmountToDisplay, convertAmountToSend } from '@/utils/amountConverter'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { parseNumber } from '@/utils/number.ts'
import { useTranslation } from '@/hooks/useTranslation'

export default defineComponent({
  name: 'CampaignServices',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const translation = useTranslation()

    const isCpa = () => props.campaign.payment_model.name === 'CPA'
    const isRevShare = () => !isCpa()

    const TYPES = {
      new: 'new',
      update: 'update',
    }

    const tables = ref({
      editable: {
        columns: getEditableColumns(),
        data: [],
      },
      info: {
        columns: getInfoColumns(),
        data: [],
      },
    })

    function getEditableColumns() {
      switch (true) {
        case isCpa():
          return {
            operator: t('campaign.operator'),
            service: t('campaign.service'),
            cpa_partner_rate: t('campaign.partnerRateCPA'),
          }
        case isRevShare():
          return {
            operator: t('campaign.operator'),
            service: t('campaign.service'),
            rsh_partner_rate: t('campaign.partnerRateRs'),
            cpa_partner_rate: t('campaign.partnerRateCPA'),
          }
      }
    }

    function getInfoColumns() {
      switch (true) {
        case isCpa():
          return {
            operator: t('campaign.operator'),
            service: t('campaign.service'),
            sigma_rate: t('campaign.sigmaRate'),
            cpa_partner_rate: t('campaign.partnerRateCPA'),
          }
        case isRevShare():
          return {
            operator: t('campaign.operator'),
            service: t('campaign.service'),
            sigma_rate: t('campaign.sigmaRate'),
            rsh_partner_rate: t('campaign.partnerRateRs'),
            cpa_partner_rate: t('campaign.partnerRateCPA'),
          }
      }
    }

    const campaignOperatorIds = ref([])

    const converCpaRate = () => {
      tables.value.editable.data.forEach(operator => {
        const cpaSum = operator.cpa_partner_rate?.sum
        if (cpaSum) {
          operator.cpa_partner_rate.sum = convertAmountToDisplay(cpaSum)
        }
      })
    }

    const getOperators = async () => {
      tables.value.editable.data = []
      campaignOperatorIds.value = []

      const campaingOperators = await store.dispatch('campaigns/getOperatorsByCampaign', props.campaign.id)
      const offerOperators = await store.dispatch('offers/getOperatorsByOffer', props.campaign.offer.id)

      campaingOperators.data.operators.forEach(operator => {
        campaignOperatorIds.value.push(`o${operator.operator.id}_s${operator.service.id}`)
      })

      if (offerOperators?.data) {
        offerOperators.data.operators.forEach(operator => {
          const offerOperatorServiceId = `o${operator.operator.id}_s${operator.service.id}`
          const isPersonalPayOut = () => campaignOperatorIds.value.includes(offerOperatorServiceId)

          if (isPersonalPayOut()) {
            campaingOperators.data.operators.forEach(row => {
              const campaignOperatorServiceId = `o${row.operator.id}_s${row.service.id}`

              if (campaignOperatorServiceId === offerOperatorServiceId) {
                tables.value.editable.data.push({ ...row, personal_payout: true })
              }
            })
          } else {
            tables.value.editable.data.push(operator)
          }
        })

        converCpaRate()
        generateInfoTable()
      }
    }

    const generateInfoTable = async () => {
      tables.value.info.data = []

      tables.value.editable.data.forEach(async row => {
        const operators = await store.dispatch('services/getOperatorsByService', row.service.id)

        if (operators.data) {
          const operator = operators.data.operators.find(operator => row.operator.id == operator.operator.id)

          if (operator) {
            switch (true) {
              case isCpa(): {
                const rate = {
                  sum: operator.cpa_rate?.sum || operator.rsh_rate?.sum,
                  currency: operator.cpa_rate?.currency || operator.rsh_rate?.currency,
                }

                const sigmaRateCurrency = await store.dispatch('currency/getCurrencyById', rate.currency)
                const sigmaRate = convertAmountToDisplay(rate.sum) || ''
                const sigmaCurrency = sigmaRateCurrency?.data.iso_name || ''

                const partnerCpaRateCurrency = await store.dispatch(
                  'currency/getCurrencyById',
                  row.cpa_partner_rate.currency
                )
                const partnerCurrency = partnerCpaRateCurrency?.data.iso_name || ''

                tables.value.info.data.push({
                  operator: `${row.operator.name} (${translation.getCountry(row.operator.country)})`,
                  service: `(${row.service.id}) ${row.service.name}`,
                  sigma_rate: `${sigmaRate} ${sigmaCurrency}`,
                  cpa_partner_rate: `${row.cpa_partner_rate?.sum} ${partnerCurrency}`,
                })

                break
              }
              case isRevShare(): {
                const rate = {
                  sum: operator.rsh_rate?.sum || operator.cpa_rate?.sum,
                  currency: operator.rsh_rate?.currency || operator.cpa_rate?.currency,
                }

                const sigmaRateCurrency = await store.dispatch('currency/getCurrencyById', rate.currency)
                const sigmaRate = convertAmountToDisplay(rate.sum)
                const percentRs = parseNumber(row.rsh_partner_rate)
                const rateRs = sigmaRate && percentRs ? (sigmaRate / 100) * percentRs : ''
                const truncRateRs = Math.trunc(rateRs * 10000) / 10000 || ''

                const partnerCpaRateCurrency = await store.dispatch(
                  'currency/getCurrencyById',
                  row.cpa_partner_rate.currency
                )
                const partnerCurrency = partnerCpaRateCurrency?.data.iso_name || ''

                tables.value.info.data.push({
                  operator: `${row.operator.name} (${translation.getCountry(row.operator.country)})`,
                  service: `(${row.service.id}) ${row.service.name}`,
                  sigma_rate: `${sigmaRate} ${sigmaRateCurrency?.data.iso_name || ''}`,
                  rsh_partner_rate: `${truncRateRs || ''} ${sigmaRateCurrency?.data.iso_name || ''}`,
                  cpa_partner_rate: `${row.cpa_partner_rate?.sum} ${partnerCurrency}`,
                })

                break
              }
            }
          }
        }
      })
    }

    const options = ref({
      currency: [],
    })

    const handleSubmit = async () => {
      for (const service of tables.value.editable.data) {
        switch (service.type) {
          case TYPES.new:
            await createService(service)
            break
          case TYPES.update:
            await updateService(service)
            break
        }
      }

      getOperators()
    }

    const createService = async service => {
      let data = {
        service_id: service.service.id,
        cpa_partner_rate: {
          sum: convertAmountToSend(service.cpa_partner_rate.sum),
          currency: service.cpa_partner_rate.currency,
        },
      }

      if (service.rsh_partner_rate) {
        data = { ...data, rsh_partner_rate: service.rsh_partner_rate }
      }

      const response = await store.dispatch('campaigns/createOperatorToCampaign', {
        campaign_id: props.campaign.id,
        operator_id: service.operator.id,
        data,
      })

      if (response) {
        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }
    }

    const updateService = async service => {
      let data = {
        service_id: service.service.id,
        cpa_partner_rate: {
          sum: convertAmountToSend(service.cpa_partner_rate.sum),
          currency: service.cpa_partner_rate.currency,
        },
      }

      if (service.rsh_partner_rate) {
        data = { ...data, rsh_partner_rate: service.rsh_partner_rate }
      }

      const response = await store.dispatch('campaigns/updateOperatorToCampaign', {
        campaign_id: props.campaign.id,
        operator_id: service.operator.id,
        data,
      })

      if (response) {
        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }
    }

    const deleteService = async operatorId => {
      const response = await store.dispatch('campaigns/deleteOperatorFromCampaign', {
        campaign_id: props.campaign.id,
        operator_id: operatorId,
      })

      if (response) {
        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        getOperators()
      }
    }

    const changeHandler = index => {
      const record = tables.value.editable.data[index] || {}
      if (record.type) {
        return
      }

      const isNew = () => !record.personal_payout
      const isUpdate = () => record.personal_payout

      switch (true) {
        case isNew():
          record.type = TYPES.new
          break
        case isUpdate():
          record.type = TYPES.update
          break
      }
    }

    const getOptionsCurrency = async () => {
      const currency = await store.dispatch('currency/getCurrencies', { full: false })
      options.value.currency = currency.data ? currency.data : []
    }

    getOperators()

    // Dropdown options
    getOptionsCurrency()

    return {
      t,
      translation,
      tables,
      options,
      TYPES,
      handleSubmit,
      changeHandler,
      deleteService,
      isRevShare,
    }
  },
})
</script>
