import { getDate } from './parameters/date'
import { getDateRange } from './parameters/dateRange'

/*
 Convert params to display
*/
export const convertParamsToDisplay = (query, route) => {
  const params = query.options.parameters

  Object.entries(params).forEach(([index, param]) => {
    param = getUrlParam(param, route)

    switch (param.type) {
      case 'date':
        query.options.parameters[index].value = getDate(param.value)
        break
      case 'datetime-range-with-seconds':
        query.options.parameters[index].value = getDateRange(param.value)
        break
      default:
        query.options.parameters[index].value = param.value
    }
  })

  return query
}

/*
 Convert params for send
*/
export const convertParamsForSend = (params, router = null) => {
  let sendingParams = {}

  Object.values(params).forEach(param => {
    switch (param.type) {
      case 'datetime-range-with-seconds':
        sendingParams[param.name] = param.value ? { start: param.value[0], end: param.value[1] } : []
        break
      case 'text':
        sendingParams[param.name] = String(param.value).length ? param.value : '0'
        break
      case 'number':
        sendingParams[param.name] = String(param.value).length ? Number(param.value) : '0'
        break
      default:
        sendingParams[param.name] = param.value
    }
  })

  router && setUrlParams(params, router)

  return sendingParams
}

const setUrlParams = (params, router) => {
  const urlParams = {}

  Object.values(params).forEach(param => {
    switch (param.type) {
      case 'enum':
      case 'text':
      case 'number':
      case 'date':
        urlParams[param.name] = encodeURIComponent(param.value)
        break
      case 'datetime-range-with-seconds':
        urlParams[param.name] = encodeURIComponent(param.value.join('--'))
        break
      case 'query':
        if (param.multiValuesOptions) {
          urlParams[param.name] = encodeURIComponent(param.value.join(','))
          break
        }

        urlParams[param.name] = encodeURIComponent(param.value)
        break
    }
  })

  router?.push({ query: urlParams })
}

const getUrlParam = (param, route) => {
  switch (param.type) {
    case 'enum':
    case 'text':
    case 'number':
    case 'date':
      if (param.name in route?.query) {
        param = {
          ...param,
          value: decodeURIComponent(route.query[param.name]) || param.value,
        }
      }
      break
    case 'datetime-range-with-seconds':
      if (param.name in route?.query) {
        const decodeRangeDate = decodeURIComponent(route.query[param.name])
        const splitRangeDate = decodeRangeDate.split('--')
        const rangeDate = {
          start: splitRangeDate[0],
          end: splitRangeDate[1],
        }

        param = {
          ...param,
          value: rangeDate || param.value,
        }
      }
      break
    case 'query':
      if (param.name in route?.query) {
        if (param.multiValuesOptions) {
          const filterValue = decodeURIComponent(route.query[param.name]).split(',')

          param = {
            ...param,
            value: filterValue || param.value,
          }

          break
        }

        param = {
          ...param,
          value: decodeURIComponent(route.query[param.name]) || param.value,
        }
      }
      break
  }

  return param
}
