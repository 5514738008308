export function useServerErrorHandler(rules, serverError, v$) {
  const errorFieldNames = Object.keys(serverError)
  const rulesFieldNames = Object.keys(rules)

  rulesFieldNames.forEach(ruleName => {
    const idx = errorFieldNames.indexOf(ruleName)

    if (idx !== -1 && errorFieldNames[idx] === ruleName) {
      serverError[ruleName].forEach(msg => {
        v$.value[ruleName].$errors.push({ $message: msg })
      })
    }
  })
}
