<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link :to="{ path: '/' }" class="-intro-x flex items-center pt-5">
            <img alt="Sigma" class="w-40" :src="require(`@/assets/images/logo.png`).default" />
          </router-link>

          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/sing_in.png`).default"
            />
            <div class="intro-x text-white font-medium text-4xl leading-tight mt-10">{{ t('auth.bigTextUp') }}</div>
            <div class="intro-x text-white font-medium text-4xl leading-tight ">{{ t('auth.bigTextDown') }}</div>
            <div class="intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
              {{ t('auth.smallText') }}
            </div>
          </div>
        </div>

        <form @submit.prevent="submitResetPassword" class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            v-if="!responseMessage"
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <fieldset :disabled="loading" class="relative">
              <legend class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                {{ t('auth.recoverPassword') }}
              </legend>
              <div
                v-if="loading"
                class="z-50 absolute inset-x-0 top-46 col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="tail-spin" class="w-8 h-8" />
                <div class="text-center text-xs mt-2"></div>
              </div>
              <!-- BEGIN: Change Password -->
              <div class="intro-y lg:mt-5">
                <div class="intro-x mt-8">
                  <h3 v-if="errorMessage" class="text-theme-6">
                    {{ errorMessage }}
                  </h3>

                  <BaseInput
                    type="email"
                    label="auth.email"
                    iconLeft="MailIcon"
                    v-model="formData.email"
                    :errors="v$.email.$errors"
                  />
                </div>
                <button type="submit" class="btn btn-primary mt-4">
                  {{ t('auth.recoverPassword') }}
                </button>
              </div>
              <!-- END: Change Password -->
            </fieldset>
          </div>
          <div
            v-if="responseMessage"
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <div class="mt-2">{{ responseMessage }}</div>
            <br />
            <router-link :to="{ path: 'login' }" class="w-40 btn btn-primary mt-4">
              {{ t('auth.logIn') }}
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'

export default defineComponent({
  components: { DarkModeSwitcher },
  setup() {
    const store = useStore()
    const formData = reactive({ email: '' })
    let errorMessage = ref('')
    const loading = ref(false)
    const responseMessage = ref('')

    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
          email,
        },
      }
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const v$ = useVuelidate(rules, formData)

    const submitResetPassword = async () => {
      loading.value = true
      try {
        const result = await v$.value.$validate()
        if (!result) {
          return
        }

        const response = await store.dispatch('auth/resetPassword', formData)

        responseMessage.value = 'Проверьте почту для сброса пароля!'
        console.log('Проверьте почту: ', response)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          errorMessage.value = 'Пользователь с таким email не зарегистрирован!'
        } else {
          console.log(error)
        }
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return {
      formData,
      submitResetPassword,
      responseMessage,
      v$,
      errorMessage,
      loading,
      t,
    }
  },
})
</script>
