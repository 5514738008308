import { useErrorNotification } from '@/hooks/useErrorNotification'
import { authApi } from './index'

const state = {}

const actions = {
  async getMenu({ dispatch }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await authApi.get('/menu', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
