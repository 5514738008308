export const enVariablesMap = {
  addVariable: 'Add',
  deleteVariable: 'Delete variable',

  parameterNotFilled: 'Parameter not filled',

  addVariableTitle: 'Add parameter',
  diasbledAddVariableTitle: 'You cannot add a new parameter because previous parameters are not filled',
}

export const ruVariablesMap = {
  addVariable: 'Добавить',
  deleteVariable: 'Удалить параметр',
  parameterNotFilled: 'Не заполнен параметр',

  addVariableTitle: 'Добавить параметр',
  diasbledAddVariableTitle: 'Нельзя добавить новый параметр т.к. не заполнены предыдущие параметры',
}
