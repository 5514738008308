export const enCampaign = {
  addCampaignButton: 'Campaign setup',
  landingManagement: 'Landing management',
  serviceManagement: 'Service management',

  author: 'Author',
  id: 'ID',
  title: 'Name',
  offer: 'Offer',
  country: 'Country',
  domain: 'Domain',
  paymentModel: 'Payment type',
  isActive: 'Status',
  createdAt: 'Setup date',
  copyCampaign: 'Copy campaign',

  ransom: '(buy in)',

  confirmDialog: {
    title: 'Campaign delete',
    message: 'Are you sure you want to delete campaign?',
  },

  confirmRestoreDialog: {
    title: 'Restore a campaign',
    message: 'Do you really want to restore the campaign?',
  },

  save: 'Save',
  cancel: 'Cancel',

  // Form
  name: 'Name',
  enrollmentsRule: 'Enrollments rule',
  cpaRate: 'CPA',
  owner: 'Owner',
  ownerСallback: 'Author callback',
  investor: 'Investor',
  investorСallback: 'Investor callback',
  rshRate: 'Revenue share',
  caps: 'Cap (0 - no limit)',
  status: 'Status',
  campaignHash: 'Campaign hash',
  campaignUrl: 'Campaign URL',
  copyUrlCampaign: 'Copy URL campaign',
  campaignUrlMessage: 'Campaign URL is copied',
  copyUrlErrorMessage: 'Campaign URL copy error',
  getUrlErrorMessage: 'Get URL copy error',
  trafficSource: 'Traffic source',
  callback: 'Callback',
  trafficBack: 'Trafficback',
  currency: 'Currency',
  blacklist: 'Blacklist',
  redirectCampaign: 'Redirect campaign',

  editCampaign: 'Edit campaign',
  addCampaign: 'Setup campaign',

  // landings dialog
  rotationType: 'Rotation type',
  rotation: {
    random: 'Arbitrary',
    order: 'In order',
    weight: 'By weight',
  },

  addLanding: 'Add selected landings',
  removeLanding: 'Delete selected landings',

  hideLandings: 'Hide available landings',
  showLandings: 'Show available landings',
  selectAll: 'Select all',
  landingId: 'Id',
  order: 'Order',
  weight: 'Weight',
  close: 'Close',

  duplicateOrderError: 'There is a duplicate order in the selected landing pages',

  // CPA RevShareForm
  tableTitle_1: 'Services',
  personal: 'Personal',
  standard: 'Standard',

  tableTitle_2: 'Standard commercial terms',
  operator: 'Operator',
  service: 'Service',
  deductionsToPartners: 'Payout to partners',
  sigmaRate: 'Payin to platform',
  partnerRateCpa: 'Payout to partners',
  revShare: 'RevShare',
  CPA: 'CPA',
  partnerRateRs: 'Payout to partners (RevShare)',
  partnerRateCPA: 'Payout to partners (CPA)',
  payout_cpa: 'Payout CPA',
  payout_revshare: 'Payout Revshare',
}

export const ruCampaign = {
  addCampaignButton: 'Добавить кампанию',
  landingManagement: 'Управление лендингами',
  serviceManagement: 'Управление сервисами',

  id: 'ID',
  title: 'Название',
  offer: 'Оффер',
  country: 'Страна',
  domain: 'Домен',
  paymentModel: 'Тип оплаты',
  isActive: 'Статус',
  createdAt: 'Дата создания',
  copyCampaign: 'Скопировать кампанию',

  ransom: '(выкуп)',

  confirmDialog: {
    title: 'Удаление кампании',
    message: 'Вы действительно хотите удалить кампанию?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление кампании',
    message: 'Вы действительно хотите восстановить кампанию?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',

  // Form
  name: 'Название',
  enrollmentsRule: 'Правило зачисления',
  cpaRate: 'Отчисления CPA',
  owner: 'Владелец',
  ownerСallback: 'Коллбэк автора',
  investorСallback: 'Коллбэк инвестора',
  investor: 'Инвестор',
  author: 'Автор',
  rshRate: 'Отчисления RevShare',
  caps: 'Лимит в сутки (0 - без лимита)',
  status: 'Статус',
  campaignHash: 'Хэш кампании',
  campaignUrl: 'URL кампании',
  copyUrlCampaign: 'Скопировать URL кампании',
  campaignUrlMessage: 'URL кампании скопирован в буфер обмена',
  copyUrlErrorMessage: 'Ошибка копирования URL кампании',
  getUrlErrorMessage: 'Ошибка получения URL кампании',
  trafficSource: 'Источник трафика',
  callback: 'Коллбэк',
  trafficBack: 'Трафикбэк',
  currency: 'Валюта',
  blacklist: 'Черный список',
  redirectCampaign: 'Редирект на кампанию',

  editCampaign: 'Редактирование кампании',
  addCampaign: 'Добавление кампании',

  // landings dialog
  rotationType: 'Способ ротации',
  rotation: {
    random: 'Произвольный',
    order: 'По порядку',
    weight: 'По весу',
  },

  addLanding: 'Добавить выбранные лендинги',
  removeLanding: 'Удалить выбранные лендинги',

  hideLandings: 'Скрыть доступные лендинги',
  showLandings: 'Отобразить доступные лендинги',
  selectAll: 'Выбрать все',
  landingId: 'Id',
  order: 'Порядок',
  weight: 'Вес',
  close: 'Закрыть',

  duplicateOrderError: 'В выбранных лендингах присутствует дублирующий порядок',

  // CPA RevShareForm
  tableTitle_1: 'Сервисы',
  personal: 'Персональные',
  standard: 'Стандартные',
  tableTitle_2: 'Стандартные коммерческие условия',
  operator: 'Оператор',
  service: 'Сервис',
  deductionsToPartners: 'Отчисления партнерам',
  revShare: 'RevShare',
  CPA: 'CPA',
  sigmaRate: 'Отчисления платформе',
  partnerRateCpa: 'Отчисления партнерам',
  partnerRateRs: 'Отчисл. партнерам (RevShare)',
  partnerRateCPA: 'Отчисл. партнерам (CPA)',
  payout_cpa: 'Отчисления (CPA)',
  payout_revshare: 'Отчисления (REVSHARE)',
}
