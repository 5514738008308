export const enNotification = {
  notifications: 'Notifications',
  all: 'All',
  unread: 'Unread',
  noData: 'No notifications found in the last 30 days',
  openAllNotifications: 'Open all notifications for the last 30 days',

  // date
  justNow: 'just now',
  minutesAgo: 'min. ago',
  hoursAgo: 'h. ago',
  yesterday: 'yesterday',

  messages: {
    campaign: {
      moderating: {
        title: 'The campaign was created',
        entity: 'Your campaign',
        status: 'has been successfully created. Please wait for the moderation',
      },
      approved: {
        title: 'Campaign was approved',
        entity: 'Your campaign',
        status: 'is approved. You can start the promotion now',
      },
      unapproved: {
        title: 'Campaign was rejected',
        entity: 'Your campaign',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    trafficSource: {
      moderating: {
        title: 'The traffic source was created',
        entity: 'Your new traffic source',
        status: 'has been successfully created. Please wait for the moderation',
      },
      approved: {
        title: 'The traffic source was approved',
        entity: 'Your new traffic source',
        status: 'is approved. You can already start using it',
      },
      unapproved: {
        title: 'The traffic source was rejected',
        entity: 'Your traffic source',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    callback: {
      moderating: {
        title: 'The callback was created',
        entity: 'Your new callback',
        status: 'has been successfully created. Please wait for the moderation',
      },
      approved: {
        title: 'The callback was approved',
        entity: 'Your new callback',
        status: 'is approved. You can already start using it',
      },
      unapproved: {
        title: 'The callback was rejected',
        entity: 'Your callback',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    trafficBacks: {
      moderating: {
        title: 'The trafficback was created',
        entity: 'Your new traffic back',
        status: 'has been successfully created. Please wait for the moderation ',
      },
      approved: {
        title: 'The trafficback was approved',
        entity: 'Your new callback',
        status: 'is approved. You can already start using it',
      },
      unapproved: {
        title: 'The trafficback was rejected',
        entity: 'Your callback',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    offerWall: {
      moderating: {
        title: 'Offer was requested',
        entity: 'Your request for the offer',
        status: 'is being processed',
      },
      approved: {
        title: 'Request was granted',
        entity: 'Your request for the offer',
        status: 'is granted. You can set up your campaign now',
      },
      unapproved: {
        title: 'Request was rejected',
        entity: 'Your request for the offer',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    landingWall: {
      moderating: {
        title: 'The landing page was requested',
        entity: 'Your request for the landing page',
        status: 'is being processed',
      },
      approved: {
        title: 'Request was granted',
        entity: 'Your request for the landing page',
        status: 'is granted. You can set up your campaign now ',
      },
      unapproved: {
        title: 'Request was rejected',
        entity: 'Your request for the landing page',
        status: 'is rejected. Please contact your manager for further questions',
      },
    },

    forManager: {
      campaign: {
        title: 'New campaign created',
        entity: 'A user created a new campaign',
        status: '',
      },

      trafficSource: {
        title: 'New traffic source created',
        entity: 'A user created a new traffic source',
        status: '',
      },

      callback: {
        title: 'New callback created',
        entity: 'A user created a new callback',
        status: '',
      },

      trafficBacks: {
        title: 'New traffic back created',
        entity: 'A user created a new traffic back',
        status: '',
      },

      offerWall: {
        title: 'New offer access request',
        entity: 'The user has sent a request for access to the offer',
        status: '',
      },

      landingWall: {
        title: 'New landing access request',
        entity: 'The user has sent a request for access to the lending',
        status: '',
      },
    },
  },
}

export const ruNotification = {
  notifications: 'Уведомления',
  all: 'Все',
  unread: 'Непрочитанные',
  noData: 'За последние 30 дней уведомлений не обнаружено',
  openAllNotifications: 'Открыть все уведомления за последние 30 дней',

  // date
  justNow: 'только что',
  minutesAgo: 'мин. назад',
  hoursAgo: 'ч. назад',
  yesterday: 'вчера',

  messages: {
    campaign: {
      moderating: {
        title: 'Кампания создана',
        entity: 'Новая кампания',
        status: 'успешно создана. Пожалуйста, ожидайте модерацию',
      },
      approved: {
        title: 'Ваша кампания подтверждена',
        entity: 'Ваша кампания',
        status: 'успешно прошла модерацию. Уже можете начать продвижение',
      },
      unapproved: {
        title: 'Ваша кампания отклонена',
        entity: 'Ваша кампания',
        status: 'отклонена. Пожалуйста, свяжитесь с нами, если у вас есть дополнительные вопросы',
      },
    },

    trafficSource: {
      moderating: {
        title: 'Вы успешно добавили источник трафика',
        entity: 'Ваш новый источник трафика',
        status: 'успешно создан и сейчас на модерации, пожалуйста подождите',
      },
      approved: {
        title: 'Ваш источник трафика подтвержден',
        entity: 'Ваш новый источник трафика',
        status: 'успешно прошел модерацию. Уже можете начать пользоваться',
      },
      unapproved: {
        title: 'Ваш источник трафика отклонен',
        entity: 'Ваш запрос на создание источника трафика',
        status: 'не прошел модерацию. Пожалуйста, свяжитесь с нами, если у вас есть дополнительные вопросы',
      },
    },

    callback: {
      moderating: {
        title: 'Вы успешно добавили коллбэк',
        entity: 'Ваш новый коллбэк',
        status: 'успешно создан и сейчас на модерации, пожалуйста подождите',
      },
      approved: {
        title: 'Ваш коллбэк подтвержден',
        entity: 'Ваш новый коллбэк',
        status: 'успешно прошел модерацию. Уже можете начать пользоваться',
      },
      unapproved: {
        title: 'Ваш коллбэк отклонен',
        entity: 'Ваш запрос на создание коллбэка',
        status: 'не прошел модерацию. Пожалуйста, свяжитесь с нами, если у вас есть дополнительные вопросы',
      },
    },

    trafficBacks: {
      moderating: {
        title: 'Вы успешно добавили трафикбэк',
        entity: 'Ваш новый трафикбэк',
        status: 'успешно создан и сейчас на модерации, пожалуйста подождите',
      },
      approved: {
        title: 'Ваш трафикбэк подтвержден',
        entity: 'Ваш новый трафикбэк',
        status: 'успешно прошел модерацию. Уже можете начать пользоваться',
      },
      unapproved: {
        title: 'Ваш трафикбэк отклонен',
        entity: 'Ваш запрос на создание трафикбэка',
        status: 'не прошел модерацию. Пожалуйста, свяжитесь с нами, если у вас есть дополнительные вопросы',
      },
    },

    offerWall: {
      moderating: {
        title: 'Вы запросили доступ на оффер',
        entity: 'Ваш запрос на оффер',
        status: 'находиться на модерации',
      },
      approved: {
        title: 'Вы получили доступ на оффер',
        entity: 'Ваш доступ на оффер',
        status: 'разрешен. Уже можете создать кампанию',
      },
      unapproved: {
        title: 'Ваш доступ на оффер отклонён',
        entity: 'Ваш доступ на оффер',
        status: 'запрещен. Пожалуйста, для подробной информации свяжитесь с нашими менеджерами',
      },
    },

    landingWall: {
      moderating: {
        title: 'Вы запросили доступ на лендинг',
        entity: 'Ваш запрос на лендинг',
        status: 'находиться на модерации',
      },
      approved: {
        title: 'Вы получили доступ на лендинг',
        entity: 'Ваш доступ на лендинг',
        status: 'разрешен. Уже можете создать кампанию',
      },
      unapproved: {
        title: 'Ваш доступ на лендинг отклонён',
        entity: 'Ваш доступ на лендинг',
        status: 'запрещен. Пожалуйста, для подробной информации свяжитесь с нашими менеджерами',
      },
    },

    forManager: {
      campaign: {
        title: 'Создана новая кампания',
        entity: 'Пользователь создал новую кампанию',
        status: '',
      },

      trafficSource: {
        title: 'Создан новый источник трафика',
        entity: 'Пользователь создал новый источник трафика',
        status: '',
      },

      callback: {
        title: 'Создан новый коллбэк',
        entity: 'Пользователь создал новый коллбэк',
        status: '',
      },

      trafficBacks: {
        title: 'Создан новый трафикбэк',
        entity: 'Пользователь создал новый трафикбэк',
        status: '',
      },

      offerWall: {
        title: 'Новый запрос доступа на оффер',
        entity: 'Пользователь отправил запрос доступа на оффер',
        status: '',
      },

      landingWall: {
        title: 'Новый запрос доступа на лендинг',
        entity: 'Пользователь отправил запрос доступа на лендинг',
        status: '',
      },
    },
  },
}
