export const enTrafficBack = {
  addTrafficBackButton: 'Setup trafficback',
  addTrafficBack: 'Setup trafficback',
  editTrafficBack: 'Edit trafficback',
  trafficBackURL: 'TrafficBack URL',
  copyTrafficBack: 'Copy trafficback',

  id: 'ID',
  url: 'URL',
  name: 'Name',
  author: 'Author',
  created_at: 'Setup date',
  getRequest: 'Get request',
  status: 'Status',
  trafficSource: 'Traffic source',

  confirmDialog: {
    title: 'Delete trafficback',
    message: 'Are you sure you want to delete trafficback?',
  },

  confirmRestoreDialog: {
    title: 'Restore a trafficback',
    message: 'Do you really want to restore the trafficback?',
  },

  save: 'Save',
  cancel: 'Cancel',
}

export const ruTrafficBack = {
  addTrafficBackButton: 'Добавить трафикбэк',
  addTrafficBack: 'Добавление трафикбэка',
  editTrafficBack: 'Редактирование трафикбэка',
  trafficBackURL: 'Трафикбэк URL',
  copyTrafficBack: 'Скопировать трафикбэк',

  id: 'ID',
  url: 'URL',
  name: 'Название',
  author: 'Автор',
  created_at: 'Дата создания',
  getRequest: 'GET запрос',
  status: 'Статус',
  trafficSource: 'Источник трафика',

  confirmDialog: {
    title: 'Удаление трафикбэка',
    message: 'Вы действительно хотите удалить трафикбэк?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление трафикбэка',
    message: 'Вы действительно хотите восстановить трафикбэк?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',
}
