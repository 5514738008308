<template>
  <div class="mt-3">
    <div>
      <div v-if="loader" class="flex justify-center my-6">
        <LoadingIcon icon="tail-spin" class="w-8 h-8" />
      </div>
      <form v-else @submit.prevent="handleSubmit">
        <div v-if="!roles.length" class="alert bg-theme-12 bg-opacity-40 show flex items-center mb-2">
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          {{ t('vTable.noDataMessage') }}
        </div>
        <ul v-else class="flex flex-col w-full">
          <li
            v-for="role in roles"
            :key="role.id"
            class="my-0.5 cursor-pointer -intro-x"
            @click="role.selected = !role.selected"
          >
            <div
              class="flex flex-row items-center h-14 px-4 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
              :class="{
                'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': role.selected,
              }"
            >
              <BaseCheckbox class="mr-3" v-model="role.selected" />

              <div class="flex flex-col">
                <span class="font-medium">{{ role.name }}</span>
                <span class="font-medium text-xs text-gray-600">{{ role.description }}</span>
              </div>

              <div class="flex flex-row items-center ml-auto">
                <span
                  v-if="role.name === 'Administrator'"
                  class="bg-red-300 text-red-800 py-0.5 text-xs font-medium px-2 rounded animate-pulse"
                >
                  ADMIN
                </span>

                <span class="ml-2 truncate bg-blue-200 text-blue-800 py-0.5 text-xs font-medium px-2 rounded">
                  ID: {{ role.id }}
                </span>
              </div>
            </div>
          </li>
        </ul>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
            {{ t('users.cancel') }}
          </button>

          <button type="submit" class="btn btn-primary">
            <SaveIcon class="w-4 h-4 mr-2" />
            {{ t('users.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'UsersRoleForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const roles = ref([])
    const loader = ref(true)
    const selectedIds = ref([])

    const getSelectedIds = async () => {
      const selected = await store.dispatch('users/getUserRoles', props.user.id)

      if (selected?.data.data.length) {
        selected?.data.data.forEach(role => selectedIds.value.push(role.id))
      }
    }

    const getRoles = async () => {
      const list = await store.dispatch('roles/getRoles')

      await getSelectedIds()

      if (list?.data.data.length) {
        list?.data.data.forEach(role => {
          const isSelectedId = () => selectedIds.value.includes(role.id)
          roles.value.push({ ...role, selected: isSelectedId() })
        })
      }

      loader.value = false
    }

    const handleSubmit = async () => {
      const actions = {
        deleteIds: [],
        addIds: [],
      }

      roles.value.forEach(role => {
        const isDeleted = () => selectedIds.value.includes(role.id) && !role.selected
        const isAdded = () => !selectedIds.value.includes(role.id) && role.selected

        if (isDeleted()) {
          actions.deleteIds.push(role.id)
        }

        if (isAdded()) {
          actions.addIds.push(role.id)
        }
      })

      if (actions.deleteIds.length) {
        const response = await store.dispatch('users/deleteRolesUser', {
          id: props.user.id,
          roleIds: actions.deleteIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      if (actions.addIds.length) {
        const response = await store.dispatch('users/addRolesUser', {
          id: props.user.id,
          roleIds: actions.addIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      context.emit('close-modal')
    }

    getRoles()

    return {
      t,
      roles,
      loader,
      handleSubmit,
    }
  },
})
</script>
