<template>
  <div>
    <div v-if="paginate.page && paginate.per_page" class="flex items-center justify-between flex-wrap gap-y-6 mt-3">
      <div class="flex items-center">
        <span class="mr-2">{{ t('vTable.pageSize') }}</span>

        <select v-model="paginate.per_page" @change="updatePageSize" class="w-20 form-select sm:mt-0">
          <option v-for="item in optionsSize" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="flex items-center">
        <div class="mr-1" :class="{ 'cursor-pointer': isExistPrevPage() }" @click="prev()">
          <ChevronsLeftIcon class="w-6 h-6" />
        </div>

        <div class="flex items-center text-base select-none flex-nowrap">
          <div
            v-if="isExistPrevPage()"
            @click="prev()"
            class="mx-1 px-2 sm:px-3 py-1 rounded-xl cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            {{ `${Number(paginate.page) - 1}` }}
          </div>

          <div class="mx-1 px-2 sm:px-3 py-1 rounded-xl bg-theme-3 text-white font-bold">
            {{ paginate.page }}
          </div>

          <div
            v-if="isExistNextPage()"
            @click="next()"
            class="mx-1 px-2 sm:px-3 py-1 rounded-xl cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            {{ `${Number(paginate.page) + 1}` }}
          </div>
        </div>

        <div class="ml-1" :class="{ 'cursor-pointer': isExistNextPage() }" @click="next()">
          <ChevronsRightIcon class="w-6 h-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'QueryPagination',
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    query: {
      type: Object,
      required: true,
    },
    countRows: {
      type: Number,
      required: true,
    },
    optionsSize: {
      type: Array,
      default: () => [250, 100, 50, 30, 10],
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const paginate = ref(cloneDeep(props.modelValue))

    watch(
      () => props.modelValue,
      () => {
        paginate.value = props.modelValue
      }
    )

    const isExistNextPage = () => props.countRows > paginate.value.per_page
    const isExistPrevPage = () => paginate.value.page > 1

    const next = () => {
      if (!isExistNextPage()) {
        return
      }

      paginate.value.page = String(Number(paginate.value.page) + 1)
      context.emit('updatePaginate', paginate.value)
    }

    const prev = () => {
      if (!isExistPrevPage()) {
        return
      }

      paginate.value.page = String(Number(paginate.value.page) - 1)
      context.emit('updatePaginate', paginate.value)
    }

    const updatePageSize = () => {
      paginate.value.page = '1'
      context.emit('updatePaginate', paginate.value)
    }

    return {
      t,
      paginate,
      updatePageSize,
      isExistNextPage,
      isExistPrevPage,
      next,
      prev,
    }
  },
})
</script>
