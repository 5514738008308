import { errorHandler } from '@/api/utils/ErrorHandler'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { api } from './index'

const state = {}

const actions = {
  async getCountries({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await api.get('/countries', config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async getCountriesById({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.get(`/countries/${id}`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async getCountriesToRegion({ dispatch }, region_id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.get(`/countries/${region_id}/countries`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async getRegionsToCountry({ dispatch }, country_id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.get(`/countries/${country_id}/regions`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addCountriesToRegion({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.post(`/countries/${params.region_id}/countries/${params.country_id}`, {}, config)
      return response
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteCountriesToRegion({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.delete(`/countries/${params.region_id}/countries/${params.country_id}`, config)
      return response
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
