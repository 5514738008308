<template>
  <div class="v-traffic-callback-form">
    <form @submit.prevent="handleSubmit">
      <div class="sm:flex">
        <div class="sm:w-1/2 sm:mr-6">
          <BaseInput label="trafficCallback.name" v-model="formData.name" :required="true" :errors="v$.name.$errors" />
        </div>

        <VFieldSelect
          class="sm:w-1/2"
          label="trafficCallback.author"
          v-model="formData.author_id"
          :options="options.users"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          v-model="formData.source_id"
          :options="options.sources"
          label="trafficCallback.trafficSource"
          class="sm:w-1/2 sm:mr-6"
        />

        <VFieldSelect
          v-model="formData.request_method"
          label="trafficCallback.request_method"
          :options="[{ name: 'GET' }, { name: 'POST' }]"
          :hideSearch="true"
          :required="true"
          :errors="v$.request_method.$errors"
          class="sm:w-1/2"
          returnValue="name"
        />
      </div>

      <BaseInput
        label="trafficCallback.postbackURL"
        v-model="formData.base_url"
        iconLeft="Link2Icon"
        :required="true"
        :errors="v$.base_url.$errors"
      />

      <div class="flex border-dotted border-b mt-2">
        <span class="-mb-0.5 bg-white font-medium text-gray-700">{{ t('trafficCallback.sourceParams') }}</span>
      </div>

      <VSourceParams v-model="formData.base_url" :sourceId="formData.source_id" class="mt-1" />

      <div class="flex border-dotted border-b mt-3">
        <span class="-mb-0.5 bg-white font-medium text-gray-700">{{ t('trafficCallback.systemParams') }}</span>
      </div>

      <TheUrlVariablesMap v-model="formData.base_url" class="mt-1" />

      <VariablesMap v-model="formData.get_params_map" :title="t('trafficCallback.getRequest')" />

      <div v-if="formData.request_method === 'POST'">
        <VariablesMap v-model="formData.post_params_map" :title="t('trafficCallback.postRequest')" />

        <hr class="mt-4" />
      </div>

      <GroupRadio
        radioGroupClass="flex flex-wrap gap-y-3 gap-x-5 sm:gap-x-4"
        v-model="formData.status_id"
        :options="options.statuses"
        title="trafficCallback.status"
      />

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('trafficCallback.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('trafficCallback.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, defineComponent, watch, inject } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, url } from '@vuelidate/validators'
import { REQUIRED_MESSAGE, URL_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useStore } from 'vuex'
import VariablesMap from '@/components/VariablesMap.vue'
import TheUrlVariablesMap from '@/components/TheUrlVariablesMap.vue'
import VSourceParams from '@/components/VSourceParams.vue'

const DEFAULT_EVENTS_IDS = [1, 2, 3, 4]

export default defineComponent({
  name: 'TrafficCallbackForm',
  components: {
    VariablesMap,
    VSourceParams,
    TheUrlVariablesMap,
  },
  props: {
    callback: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()
    const getMonitoring = inject('injectMonitoring')

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const formData = ref({
      id: props.callback.id || '',
      name: props.callback.name || '',
      author_id: props.callback.author_id || store.state.auth.user.id || '',
      request_method: props.callback.request_method || '',
      source_id: props.callback.source?.id || null,
      base_url: props.callback.base_url || '',
      get_params_map: props.callback.get_params_map || [],
      post_params_map: props.callback.post_params_map || [],
      status_id: props.callback.status?.id || 1,
    })

    const options = ref({
      users: [],
      sources: [],
      statuses: [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        request_method: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        base_url: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
          url: helpers.withMessage(t(URL_MESSAGE), url),
        },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const result = await v$.value.$validate()
      if (!result) {
        return
      }

      if (!formData.value.source_id) {
        delete formData.value.source_id
      }

      props.callback.id ? updateCallback() : createCallback()
      getMonitoring()
    }

    const updateCallback = async () => {
      const result = await store.dispatch('callbacks/updateCallback', formData.value)
      const errors = result.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      context.emit('refresh')
      context.emit('close')
    }

    const createCallback = async () => {
      const callback = await store.dispatch('callbacks/createCallback', formData.value)
      const errors = callback.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      addDefaultEvents(callback.data)

      context.emit('refresh')
      context.emit('close')
    }

    const addDefaultEvents = callback => {
      DEFAULT_EVENTS_IDS.forEach(async eventId => {
        await store.dispatch('callbacks/addCallbackEvent', {
          callback_id: callback.id,
          callback_event_id: eventId,
        })
      })
    }

    watch(
      () => formData.value.request_method,
      requestMethod => {
        switch (requestMethod) {
          case 'GET':
            formData.value.post_params_map = []
            break
        }
      }
    )

    const getUsersOptions = async () => {
      const users = await store.dispatch('users/getUsers', { full: false })
      options.value.users = users.data.data ? users.data.data : []
    }

    const getSourcesOptions = async () => {
      const sources = await store.dispatch('trafficSource/getTrafficSources')

      if (!props.callback?.author_id) {
        options.value.sources = sources.data ? sources.data : []
        return
      }

      sources.data.forEach(source => {
        if (props.callback?.author_id === source.author_id) {
          options.value.sources.push(source)
        }
      })
    }

    const getStatusesOptions = async () => {
      const statuses = await store.dispatch('statuses/getStatuses')
      options.value.statuses = statuses ? statuses : []
    }

    getUsersOptions()
    getStatusesOptions()
    getSourcesOptions()

    return {
      formData,
      handleSubmit,
      options,
      v$,
      t,
    }
  },
})
</script>
