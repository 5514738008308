<template>
  <div class="v-detail-offer-dialog">
    <div v-if="!create">
      <VTabs class="flex justify-end">
        <VTab name="basic_info" :label="t('offerWall.basicInformation')" icon="InfoIcon" v-model="selectedTab" />
        <VTab
          name="commercial_terms"
          :label="t('offerWall.commercialConditions')"
          icon="SlidersIcon"
          v-model="selectedTab"
        />
        <VTab name="landings" :label="t('offerWall.landings')" icon="TrelloIcon" v-model="selectedTab" />
      </VTabs>

      <div v-if="selectedTab === 'basic_info'" class="md:flex md:flex-row mt-4 min-h-[360px]">
        <div class="md:mr-8 mb-3 sm:image-fit sm:w-2/6 sm:h-[340px]">
          <img
            data-action="zoom"
            :src="offer.image || require(`@/assets/images/no_image.jpg`).default"
            @error="$event.target.src = require(`@/assets/images/no_image.jpg`).default"
          />
        </div>

        <div class="md:w-3/5">
          <div class="md:flex md:flex-row">
            <div class="mb-4">
              <span class="text-gray-700 font-semibold">{{ t('offerWall.country') }}:</span>
              <span class="pl-2">
                {{ `[${offer.country?.alpha_2_code}] ${translation.getCountry(offer.country)}` }}
              </span>
            </div>
          </div>

          <div class="mb-4">
            <span class="text-gray-700 font-semibold">{{ t('offerWall.category') }}:</span>
            <span class="pl-2">{{ offer.category?.en_name }}</span>
          </div>

          <div class="mb-4">
            <span class="text-gray-700 font-semibold">{{ t('offerWall.forbiddenSources') }}:</span>
            <span class="pl-2">
              <span v-for="(source, index) in offer.forbidden_sources?.data" :key="index" class="mr-1">
                {{ source.name }}{{ offer.forbidden_sources?.data.length - 1 === index ? '' : ',' }}
              </span>
            </span>
          </div>

          <div class="mb-4">
            <span class="text-gray-700 font-semibold">{{ t('offerWall.description') }}:</span>
            <div class="pt-1 v-detail-offer-dialog__desciption-html" v-html="offer.description"></div>
          </div>
        </div>
      </div>

      <div v-else-if="selectedTab === 'commercial_terms'" class="mt-4 min-h-[360px]">
        <VTable :columns="columns" :data="tableData" :hideExport="true" :hideButtons="['edit', 'delete']" />
      </div>

      <div v-else-if="selectedTab === 'landings'" class="mt-4 min-h-[360px] px-8">
        <div v-if="!offerLandings.length" class="flex justify-center py-6 text-gray-600">
          <AlertTriangleIcon class="w-5 h-5 mr-2" />
          {{ t('vTable.message') }}
        </div>
        <TinySlider
          v-else
          :options="{
            controls: true,
            nav: true,
            mouseDrag: true,
            loop: true,
            autoplayTimeout: '9000',
            items: 1,
            responsive: {
              850: {
                items: 3,
              },
              500: {
                items: 2,
              },
            },
          }"
        >
          <div v-for="landing in offerLandings" :key="landing.id" class="h-[340px] px-2">
            <div class="h-full border rounded-b-md">
              <div class="relative h-[280px]">
                <img
                  data-action="zoom"
                  class="w-full h-full object-cover"
                  :src="landing.image || require(`@/assets/images/no_image.jpg`).default"
                  @error="$event.target.src = require(`@/assets/images/no_image.jpg`).default"
                />
                <div class="absolute text-theme-2 top-1 left-0 z-50 py-1 px-2 bg-theme-6 bg-opacity-80 font-bold">
                  ID:{{ landing.id }}
                </div>
              </div>

              <router-link
                type="button"
                :to="{
                  name: 'landing-wall',
                  params: { id: landing.id },
                }"
                target="_blank"
                class="p-2 flex items-center justify-center cursor-pointer text-blue-700"
              >
                <span class="font-bold pt-2">{{ landing.name }}</span>
                <InfoIcon class="w-4 h-4 ml-1.5 mt-1.5" />
              </router-link>
            </div>
          </div>
        </TinySlider>
      </div>

      <div class="modal-footer">
        <button @click="$emit('close-modal')" type="button" class="btn btn-outline-secondary">
          {{ t('offerWall.close') }}
        </button>

        <button
          v-if="!loadingAccess && !isModeratingStatus()"
          @click="onClickButton()"
          type="submit"
          class="btn btn-primary px-6 ml-2"
        >
          <UnlockIcon class="w-4 h-4 mr-2" />
          {{ getButtonTitle() }}
        </button>
      </div>
    </div>

    <div v-else>
      <AddCampaignForm
        :offer="offer"
        @close-modal="$emit('close-modal')"
        @openLandingDialog="campaignId => $emit('openLandingDialog', campaignId)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useTranslation } from '@/hooks/useTranslation'
import { convertAmountToDisplay } from '@/utils/amountConverter'
import { parseNumber } from '@/utils/number.ts'
import AddCampaignForm from './AddCampaignForm.vue'

export default defineComponent({
  components: { AddCampaignForm },
  name: 'DetailOfferDialog',
  props: {
    offer: {
      type: Object,
      required: true,
    },
    addCampaign: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const translation = useTranslation()

    const tableData = ref([])
    const offerLandings = ref([])
    const create = ref(props.addCampaign)
    const selectedTab = ref('basic_info')
    const loadingAccess = ref(true)

    const offerAccessStatus = ref(false)
    const isOnDemandOffer = props.offer.privacy_type.name === 'OnDemand'

    const isModeratingStatus = () => {
      return offerAccessStatus.value === 1 && isOnDemandOffer ? true : false
    }

    const isApprovedStatus = () => {
      return offerAccessStatus.value === 2 && isOnDemandOffer ? true : false
    }

    const isUnApprovedStatus = () => {
      return offerAccessStatus.value === 3 && isOnDemandOffer ? true : false
    }

    const getButtonClass = () => {
      switch (true) {
        case isModeratingStatus():
          return 'btn-warning'
        case isApprovedStatus():
          return 'btn-success'
        case isUnApprovedStatus():
          return 'btn-danger'
        case isOnDemandOffer:
          return 'btn-twitter'
        default:
          return 'btn-success'
      }
    }

    const onClickButton = () => {
      switch (true) {
        case isApprovedStatus():
          create.value = true
          break
        case isOnDemandOffer:
        case isUnApprovedStatus():
          context.emit('requestAccess', props.offer.id)
          context.emit('close-modal')
          break
        default:
          create.value = true
      }
    }

    const getButtonTitle = () => {
      switch (true) {
        case isApprovedStatus():
          return t('offerWall.createCompany')
        case isUnApprovedStatus():
        case isOnDemandOffer:
          return t('offerWall.requestAccess')
        default:
          return t('offerWall.createCompany')
      }
    }

    const getAccessStatus = async () => {
      loadingAccess.value = true
      const accessUserOffers = await store.dispatch('access/getAccessUserOffers')

      const key = `offer-${props.offer.id}`
      if (key in accessUserOffers) {
        offerAccessStatus.value = accessUserOffers[key]?.status_id || null
      }

      loadingAccess.value = false
    }

    const generateTableColumn = () => {
      switch (props.offer.payment_model.name) {
        case 'CPA':
          return {
            operator: t('offerWall.operator'),
            price: t('offerWall.payout_cpa'),
          }
        case 'RevShare':
          return {
            operator: t('offerWall.operator'),
            trial: t('offerWall.trial'),
            trial_price: t('offerWall.trialPrice'),
            schedule: t('offerWall.schedule'),
            price_revshare: t('offerWall.payout_revshare'),
            price_cpa: t('offerWall.payout_cpa'),
          }
      }
    }

    const columns = generateTableColumn()

    const generateTableData = offersOperators => {
      tableData.value = []

      offersOperators.forEach(async offerOperator => {
        const servicesOperators = await store.dispatch(
          'services/getCabinetOperatorsByService',
          offerOperator.service.id
        )

        if (servicesOperators?.data) {
          const servicesOperator = servicesOperators.data.operators.find(
            operator => offerOperator.operator.id == operator.operator.id
          )

          if (servicesOperator) {
            switch (props.offer.payment_model.name) {
              case 'CPA': {
                const currencyCpa = await store.dispatch(
                  'currency/getCurrencyById',
                  offerOperator.cpa_partner_rate.currency
                )

                const cpaPartnerRate = convertAmountToDisplay(offerOperator.cpa_partner_rate?.sum) || ''
                const currency = currencyCpa?.data?.iso_name || ''

                tableData.value.push({
                  operator: offerOperator.operator?.name,
                  price: `${cpaPartnerRate} ${currency}`,
                })

                break
              }
              case 'RevShare': {
                const currencyRs = await store.dispatch('currency/getCurrencyById', servicesOperator.rsh_rate?.currency)

                const trialPrice = convertAmountToDisplay(servicesOperator.rsh_trial_price?.sum) || ''
                const currencyName = currencyRs.data?.iso_name || ''

                // Prise RevShare
                const rshRate = convertAmountToDisplay(servicesOperator.rsh_rate.sum)
                const parseRshRate = parseNumber(rshRate)
                const percentRs = parseNumber(offerOperator.rsh_partner_rate)
                const rateRs = parseRshRate && percentRs ? (parseRshRate / 100) * percentRs : ''
                const truncRateRs = Math.trunc(rateRs * 10000) / 10000 || ''

                // Prise CPA
                const priseCpa = convertAmountToDisplay(offerOperator.cpa_partner_rate?.sum) ?? ''
                const currencyCpa =
                  offerOperator.cpa_partner_rate?.currency > 0
                    ? await store.dispatch('currency/getCurrencyById', offerOperator.cpa_partner_rate?.currency)
                    : {}

                tableData.value.push({
                  operator: offerOperator.operator?.name,
                  trial: servicesOperator.rsh_trial_period,
                  trial_price: `${trialPrice} ${currencyName}`,
                  schedule: servicesOperator.rsh_payment_schedule,
                  price_revshare: `${truncRateRs} ${currencyName}`,
                  price_cpa: `${priseCpa} ${currencyCpa.data?.iso_name ?? ''}`,
                })

                break
              }
            }
          }
        }
      })
    }

    const getOfferLandings = async () => {
      const landings = await store.dispatch('offerWall/getLandingsToOffer', props.offer.id)
      offerLandings.value = landings.data.data ? landings.data.data : []
    }

    const getOperatorsByOffer = async () => {
      const response = await store.dispatch('offerWall/getOperatorsByOffer', props.offer.id)

      if (response.data) {
        generateTableData(response.data.operators)
      }
    }

    getOperatorsByOffer()
    getOfferLandings()
    getAccessStatus()

    return {
      isOnDemandOffer,
      isModeratingStatus,
      isApprovedStatus,
      isUnApprovedStatus,
      onClickButton,
      getButtonClass,
      getButtonTitle,
      offerLandings,
      loadingAccess,
      columns,
      tableData,
      create,
      selectedTab,
      t,
      translation,
    }
  },
})
</script>

<style lang="scss">
.v-detail-offer-dialog {
  &__desciption-html {
    ul {
      padding-left: 1rem;
      line-height: 1.3;
    }
    ul li::before {
      content: '\2022';
      font-weight: 900;
      font-size: 17px;
      padding-right: 0.4rem;
    }

    ol {
      padding-left: 1rem;
      counter-reset: num;
      line-height: 1.6;
    }
    ol li::before {
      content: counter(num) '. ';
      counter-increment: num;
    }
  }
}
</style>
