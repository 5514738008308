<template>
  <div class="v-edit-offers-form">
    <form @submit.prevent="handleSubmit">
      <BaseInput label="offer.name" name="name" v-model="formData.name" :errors="v$.name.$errors" :required="true" />

      <VQuillEditor v-model="formData.description" :label="t('offer.description')" />

      <GroupCheckbox
        v-model="formData.forbidden_sources"
        :options="options.forbidden"
        title="offer.forbidden"
        :horizontal="true"
        customClass="w-36"
      />

      <div class="sm:flex sm:flex-row">
        <div class="sm:w-3/5">
          <VFieldSelect
            label="offer.privacy"
            :options="options.privacy"
            v-model="formData.privacy_type_id"
            :errors="v$.privacy_type_id.$errors"
            :hideSearch="true"
            :required="true"
          />

          <VFieldSelect
            label="offer.country"
            v-model="formData.country_id"
            :options="options.country"
            :errors="v$.country_id.$errors"
            :optionName="translation.getCountryOptionName()"
            :required="true"
          />

          <VFieldSelect
            label="offer.paymentModel"
            :hideSearch="true"
            :disabled="!!formData.id"
            v-model="formData.payment_model_id"
            :errors="v$.payment_model_id.$errors"
            :options="options.paymentModel"
            :required="true"
          />

          <VFieldSelect
            label="offer.flowType"
            v-model="formData.flow_type_id"
            :errors="v$.flow_type_id.$errors"
            :options="options.flowType"
            :hideSearch="true"
            :required="true"
          />

          <VFieldSelect
            v-model="formData.enrollment_rule_id"
            :options="options.enrollmentsRule"
            label="offer.enrollmentsRule"
            :required="true"
            :errors="v$.enrollment_rule_id.$errors"
          />

          <VFieldSelect
            label="offer.category"
            v-model="formData.category_id"
            :options="options.categorys"
            optionName="en_name"
            :required="true"
            :errors="v$.category_id.$errors"
          />

          <div class="flex items-end">
            <div class="w-4/5">
              <BaseInput
                v-model="formData.image"
                label="offer.image"
                name="image"
                class="mr-1"
                iconLeft="ImageIcon"
                :required="true"
                :errors="v$.image.$errors"
              />
            </div>

            <VFileUploader v-model="formData.image" />
          </div>
        </div>

        <div class="sm:w-2/4 sm:image-fit mt-10 sm:ml-8">
          <img
            :src="formData.image || require(`@/assets/images/no_image.jpg`).default"
            @error="imgErrorHandler(), ($event.target.src = require(`@/assets/images/no_image.jpg`).default)"
            data-action="zoom"
            class="w-full rounded-md"
          />
        </div>
      </div>

      <VFieldTextarea label="offer.terms1" name="terms1" v-model="formData.landing_terms_1" />

      <VFieldTextarea label="offer.terms2" name="terms2" v-model="formData.landing_terms_2" />

      <EditOffersFormOfferTypes v-model="formData.offer_types" />

      <BaseCheckbox class="mt-4" label="offer.isCustom" v-model="formData.is_custom" />

      <GroupRadio
        radioGroupClass="flex flex-wrap gap-y-3 gap-x-6 sm:gap-x-5"
        v-model="formData.is_active"
        :options="options.status"
        title="offer.isActive"
      />

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
          {{ t('offer.cancel') }}
        </button>
        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('offer.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useTranslation } from '@/hooks/useTranslation'
import useVuelidate from '@vuelidate/core'
import EditOffersFormOfferTypes from './EditOffersFormOfferTypes.vue'

export default defineComponent({
  name: 'EditOffersForm',
  components: {
    EditOffersFormOfferTypes,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const toast = useToast()
    const translation = useTranslation()

    const formData = ref({
      id: props.offer.id || '',
      name: props.offer.name || '',
      description: props.offer.description || '',
      privacy_type_id: props.offer.privacy_type?.id || '',
      is_active: props.offer.id ? props.offer.is_active : 1,
      forbidden_sources: props.offer.forbidden_sources?.list || [],
      country_id: props.offer.country?.id || '',
      payment_model_id: props.offer.payment_model?.id || '',
      flow_type_id: props.offer.flow_type?.id || '',
      enrollment_rule_id: props.offer.enrollment_rule?.id || '',
      category_id: props.offer.category?.id || '',
      landing_terms_1: props.offer.landing_terms_1 || '',
      landing_terms_2: props.offer.landing_terms_2 || '',
      offer_types: props.offer.offer_types || {},
      is_custom: props.offer.is_custom || 0,
      image: props.offer.image || '',
    })

    const options = ref({
      privacy: [],
      forbidden: [],
      country: [],
      paymentModel: [],
      flowType: [],
      enrollmentsRule: [],
      categorys: [],
      status: [
        { id: 1, name: t('consts.statusOptions.active') },
        { id: 0, name: t('consts.statusOptions.notActive') },
      ],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        privacy_type_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        country_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        payment_model_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        category_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        flow_type_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        enrollment_rule_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        image: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      props.offer.id ? updateOffer() : createOffer()
    }

    const updateOffer = () => {
      store.dispatch('offers/updateOffer', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close-modal')
      })
    }

    const createOffer = () => {
      store.dispatch('offers/addOffer', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close-modal')
      })
    }

    const imgErrorHandler = () => {
      toast.error(t('offer.imgErrorHandlerMessage'), {
        closeOnClick: false,
      })
    }

    const getPrivacyOptions = async () => {
      const response = await store.dispatch('offerPrivacyTypes/getOfferPrivacyTypes')
      options.value.privacy = response?.data ? response.data : []
    }
    const getForbiddenOptions = async () => {
      const response = await store.dispatch('forbiddenSources/getForbiddenSources')
      options.value.forbidden = response?.data ? response.data : []
    }
    const getCountriesOptions = async () => {
      const response = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = response?.data ? response.data : []
    }
    const getPaymentModelOptions = async () => {
      const response = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModel = response?.data ? response.data : []
    }
    const getFlowTypeOptions = async () => {
      const response = await store.dispatch('flowTypes/getFlowTypes')
      options.value.flowType = response?.data ? response.data : []
    }
    const getEnrollmentsRuleOptions = async () => {
      const enrollmentsRule = await store.dispatch('enrollmentRules/getEnrollmentRules', { full: false })
      options.value.enrollmentsRule = enrollmentsRule.data ? enrollmentsRule.data : []
    }
    const getCategoriesOptions = async () => {
      const categories = await store.dispatch('offers/getCategories')
      options.value.categorys = categories ? categories : []
    }

    getPrivacyOptions()
    getForbiddenOptions()
    getCountriesOptions()
    getPaymentModelOptions()
    getFlowTypeOptions()
    getEnrollmentsRuleOptions()
    getCategoriesOptions()

    return {
      v$,
      formData,
      options,
      imgErrorHandler,
      handleSubmit,
      translation,
      t,
    }
  },
})
</script>
