<template>
  <div>
    <label class="block mt-3.5 mb-0.5">{{ t('offer.offerType') }}</label>
    <ul class="flex flex-col w-full gap-2">
      <li class="cursor-pointer w-full" @click="updateOfferTypes('Top')">
        <div
          class="flex flex-row items-center px-4 py-2 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
          :class="{
            'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': offerTypes.top,
          }"
        >
          <BaseCheckbox class="mr-0.5" v-model="offerTypes.top" />

          <span class="font-medium">Top</span>
          <VFieldMultiSelect
            v-model="topOfferUserIds"
            class="pl-4 min-w-[10rem] sm:min-w-[18rem] ml-auto"
            :disabled="!offerTypes.top"
            :key="offerTypes.top"
            :options="options.users"
          />
        </div>
      </li>

      <li class="cursor-pointer w-full" @click="updateOfferTypes('Inhouse')">
        <div
          class="flex flex-row items-center h-[58px] px-4 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
          :class="{
            'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': offerTypes.inhouse,
          }"
        >
          <BaseCheckbox class="mr-0.5" v-model="offerTypes.inhouse" />

          <span class="font-medium">Inhouse</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'EditOffersFormOfferTypes',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()

    const offerTypes = ref({
      top: false,
      inhouse: false,
    })

    const topOfferUserIds = ref([])

    const options = ref({
      users: [],
    })

    watch(
      () => topOfferUserIds.value,
      () => setOfferType()
    )

    const updateOfferTypes = type => {
      if (type === 'Top') {
        offerTypes.value.top = !offerTypes.value.top
      }

      if (type === 'Inhouse') {
        offerTypes.value.inhouse = !offerTypes.value.inhouse
      }

      setOfferType()
    }

    const setOfferType = () => {
      const data = {}

      if (offerTypes.value.inhouse) {
        data[2] = {
          user_id: [0],
        }
      }

      if (offerTypes.value.top) {
        const userIds = []
        let spliceIndexUser = null

        if (!topOfferUserIds.value.length) {
          topOfferUserIds.value = [0]
        }

        topOfferUserIds.value.forEach((id, index) => {
          if (id === '0') {
            spliceIndexUser = index
            return
          }
          userIds.push(Number(id))
        })

        if (topOfferUserIds.value.length > 1 && spliceIndexUser !== null) {
          topOfferUserIds.value.splice(spliceIndexUser, 1)
        }

        data[3] = {
          user_id: userIds,
        }
      }

      context.emit('update:modelValue', data)
    }

    const getOfferType = () => {
      props.modelValue?.data?.forEach(type => {
        if (type.offer_type_id === 3) {
          offerTypes.value.top = true

          topOfferUserIds.value = JSON.parse(type.user_id)
        }

        if (type.offer_type_id === 2) {
          offerTypes.value.inhouse = true
        }
      })

      setOfferType()
    }

    const getUsersOptions = async () => {
      const users = await store.dispatch('users/getUsers', { full: false })
      options.value.users = users.data.data ? users.data.data : []

      options.value.users.unshift({ id: 0, name: t('offer.allUsers') })
    }

    getOfferType()
    getUsersOptions()

    return {
      options,
      offerTypes,
      updateOfferTypes,
      topOfferUserIds,
      t,
    }
  },
})
</script>
