export const enUsers = {
  addUserButton: 'Add user',
  addUser: 'Add user',
  editUser: 'Edit user',
  roleSettings: 'Role settings',

  id: 'ID',
  name: 'Name',
  email: 'Email',
  status: 'Status',
  password: 'Password',
  repeat_password: 'Password',

  personalInfo: 'Personal information',

  confirmDialog: {
    title: 'Delete user',
    message: 'Are you sure you want to delete user {propName}?',
  },

  confirmRestoreDialog: {
    title: 'Restore a user',
    message: 'Do you really want to restore the user?',
  },

  roles: 'Roles',

  save: 'Save',
  cancel: 'Cancel',
}

export const ruUsers = {
  addUserButton: 'Добавить пользователя',
  addUser: 'Добавление пользователя',
  editUser: 'Редактирование пользователя',
  roleSettings: 'Настройка ролей',

  id: 'ID',
  name: 'Имя',
  email: 'Email',
  status: 'Статус',
  password: 'Пароль',
  repeat_password: 'Подтвердите пароль',

  personalInfo: 'Персональная информация',

  confirmDialog: {
    title: 'Удаление пользователя',
    message: 'Вы действительно хотите удалить пользователя {propName}?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление пользователя',
    message: 'Вы действительно хотите восстановить пользователя?',
  },

  roles: 'Роли',

  save: 'Сохранить',
  cancel: 'Отмена',
}
