<template>
  <div class="v-edit-campaign-form">
    <form @submit.prevent="handleSubmit">
      <BaseInput
        label="myCampaign.name"
        name="name"
        :required="true"
        v-model="campaignForm.name"
        :errors="v$.name.$errors"
      />

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-8"
          label="myCampaign.offer"
          :required="true"
          :disabled="!!campaign.id"
          :options="options.offers"
          :showId="true"
          v-model="campaignForm.offer_id"
          :errors="v$.offer_id.$errors"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="myCampaign.trafficSource"
          :required="true"
          :options="options.sources"
          v-model="campaignForm.source_id"
          :errors="v$.source_id.$errors"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-8"
          label="myCampaign.callback"
          :options="options.callbacks"
          optionName="base_url"
          clearable
          v-model="campaignForm.callback_id"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="myCampaign.domain"
          :required="true"
          :options="options.domains"
          v-model="campaignForm.domain_id"
          :errors="v$.domain_id.$errors"
        />
      </div>

      <div class="sm:flex">
        <div class="sm:w-2/4 flex items-end sm:mr-8" v-if="campaign.id">
          <div class="w-[100%]">
            <BaseInput label="campaign.campaignUrl" v-model="campaignUrl" :disabled="true" />
          </div>
          <Tippy :content="t('campaign.copyUrlCampaign')">
            <button type="button" class="btn btn-linkedin ml-1 h-10" @click="copyCampaignUrl">
              <CopyIcon class="w-5 h-5" />
            </button>
          </Tippy>
        </div>

        <VFieldSelect
          class="sm:w-2/4"
          label="myCampaign.paymentModel"
          :disabled="!!campaignForm.id"
          :hideSearch="true"
          :required="true"
          v-model="campaignForm.payment_model_id"
          :options="options.paymentsModel"
          :errors="v$.payment_model_id.$errors"
        />
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('myCampaign.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('myCampaign.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'EditMyCampaignForm',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()
    const toast = useToast()

    const campaignForm = ref({
      id: props.campaign.id || '',
      name: props.campaign.name || '',
      offer_id: props.campaign.offer?.id || '',
      source_id: props.campaign.source?.id || '',
      callback_id: props.campaign.callback?.id || null,
      trafficback_id: 1,
      domain_id: props.campaign.domain?.id || '3',
      payment_model_id: props.campaign.payment_model?.id || '',
      enrollment_rule_id: props.campaign.enrollment_rule?.id || '',
    })

    const campaignUrl = props.campaign.url

    const options = ref({
      offers: [],
      sources: [],
      callbacks: [],
      domains: [],
      paymentsModel: [],
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        offer_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        source_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        domain_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        payment_model_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, campaignForm)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      const data = dataPreparation()

      props.campaign.id ? editCampaign(data) : addCampaign(data)
    }

    const dataPreparation = () => {
      let data = cloneDeep(campaignForm.value)

      if (!data.callback_id) {
        data.callback_id = null
      }

      if (!data.enrollment_rule_id) {
        delete data.enrollment_rule_id
      }

      return data
    }

    const addCampaign = data => {
      store.dispatch('myCampaigns/addCampaign', data).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
        context.emit('openLandingDialog', result.data.data.id)
      })
    }

    const editCampaign = data => {
      delete data.offer_id

      store.dispatch('myCampaigns/updateCampaign', data).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const copyCampaignUrl = async () => {
      try {
        await navigator.clipboard.writeText(campaignUrl)

        toast.success(t('myCampaign.campaignUrlMessage'), {
          closeOnClick: false,
        })
      } catch (error) {
        console.error(error)
        toast.error(t('myCampaign.copyUrlErrorMessage'), {
          closeOnClick: false,
        })
      }
    }

    watch(
      () => campaignForm.value.offer_id,
      offerId => setEnrollmentRule(offerId)
    )

    const setEnrollmentRule = async offerId => {
      if (!offerId) {
        return
      }

      const offer = await store.dispatch('offerWall/getOfferById', campaignForm.value.offer_id)

      if (offer?.data) {
        campaignForm.value.enrollment_rule_id = offer.data.data.enrollment_rule?.id
      }
    }

    const getOffersOptions = async () => {
      const offers = await store.dispatch('offerWall/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }

    const getSourcesOptions = async () => {
      const sources = await store.dispatch('myTrafficSource/getTrafficSources', { full: false })
      options.value.sources = sources.data ? sources.data : []
    }

    const getCallbacksOptions = async () => {
      const callbacks = await store.dispatch('myTrafficCallbacks/getCallbacks', { full: false })
      options.value.callbacks = callbacks.data ? callbacks.data : []
    }

    const getDomainsOptions = async () => {
      const domains = await store.dispatch('domains/getDomains', { full: false })
      options.value.domains = domains.data ? domains.data : []
    }

    const getPaymentsModelOptions = async () => {
      const paymentsModel = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentsModel = paymentsModel.data ? paymentsModel.data : []
    }

    getOffersOptions()
    getSourcesOptions()
    getCallbacksOptions()
    getDomainsOptions()
    getPaymentsModelOptions()

    return {
      v$,
      campaignForm,
      options,
      campaignUrl,
      handleSubmit,
      copyCampaignUrl,
      t,
    }
  },
})
</script>
