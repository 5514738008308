import * as types from './mutation-types'
import { authApi } from './index'

const state = () => {
  return {
    darkMode: false,
    accessToken: '',
    user: null,
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },

  async sendRegisterParams(_, params) {
    const response = await authApi.post(`/register`, params)
    return response.data
  },

  async sendLoginParams(_, params) {
    try {
      const response = await authApi.post(`/login`, params)
      return response.data
    } catch (error) {
      return error
    }
  },

  async resetPassword(_, params) {
    const response = await authApi.post(`/reset_password`, params)
    return response.data
  },

  async setPassword(_, params) {
    const response = await authApi.post(`/set_password`, params)
    return response.data
  },

  async sendLogout({ dispatch, commit }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await authApi.post(`/logout`, {}, config)
      localStorage.removeItem('sigma-token')
      commit(types.SET_USER, null)
      commit(types.SET_AUTH, '')
      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  async getUser(_, token) {
    if (!token) return null
    try {
      const response = await authApi.get(`/user_id/${token}`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  async verifyToken(_, token) {
    try {
      const response = await authApi.get(`/verify_email/${token}`)
      return response.data
    } catch (error) {
      return false
    }
  },

  async isAuthenticated({ dispatch, commit, state }) {
    const authToken = localStorage.getItem('sigma-token')
    if (!authToken) return false
    const currentUserID = await dispatch('getUser', authToken)
    if (currentUserID) {
      commit(types.SET_AUTH, authToken)
      if (!state.user) {
        try {
          const currentUser = await dispatch('users/getUserById', currentUserID.data.id, { root: true })
          if (currentUser) {
            commit(types.SET_USER, currentUser.data.data)
          }
        } catch (error) {
          console.log(error)
        }
      }
      return true
    } else {
      commit(types.SET_USER, null)
      commit(types.SET_AUTH, '')
      return false
    }
  },
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  [types.SET_AUTH](state, auth) {
    state.accessToken = auth
  },
  [types.SET_USER](state, user) {
    state.user = user
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
