import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getAccess({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/user_entity_access', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getAccessById(_, id) {
    try {
      const response = await http.get(`/user_entity_access/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
    }
  },

  // OFFERS
  async getAccessUserOffers() {
    try {
      const response = await http.get(`/cabinet/user_entity_access/offers`)
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  },

  // LANDINGS
  async getAccessUserLandings() {
    try {
      const response = await http.get(`/cabinet/user_entity_access/landings`)
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  },

  /**
   * Access management
   *
   * @param {number} user_id user id.
   * @param {number} entity_type_id entity type (offer: 1, landing:2).
   * @param {number} entity_id entity id (offer or landing id).
   * @param {number?} status_id status id (moderating: 1, approved: 2, not approved: 3).
   */
  async addAccess(_, params) {
    try {
      const response = await http.post('/user_entity_access', params)
      return response
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  /**
   * Cabinet
   *
   * @param {number} user_id user id.
   * @param {number} entity_type_id entity type (offer: 1, landing:2).
   * @param {number} entity_id entity id (offer or landing id).
   * @param {number?} status_id status id (moderating: 1, approved: 2, not approved: 3).
   */
  async addCabinetAccess(_, params) {
    try {
      const response = await http.post('/cabinet/user_entity_access', params)
      return response
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateAccess(_, params) {
    try {
      const response = await http.put(`/user_entity_access/${params.id}`, {
        status_id: params.status_id,
      })
      return response
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteAccess(_, id) {
    try {
      return await http.delete(`/user_entity_access/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
