<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu :menu="formattedMenu" :monitoring="monitoring" />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'side-menu-dashboard-overview-1' }"
          tag="a"
          class="intro-x flex items-center mt-2 select-none"
        >
          <img class="side-nav__logo" alt="Sigma" src="@/assets/images/svg/logo.svg" />
          <img class="side-nav__logo-samll ml-4" alt="Sigma" src="@/assets/images/svg/logo_small.svg" />
        </router-link>
        <!-- END: Logo -->
        <div class="border-b border-opacity-20 my-4"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li v-if="menu == 'devider'" :key="menu + menuKey" class="side-nav__devider my-6"></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="t(menu.title)"
                :href="menu.subMenu ? 'javascript:;' : router.resolve({ name: menu.pageName }).path"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router, $event)"
                @contextmenu="toggleContextMenu(menu, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ t(menu.title) }}

                  <div
                    v-if="!menu.activeDropdown && monitoring.isModeraitonPage(menu.pageName)"
                    class="ml-2.5 bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded animate-pulse"
                  >
                    {{ monitoring.count.value.all }}
                  </div>

                  <div
                    v-if="menu.subMenu.length"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                    <SideMenuTooltip
                      tag="a"
                      :content="t(subMenu.title) || subMenu.title"
                      class="side-menu cursor-pointer"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                      @click.middle="linkTo(subMenu, router, $event, true)"
                      @contextmenu="toggleContextMenu(subMenu, $event)"
                    >
                      <div class="side-menu__icon">
                        <component v-if="subMenu.icon" :is="subMenu.icon" />
                        <ActivityIcon v-else />
                      </div>
                      <div class="side-menu__title">
                        {{ t(subMenu.title) || subMenu.title }}

                        <div
                          v-if="!!monitoring.getPageCount(subMenu.pageName)"
                          class="ml-auto bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded"
                        >
                          {{ monitoring.getPageCount(subMenu.pageName) }}
                        </div>

                        <div
                          v-if="subMenu.subMenu && subMenu.subMenu.length"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown,
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                          <SideMenuTooltip
                            tag="a"
                            :content="t(lastSubMenu.title) || lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu ? 'javascript:;' : router.resolve({ name: lastSubMenu.pageName }).path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                            @contextmenu="toggleContextMenu(lastSubMenu, $event)"
                          >
                            <div class="side-menu__icon">
                              <component v-if="lastSubMenu.icon" :is="lastSubMenu.icon" class="w-5 h-5" />
                              <ZapIcon v-else />
                            </div>
                            <div class="side-menu__title">
                              {{ t(lastSubMenu.title) || lastSubMenu.title }}

                              <div
                                v-if="!!monitoring.getPageCount(lastSubMenu.pageName)"
                                class="ml-auto bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded"
                              >
                                {{ monitoring.getPageCount(lastSubMenu.pageName) }}
                              </div>
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- BEGIN: Context Menu -->
      <VContextSideMenu :contextMenu="contextMenu" />
      <!-- END: Context Menu -->
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { useI18n } from 'vue-i18n'
import { useGenerateSideMenu } from '@/hooks/useGenerateSideMenu'
import { useMonitoring } from '@/hooks/useMonitoring'
import { useNotification } from '@/hooks/useNotification'
import VContextSideMenu from '@/components/VContextSideMenu.vue'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip,
    VContextSideMenu,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const contextMenu = ref({
      class: 'v-context-menu',
      pageName: '',
    })

    const monitoring = useMonitoring()

    useNotification(store).run()

    const getSideMenu = async () => {
      const menu = await store.dispatch('sideMenu/getMenu')

      if (menu) {
        formattedMenu.value = await useGenerateSideMenu(menu, route, store)

        if (monitoring.accessToModeration(formattedMenu.value)) {
          monitoring.getMontoring()
          monitoring.runInterval()
        }
      }
    }

    const toggleContextMenu = (menu, e) => {
      if (menu.subMenu?.length || false) {
        return
      }

      const cm = document.querySelector(`.${contextMenu.value.class}`)

      if (contextMenu.value.pageName !== menu.pageName) {
        contextMenu.value.pageName = menu.pageName
      }

      const indent = 13
      let x = e.pageX + indent
      let y = e.pageY + indent

      const winHeight = window.innerHeight
      const cmHeight = cm.offsetHeight
      const scrollHeight = winHeight + window.scrollY

      y = y > scrollHeight - cmHeight ? scrollHeight - cmHeight - 20 : y

      cm.style.left = `${x}px`
      cm.style.top = `${y}px`
      cm.style.visibility = 'visible'

      e.preventDefault()
    }

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = nestedMenu(formattedMenu.value, route)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
    })

    getSideMenu()

    return {
      formattedMenu,
      monitoring,
      router,
      linkTo,
      enter,
      leave,
      contextMenu,
      toggleContextMenu,
      t,
    }
  },
})
</script>

<style>
html {
  background: #2099d6 !important;
}
</style>
