<template>
  <div
    class="v-field-multi-select"
    :class="{
      'v-field-multi-select__hide_search': hideSearch,
      'v-field-multi-select__error': errors.length,
      'v-field-multi-select__disabled': disabledStyle,
    }"
  >
    <label class="block mt-3.5 mb-0.5" v-if="label">
      {{ t(label) }}
      <span v-if="required" class="text-theme-6">*</span>
    </label>

    <TomSelect
      v-model="selectedId"
      :options="{ placeholder: 'Поиск', maxOptions: null, create: false }"
      :disabled="disabled"
      class="w-full"
      @change="changeValue"
      multiple
    >
      <option value="" class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default" />
      <option
        v-for="option of options"
        :value="option[returnValue]"
        :key="option[returnValue]"
        :disabled="option.disabled || disabledStyle"
        class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default"
      >
        {{ option[optionName] === 'query.all' ? t(option[optionName]) : option[optionName] }}
      </option>
    </TomSelect>

    <div v-for="error of errors" :key="error.$uid" class="text-xs text-theme-6 ml-1 v-field-multi-select__intro-error">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VFieldMultiSelect',
  props: {
    options: {
      type: [Array, Object],
      required: true,
    },
    optionName: {
      type: String,
      default: 'name',
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    // does not change reactively disabled
    disabledStyle: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    returnValue: {
      type: String,
      default: 'id',
    },
  },

  setup(props, context) {
    const selectedId = ref(props.modelValue)

    watch(
      () => props.modelValue,
      value => {
        selectedId.value = value
      }
    )

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const changeValue = () => {
      context.emit('update:modelValue', selectedId.value)
    }

    return {
      selectedId,
      changeValue,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-field-multi-select {
  .tom-select.multi .ts-input {
    padding-right: 2.5rem !important;
  }

  &__error {
    .tom-select .ts-input {
      border-color: #d32a29;
      white-space: nowrap;
    }
  }

  &__hide_search {
    .dropdown-input-wrap {
      display: none;
    }
  }

  &__disabled {
    .tom-select .ts-input {
      background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
      cursor: not-allowed;
    }
  }

  &__intro-error {
    z-index: 48;
    position: relative;
    opacity: 0;
    transform: translateY(-9px);
    -webkit-animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}
</style>
