import { api } from './index'

const state = {}

const actions = {
  async getOfferPrivacyTypes({ dispatch }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/offer_privacy_types`, config)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  async getOfferPrivacyTypesById({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/offer_privacy_types/${id}`, config)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
