import { isDev, isLocal } from '@/utils/stand'

const HIDDEN_COLUMNS = [
  // Статистика -> General
  {
    query_id: isLocal() || isDev() ? 20 : 50,
    columns: ['Траффикбек'],
    roles: ['Administrator', 'Manager', 'TB access for partners'],
  },
]

export const generateColumns = (queryId = null, queryColumns, userRoles) => {
  const columns = {}

  queryColumns.forEach(column => {
    if (isHiddenColumn(queryId, column.name, userRoles)) {
      return
    }

    columns[column.name] = {
      label: column.friendly_name,
      sortable: true,
      sort_name: column.name,
    }
  })

  return columns
}

export const onSortingColumn = (rows, sort) => {
  return rows.sort((a, b) => {
    if (sort.order === 'ASC') {
      return (a[sort.order_by] > b[sort.order_by]) - (a[sort.order_by] < b[sort.order_by])
    }

    if (sort.order === 'DESC') {
      return (a[sort.order_by] < b[sort.order_by]) - (a[sort.order_by] > b[sort.order_by])
    }
  })
}

const isHiddenColumn = (queryId, columnName, userRoles = []) => {
  let hideColumn = false

  HIDDEN_COLUMNS.forEach(setting => {
    if (queryId === setting.query_id) {
      const isValidColumn = setting.columns.includes(columnName)

      if (isValidColumn) {
        const rolesAccess = userRoles.value.filter(userRole => setting.roles.includes(userRole))

        if (!rolesAccess.length) {
          hideColumn = true
        }
      }
    }
  })

  return hideColumn
}
