<template>
  <form @submit.prevent="handleSubmit">
    <div class="sm:flex">
      <div class="sm:w-3/5 sm:mr-6">
        <BaseInput
          label="landing.name"
          name="name"
          v-model="formData.name"
          :required="true"
          :errors="v$.name.$errors"
        />

        <BaseInput
          v-model="formData.base_url"
          label="landing.base_url"
          name="base_url"
          iconLeft="Link2Icon"
          :required="true"
          :errors="v$.base_url.$errors"
        />

        <VFieldSelect
          label="landing.privacyType"
          v-model="formData.privacy_type_id"
          :options="options.privacy"
          :hideSearch="true"
          :required="true"
          :errors="v$.privacy_type_id.$errors"
        />

        <VFieldSelect
          label="landing.flow_type"
          v-model="formData.flow_type_id"
          :options="options.flowType"
          :hideSearch="true"
          :required="true"
          :errors="v$.flow_type_id.$errors"
        />

        <VFieldSelect
          label="landing.country"
          v-model="formData.country_id"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          :required="true"
          :errors="v$.country_id.$errors"
        />

        <VFieldSelect
          label="landing.category"
          v-model="formData.category_id"
          :options="options.categorys"
          optionName="en_name"
          :required="true"
          :errors="v$.category_id.$errors"
        />

        <div class="flex items-end">
          <div class="w-4/5">
            <BaseInput
              v-model="formData.image"
              label="landing.image"
              name="image"
              class="mr-1"
              iconLeft="ImageIcon"
              :required="true"
              :errors="v$.image.$errors"
              :inputGroup="true"
            />
          </div>

          <VFileUploader v-model="formData.image" />
        </div>
      </div>

      <div class="sm:w-2/5 sm:image-fit mt-10">
        <img
          :src="formData.image || require(`@/assets/images/no_image.jpg`).default"
          @error="$event.target.src = require(`@/assets/images/no_image.jpg`).default"
          data-action="zoom"
          class="w-full rounded-md"
        />
      </div>
    </div>

    <hr class="mt-4" />
    <VariablesMap v-model="formData.variables_map" :title="t('landing.variablesMap')" />

    <hr class="mt-4" />
    <!-- MANDATORY VARIABLES -->
    <div class="flex justify-between my-4">
      <h4 class="text-lg font-medium">{{ t('landing.requiredParameters') }}</h4>

      <button
        type="button"
        class="btn btn-success ml-3"
        @click="formData.mandatory_variables.push('')"
        :disabled="disabledMandatory"
        :title="disabledMandatory ? t('variablesMap.diasbledAddVariableTitle') : t('variablesMap.addVariableTitle')"
      >
        <PlusIcon class="w-4 h-4 mr-1" />
        {{ t('variablesMap.addVariable') }}
      </button>
    </div>

    <div class="flex flex-row flex-wrap gap-y-3 gap-x-3">
      <div v-for="(variable, index) in formData.mandatory_variables" :key="index">
        <div class="input-group mt-2">
          <input type="text" class="form-control w-52" v-model="formData.mandatory_variables[index]" />

          <div
            class="input-group-text cursor-pointer hover:bg-gray-200 active:bg-gray-400"
            @click="formData.mandatory_variables.splice(index, 1)"
            :title="t('variablesMap.deleteVariable')"
          >
            <Trash2Icon class="w-5 h-5 text-theme-6" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
        {{ t('landing.cancel') }}
      </button>

      <button type="submit" class="btn btn-primary">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('landing.save') }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { helpers, required, url } from '@vuelidate/validators'
import { REQUIRED_MESSAGE, URL_MESSAGE } from '@/consts/validate.ts'
import { useTranslation } from '@/hooks/useTranslation'
import useVuelidate from '@vuelidate/core'
import VariablesMap from '@/components/VariablesMap.vue'

export default defineComponent({
  name: 'EditLandingForm',
  components: { VariablesMap },
  props: {
    landing: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const toast = useToast()
    const translation = useTranslation()

    const formData = ref({
      id: props.landing.id || '',
      name: props.landing.name || '',
      base_url: props.landing.base_url || '',
      image: props.landing.image || '',
      flow_type_id: props.landing.flow_type?.id || '',
      privacy_type_id: props.landing.privacy_type?.id || 1,
      country_id: props.landing.country?.id || '',
      category_id: props.landing.category?.id || '',
      mandatory_variables: props.landing.mandatory_variables || [],
      variables_map: props.landing.variables_map || [],
    })

    const options = ref({
      country: [],
      flowType: [],
      privacy: [],
      categorys: [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        base_url: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
          url: helpers.withMessage(t(URL_MESSAGE), url),
        },
        flow_type_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        privacy_type_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        country_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        category_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        image: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()
      if (!validate) {
        return
      }

      if (disabledMandatory.value || disabledMap.value) {
        toast.error(t('landing.parameterNotFilled'), {
          closeOnClick: false,
        })

        return
      }

      props.landing.id ? updateLanding() : createLanding()
    }

    const updateLanding = () => {
      store.dispatch('landings/updateLanding', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const createLanding = () => {
      store.dispatch('landings/createLanding', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const disabledMandatory = computed(() => {
      return formData.value.mandatory_variables.some(variable => !variable?.replace(/\s/g, ''))
    })

    const disabledMap = computed(() => {
      return formData.value.variables_map.some(
        variable => !variable.name.replace(/\s/g, '') || !variable?.value.replace(/\s/g, '')
      )
    })

    const getFlowTypeOptions = async () => {
      const flowTypes = await store.dispatch('flowTypes/getFlowTypes')
      options.value.flowType = flowTypes.data ? flowTypes.data : []
    }
    const getPrivacyOptions = async () => {
      const response = await store.dispatch('landings/getLandingPrivacyTypes')
      options.value.privacy = response?.data ? response.data : []
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getCategoriesOptions = async () => {
      const categories = await store.dispatch('landings/getCategories')
      options.value.categorys = categories ? categories : []
    }

    getFlowTypeOptions()
    getPrivacyOptions()
    getCountriesOptions()
    getCategoriesOptions()

    return {
      formData,
      options,
      handleSubmit,
      disabledMandatory,
      t,
      translation,
      v$,
    }
  },
})
</script>
