<template>
  <div class="absolute invisible z-[999]" :class="contextMenu.class">
    <div class="w-max box">
      <div class="dropdown-menu__content flex w-full p-2 box">
        <ul class="flex flex-col w-full">
          <li class="my-px cursor-pointer">
            <span
              type="button"
              href="#"
              class="flex flex-row items-center h-10 px-3 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              @click="openNewTab"
            >
              <ExternalLinkIcon class="w-4 h-4 mr-2" />
              <span>{{ t('sideMenu.openNewTab') }}</span>
            </span>
          </li>

          <li class="my-px cursor-pointer">
            <span
              type="button"
              href="#"
              class="flex flex-row items-center h-10 px-3 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              @click="openNewWindow"
            >
              <LayersIcon class="w-4 h-4 mr-2" />
              <span>{{ t('sideMenu.openNewWindow') }}</span>
            </span>
          </li>

          <li class="my-px cursor-pointer">
            <span
              type="button"
              href="#"
              class="flex flex-row items-center h-10 px-3 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              @click="copyUrl"
            >
              <CopyIcon class="w-4 h-4 mr-2" />
              <span>{{ t('sideMenu.copyUrl') }}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'VContextSideMenu',
  props: {
    contextMenu: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const toast = useToast()
    const router = useRouter()

    const openNewTab = () => {
      const isQuery = props.contextMenu.pageName?.includes('query/')
      if (isQuery) {
        const [name, id] = props.contextMenu.pageName.split('/')

        let route = router.resolve({ name: name, params: { id: id, transitionFromMenu: true } })
        window.open(route.href, '_blank')

        return
      }

      const route = router.resolve({ name: props.contextMenu.pageName })
      window.open(route.href, '_blank')
    }

    const openNewWindow = () => {
      const isQuery = props.contextMenu.pageName?.includes('query/')
      if (isQuery) {
        const [name, id] = props.contextMenu.pageName.split('/')

        let route = router.resolve({ name: name, params: { id: id, transitionFromMenu: true } })
        window.open(route.href, '_blank', 'location=yes,height=570,width=1020,scrollbars=yes,status=yes')

        return
      }

      const route = router.resolve({ name: props.contextMenu.pageName })
      window.open(route.href, '_blank', 'location=yes,height=570,width=1020,scrollbars=yes,status=yes')
    }

    const copyUrl = async () => {
      const isQuery = props.contextMenu.pageName?.includes('query/')
      if (isQuery) {
        const [name, id] = props.contextMenu.pageName.split('/')
        let route = router.resolve({ name: name, params: { id: id, transitionFromMenu: true } })
        const url = 'https://' + window.location.hostname + route.href
        await navigator.clipboard.writeText(url)
      } else {
        const route = router.resolve({ name: props.contextMenu.pageName })
        const url = 'https://' + window.location.hostname + route.href
        await navigator.clipboard.writeText(url)
      }

      toast.success(t('sideMenu.copySuccess'), {
        closeOnClick: true,
        timeout: 1500,
      })
    }

    document.addEventListener('click', () => {
      const contextMenu = document.querySelector(`.${props.contextMenu.class}`)
      if (contextMenu) {
        contextMenu.style.visibility = 'hidden'
      }
    })

    return {
      t,
      openNewTab,
      openNewWindow,
      copyUrl,
    }
  },
})
</script>
