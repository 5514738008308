<template>
  <div
    class="v-field-search-select"
    :class="{
      'v-field-search-select__hide_search': hideSearch,
      'v-field-search-select__error': errors.length,
      'v-field-search-select__disabled': disabledStyle,
    }"
  >
    <label class="block mt-3.5 mb-0.5" v-if="label">
      {{ t(label) }}
      <span v-if="required" class="text-theme-6 -intro-x">*</span>
    </label>

    <div class="flex">
      <div
        v-if="clearable && selectedId"
        @click="selectedId = ''"
        class="z-30 rounded-l w-10 flex items-center justify-center bg-gray-100 border text-gray-600 hover:text-gray-900 dark:hover:text-gray-100 dark:bg-dark-1 dark:border-dark-4 -mr-1"
        :class="{ 'cursor-pointer': selectedId }"
      >
        <XIcon class="w-4 h-4" />
      </div>

      <TomSelect
        v-model="selectedId"
        :options="{ placeholder: 'Поиск', maxOptions: null }"
        :disabled="disabled"
        class="w-full"
        @change="changeValue"
      >
        <option value="" class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default" />
        <option
          v-for="option of options"
          :value="option[returnValue]"
          :key="option.id"
          :disabled="option.disabled || disabledStyle"
          class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default"
        >
          <span v-if="showId">
            {{ option.id ? `(${option.id})` : '' }}
          </span>

          {{ option[optionName] === 'query.all' ? t(option[optionName]) : option[optionName] }}
        </option>
      </TomSelect>
    </div>

    <div v-for="error of errors" :key="error.$uid" class="text-xs text-theme-6 ml-1 v-field-search-select__intro-error">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VFieldSelect',
  props: {
    options: {
      type: [Array, Object],
      required: true,
    },
    optionName: {
      type: String,
      default: 'name',
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    // does not change reactively disabled
    disabledStyle: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    returnValue: {
      type: String,
      default: 'id',
    },
    showId: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    // todo: old code:
    // const selectedId = ref(String(props.modelValue) || '')
    const selectedId = ref(props.modelValue === null || props.modelValue === undefined ? '' : String(props.modelValue))

    watch(
      () => props.modelValue,
      value => {
        // todo: old code:
        // selectedId.value = String(value)
        selectedId.value = value === null || value === undefined ? '' : String(value)
      }
    )

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const changeValue = () => {
      if (!selectedId.value) {
        context.emit('update:modelValue', '')
        return
      }

      const isId = !Number.isNaN(Number(selectedId.value))

      if (isId) {
        context.emit('update:modelValue', Number(selectedId.value))
        return
      }

      context.emit('update:modelValue', selectedId.value)
    }

    return {
      selectedId,
      changeValue,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-field-search-select {
  &__error {
    .tom-select .ts-input {
      border-color: #d32a29;
      white-space: nowrap;
    }
  }

  &__hide_search {
    .dropdown-input-wrap {
      display: none;
    }
  }

  &__disabled {
    .tom-select .ts-input {
      background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
      cursor: not-allowed;
    }
  }

  &__intro-error {
    z-index: 48;
    position: relative;
    opacity: 0;
    transform: translateY(-9px);
    -webkit-animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}
</style>
