<template>
  <div class="v-table-filter">
    <div class="v-table-filter__fields">
      <slot> </slot>
    </div>

    <div class="v-table-filter__buttons">
      <button class="btn btn-primary mr-2" @click="applyFilter">
        <SearchIcon class="w-4 h-4 mr-1" />
        {{ t('tableFilter.search') }}
      </button>

      <button class="btn" @click="resetFilter">
        <RotateCcwIcon class="w-4 h-4 mr-1" />
        {{ t('tableFilter.reset') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUnmounted, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'VTableFilter',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const router = useRouter()
    const filterFields = ref(cloneDeep(props.modelValue))

    addEventListener('keyup', onEnter)

    watch(props.modelValue, () => {
      filterFields.value = cloneDeep(props.modelValue)
    })

    const applyFilter = () => {
      updateUrlParams()
      context.emit('applyFilter')
    }

    const resetFilter = () => {
      Object.keys(filterFields.value).forEach(key => {
        const value = filterFields.value[key] ?? null
        if (!value) {
          return
        }

        switch (typeof value) {
          case 'object':
            if (value instanceof Array) {
              // Multiple Select Field
              filterFields.value[key] = []
            } else {
              // Date Field
              filterFields.value[key] = null
            }
            break
          case 'boolean':
            filterFields.value[key] = false
            break
          default:
            // Text, Number and Select Fields
            filterFields.value[key] = ''
        }
      })

      updateUrlParams()

      context.emit('update:modelValue', filterFields.value)
      context.emit('resetFilter')
    }

    const updateUrlParams = () => {
      const urlParams = {}

      Object.entries(filterFields.value).forEach(([key, param]) => {
        if (param) {
          urlParams[key] = encodeURIComponent(param)
        }
      })

      router?.push({ query: urlParams })
    }

    function onEnter(e) {
      if (e?.key === 'Enter') {
        updateUrlParams()
        context.emit('applyFilter')
      }
    }

    onUnmounted(() => {
      removeEventListener('keyup', onEnter)
    })

    return {
      applyFilter,
      resetFilter,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-table-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: flex-end;

  &__fields {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: auto;
    gap: 0 1rem;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: col;

    &__fields,
    &__buttons,
    .v-field-input,
    .v-field-input input,
    .v-field-search-select,
    .v-field-checkbox {
      width: 100%;
    }

    &__buttons {
      flex-direction: column;
      justify-content: start;
      flex-wrap: wrap;
      margin-top: 0.875rem;
      row-gap: 0.875rem;

      button {
        margin-right: 0;
      }
    }
  }
}
</style>
