export const enMyReport = {
  addReportButton: 'Add Report',
  backToReportsButton: 'Back to reports',
  test: 'Test',
  save: 'Save',
  cancel: 'Cancel',
  saveAsTable: 'Save As Table',
  saveAsTableDialogHeader: 'Saving a table',
  tableName: 'Table name',
  addReport: 'Add Report',
  editReport: 'Edit Report',
  execute: 'Execute',
  add: 'Add',
  back: 'Back',
  next: 'Next',
  name: 'Name',
  apply: 'Apply',
  clear: 'Clear',
  runTime: 'Runtime',
  exportError: 'An export error has occurred',

  providerEvents: 'Provider events',
  partnerEvents: 'Partner events',

  requests: 'General information',
  requestTrafficbacks: 'Request trafficbacks',
  requestLandingRedirects: 'Request landing redirects',
  requestContentRedirects: 'Request content redirects',

  confirmDialog: {
    title: 'Deleting a Report',
    message: 'Do you really want to remove the Report?',
  },

  reports: {
    headers: {
      id: 'ID',
      name: 'Name',
      is_common: 'Public',
      author: 'Author',
      created_at: 'Created',
      updated_at: 'Changed',
    },
    isCommon: {
      yes: 'Yes',
      no: 'No',
    },
  },

  toastiFy: {
    success: 'Testing was successful',
    error: 'Message from the database: Request validation error',
  },

  main: {
    header: '1 - Title',
    fields: {
      name: { label: 'Report name' },
      is_common: { label: 'Make the report public' },
      table: { label: 'Table' },
    },
  },

  from: {
    header: '2 - Sample tables',
    dataFlow: { table1: { placeholder: 'Table 1' } },
    table2: {
      placeholder: 'Table 2',
    },
    field1: {
      placeholder: 'Field 1',
    },
    field2: {
      placeholder: 'Field 2',
    },
  },

  select: {
    header: '3 - Selection Fields',
    dataFlow: {
      name: {
        placeholder: 'Table 1',
        options: {
          nameField: 'Field',
          nameFunction: 'Function',
        },
      },
      __field: {
        field: {
          placeholder: 'Table Field',
        },
        alias: {
          placeholder: 'Display name',
        },
      },
      __function: {
        function: {
          placeholder: 'Function',
        },
        alias: {
          placeholder: 'Display name',
        },
        pseudo: {
          placeholder: 'Pseudonym',
        },
        data_type: {
          placeholder: 'Data type',
        },
      },
    },
  },

  where: {
    header: '4 - Sampling conditions',
    dataFlow: {
      type: {
        label: 'Type of comparison',
      },
      empty_field: {
        label: "Don't pass value",
      },
      value: { label: 'Value (optional)' },
    },
  },

  group: {
    header: '5 - Groupings',
    dataFlow: {
      field: {
        label: 'Field',
      },
      with_rollup: {
        label: 'With rollup',
      },
      with_cube: {
        label: 'With cube',
      },
      with_totals: {
        label: 'With total',
      },
      __order: {
        label: '',
      },
    },
    description: 'Group by: ',
  },

  having: {
    header: '6 - Function Filter',
    dataFlow: {
      condition: {
        label: 'Types of comparisons',
      },
      order: {
        label: 'Value (optional)',
      },
      empty_field: {
        label: "Don't pass value",
      },
    },
  },

  order: {
    header: '7 - Sorting',
    dataFlow: {
      field: {
        label: 'Field',
      },
    },
  },
}

export const ruMyReport = {
  addReportButton: 'Добавить отчет',
  backToReportsButton: 'Вернуться к отчетам',
  test: 'Тестировать',
  save: 'Сохранить',
  cancel: 'Закрыть',
  saveAsTable: 'Сохранить как таблицу',
  saveAsTableDialogHeader: 'Сохранение таблицы',
  tableName: 'Название таблицы',
  addReport: 'Добавление отчета',
  editReport: 'Редактирование отчета',
  execute: 'Выполнить',
  add: 'Добавить',
  back: 'Назад',
  next: 'Далее',
  name: 'Имя',
  apply: 'Применить',
  clear: 'Очистить',
  runTime: 'Время выполнения',
  exportError: 'Произошла ошибка экспорта',

  providerEvents: 'События провайдеров',
  partnerEvents: 'Уведомления партнерам',

  requests: 'Общая информация',
  requestTrafficbacks: 'Возврат трафика',
  requestLandingRedirects: 'Перенаправление на лендинг',
  requestContentRedirects: 'Перенаправление на сервис',

  confirmDialog: {
    title: 'Удаление отчета',
    message: 'Вы действительно хотите удалить отчет?',
  },

  reports: {
    headers: {
      id: 'ID',
      name: 'Название',
      is_common: 'Публичный',
      author: 'Автор',
      created_at: 'Дата создания',
      updated_at: 'Дата изменения',
    },
    isCommon: {
      yes: 'Да',
      no: 'Нет',
    },
  },

  toastiFy: {
    success: 'Тестирование прошло успешно',
    error: 'Сообщение от базы данных: Ошибка валидации запроса',
  },

  main: {
    header: '1 - Заголовок',
    fields: {
      name: { label: 'Название отчета' },
      is_common: { label: 'Сделать отчет публичным' },
      table: { label: 'Таблица' },
    },
  },

  from: {
    header: '2 - Таблицы выборки',
    dataFlow: { table1: { placeholder: 'Таблица 1' } },
    table2: {
      placeholder: 'Таблица 2',
    },
    field1: {
      placeholder: 'Поле 1',
    },
    field2: {
      placeholder: 'Поле 2',
    },
  },

  select: {
    header: '3 - Поля выборки',
    dataFlow: {
      name: {
        placeholder: 'Таблица 1',
        options: {
          nameField: 'Поле',
          nameFunction: 'Функция',
        },
      },
      __field: {
        field: {
          placeholder: 'Поле таблицы',
        },
        alias: {
          placeholder: 'Отображаемое имя',
        },
      },
      __function: {
        function: {
          placeholder: 'Функция',
        },
        alias: {
          placeholder: 'Отображаемое имя',
        },
        pseudo: {
          placeholder: 'Псевдоним',
        },
        data_type: {
          placeholder: 'Тип данных',
        },
      },
    },
  },

  where: {
    header: '4 - Условия выборки',
    dataFlow: {
      type: {
        label: 'Тип сравнения',
      },
      empty_field: {
        label: 'Не передавать значение',
      },
      value: { label: 'Значение (не обязательно)' },
    },
  },

  group: {
    header: '5 - Группировки',
    dataFlow: {
      field: {
        label: 'Поле',
      },
      with_rollup: {
        label: 'With rollup',
      },
      with_cube: {
        label: 'With cube',
      },
      with_totals: {
        label: 'With total',
      },
      __order: {
        label: '',
      },
    },
    description: 'Группировка: ',
  },

  having: {
    header: '6 - Фильтр функций',
    dataFlow: {
      condition: {
        label: 'Типы сравнений',
      },
      order: {
        label: 'Значение (не обязательно)',
      },
      empty_field: {
        label: 'Не передавать значение',
      },
    },
  },

  order: {
    header: '7 - Сортировка',
    dataFlow: {
      field: {
        label: 'Поле',
      },
    },
  },
}
