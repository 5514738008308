<template>
  <div class="v-edit-operator-form">
    <form @submit.prevent="handleSubmit">
      <div class="sm:flex">
        <div class="sm:w-2/4">
          <BaseInput
            class="sm:mr-6"
            label="operators.name"
            name="name"
            v-model="formData.name"
            :required="true"
            :errors="v$.name.$errors"
          />
        </div>

        <div class="sm:w-2/4">
          <BaseInput label="operators.alias" name="alias" v-model="formData.alias" />
        </div>
      </div>

      <VFieldSelect
        label="operators.country"
        v-model="formData.country_id"
        :options="options.country"
        :errors="v$.country_id.$errors"
        :optionName="translation.getCountryOptionName()"
        :required="true"
      />

      <AdditionalAliases v-if="operator.id" :operator="operator" />

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('operators.cancel') }}
        </button>
        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('operators.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { required, helpers } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useTranslation } from '@/hooks/useTranslation'
import useVuelidate from '@vuelidate/core'
import AdditionalAliases from './AdditionalAliases.vue'

export default defineComponent({
  name: 'EditOperatorForm',
  components: {
    AdditionalAliases,
  },

  props: {
    operator: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const translation = useTranslation()

    const formData = ref({
      id: props.operator.id || '',
      name: props.operator.name || '',
      alias: props.operator.alias || '',
      country_id: props.operator.country?.id || null,
    })

    const options = ref({
      country: [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        country_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      props.operator.id ? updateOperator() : createOperator()
    }

    const updateOperator = () => {
      store.dispatch('operators/updateOperator', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const createOperator = () => {
      store.dispatch('operators/createOperator', formData.value).then(result => {
        const errors = result.response?.data?.errors

        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }

    getCountriesOptions()

    return {
      handleSubmit,
      formData,
      options,

      v$,
      t,
      translation,
    }
  },
})
</script>
