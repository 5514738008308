import {
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from 'date-fns'

const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

const DATE_RANGES = {
  d_today: {
    dateTimeFormat: () => [
      format(startOfDay(new Date()), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_yesterday: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(1)), DATE_TIME_FORMAT),
      format(endOfDay(getLastDate(1)), DATE_TIME_FORMAT),
    ],
  },
  d_this_week: {
    dateTimeFormat: () => [
      format(startOfWeek(new Date(), { weekStartsOn: 1 }), DATE_TIME_FORMAT),
      format(endOfWeek(new Date(), { weekStartsOn: 1 }), DATE_TIME_FORMAT),
    ],
  },
  d_this_month: {
    dateTimeFormat: () => [
      format(startOfMonth(new Date()), DATE_TIME_FORMAT),
      format(endOfMonth(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_this_year: {
    dateTimeFormat: () => [
      format(startOfYear(new Date()), DATE_TIME_FORMAT),
      format(endOfYear(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_week: {
    dateTimeFormat: () => [
      format(startOfWeek(getLastDate(7), { weekStartsOn: 1 }), DATE_TIME_FORMAT),
      format(endOfWeek(getLastDate(7), { weekStartsOn: 1 }), DATE_TIME_FORMAT),
    ],
  },
  d_last_month: {
    dateTimeFormat: () => [
      format(startOfMonth(subMonths(new Date(), 1)), DATE_TIME_FORMAT),
      format(endOfMonth(subMonths(new Date(), 1)), DATE_TIME_FORMAT),
    ],
  },
  d_last_year: {
    dateTimeFormat: () => [
      format(startOfYear(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), DATE_TIME_FORMAT),
      format(endOfYear(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), DATE_TIME_FORMAT),
    ],
  },
  d_last_7_days: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(7)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_14_days: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(14)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_30_days: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(30)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_60_days: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(60)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_90_days: {
    dateTimeFormat: () => [
      format(startOfDay(getLastDate(90)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
  d_last_12_months: {
    dateTimeFormat: () => [
      format(startOfMonth(subMonths(new Date(), 12)), DATE_TIME_FORMAT),
      format(endOfDay(new Date()), DATE_TIME_FORMAT),
    ],
  },
}

const getLastDate = day => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - day)
}

const isDateRangeString = value => {
  return value in DATE_RANGES
}

export const getDateRange = value => {
  if (value.start && value.end) {
    return [value.start, value.end]
  }

  if (!isDateRangeString(value)) {
    return DATE_RANGES['d_this_month'].dateTimeFormat()
  }

  return DATE_RANGES[value].dateTimeFormat()
}
