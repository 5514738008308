<template>
  <div class="v-cpa-operators">
    <VTable
      :columns="columns"
      :data="data"
      :hideExport="true"
      :hideButtons="['edit']"
      :clickableRow="true"
      @rowClick="control.rowClick"
      @delete="deleteOperatorByService"
    />

    <h4 v-if="control.showFormData.value" class="text-lg font-medium mt-4">
      {{ control.formData.value.new_record ? t('services.createOperator') : t('services.editOperator') }}
    </h4>

    <form @submit.prevent="handleSubmit">
      <div v-if="control.showFormData.value" class="sm:flex box mt-2 pb-5 px-4 border border-gray-400 dark:border-dark">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-6"
          label="services.operator"
          v-model="control.formData.value.operator_id"
          :options="options.operators"
          @change="control.changeHandler()"
          :errors="v$.operator_id.$errors"
          :disabledStyle="!control.formData.value.new_record"
        />

        <BaseInput
          class="sm:mr-6 sm:w-60"
          label="services.rate"
          name="rate"
          v-model="control.formData.value.rate"
          @input="control.changeHandler()"
          :errors="v$.rate.$errors"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="services.currency"
          v-model="control.formData.value.currency_id"
          :options="options.currencies"
          @change="control.changeHandler()"
          :errors="v$.currency_id.$errors"
        />
      </div>

      <div class="modal-footer">
        <div class="flex items-center" v-if="control.editableRecord.value">
          <button type="button" class="btn btn-danger mr-2" @click="control.cancelСhanges()">
            <XIcon class="w-4 h-4 mr-1" />
            {{ t('services.cancel') }}
          </button>

          <button type="submit" class="btn btn-primary">
            <SaveIcon class="w-4 h-4 mr-1" />
            {{ t('services.save') }}
          </button>
        </div>

        <button
          v-if="!control.editableRecord.value"
          type="button"
          class="btn btn-outline-secondary mr-2"
          @click="$emit('close')"
        >
          {{ t('services.close') }}
        </button>

        <button
          type="button"
          class="btn btn-success"
          @click="control.addOperator()"
          v-if="!control.editableRecord.value"
        >
          <PlusIcon class="w-5 h-5 mr-1" />
          {{ t('services.addOperatorButton') }}
        </button>
      </div>
    </form>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useControlOperator } from './useControlOperator.js'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification.js'
import { convertAmountToSend } from '@/utils/amountConverter.js'

export default defineComponent({
  name: 'CpaForm',
  props: {
    service: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const control = useControlOperator(props.service)
    const store = useStore()
    const confirmDialog = ref(null)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const columns = {
      operator: t('services.operator'),
      rate: t('services.rate'),
      currency: t('services.currency'),
    }

    const data = computed(() => {
      return control.operators.value.map(operator => {
        const { name } = props.options.currencies.find(currency => currency.id == operator.cpa_rate?.currency) || ''

        return {
          operator: operator.operator?.name,
          rate: operator.cpa_rate?.sum || '-',
          currency: name || '-',
        }
      })
    })

    const rules = computed(() => {
      return {
        operator_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        rate: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        currency_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, control.formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      const currencyId = Number(control.formData.value.currency_id)

      let operator = {
        cpa_rate: { sum: convertAmountToSend(control.formData.value.rate), currency: currencyId },
        rsh_eup_brutto: {},
        rsh_eup_netto: {},
        rsh_trial_price: {},
        rsh_trial_period: 0,
        rsh_rate: {},
        rsh_payment_schedule: 0,
        rsh_max_subscription_period: 0,
      }

      control.formData.value.new_record ? createOperator(operator) : updateOperator(operator)
    }

    const createOperator = operator => {
      store
        .dispatch('services/createServiceOperator', {
          service_id: props.service.id,
          operator_id: control.formData.value.operator_id,
          operator,
        })
        .then(result => {
          const errors = result.response?.data?.errors

          if (errors) {
            useErrorNotification(errors)
            return
          }

          control.getOperatorsByService()
          control.showFormData.value = false
          control.clearEditableRecord()
        })
    }

    const deleteOperatorByService = async (id, index) => {
      const operator = control.operators.value[index]
      const serviceId = props.service.id

      if (!operator || !serviceId) {
        return
      }

      const dialog = await confirmDialog.value.onShowDialog({
        title: 'services.confirmDialogOperators.title',
        message: 'services.confirmDialogOperators.message',
      })

      if (dialog) {
        store
          .dispatch('services/deleteOperatorFromService', {
            service_id: serviceId,
            operator_id: operator.operator.id,
          })
          .then(() => {
            control.getOperatorsByService()
            control.showFormData.value = false
            control.clearEditableRecord()
          })
      }
    }

    const updateOperator = operator => {
      store
        .dispatch('services/updateServiceOperator', {
          service_id: props.service.id,
          operator_id: control.formData.value.operator_id,
          operator,
        })
        .then(result => {
          const errors = result.response?.data?.errors
          if (errors) {
            useErrorNotification(errors)
            return
          }

          control.getOperatorsByService()
          control.showFormData.value = false
          control.clearEditableRecord()
        })
    }

    return {
      v$,
      control,
      columns,
      data,
      confirmDialog,
      handleSubmit,
      deleteOperatorByService,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-cpa-operators {
  .preview {
    max-height: 250px;
  }
}
</style>
