export const enMyCampaign = {
  addCampaignButton: 'Setup campaign',

  author: 'Author',

  id: 'ID',
  title: 'Name',
  offer: 'Offer',
  country: 'Country',
  domain: 'Domain',
  paymentModel: 'Payment method',
  isActive: 'Status',
  createdAt: 'Setup date',
  copyCampaignUrl: 'Copy campaign URL',

  confirmDialog: {
    title: 'Campaign delete',
    message: 'Are you sure you want to delete the campaign?',
  },

  save: 'Save',
  cancel: 'Cancel',

  //form
  name: 'Name',
  enrollmentsRule: 'Enrollments rule',
  cpaRate: 'CPA',
  owner: 'Owner',
  rshRate: 'Revenue share',
  caps: 'Cap',
  status: 'Status',
  campaignHash: 'Campaign Hash',
  campaignUrl: 'Campaign URL',
  copyUrl: 'Copy URL',
  copyUrlCampaign: 'Copy URL campaign',
  campaignUrlMessage: 'Campaign URL is copied',
  copyUrlErrorMessage: 'Campaign URL copy error',
  getUrlErrorMessage: 'Get URL copy error',
  trafficSource: 'Traffic source',
  callback: 'Callback',
  trafficBack: 'Trafficback',

  // Deductions Dialog
  deductions: 'Payouts',
  price: 'Price',
  currency: 'Currency',

  editCampaign: 'Edit Campaign',
  addCampaign: 'Setup campaign',

  toastifyMessage: 'Campaign hash is copied',
}

export const ruMyCampaign = {
  addCampaignButton: 'Добавить кампанию',

  author: 'Автор',

  id: 'ID',
  title: 'Название',
  offer: 'Оффер',
  country: 'Страна',
  domain: 'Домен',
  paymentModel: 'Тип оплаты',
  isActive: 'Статус',
  createdAt: 'Дата создания',
  copyCampaignUrl: 'Скопировать URL кампании',

  confirmDialog: {
    title: 'Удаление кампании',
    message: 'Вы действительно хотите удалить кампанию?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',

  //form
  name: 'Название',
  enrollmentsRule: 'Правило зачисления',
  cpaRate: 'Отчисления CPA',
  owner: 'Владелец',
  rshRate: 'Отчисления RevShare',
  caps: 'Лимит в сутки',
  status: 'Статус',
  campaignHash: 'Хэш кампании',
  campaignUrl: 'URL кампании',
  copyUrl: 'Скопировать URL',
  copyUrlCampaign: 'Скопировать URL кампании',
  campaignUrlMessage: 'URL кампании скопирован в буфер обмена',
  copyUrlErrorMessage: 'Ошибка копирования URL кампании',
  getUrlErrorMessage: 'Ошибка получения URL кампании',
  trafficSource: 'Источник трафика',
  callback: 'Коллбэк',
  trafficBack: 'Трафикбэк',

  // Deductions Dialog
  deductions: 'Отчисления',
  price: 'Цена',
  currency: 'Валюта',

  editCampaign: 'Редактирование кампании',
  addCampaign: 'Добавление кампании',

  toastifyMessage: 'Хэш кампании скопирован в буфер обмена',
}
