<template>
  <div class="accordion-item mt-5 mb-5 border rounded-lg">
    <div
      class="accordion-head font-medium text-base py-2.5 px-3 cursor-pointer hover:text-primary-11"
      :class="isOpen && '_active border-b'"
    >
      <slot name="header" />
      <ChevronDownIcon :class="['arrow', { _reverse: isOpen }]" />
    </div>
    <div ref="content" :class="['accordion-content px-2 overflow-visible', isOpen && 'py-3 _overflow-visible']">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUpdated } from 'vue'

const props = defineProps({
  id: {
    type: [Number, String],
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['updateId'])

const content = ref(null)

watch(
  () => props.isOpen,
  val => {
    if (val) {
      content.value.style.maxHeight = `${content.value.scrollHeight + 24}px`
    } else {
      content.value.style.maxHeight = null
    }
  }
)

const setMaxHeight = () => {
  if (props.isOpen) content.value.style.maxHeight = content.value.scrollHeight + 24 + 'px'
}

onMounted(() => {
  setMaxHeight()
})
onUpdated(() => {
  setMaxHeight()
})
</script>

<style scoped lang="scss">
.accordion-item {
  transition: 0.4s;

  .accordion-head {
    position: relative;

    .arrow {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate3d(0, -50%, 0);
      transition: all 0.25s;

      &._reverse {
        transform: translate3d(0, -50%, 0) rotate(180deg);
      }
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    &._overflow-visible {
      overflow: visible;
    }
  }
}
</style>
