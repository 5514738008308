<template>
  <div class="box" :class="{ 'mt-3': !fromAccountProfile }">
    <div class="my-auto mx-auto w-full px-5 pb-5" :class="{ 'sm:w-3/4 lg:w-2/4 pt-3': !fromAccountProfile }">
      <form @submit.prevent="handleSubmit">
        <div class="pos__ticket p-2">
          <div class="-intro-y">
            <BaseInput
              v-model="formData.old_password"
              label="changePassword.oldPassword"
              type="password"
              iconLeft="ShieldIcon"
              :required="true"
              :errors="v$.old_password.$errors"
            />

            <div v-if="errorMessage" class="text-xs text-theme-6 ml-1 -intro-x">
              {{ errorMessage }}
            </div>

            <BaseInput
              v-model="formData.password"
              label="changePassword.newPassword"
              type="password"
              iconLeft="LockIcon"
              :required="true"
              :errors="v$.password.$errors"
            />

            <BaseInput
              v-model="formData.password_confirmation"
              label="changePassword.confirmPassword"
              type="password"
              iconLeft="LockIcon"
              :required="true"
              :errors="v$.password_confirmation.$errors"
            />
          </div>
        </div>

        <div class="flex justify-end mt-5">
          <button type="submit" class="btn btn-primary px-6 border-gray-400 dark:border-dark-5">
            <RefreshCwIcon class="w-4 h-4 mr-2" />
            {{ t('changePassword.changePasswordButton') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { REPEAT_PASSWORD_MESSAGE, REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'ChangePasswordForm',
  props: {
    fromAccountProfile: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const toast = useToast()
    const store = useStore()
    const user = computed(() => store.state.auth.user)

    const formData = ref({
      old_password: '',
      password: '',
      password_confirmation: '',
    })

    const errorMessage = ref('')

    const rules = computed(() => {
      return {
        old_password: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        password: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        password_confirmation: {
          sameAs: helpers.withMessage(t(REPEAT_PASSWORD_MESSAGE), sameAs(formData.value.password)),
        },
      }
    })

    const v$ = useVuelidate(rules, formData.value)

    const handleSubmit = async () => {
      errorMessage.value = ''
      const result = await v$.value.$validate()
      if (!result) {
        return
      }

      const response = await store.dispatch('users/changePassword', formData.value)

      if (response) {
        const isInvalidOldPassword = response.response?.data.message === 'Invalid old password'
        if (isInvalidOldPassword) {
          errorMessage.value = t('changePassword.invalidOldPassword')
          return
        }

        const isPasswordChanged = response.data.message === 'Password changed'
        if (isPasswordChanged) {
          toast.success(t('changePassword.passwordChanged'), {
            closeOnClick: false,
          })

          formData.value = {
            old_password: '',
            password: '',
            password_confirmation: '',
          }

          v$.value.$reset()
        }
      }
    }

    return {
      v$,
      t,
      user,
      errorMessage,
      formData,
      handleSubmit,
    }
  },
})
</script>
