<template>
  <div>
    <div class="dropdown intro-x" data-placement="bottom">
      <div class="dropdown-toggle cursor-pointer text-gray-600 hover:bg-gray-300 hover:text-gray-700 p-1 rounded-full">
        <svg class="fill-current h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            d="M9.4 5.4H4V6.6H13.2189C13.1612 6.78478 13.0895 6.99578 13.0025 7.22211C12.7032 8.00031
                 12.2402 8.91125 11.5757 9.57574L10 11.1515L9.42426 10.5757C8.72102 9.8725 8.25297 9.16987
                 7.96199 8.64611C7.81668 8.38455 7.71617 8.16874 7.65305 8.02146C7.62151 7.94787
                 7.59937 7.89154 7.5857 7.85534C7.57886 7.83725 7.57415 7.8242 7.57144 7.81657L7.56886
                 7.80922C7.56886 7.80922 7.56921 7.81026 7 8C6.43079 8.18974 6.43091 8.19009 6.43091
                 8.19009L6.43133 8.19135L6.43206 8.19351L6.4341 8.19948L6.44052 8.21786C6.44587
                 8.23292 6.45336 8.25357 6.46313 8.27942C6.48266 8.33112 6.5113 8.40369 6.55008
                 8.49416C6.62758 8.67501 6.74582 8.92795 6.91301 9.22889C7.24703 9.83013 7.77898
                 10.6275 8.57574 11.4243L9.15147 12L4.57964 16.5718L4.57655 16.5749L4.57577
                 16.5757L5.4243 17.4242L5.42688 17.4216L10.0368 12.8117L12.6159 14.9609L13.3841
                 14.0391L10.8888 11.9597L12.4243 10.4243C13.2598 9.58875 13.7968 8.49969 14.1225
                 7.65289C14.2818 7.23863 14.395 6.87072 14.4696 6.6H16V5.4H10.6V4H9.4V5.4ZM17.4405
                 10L21.553 19.7672H20.2509L19.1279 17.1H14.8721L13.7491 19.7672H12.447L16.5595
                 10H17.4405ZM15.3773 15.9H18.6227L17 12.0462L15.3773 15.9Z"
          />
        </svg>
      </div>

      <div class="dropdown-menu w-max">
        <div class="dropdown-menu__content flex w-full p-2 box mt-1">
          <ul class="flex flex-col w-full">
            <li class="my-px cursor-pointer">
              <span
                type="button"
                @click="hide(), (locale = 'ru')"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="mr-4">Русский</span>

                <span v-if="locale === 'ru'" class="flex items-center justify-center text-lg">
                  <CheckIcon class="h-4 w-4 text-theme-1" />
                </span>
              </span>
            </li>

            <li class="my-px cursor-pointer">
              <span
                type="button"
                @click="hide(), (locale = 'en')"
                href="#"
                class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
              >
                <span class="mr-4">English</span>

                <span v-if="locale === 'en'" class="flex items-center justify-center text-lg">
                  <CheckIcon class="h-4 w-4 text-theme-1" />
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useGenerateBreadcrumbs } from '@/hooks/useGenerateBreadcrumbs'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TopBarTranslation',

  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const translation = useTranslation()

    const hide = () => {
      cash('#programmatically-dropdown').dropdown('hide')
    }

    watch(
      () => locale.value,
      () => {
        useGenerateBreadcrumbs(route.matched)
        setLanguage(locale.value)
      }
    )

    const setLanguage = async locale => {
      await store.dispatch('users/updateProfile', {
        language: translation.getTranslationId(locale),
      })
    }

    return {
      hide,
      locale,
      t,
    }
  },
})
</script>
