<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addLanding">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('landing.addLandingButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getLandings"
        @applyFilter=";(table.pagination.pageData.page = 1), getLandings()"
      >
        <BaseInput type="number" class="w-24" label="landing.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-41" label="landing.name" name="id" v-model="table.filter.name" />

        <VFieldSelect
          v-model="table.filter.flow_type"
          :options="options.flowTypes"
          label="landing.flow_type"
          :clearable="true"
          :hideSearch="true"
          class="w-36"
        />

        <VFieldSelect
          v-model="table.filter.category"
          :options="options.categorys"
          optionName="en_name"
          label="landing.category"
          :clearable="true"
          class="w-52"
        />

        <VFieldSelect
          v-model="table.filter.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          label="landing.country"
          :clearable="true"
          class="w-60"
        />

        <VFieldSelect
          v-model="table.filter.provider"
          :options="options.providers"
          class="w-60"
          label="landing.provider"
          :clearable="true"
          :showId="true"
        />

        <VFieldSelect
          v-model="table.filter.service"
          :options="options.services"
          class="w-60"
          label="landing.service"
          :clearable="true"
          :showId="true"
        />

        <VFieldSelect
          v-model="table.filter.offer"
          :options="options.offers"
          class="w-60"
          label="landing.offer"
          :clearable="true"
          :showId="true"
        />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>
    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.headers"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getLandings"
        @edit="editLanding"
        @delete="deleteLanding"
        @restore="restoreLanding"
        @updateSorting="sort => ((table.sorting = sort), getLandings())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="copyLanding(slotProps.id)">
            <Tippy :content="t('landing.copyLanding')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-33" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showModal"
      @close="showModal = false"
      :textHeader="landing.id ? t('landing.editLanding') : t('landing.addLanding')"
      :recordId="landing.id"
    >
      <EditLandingForm :landing="landing" @refresh="getLandings" @close="showModal = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import EditLandingForm from '@/views/landings/EditLandingForm.vue'

export default defineComponent({
  name: 'Landings',
  components: {
    EditLandingForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const translation = useTranslation()

    const landings = ref([])
    const landing = ref({})

    const confirmDialog = ref(null)
    const showModal = ref(false)

    const options = ref({
      flowTypes: [],
      categorys: [],
      country: [],
      providers: [],
      services: [],
      offers: [],
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        flow_type: route.query.flow_type || '',
        category: route.query.category || '',
        country: route.query.country || '',
        provider: route.query.provider || '',
        service: route.query.service || '',
        offer: route.query.offer || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      headers: {
        id: { label: t('landing.id'), sortable: true, sort_name: 'id' },
        image: '',
        name: { label: t('landing.name'), sortable: true, sort_name: 'name' },
        base_url: t('landing.base_url'),
        flow_type: t('landing.flow_type'),
        category: t('landing.category'),
        privacy_type: t('landing.privacyType'),
        country: { label: t('landing.country'), sortable: true, sort_name: 'country_id' },
        count_offers: t('landing.qtyOffers'),
        created_at: { label: t('landing.created_at'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return landings.value.map(landing => {
          return {
            id: landing.id,
            image: { is_image: true, path: landing.image },
            name: landing.name,
            base_url: landing.base_url,
            flow_type: landing.flow_type?.name,
            category: landing.category?.en_name,
            privacy_type: landing.privacy_type?.name,
            country: translation.getCountry(landing.country),
            count_offers: landing.count_offers,
            created_at: new Date(landing.created_at).toLocaleString() || '',
            is_deleted: !!landing.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getLandings = () => {
      store
        .dispatch('landings/getLandings', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(result => {
          if (result?.data) {
            landings.value = result.data
            table.value.pagination.meta = result.meta
          }
        })
    }

    const addLanding = () => {
      landing.value = {}
      showModal.value = true
    }

    const editLanding = id => {
      store.dispatch('landings/getLanding', id).then(res => {
        landing.value = res.data
        showModal.value = true
      })
    }

    const deleteLanding = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'landing.confirmDialog.title',
        message: 'landing.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('landings/deleteLanding', id).then(() => {
          getLandings()
        })
      }
    }

    const restoreLanding = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'landing.confirmRestoreDialog.title',
        message: 'landing.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('landings/restoreLanding', id).then(() => {
          getLandings()
        })
      }
    }

    const copyLanding = id => {
      store.dispatch('landings/getLanding', id).then(response => {
        if (response.data) {
          response.data = {
            ...response.data,
            id: '',
            name: `${response.data.name} | COPY`,
          }

          landing.value = response.data
          showModal.value = true
        }
      })
    }

    const getFlowTypesOptions = async () => {
      const flowTypes = await store.dispatch('flowTypes/getFlowTypes')
      options.value.flowTypes = flowTypes.data ? flowTypes.data : []
    }
    const getCategoriesOptions = async () => {
      const categories = await store.dispatch('landings/getCategories')
      options.value.categorys = categories ? categories : []
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getProvidersOptions = async () => {
      const providers = await store.dispatch('providers/getProviders')
      options.value.providers = providers.data ? providers.data : []
    }
    const getServicesOptions = async () => {
      const services = await store.dispatch('services/getServices', { full: false })
      options.value.services = services.data ? services.data : []
    }
    const getOffersOptions = async () => {
      const offers = await store.dispatch('offers/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }

    getLandings()

    // Dropdown options
    getFlowTypesOptions()
    getCategoriesOptions()
    getCountriesOptions()
    getProvidersOptions()
    getServicesOptions()
    getOffersOptions()

    return {
      table,
      options,
      store,
      showModal,
      landings,
      landing,
      confirmDialog,
      getLandings,
      addLanding,
      editLanding,
      deleteLanding,
      restoreLanding,
      copyLanding,
      t,
      translation,
    }
  },
})
</script>
