export const enVTable = {
  noDataMessage: 'No data found',
  message: 'No Data Found',
  edit: 'Edit',
  delete: 'Delete',
  restore: 'Restore',
  pageSize: 'Page size',
  of: 'of',
  copiedText: 'Copied to clipboard',
  copyErrorText: 'A copy error has occurred',
  export: 'Export',
  exportTo: 'Export to',
}

export const ruVTable = {
  noDataMessage: 'Данных не обнаружено',
  message: 'Записей не обнаружено',
  edit: 'Редактировать',
  delete: 'Удалить',
  restore: 'Восстановить',
  pageSize: 'Кол-во записей',
  of: 'из',
  copiedText: 'Скопировано в буфер обмена',
  copyErrorText: 'Произошла ошибка копирования',
  export: 'Экспорт',
  exportTo: 'Экспортировать в',
}
