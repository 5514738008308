import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { http_auth } from '@/api/http'

const state = {}

const actions = {
  async getUsers({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http_auth.get('/users', config)
      return response
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }
      console.error(error)
    }
  },

  async getUserById(_, id) {
    try {
      const response = await http_auth.get(`/users/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateUserInfo(_, params) {
    try {
      const response = await http_auth.put(`/users/${params.id}`, params.userInfo)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addUser(_, params) {
    try {
      const response = await http_auth.post(`/users`, params)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteUser(_, id) {
    try {
      const response = await http_auth.delete(`/users/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreUser(_, id) {
    try {
      const response = await http_auth.put(`/users/${id}/restore`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Role */
  async getUserRoles(_, id) {
    try {
      const response = await http_auth.get(`/users/${id}/roles`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addRolesUser(_, params) {
    try {
      const response = await http_auth.post(`/users/${params.id}/roles`, { roles: params.roleIds })
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async deleteRolesUser(_, params) {
    try {
      const response = await http_auth.delete(`/users/${params.id}/roles`, { data: { roles: params.roleIds } })
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  /* Statuses */
  async getUserStatuses() {
    try {
      const response = await http_auth.get('/statuses')
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* User name dictionary */
  async getUsersName(_, ids) {
    try {
      const response = await http_auth.post('/users/dictionary', ids)
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  /* Profile */
  async getProfile() {
    try {
      const response = await http_auth.get('/profile')
      return response.data.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async getProfileById(_, id) {
    try {
      const response = await http_auth.get(`/profile/${id}`)
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateProfile(_, params) {
    try {
      const response = await http_auth.put('/profile', params)
      return response
    } catch (error) {
      return error
    }
  },

  async changePassword(_, params) {
    try {
      const response = await http_auth.post('/users/change-password', params)
      return response
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
