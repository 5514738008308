<template>
  <div>
    <div v-if="isImage()" class="image-fit w-[40px] h-[40px] select-none">
      <img
        class="rounded"
        :src="text.path || require('@/assets/images/no_image.jpg').default"
        @error="$event.target.src = require('@/assets/images/no_image.jpg').default"
        data-action="zoom"
      />
    </div>

    <router-link
      v-else-if="query.isQuery()"
      :to="{
        name: 'logs-query',
        params: { id: query.getQueryId(text) },
        query: { request_id: query.getRequestId(text) },
      }"
      type="button"
      target="_blank"
      class="btn btn-linkedin h-[23px]"
    >
      <GitPullRequestIcon class="mr-1 h-3.5 w-3.5" />
      {{ query.getRequestId(text) }}
    </router-link>

    <div v-else>
      <div v-if="text?.length > TRUNCATE_LENGTH" class="flex items-center">
        <Tippy
          :key="text"
          :content="renderTippyContent(text)"
          :options="{
            maxWidth: 1000,
            allowHTML: true,
            theme: 'light',
          }"
        >
          <div class="max-w-[320px]">
            <span class="truncate block text-theme-1">
              {{ text }}
            </span>
          </div>
        </Tippy>
        <Tippy
          @click="copyTruncateText(text)"
          :key="text"
          :content="t('common.copy')"
          :options="{
            theme: 'light',
          }"
        >
          <CopyIcon class="w-4 h-4 text-theme-6 hover:text-opacity-80 active:text-opacity-100 cursor-pointer" />
        </Tippy>
      </div>

      <span v-else class="truncate">
        <span v-if="columnType === 'status'">
          <span v-if="text === 1" class="bg-orange-300 text-orange-800 text-xs font-medium px-3 py-1 rounded">
            {{ t('common.moderation') }}
          </span>
          <span v-else-if="text === 2" class="bg-green-200 text-green-700 text-xs font-medium px-3 py-1 rounded">
            {{ t('common.active') }}
          </span>
          <span v-else-if="text === 3" class="bg-red-300 text-red-800 text-xs font-medium px-3 py-1 rounded">
            {{ t('common.blocked') }}
          </span>
          <span v-else>{{ text }}</span>
        </span>

        <span v-else-if="columnType === 'email'" class="text-blue-800">
          <a :href="`mailto:${text}`">{{ text }}</a>
        </span>

        <span v-else-if="columnType === 'telegram'" class="text-blue-800">
          <a :href="`tg://resolve?domain=${text}`">{{ text }}</a>
        </span>

        <span v-else-if="columnType === 'skype'" class="text-blue-800">
          <a :href="`skype:${text}?chat`">{{ text }}</a>
        </span>

        <span v-else class="block">
          {{ renderContent(text) }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { renderTippyJson } from '@/utils/json'

export default defineComponent({
  name: 'VTableTextFormatter',
  props: {
    text: {
      required: true,
      default: '',
    },
    columnType: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const toast = useToast()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const TRUNCATE_LENGTH = 60

    const isImage = () => props.text?.is_image === true

    const isJson = value => {
      if (!value || typeof value !== 'string') {
        return false
      }

      try {
        const json = JSON.parse(value)
        return typeof json === 'object' && json !== null
      } catch (e) {
        return false
      }
    }

    const query = {
      isQuery: () => {
        if (!props.text || typeof props.text !== 'string') {
          return false
        }
        return props.text?.includes('reports/query/')
      },
      getQueryId: patch => {
        const [, , params] = patch.split('/')
        const [id] = params.split('?')
        return id
      },
      getRequestId: patch => {
        const [, request_id] = patch.split('=')
        return request_id
      },
    }

    const renderContent = text => {
      try {
        if (isJson(text)) {
          let isCurrency = true
          const object = JSON.parse(text)

          const currencys = Object.entries(object).map(([key, value]) => {
            const isSrtingKey = typeof key === 'string'
            const isNumberValue = typeof value === 'number'

            if (!isSrtingKey || !isNumberValue) {
              isCurrency = false
            }

            return `${key}: ${value}`
          })

          return isCurrency ? currencys.join(', ') : text
        }

        return text
      } catch (e) {
        return text
      }
    }

    const renderTippyContent = text => {
      if (typeof text === 'string') {
        let value = text

        if (isJson(text)) {
          return renderTippyJson(value)
        }

        value = value.replaceAll('=', '<span class="font-black">=</span>')
        value = value.replaceAll('?', '</br><span class="font-medium">?</span>')
        value = value.replaceAll('&', '</br><span class="font-medium">&</span>')

        return value
      }

      return text
    }

    const copyTruncateText = async text => {
      try {
        await navigator.clipboard.writeText(text)
        toast.success(t('vTable.copiedText'), {
          timeout: 1500,
          closeOnClick: false,
        })
      } catch (error) {
        toast.error(t('vTable.copyErrorText'), {
          timeout: 1500,
          closeOnClick: false,
        })
        console.error(error)
      }
    }

    return {
      t,
      TRUNCATE_LENGTH,
      isImage,
      query,
      renderContent,
      renderTippyContent,
      copyTruncateText,
    }
  },
})
</script>
