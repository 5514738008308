import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getServices({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/services`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getService(_, id) {
    try {
      const response = await http.get(`/services/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async createService(_, payload) {
    try {
      const response = await http.post(`/services`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateService(_, payload) {
    try {
      const response = await http.put(`/services/${payload.id}`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteService(_, id) {
    try {
      const response = await http.delete(`/services/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreService(_, id) {
    try {
      const response = await http.put(`/services/${id}/restore`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Operators
  async createServiceOperator(_, payload) {
    try {
      const response = await http.post(
        `/services/${payload.service_id}/operators/${payload.operator_id}`,
        payload.operator
      )
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateServiceOperator(_, payload) {
    try {
      const response = await http.put(
        `/services/${payload.service_id}/operators/${payload.operator_id}`,
        payload.operator
      )
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async getOperatorsByService(_, id) {
    try {
      const response = await http.get(`/services/${id}/operators`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async deleteOperatorFromService(_, params) {
    try {
      return await http.delete(`/services/${params.service_id}/operators/${params.operator_id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  // Cabinet Services
  async getCabinetOperatorsByService(_, id) {
    try {
      const response = await http.get(`/cabinet/services/${id}/operators`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
