export const enPaymentDetails = {
  addRequisite: 'Add payment details',
  method: 'Payment method',
  currency: 'Currency',
  sum: 'Minimum amount',
  payment_fee: 'Payment fee',
  requisite: 'Account',

  save: 'Save',
  cancel: 'Cancel',
}

export const ruPaymentDetails = {
  addRequisite: 'Добавление реквизита',
  method: 'Платежный метод',
  currency: 'Валюта',
  sum: 'Минимальная сумма',
  payment_fee: 'Payment fee',
  requisite: 'Реквизит',

  save: 'Сохранить',
  cancel: 'Отменить',
}
