import { convertAmountToDisplay } from '@/utils/amountConverter'
import { cloneDeep } from 'lodash'
import { ref } from 'vue'
import { useStore } from 'vuex'

export const useControlOperator = service => {
  const store = useStore()

  const operators = ref([])
  const editableRecord = ref(null)
  const showFormData = ref(false)
  const formData = ref({})

  const clearEditableRecord = () => (editableRecord.value = null)

  const rowClick = index => {
    const operator = operators.value[index]
    if (!operator) {
      return
    }

    clearEditableRecord()
    showFormData.value = true
    setFormData(operator, false)
  }

  const setFormData = (operator = {}, newRecord = true) => {
    switch (service.payment_model.name) {
      case 'CPA':
        formData.value = {
          operator_id: operator.operator?.id,
          rate: operator.cpa_rate?.sum || '',
          currency_id: operator.cpa_rate?.currency || '',
          new_record: newRecord,
        }

        break
      case 'RevShare':
        formData.value = {
          operator_id: operator.operator?.id,
          currency_id: operator.rsh_rate?.currency || '',
          rate: operator.rsh_rate?.sum || '',
          brutto: operator.rsh_eup_brutto?.sum || '',
          netto: operator.rsh_eup_netto?.sum || '',
          trial_period: operator.rsh_trial_period || 0,
          trial_rate: operator.rsh_trial_price?.sum || '',
          schedule: operator.rsh_payment_schedule || 0,
          max_period: operator.rsh_max_subscription_period || 0,
          number: operator.service_number || '',
          new_record: newRecord,
        }
        break
    }
  }

  const addOperator = () => {
    setFormData()
    editableRecord.value = formData
    showFormData.value = true
  }

  const cancelСhanges = () => {
    if (!editableRecord.value) {
      return
    }

    if (!formData.value.rate) {
      showFormData.value = false
    }

    formData.value = editableRecord.value
    clearEditableRecord()
  }

  const changeHandler = () => {
    if (editableRecord.value !== null) {
      return
    }

    editableRecord.value = cloneDeep(formData.value)
  }

  const convertAmount = () => {
    operators.value.forEach((operator, key) => {
      const cpa = operator.cpa_rate?.sum
      const revShare = operator.rsh_rate?.sum
      const brutto = operator.rsh_eup_brutto?.sum
      const netto = operator.rsh_eup_netto?.sum
      const trialPrice = operator.rsh_trial_price?.sum

      if (cpa) {
        operators.value[key].cpa_rate.sum = convertAmountToDisplay(cpa)
      }

      if (revShare) {
        operators.value[key].rsh_rate.sum = convertAmountToDisplay(revShare)
      }

      if (brutto) {
        operators.value[key].rsh_eup_brutto.sum = convertAmountToDisplay(brutto)
      }

      if (netto) {
        operators.value[key].rsh_eup_netto.sum = convertAmountToDisplay(netto)
      }

      if (trialPrice) {
        operators.value[key].rsh_trial_price.sum = convertAmountToDisplay(trialPrice)
      }
    })
  }

  const getOperatorsByService = async () => {
    const response = await store.dispatch('services/getOperatorsByService', service.id)

    if (response.data) {
      operators.value = response.data.operators

      convertAmount()
    }
  }

  getOperatorsByService()

  return {
    operators,
    editableRecord,
    showFormData,
    formData,
    changeHandler,
    rowClick,
    addOperator,
    cancelСhanges,
    getOperatorsByService,
    clearEditableRecord,
  }
}
