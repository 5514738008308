export const enChangePassword = {
  oldPassword: 'Password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  changePasswordButton: 'Change',
  invalidOldPassword: 'Invalid password entered',
  passwordChanged: 'Your password has been successfully changed',
}

export const ruChangePassword = {
  oldPassword: 'Текущий пароль',
  newPassword: 'Новый пароль',
  confirmPassword: 'Повторите новый пароль',
  changePasswordButton: 'Изменить пароль',
  invalidOldPassword: 'Введен неверный пароль',
  passwordChanged: 'Ваш пароль успешно изменен',
}
