<template>
  <!-- BEGIN: Top Bar -->
  <div>
    <div class="top-bar">
      <div class="mr-auto"></div>

      <TopBarTranslation class="mr-2" />
      <TopBarNotification class="mr-8" />
      <TopBarAccountMenu />
    </div>

    <!-- BEGIN: Breadcrumb -->
    <div class="breadcrumb mt-3 -intro-x">
      <div class="flex justify-center items-center">
        <div class="flex items-center overflow-y-auto whitespace-nowrap">
          <span class="text-gray-600 dark:text-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              />
            </svg>
          </span>

          <span class="text-gray-600 dark:text-gray-200 hover:underline ml-2">
            {{ t('sideMenu.cabinet') }}
          </span>

          <span v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="flex items-center">
            <span class="mx-0.5 sm:mx-1.5 text-gray-500 dark:text-gray-300">/</span>
            <span
              :class="{
                'text-gray-600 dark:text-gray-200 hover:underline': index !== breadcrumbs.length - 1,
                'text-blue-600 dark:text-blue-400 hover:underline': index === breadcrumbs.length - 1,
              }"
            >
              {{ t(breadcrumb.name) }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <!-- END: Breadcrumb -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TopBarTranslation from './TopBarActions/TopBarTranslation.vue'
import TopBarNotification from './TopBarActions/TopBarNotification.vue'
import TopBarAccountMenu from './TopBarActions/TopBarAccountMenu.vue'

export default defineComponent({
  name: 'TopBar',
  components: {
    TopBarTranslation,
    TopBarNotification,
    TopBarAccountMenu,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const router = useRouter()
    const breadcrumbs = ref([])

    watch(
      () => router.currentRoute.value,
      router => {
        generateBreadcrumbs(router)
      }
    )

    const generateBreadcrumbs = route => {
      breadcrumbs.value = []

      route.matched.forEach(match => {
        const breadcrumb = match.meta.breadcrumb?.name

        if (breadcrumb) {
          breadcrumbs.value.push({ name: breadcrumb })
        }
      })
    }

    const hide = () => {
      cash('#programmatically-dropdown').dropdown('hide')
    }

    generateBreadcrumbs(router.currentRoute.value)

    return {
      breadcrumbs,
      hide,
      t,
    }
  },
})
</script>
