import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getOperators({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/operators', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getCabinetOperators({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/cabinet/operators', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getOperator(_, id) {
    try {
      const response = await http.get(`/operators/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async createOperator(_, payload) {
    try {
      const response = await http.post(`/operators`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateOperator(_, payload) {
    try {
      const response = await http.put(`/operators/${payload.id}`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOperator(_, id) {
    try {
      const response = await http.delete(`/operators/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addOperatorAliases(_, payload) {
    try {
      const response = await http.post('/operators/aliases', payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOperatorAliases(_, id) {
    try {
      const response = await http.delete(`/operators/aliases/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
