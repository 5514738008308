<template>
  <div class="v-table">
    <div class="flex items-end justify-between mb-1.5">
      <div>
        <VExportTable :formats="exportFormat" @export="format => $emit('export', format)" />
      </div>

      <div class="text-gray-600 dark:text-gray-200">
        {{ counter }}
      </div>
    </div>

    <div class="relative preview" :class="{ 'overflow-x-auto overflow-y-auto': !hideScroll }">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="bg-white text-xs text-black uppercase dark:bg-gray-700 dark:text-gray-400 border-b border-t border-gray-500 border-opacity-40 dark:border-dark-5"
          :class="{
            'whitespace-nowrap': size === 'base',
          }"
        >
          <tr>
            <th
              v-for="column of columns"
              :key="column"
              scope="col"
              :class="{
                'px-2 py-3': size === 'xs',
                'px-2.5 py-3.5': size === 'base',
                'hover:bg-gray-200 active:bg-gray-300 cursor-pointer': column.sortable,
              }"
              @click="column.sortable && onStorable(column)"
            >
              <div class="flex flex-row items-center">
                <div>{{ typeof column === 'string' ? column : column.label }}</div>

                <ChevronDownIcon
                  :key="`${sorting.order}-${sorting.order_by}`"
                  v-if="column.sortable"
                  class="ml-1.5 w-4 h-4"
                  :class="{
                    'transform rotate-180': column.sort_name === sorting.order_by && sorting.order === 'ASC',
                    'transform rotate-180': column.sort_name === sorting.order_by && sorting.order === 'DESC',
                    'text-gray-500': column.sort_name !== sorting.order_by,
                  }"
                />
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <slot name="rows">
            <tr
              v-for="(record, index) in data"
              :key="record.id"
              class="bg-white border-b border-opacity-60 border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100 last:border-gray-500 last:border-opacity-30"
              :class="{
                'v-table__clickable_row': clickableRow,
                'v-table__active': activeRow === (record.id || index),
                'odd:bg-gray-100 odd:bg-opacity-40': !record.is_deleted,
                'bg-theme-6 bg-opacity-5 hover:bg-theme-6 hover:bg-opacity-10 border-l-2 border-l-theme-6 dark:border-l-theme-6':
                  record.is_deleted,
              }"
              @click="rowClick(record.id || index)"
            >
              <td
                v-for="(column, key) of columns"
                :key="column"
                scope="row"
                class="text-gray-900 dark:text-white"
                :class="{
                  'px-2 py-2': size === 'xs',
                  'px-2.5 py-2.5': size === 'base',
                  'font-medium': key === 'name' || key === 'title',
                  'py-[4px]': record[key]?.is_image === true,
                  'py-0.5': column.type === 'status',
                }"
              >
                <VTableTextFormatter :text="record[key]" :columnType="column.type" />
              </td>

              <td class="whitespace-nowrap" align="right">
                <div v-if="record.is_deleted">
                  <button type="button" class="mr-2" @click.stop="$emit('restore', record.id)">
                    <Tippy :content="t('vTable.restore')">
                      <RotateCcwIcon
                        class="w-9 h-9 rounded-full hover:bg-gray-200 hover:bg-opacity-40 active:bg-opacity-50 active:bg-gray-300 p-1 text-theme-31"
                      />
                    </Tippy>
                  </button>
                </div>
                <div v-else>
                  <button type="button" class="mr-1" @click.stop="$emit('edit', record.id)" v-if="hide.edit">
                    <Tippy :content="t('vTable.edit')">
                      <SettingsIcon
                        class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-blue-700"
                      />
                    </Tippy>
                  </button>

                  <slot name="buttons" :record="record" :id="record.id"> </slot>

                  <button type="button" class="mr-2" @click.stop="$emit('delete', record.id, index)" v-if="hide.delete">
                    <Tippy :content="t('vTable.delete')">
                      <Trash2Icon class="w-9 h-9 rounded-full hover:bg-gray-200 active:bg-gray-300 p-1 text-red-600" />
                    </Tippy>
                  </button>
                </div>
              </td>
            </tr>

            <!-- Total -->
            <tr class="border-b bg-theme-12 bg-opacity-40 hover:bg-opacity-50">
              <td
                v-for="(cell, key) of total"
                :key="key"
                scope="row"
                class="text-gray-900 dark:text-white"
                :class="{
                  'px-2 py-1.5': size === 'xs',
                  'px-2.5 py-2.5': size === 'base',
                }"
              >
                <VTableTextFormatter :text="cell" />
              </td>
            </tr>
          </slot>
        </tbody>
      </table>
      <VTableLoader v-if="tableLoader" :data="data" />
    </div>

    <VPagination
      v-if="paginationOptions?.current_page"
      :pagination="paginationOptions"
      @send-page="sendPage"
      :options-size="optionsPageSize"
      class="mt-3"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VExportTable from './VExportTable.vue'
import VPagination from './VPagination.vue'
import VTableLoader from './VTableLoader.vue'
import VTableTextFormatter from './VTableTextFormatter.vue'

export default defineComponent({
  name: 'VTable',
  components: {
    VPagination,
    VTableLoader,
    VTableTextFormatter,
    VExportTable,
  },
  props: {
    columns: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    paginationOptions: {
      type: Object,
    },
    hideButtons: {
      type: Array,
      default: () => [''],
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
    clickableRow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'base',
      validator: size => ['xs', 'base'].includes(size),
    },
    hideScroll: {
      type: Boolean,
      default: false,
    },
    optionsPageSize: {
      type: Array,
      default: () => [5, 15, 30, 100],
    },
    tableLoader: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Object,
      default: () => {},
    },
    exportFormat: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const activeRow = ref(null)

    const sendPage = (params = {}) => {
      context.emit('updatePageData', params)
      context.emit('getData')
    }

    const hide = {
      edit: !props.hideButtons.includes('edit'),
      delete: !props.hideButtons.includes('delete'),
    }

    const counter = computed(() => {
      const from = props.paginationOptions?.from
      const to = props.paginationOptions?.to
      const total = props.paginationOptions?.total

      if (from && to && total) {
        return `${from} - ${to} ${t('vTable.of')} ${total}`
      }

      return ''
    })

    const sorting = ref({
      order: props.sort?.order || '',
      order_by: props.sort?.order_by || '',
    })

    const rowClick = id => {
      if (!props.clickableRow) {
        return
      }

      activeRow.value = activeRow.value === id ? null : id
      context.emit('rowClick', id)
    }

    const onStorable = column => {
      const isAnotherOrder = () => column.sort_name !== sorting.value.order_by
      const isOrderEmpty = () => !sorting.value.order && !sorting.value.order_by
      const isOrderAsc = () => sorting.value.order === 'ASC'
      const isOrderDesc = () => sorting.value.order === 'DESC'

      switch (true) {
        case isOrderEmpty() || isAnotherOrder():
          sorting.value = {
            order: 'ASC',
            order_by: column.sort_name,
          }
          break
        case isOrderAsc():
          sorting.value = {
            order: 'DESC',
            order_by: column.sort_name,
          }
          break
        case isOrderDesc():
          sorting.value = {
            order: '',
            order_by: '',
          }
          break
      }

      context.emit('updateSorting', sorting.value)
    }

    return {
      activeRow,
      sendPage,
      rowClick,
      sorting,
      onStorable,
      hide,
      counter,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-table {
  &__active {
    background-color: #dfecfe !important;
  }
}
</style>
