import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getCampaigns({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/companies', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getCampaignById(_, id) {
    try {
      return await http.get(`/companies/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addCampaign(_, params) {
    try {
      return await http.post('/companies', params)
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateCampaign(_, params) {
    try {
      return await http.put(`/companies/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteCampaign(_, id) {
    try {
      return await http.delete(`/companies/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreCampaign(_, id) {
    try {
      await http.put(`/companies/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // LANDINGS ACTIONS
  async getLandingsToCampaign(_, id) {
    try {
      const response = await http.get(`/companies/${id}/landings`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateLandingsToCampaign(_, params) {
    try {
      const response = await http.post(`/companies/${params.campaign_id}/landings`, {
        rotation_type: params.rotation_type,
        landings: params.landings,
      })

      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  //  OPERATORS ACTIONS
  async getOperatorsByCampaign(_, id) {
    try {
      const response = await http.get(`/companies/${id}/operators`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createOperatorToCampaign(_, params) {
    try {
      const response = await http.post(`/companies/${params.campaign_id}/operators/${params.operator_id}`, params.data)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateOperatorToCampaign(_, params) {
    try {
      const response = await http.put(`/companies/${params.campaign_id}/operators/${params.operator_id}`, params.data)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOperatorFromCampaign(_, params) {
    try {
      return await http.delete(`/companies/${params.campaign_id}/operators/${params.operator_id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async getBlacklistTypes() {
    try {
      const response = await http.get('/blacklist_types')
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
