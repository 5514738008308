export const enTrafficCallback = {
  addCallbackButton: 'Setup callback',
  addCallback: 'Setup callback',
  editCallback: 'Edit callback',
  postbackURL: 'Postback URL',
  copyCallback: 'Copy callback',

  tableFilter: {
    method: 'Method',
  },

  id: 'ID',
  name: 'Name',
  base_url: 'URL',
  request_method: 'Method',
  author: 'Author',
  created_at: 'Setup date',
  getRequest: 'Get request',
  postRequest: 'Post request',
  status: 'Status',
  trafficSource: 'Traffic source',
  sourceParams: 'Traffic source parameters:',
  systemParams: 'System parameters:',

  // Callback events form
  callbackEvents: 'Callback events',
  addEvent: 'Add event',
  event: 'Event',
  selectedEvents: 'Selected events',
  description: 'Description',

  confirmDialog: {
    title: 'Delete callback',
    message: 'Are you sure you want to delete callback?',
  },

  confirmRestoreDialog: {
    title: 'Restore a callback',
    message: 'Do you really want to restore the callback?',
  },

  save: 'Save',
  cancel: 'Cancel',
  close: 'Close',
}

export const ruTrafficCallback = {
  addCallbackButton: 'Добавить коллбэк',
  addCallback: 'Добавление коллбэка',
  editCallback: 'Редактирование коллбэка',
  postbackURL: 'Постбэк URL',
  copyCallback: 'Скопировать коллбэк',

  tableFilter: {
    method: 'Метод',
  },

  id: 'ID',
  name: 'Название',
  base_url: 'URL',
  request_method: 'Метод',
  author: 'Автор',
  created_at: 'Дата создания',
  getRequest: 'GET запрос',
  postRequest: 'POST запрос',
  status: 'Статус',
  trafficSource: 'Источник трафика',
  sourceParams: 'Параметры источника трафика:',
  systemParams: 'Системные параметры:',

  // Callback events form
  callbackEvents: 'События уведомлений',
  addEvent: 'Добавить событие',
  event: 'Событие',
  selectedEvents: 'Выбранные события',
  description: 'Описание',

  confirmDialog: {
    title: 'Удаление коллбэка',
    message: 'Вы действительно хотите удалить коллбэк?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление коллбэка',
    message: 'Вы действительно хотите восстановить коллбэк?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',
  close: 'Закрыть',
}
