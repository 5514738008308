<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link :to="{ path: '/' }" class="-intro-x flex items-center pt-5 select-none">
            <img alt="Sigma" class="w-40" :src="require(`@/assets/images/logo.png`).default" />
            <!--            <span class="text-white text-lg ml-3">-->
            <!--              Ru<span class="font-medium">bick</span>-->
            <!--            </span>-->
          </router-link>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16 select-none"
              :src="require(`@/assets/images/sing_in.png`).default"
            />
            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
              Пару кликов <br />
              чтобы войти в аккаунт
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
              Управляйте всеми коммерческими аккаунтами в одном месте
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <form @submit.prevent="submitChangePassword" class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              Введите новый пароль
            </h2>
            <div class="mt-3">
              <BaseInput type="password" label="Пароль" v-model="formData.password" :errors="v$.password.$errors" />
            </div>
            <div class="mt-3">
              <BaseInput
                type="password"
                label="Пароль еще раз"
                v-model="formData.password_confirmation"
                :errors="v$.password_confirmation.$errors"
              />
            </div>
            <button type="submit" class="btn btn-primary mt-4">
              Сменить пароль
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'

export default defineComponent({
  components: { DarkModeSwitcher },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const formData = reactive({ password: '', password_confirmation: '' })

    const getTokenRoute = route.params.token

    const rules = computed(() => {
      return {
        password: {
          required,
        },
        password_confirmation: {
          sameAs: sameAs(formData.password),
        },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const submitChangePassword = async () => {
      const result = await v$.value.$validate()
      if (!result) {
        return
      }
      const params = { ...formData, token: getTokenRoute }
      try {
        await store.dispatch('auth/setPassword', params)

        // store.dispatch("auth/sendLogout");

        router.push({
          name: 'login',
        })
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return { submitChangePassword, formData, v$ }
  },
})
</script>
