<template>
  <div class="v-table-loader">
    <div v-if="!data.length" class="flex justify-center my-6">
      <LoadingIcon icon="tail-spin" class="w-8 h-8" v-if="!noData" />
      <div class="text-gray-600 flex items-center" v-if="noData">
        <AlertTriangleIcon class="w-5 h-5 mr-2" />
        {{ t('vTable.message') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'VTableLoader',
  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
  },

  setup(props) {
    const noData = ref(false)

    setTimeout(() => {
      noData.value = true
    }, 1000)

    watch(
      () => props.data,
      () => {
        if (noData.value) {
          noData.value = false
          setTimeout(() => {
            noData.value = true
          }, 300)
        }
      }
    )

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    return {
      noData,
      t,
    }
  },
})
</script>
