<template>
  <div class="v-quill-editor">
    <label class="block mt-3.5 mb-0.5" v-if="label">
      {{ label }}
    </label>

    <QuillEditor theme="snow" :content="content" @update:content="content = $event" contentType="html" />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default defineComponent({
  name: 'VQuillEditor',
  components: {
    QuillEditor,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const content = ref(props.modelValue || '')

    watch(
      () => content.value,
      value => context.emit('update:modelValue', value)
    )

    return {
      content,
    }
  },
})
</script>

<style lang="scss">
.v-quill-editor {
  .ql-toolbar .ql-container {
    border-color: rgba(226, 232, 240, 1);
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-width: 1px;
  }

  .ql-toolbar {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  .ql-container {
    font-family: Roboto;
    font-size: 14px;
    min-height: 70px;
    max-height: 250px;
    overflow: auto;
    border-radius: 0 0 0.375rem 0.375rem;
  }
}
</style>
