export const enServices = {
  addServiceButton: 'Add Service',
  operatorManagement: 'Operator management',
  copyService: 'Copy service',

  id: 'ID',
  title: 'Name',
  paymentModel: 'Payment model',
  provider: 'Provider',
  providerParams: 'Provider settings',
  country: 'Country',
  createdAt: 'Created at',

  dayLimit: 'Day limit (0 - no limit)',
  redirectLimit: 'Redirections upon reaching the limit',
  successUrl: 'Success URL',
  uuid: 'Uuid',
  generateUuid: 'Generate uuid',
  status: 'Status',
  subIsActive: 'Subscription is active upon payment (not OTP)',

  name: 'Name',
  description: 'Description',

  addService: 'Add service',
  editService: 'Edit service',

  confirmDialog: {
    title: 'Deleting a service',
    message: 'Do you really want to remove the service?',
  },

  confirmRestoreDialog: {
    title: 'Restore a service',
    message: 'Do you really want to restore the service?',
  },

  confirmDialogOperators: {
    title: 'Deleting a operator',
    message: 'Do you really want to remove the operator?',
  },

  save: 'Save',
  cancel: 'Cancel',
  close: 'Close',

  // Revshare form
  addOperatorButton: 'Add operator',
  operator: 'Operator',
  brutto: 'Brutto',
  netto: 'Netto',
  rate: 'Rate',
  trialPeriod: 'Trial period',
  currency: 'Currency',
  trialRate: 'Trial fee',
  schedule: 'Schedule',
  maxPeriod: 'Max period',
  serviceNumber: 'Service number',
  editOperator: 'Edit operator',
  createOperator: 'Add operator',

  variablesMap: 'Required parameters',
}

export const ruServices = {
  addServiceButton: ' Добавить сервис',
  operatorManagement: 'Управление операторами',
  copyService: 'Скопировать сервис',

  id: 'ID',
  title: 'Название',
  paymentModel: 'Тип монетизации',
  provider: 'Провайдер',
  providerParams: 'Параметры провайдера',
  country: 'Страна',
  createdAt: 'Дата создания',

  dayLimit: 'Лимит в сутки (0 - без лимита)',
  redirectLimit: 'Перенаправления по достижению лимита',
  successUrl: 'Контент URL',
  uuid: 'Uuid',
  generateUuid: 'Сгенерировать uuid',
  status: 'Статус',
  subIsActive: 'Подписка активна при наличии оплаты (не OTP)',

  name: 'Название',
  description: 'Описание',

  addService: 'Добавление сервиса',
  editService: 'Редактирование сервиса',

  confirmDialog: {
    title: 'Удаление сервиса',
    message: 'Вы действительно хотите удалить сервис?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление сервиса',
    message: 'Вы действительно хотите восстановить сервис?',
  },

  confirmDialogOperators: {
    title: 'Удаление оператора',
    message: 'Вы действительно хотите удалить оператора?',
  },

  save: 'Сохранить',
  cancel: 'Отменить',
  close: 'Закрыть',

  // Revshare form
  addOperatorButton: 'Добавить оператора',
  operator: 'Оператор',
  brutto: 'Полная стоимость',
  netto: 'Без налога',
  rate: 'Отчисления',
  trialPeriod: 'Пробный период',
  currency: 'Валюта',
  trialRate: 'Отчисления за подключение',
  schedule: 'Периодичность',
  maxPeriod: 'Макс. период',
  serviceNumber: 'Сервисный номер',
  editOperator: 'Редактирование оператора',
  createOperator: 'Добавление оператора',

  variablesMap: 'Обязательные параметры',
}
