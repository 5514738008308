import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getUserAgents({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get('/blacklist/user_agent', config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)

      if (errors) {
        useErrorNotification(errors)
      }
    }
  },

  async getUserAgentById(_, id) {
    try {
      const response = await http.get(`/blacklist/user_agent/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
    }
  },

  async addUserAgent(_, params) {
    try {
      return await http.post('/blacklist/user_agent', params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async updateUserAgent(_, params) {
    try {
      return await http.put(`/blacklist/user_agent/${params.id}`, params)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },

  async deleteUserAgent(_, id) {
    try {
      return await http.delete(`/blacklist/user_agent/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async importUserAgents(_, file) {
    try {
      return await http.post('/blacklist/user_agent/upload', file)
    } catch (error) {
      errorHandler(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
