import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getInternalVariables({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)
    try {
      const response = await http.get('/internal_variables', config)
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
