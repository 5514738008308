<template>
  <div>
    <div v-if="!loading && rows.length">
      <div class="flex flex-row items-center flex-wrap gap-y-2 gap-x-2 mt-3">
        <span
          v-for="(table, tableName) of { ...tables.vertical, ...tables.horizontal }"
          :key="tableName"
          @click="scrollToTable(`scroll_${tableName}`)"
          class="px-2 py-1.5 rounded-md select-none bg-white hover:bg-gray-100 active:bg-gray-200 hover:border-gray-400 border text-gray-700 hover:text-gray-800 cursor-pointer intro-x flex items-center"
        >
          <div
            v-if="tableName !== 'requests'"
            class="absolute -top-1 -right-1 text-[0.66rem] leading-3 font-medium px-1 rounded-md select-none"
            :class="table.data.length === 0 ? 'bg-orange-200 text-orange-900' : 'bg-blue-200 text-blue-900'"
          >
            {{ table.data.length }}
          </div>
          <component :is="table.icon" class="w-4 h-4 mr-1.5" />
          {{ table.title }}
        </span>
      </div>

      <div
        class="mt-3 box intro-x"
        v-for="(name, tableName) of tables.vertical"
        :key="tableName"
        :id="`scroll_${tableName}`"
      >
        <div
          class="font-medium p-3 uppercase flex items-center bg-white border-b rounded-t-md"
          :class="{ 'sticky top-0 z-[100]': tables.vertical[tableName].data.length }"
        >
          <component :is="tables.vertical[tableName].icon" class="w-5 h-5 mr-2" />
          {{ tables.vertical[tableName].title }}
        </div>

        <div class="p-3 overflow-x-auto overflow-y-auto">
          <div v-if="!tables.vertical[tableName].data.length">
            <div class="alert bg-theme-12 bg-opacity-40 show flex items-center">
              <AlertCircleIcon class="w-6 h-6 mr-2" />
              {{ t('vTable.noDataMessage') }}
            </div>
          </div>

          <table v-else class="w-full">
            <tbody>
              <tr
                v-for="item of tables.vertical[tableName].data"
                :key="item.key"
                class="border-opacity-60 border-gray-300 hover:bg-gray-100 odd:bg-gray-100 odd:bg-opacity-40 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600"
              >
                <td class="px-2.5 py-2.5 border font-semibold text-gray-700">{{ item.name.label }}</td>
                <td class="px-2.5 py-2.5 border">{{ item.value || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        v-for="(name, tableName) of tables.horizontal"
        :key="tableName"
        class="mt-3 box intro-x"
        :id="`scroll_${tableName}`"
      >
        <div
          class="p-3 flex items-center border-b bg-white rounded-t-md"
          :class="{ 'sticky top-0 z-[100]': tables.horizontal[tableName].data.length }"
        >
          <div class="font-medium uppercase">
            <component :is="tables.horizontal[tableName].icon" class="w-5 h-5 mr-2" />
            {{ tables.horizontal[tableName].title }}
          </div>

          <div v-if="tables.horizontal[tableName].data.length" class="ml-auto uppercase text-xs">
            {{ `${t('query.qty')} ${tables.horizontal[tableName].data.length}` }}
          </div>
        </div>

        <div class="p-3 overflow-x-auto overflow-y-auto">
          <div v-if="!tables.horizontal[tableName].data.length">
            <div class="alert bg-theme-12 bg-opacity-40 show flex items-center">
              <AlertCircleIcon class="w-6 h-6 mr-2" />
              {{ t('vTable.noDataMessage') }}
            </div>
          </div>
          <table v-else-if="tables.horizontal[tableName].data.length === 1" class="w-full">
            <tbody>
              <tr
                v-for="(item, key) of tables.horizontal[tableName].data[0]"
                :key="key"
                class="border-opacity-60 border-gray-300 hover:bg-gray-100 odd:bg-gray-100 odd:bg-opacity-40 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600"
              >
                <td class="px-2.5 py-2.5 border font-semibold text-gray-700">
                  {{ headers[key] || key }}
                </td>
                <td class="px-2.5 py-2.5 border">{{ item || '—' }}</td>
              </tr>
            </tbody>
          </table>
          <VTable
            v-else
            :columns="tables.horizontal[tableName].headers"
            :data="tables.horizontal[tableName].data"
            :hide-buttons="['edit', 'delete']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const EMPTY_ID = 0

export default defineComponent({
  name: 'QueryRequestCard',
  props: {
    headers: {
      type: Object,
      required: true,
    },
    rows: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const tables = ref({
      vertical: {
        requests: {
          icon: 'BookmarkIcon',
          title: t('query.requests'),
          data: [],
        },
      },
      horizontal: {
        request_trafficbacks: {
          icon: 'BarChart2Icon',
          title: t('query.requestTrafficbacks'),
          headers: {},
          data: [],
        },
        request_landing_redirects: {
          icon: 'LayoutIcon',
          title: t('query.requestLandingRedirects'),
          headers: {},
          data: [],
        },
        request_content_redirects: {
          icon: 'ServerIcon',
          title: t('query.requestContentRedirects'),
          headers: {},
          data: [],
        },
        provider_events: {
          icon: 'BarChartIcon',
          title: t('query.providerEvents'),
          headers: {},
          data: [],
        },
        partner_events: {
          icon: 'BellIcon',
          title: t('query.partnerEvents'),
          headers: {},
          data: [],
        },
        subscribers: {
          icon: 'UsersIcon',
          title: t('query.subscribers'),
          headers: {},
          data: [],
        },
        subscribers_log_events: {
          icon: 'ActivityIcon',
          title: t('query.subscribersLogEvents'),
          headers: {},
          data: [],
        },
        receipts: {
          icon: 'FileTextIcon',
          title: t('query.receipts'),
          headers: {},
          data: [],
        },
        request_subscriptions: {
          icon: 'GitPullRequestIcon',
          title: t('query.requestSubscriptions'),
          headers: {},
          data: [],
        },
      },
    })

    watch(
      () => props.rows,
      () => {
        clearTable()
        genrateCard()
      },
      { deep: true }
    )

    const genrateCard = () => {
      verticalDataPreparation()
      horizontalDataPreparation()
    }

    const verticalDataPreparation = () => {
      const row = props.rows[0] || []

      for (const [key, value] of Object.entries(row)) {
        Object.keys(tables.value.vertical).forEach(tableName => {
          if (key.includes(tableName)) {
            const filedId = `${tableName}_request_id`

            if (filedId in props.rows[0] && props.rows[0][filedId] === EMPTY_ID) {
              return
            }

            tables.value.vertical[tableName].data.push({
              key: key,
              name: props.headers[key] || key,
              value: value,
            })
          }
        })
      }
    }

    const horizontalDataPreparation = () => {
      const row = props.rows[0] || []

      Object.keys(tables.value.horizontal).forEach(tableName => {
        if (!row[tableName]?.length) {
          return
        }

        Object.keys(row[tableName][0]).forEach(key => {
          tables.value.horizontal[tableName].headers[key] = props.headers[key] || key
        })

        tables.value.horizontal[tableName].data = row[tableName]
      })
    }

    const clearTable = () => {
      Object.keys(tables.value.vertical).forEach(tableName => {
        tables.value.vertical[tableName].data = []
      })

      Object.keys(tables.value.horizontal).forEach(tableName => {
        tables.value.horizontal[tableName].data = []
      })
    }

    const scrollToTable = table => {
      let element = document.getElementById(table)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return {
      t,
      tables,
      scrollToTable,
    }
  },
})
</script>
