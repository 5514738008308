import { parseNumber } from './number.ts'

const TEN_THOUSAND = 10000

export const convertAmountToSend = value => {
  const sum = parseNumber(value)
  if (!sum) {
    return ''
  }

  const convertSum = Math.trunc(Number(sum) * TEN_THOUSAND)

  if (Number.isNaN(convertSum)) {
    console.error('Amount conversion error:', sum)
    return ''
  }

  return String(convertSum)
}

export const convertAmountToDisplay = value => {
  const sum = parseNumber(value)
  if (!sum) {
    return ''
  }

  const convertSum = Number(sum) / TEN_THOUSAND

  if (Number.isNaN(convertSum)) {
    console.error('Amount conversion error:', sum)
    return ''
  }

  return String(convertSum)
}
