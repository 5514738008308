<template>
  <div>
    <VTable :columns="table.columns" :data="table.data" :hideButtons="['edit', 'delete']" />

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" @click="$emit('close')">
        {{ t('offerWall.close') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { convertAmountToDisplay } from '@/utils/amountConverter'
import { parseNumber } from '@/utils/number.ts'
import { useTranslation } from '@/hooks/useTranslation'

export default defineComponent({
  name: 'MyCampaignsDeductions',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const translation = useTranslation()

    const isCpa = () => props.campaign.payment_model?.name === 'CPA'
    const isRevShare = () => !isCpa()

    const table = ref({
      columns: getColumns(),
      data: [],
    })

    const operators = ref([])
    const campaignOperatorIds = ref([])

    function getColumns() {
      switch (true) {
        case isCpa():
          return {
            operator: t('campaign.operator'),
            cpa_partner_rate: t('campaign.payout_cpa'),
          }
        case isRevShare():
          return {
            operator: t('campaign.operator'),
            rsh_partner_rate: t('campaign.payout_revshare'),
          }
      }
    }

    const generateTable = async () => {
      await getOperators()
      await converCpaRate()
      await getOperatorServices()
    }

    const converCpaRate = async () => {
      operators.value.forEach(operator => {
        const cpaSum = operator.cpa_partner_rate?.sum
        if (cpaSum) {
          operator.cpa_partner_rate.sum = convertAmountToDisplay(cpaSum)
        }
      })
    }

    const getOperators = async () => {
      operators.value = []
      campaignOperatorIds.value = []

      const campaingOperators = await store.dispatch('myCampaigns/getOperatorsByCampaign', props.campaign.id)
      const offerOperators = await store.dispatch('offerWall/getOperatorsByOffer', props.campaign.offer.id)

      campaingOperators.data.operators.forEach(operator => {
        campaignOperatorIds.value.push(`o${operator.operator.id}_s${operator.service.id}`)
      })

      if (offerOperators?.data) {
        offerOperators.data.operators.forEach(operator => {
          const offerOperatorServiceId = `o${operator.operator.id}_s${operator.service.id}`
          const isPersonalPayOut = () => campaignOperatorIds.value.includes(offerOperatorServiceId)

          if (isPersonalPayOut()) {
            campaingOperators.data.operators.forEach(row => {
              const campaignOperatorServiceId = `o${row.operator.id}_s${row.service.id}`

              if (campaignOperatorServiceId === offerOperatorServiceId) {
                operators.value.push({ ...row, personal_payout: true })
              }
            })
          } else {
            operators.value.push(operator)
          }
        })
      }
    }

    const getOperatorServices = async () => {
      operators.value.forEach(async row => {
        const operators = await store.dispatch('services/getCabinetOperatorsByService', row.service.id)

        if (operators.data) {
          const operator = operators.data.operators.find(operator => row.operator.id == operator.operator.id)

          if (operator) {
            switch (true) {
              case isCpa(): {
                const partnerCpaRateCurrency = await store.dispatch(
                  'currency/getCurrencyById',
                  row.cpa_partner_rate.currency
                )
                const partnerCurrency = partnerCpaRateCurrency?.data.iso_name || ''

                table.value.data.push({
                  operator: `${row.operator.name} (${translation.getCountry(row.operator.country)})`,
                  cpa_partner_rate: `${row.cpa_partner_rate?.sum} ${partnerCurrency}`,
                })

                break
              }
              case isRevShare(): {
                const currencyRsh = await store.dispatch('currency/getCurrencyById', operator.rsh_rate.currency)
                const sigmaRate = convertAmountToDisplay(operator.rsh_rate.sum)
                const percentRs = parseNumber(row.rsh_partner_rate)
                const rateRs = sigmaRate && percentRs ? (sigmaRate / 100) * percentRs : ''
                const truncRateRs = Math.trunc(rateRs * 10000) / 10000 || ''

                table.value.data.push({
                  operator: `${row.operator.name} (${translation.getCountry(row.operator.country)})`,
                  rsh_partner_rate: `${truncRateRs || ''} ${currencyRsh?.data.iso_name || ''}`,
                })

                break
              }
            }
          }
        }
      })
    }

    generateTable()

    return {
      t,
      table,
    }
  },
})
</script>
