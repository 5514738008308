export const enDomain = {
  id: 'ID',
  name: 'Name',
  createdAt: 'Setup date',
  url: 'Domain Url',
  status: 'Status',
}

export const ruDomain = {
  id: 'ID',
  name: 'Название',
  createdAt: 'Дата создания',
  url: 'URL домена',
  status: 'Статус',
}
