<template>
  <div class="form-check">
    <input
      type="checkbox"
      class="form-check-input border mr-2"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      :id="uuid"
    />
    <label class="cursor-pointer select-none" :for="uuid" v-if="label"> {{ t(label) }}</label>
  </div>
</template>

<script>
import UniqueID from '@/utils/UniqueID.js'
import { useI18n } from 'vue-i18n'
export default {
  props: {
    label: { type: String, default: '' },
    modelValue: { type: Boolean, default: false },
  },
  setup() {
    const uuid = UniqueID().getID()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    return {
      uuid,
      t,
    }
  },
}
</script>
