<template>
  <div class="flex flex-row flex-wrap gap-2">
    <Tippy
      v-for="param in options.variables"
      :key="param.id"
      :content="param.description"
      class="cursor-pointer bg-gray-100 hover:bg-gray-200 rounded py-1 px-3 border border-gray-400 select-none"
      :class="{
        'bg-green-200 hover:bg-green-300 border-green-400 text-green-800 font-medium': param.active,
      }"
      @click="handlerParams(param)"
    >
      <span class="px-1">{{ param.name }}</span>
    </Tippy>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TheUrlVariablesMap',
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Object,
      default: () => {
        return { admin: true }
      },
    },
  },

  setup(props, context) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const options = ref({
      variables: [],
    })

    const handlerParams = param => {
      const url = props.modelValue
      const existsParam = url.includes(`{${param.name}}`)

      existsParam ? deleteParam(param) : addParam(param)
    }

    const addParam = param => {
      const url = props.modelValue
      const noParams = !url.includes('?')
      const delimiter = noParams ? '?' : '&'

      context.emit('update:modelValue', `${url}${delimiter}${param.name}={${param.name}}`)
      param.active = true
    }

    const deleteParam = param => {
      const [url, parameters] = props.modelValue.split('?')
      const paramsList = parameters?.split('&')
      const clearedParams = paramsList?.filter(value => !value.includes(`{${param.name}}`)).join('&')
      const returnUrl = clearedParams ? `${url}?${clearedParams}` : url

      context.emit('update:modelValue', returnUrl)
      param.active = false
    }

    const generateSourceParams = async () => {
      options.value.variables?.forEach(param => {
        const url = props.modelValue
        const displayParam = `{${param.name}}`
        const activeParam = url.includes(displayParam)

        param.active = activeParam
      })
    }

    const getInternalVariables = async () => {
      const variables = await store.dispatch('internalVariables/getInternalVariables', { full: true, ...props.type })
      options.value.variables = variables ? variables : []
      generateSourceParams()
    }

    watch(
      () => props.modelValue,
      () => generateSourceParams()
    )

    getInternalVariables()

    return {
      t,
      options,
      handlerParams,
    }
  },
})
</script>
