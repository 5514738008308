import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getProviders({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/providers`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getProvider(_, id) {
    try {
      const response = await http.get(`/providers/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async createProvider(_, payload) {
    try {
      const response = await http.post(`/providers`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateProvider(_, payload) {
    try {
      const response = await http.put(`/providers/${payload.id}`, payload)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async deleteProvider(_, id) {
    try {
      const response = await http.delete(`/providers/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreProvider(_, id) {
    try {
      await http.put(`/providers/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Operator Alias
  async getOperatorAlias(_, id) {
    try {
      const response = await http.get(`/provider_operator_alias?provider_id=${id}`)
      return response.data.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async createOperatorAlias(_, params) {
    try {
      const response = await http.post('/provider_operator_alias', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateOperatorAlias(_, params) {
    try {
      const response = await http.put(`/provider_operator_alias/${params.id}`, {
        provider_alias: params.provider_alias,
        operator_id: params.operator_id,
      })
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOperatorAlias(_, id) {
    try {
      const response = await http.delete(`/provider_operator_alias/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Callback Events
  async getCallbackEvents(_, id) {
    try {
      const response = await http.get(`/provider_callback_events?provider_id=${id}`)
      return response.data.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async createCallbackEvent(_, params) {
    try {
      const response = await http.post('/provider_callback_events', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateCallbackEvent(_, params) {
    try {
      const response = await http.put(`/provider_callback_events/${params.id}`, {
        provider_event: params.provider_event,
        callback_event_id: params.callback_event_id,
      })
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteCallbackEvent(_, id) {
    try {
      const response = await http.delete(`/provider_callback_events/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  //Trafficback Reasons
  async getTrafficbackReasons(_, id) {
    try {
      const response = await http.get(`/provider_trafficback_reasons?provider_id=${id}`)
      return response.data.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async createTrafficbackReason(_, params) {
    try {
      const response = await http.post('/provider_trafficback_reasons', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateTrafficbackReason(_, params) {
    try {
      const response = await http.put(`/provider_trafficback_reasons/${params.id}`, {
        provider_reason: params.provider_reason,
        trafficback_reason_id: params.trafficback_reason_id,
      })
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteTrafficbackReason(_, id) {
    try {
      const response = await http.delete(`/provider_trafficback_reasons/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  //Callback Responce
  async getCallbackResponces(_, id) {
    try {
      const response = await http.get(`/provider_callback_responses?provider_id=${id}`)
      return response.data.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async createCallbackResponce(_, params) {
    try {
      const response = await http.post('/provider_callback_responses', params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateCallbackResponce(_, params) {
    try {
      const response = await http.put(`/provider_callback_responses/${params.id}`, {
        response_body: params.response_body,
        response_code: params.response_code,
      })
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteCallbackResponce(_, id) {
    try {
      const response = await http.delete(`/provider_callback_responses/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
