<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link :to="{ path: '/' }" class="-intro-x flex items-center pt-5 select-none">
            <img alt="Sigma" class="w-40" :src="require(`@/assets/images/logo.png`).default" />
          </router-link>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16 select-none"
              :src="require(`@/assets/images/sing_in.png`).default"
            />
            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
              {{ t('auth.bigTextUp') }}
            </div>
            <div class="-intro-x text-white font-medium text-4xl leading-tight ">
              {{ t('auth.bigTextDown') }}
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
              {{ t('auth.smallText') }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <form @submit.prevent="submitLogin" class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              {{ t('auth.logIn') }}
            </h2>

            <h3 v-if="errorMessage" class="text-theme-6">{{ errorMessage }}</h3>

            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              {{ t('auth.fullText') }}
            </div>

            <div class="intro-x mt-8">
              <div :class="{ 'text-theme-6': v$.email.$errors.length }">
                <BaseInput
                  class="sm:min-w-[320px]"
                  v-model="formData.email"
                  label="auth.email"
                  type="email"
                  iconLeft="MailIcon"
                  :required="true"
                  :errors="v$.email.$errors"
                />
              </div>

              <div :class="{ 'text-theme-6': v$.password.$errors.length }">
                <BaseInput
                  class="sm:min-w-[290px]"
                  v-model="formData.password"
                  label="auth.password"
                  type="password"
                  iconLeft="LockIcon"
                  :errors="v$.password.$errors"
                  :required="true"
                />
              </div>
            </div>
            <div class="intro-x flex text-blue-800 text-xs sm:text-sm mt-4">
              <router-link :to="{ name: 'forgot-password' }">{{ t('auth.forgotPassword') }}</router-link>
            </div>
            <div class="intro-x mt-4 xl:mt-8 text-center xl:text-left">
              <button
                @click="openSingUpForm"
                type="button"
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 xl:mr-3 mb-3 xl:mt-0 align-top"
              >
                {{ t('auth.registration') }}
              </button>

              <button type="submit" class="btn btn-primary py-3 px-4 w-full xl:w-32 align-top">
                <LogInIcon class="w-4 h-4 mr-2" />
                {{ t('auth.logIn') }}
              </button>
            </div>
          </div>
        </form>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DarkModeSwitcher },
  setup() {
    const store = useStore()
    const router = useRouter()
    const formData = reactive({ email: '', password: '' })
    let errorMessage = ref('')

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = {
      password: {
        required: helpers.withMessage(t('auth.enterPassword'), required),
      },
      email: {
        required: helpers.withMessage(t('auth.enterEmail'), required),
        email: helpers.withMessage(t('auth.invalidEmailFormat'), email),
      },
    }

    const v$ = useVuelidate(rules, formData)

    const submitLogin = async () => {
      const result = await v$.value.$validate()
      if (!result) {
        return
      }

      const response = await store.dispatch('auth/sendLoginParams', formData)

      if (response?.data?.token) {
        const splitToken = response.data.token ? response.data.token.split(' ')[1] : null
        localStorage.setItem('sigma-token', splitToken)

        router.push({
          path: '/',
        })
      }

      switch (response?.response?.data?.message) {
        case 'Invalid email/password':
          errorMessage.value = t('auth.invalidUsernameOrPassword')
          return
        case 'User has not been moderated yet':
          errorMessage.value = 'Ваша заявка на регистрацию зарегистрирована, пожалуйста ожидайте'
          return
        case 'User is blocked':
          errorMessage.value = 'Произошла ошибка, пожалуйста, повторите попытку позже'
          return
      }
    }

    const openSingUpForm = () => {
      router.push({
        name: 'register',
      })
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return {
      formData,
      openSingUpForm,
      submitLogin,
      errorMessage,
      v$,
      t,
    }
  },
})
</script>
