import { api } from './index'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getTrafficbacks({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await api.get(`/cabinet/trafficbacks`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getTrafficbacksById({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/cabinet/trafficbacks/${id}`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateTrafficbacks({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.put(`/cabinet/trafficbacks/${params.id}`, params, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createTrafficbacks({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    try {
      const response = await api.post(`/cabinet/trafficbacks`, params, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteTrafficbacks({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    await api.delete(`/cabinet/trafficbacks/${id}`, config)
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
