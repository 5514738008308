import Velocity from 'velocity-animate'

// Setup side menu
const findActiveMenu = (subMenu, route) => {
  const routeName = getRouteName(route)
  let match = false

  subMenu.forEach(item => {
    if (item.pageName === routeName && !item.ignore) {
      match = true
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route)
    }
  })
  return match
}

const nestedMenu = (menu, route) => {
  const routeName = getRouteName(route)

  menu.forEach((item, key) => {
    if (typeof item !== 'string') {
      let menuItem = menu[key]
      menuItem.active =
        (item.pageName === routeName || (item.subMenu && findActiveMenu(item.subMenu, route))) && !item.ignore

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route)
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route),
        }
      }
    }
  })

  return menu
}

const linkTo = (menu, router, event, middleClick = false) => {
  if (menu.subMenu?.length > 0) {
    menu.activeDropdown = !menu.activeDropdown
  } else {
    event.preventDefault()

    const isReport = menu.pageName.includes('report/')
    const isQuery = menu.pageName.includes('query/')

    if (isReport || isQuery) {
      const [name, id] = menu.pageName.split('/')

      if (middleClick) {
        let route = router.resolve({ name: name, params: { id: id, transitionFromMenu: true } })
        window.open(route.href, '_blank')

        return
      }

      router.push({
        name: name,
        params: { id: id, transitionFromMenu: true },
      })
    } else {
      if (middleClick) {
        let route = router.resolve({ name: menu.pageName })
        window.open(route.href, '_blank')

        return
      }

      router.push({
        name: menu.pageName,
      })
    }
  }
}

const getRouteName = route => {
  if (route.params?.id) {
    return [route.name, route.params.id].join('/')
  }

  return route.name
}

const enter = (el, done) => {
  Velocity(el, 'slideDown', { duration: 300 }, { complete: done })
}

const leave = (el, done) => {
  Velocity(el, 'slideUp', { duration: 300 }, { complete: done })
}

export { nestedMenu, linkTo, enter, leave }
