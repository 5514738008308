import i18n from '@/i18n'

export const useTranslation = () => {
  const { locale } = i18n.global

  const options = [
    { id: 1, name: 'Русский' },
    { id: 2, name: 'English' },
  ]

  const getLanguageId = code => {
    switch (code) {
      case 'ru-RU':
        return 1
      case 'en-EN':
        return 2
      default:
        return 1
    }
  }

  const getTranslationId = locale => {
    switch (locale) {
      case 'ru':
        return 1
      case 'en':
        return 2
      default:
        return 1
    }
  }

  const getCountry = country => {
    switch (locale.value) {
      case 'ru':
        return country?.ru_name
      case 'en':
        return country?.en_name
      default:
        return country?.ru_name
    }
  }

  const getCountryOptionName = () => {
    switch (locale.value) {
      case 'ru':
        return 'ru_name'
      case 'en':
        return 'en_name'
      default:
        return 'ru_name'
    }
  }

  return {
    options,
    getLanguageId,
    getTranslationId,
    getCountry,
    getCountryOptionName,
  }
}
