<template>
  <div class="v-field-input">
    <label class="block mt-3.5 mb-0.5" :for="uuid" v-if="t(label)">
      {{ t(label) }}
      <span v-if="required" class="text-theme-6">*</span>
    </label>

    <div :class="{ 'input-group': inputGroup || iconLeft || type === 'password' }">
      <div
        v-if="iconLeft"
        class="select-none pl-2 pr-1 py-2 border-t border-b border-l rounded-l h-[40px]"
        :class="{ 'bg-gray-200 bg-opacity-40 cursor-not-allowed': disabled, 'border-theme-6': errors.length }"
      >
        <component :is="iconLeft" class="w-5 h-5 text-gray-600" />
      </div>

      <slot name="inputGroupLeft"></slot>

      <input
        v-bind="$attrs"
        :disabled="disabled"
        :type="inputType"
        :placeholder="t(placeholder) || t(label)"
        class="form-control py-3 px-3 border-gray-300 block v-field-input__input"
        :class="{
          'border-l-0 pl-1': iconLeft,
          'border-r-0': type === 'password',
          'border-theme-6': errors.length,
        }"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="uuid"
      />

      <slot name="inputGroup"></slot>

      <div
        v-if="type === 'password'"
        class="select-none pr-2 py-1.5 border-t border-b border-r rounded-r h-[40px]"
        :class="{ 'bg-gray-200 bg-opacity-40 cursor-not-allowed': disabled, 'border-theme-6': errors.length }"
      >
        <EyeIcon
          v-if="inputType === 'password' && modelValue.length"
          @click="inputType = 'text'"
          class="w-6 h-6 text-gray-600 hover:text-gray-700 cursor-pointer"
        />

        <EyeOffIcon
          v-if="inputType === 'text' && modelValue.length"
          @click="inputType = 'password'"
          class="w-6 h-6 text-gray-600 hover:text-gray-700 cursor-pointer"
        />
      </div>
    </div>

    <div v-for="error of errors" :key="error.$uid" class="text-xs text-theme-6 ml-1 v-field-input__intro-error">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import UniqueID from '@/utils/UniqueID.js'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    label: {
      type: String,
      default: 'baseInput.label',
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'baseInput.placeholder',
    },
    inputGroup: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    iconLeft: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const uuid = UniqueID().getID()
    const inputType = ref(props.type)

    return {
      uuid,
      inputType,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-field-input {
  &__input {
    height: 40px;
  }

  &__intro-error {
    z-index: 48;
    position: relative;
    opacity: 0;
    transform: translateY(-9px);
    -webkit-animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    animation: 0.2s intro-y-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .form-control {
    box-shadow: none;
  }
}
</style>
