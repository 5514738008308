export const enCountries = {
  id: 'ID',
  name: 'Name',
  regions: 'Regions',
  countries: 'Countries',
  editRegion: 'Edit Region',
  alpha_2_code: 'Alpha code',
  iso_code: 'ISO code',
  currency: 'Currency',
  save: 'Save',
  cancel: 'Close',
}

export const ruCountries = {
  id: 'ID',
  name: 'Название',
  regions: 'Регионы',
  countries: 'Страны',
  editRegion: 'Редактировать регион',
  alpha_2_code: 'Alpha код',
  iso_code: 'ISO код',
  currency: 'Валюта',
  save: 'Сохранить',
  cancel: 'Закрыть',
}
