import { errorHandler } from '@/api/utils/ErrorHandler'
import { api } from './index'

const state = {}

const actions = {
  async getForbiddenSources({ dispatch }) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/forbidden_sources`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.log(error)
    }
  },
  async getForbiddenSourcesById({ dispatch }, id) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })
    try {
      const response = await api.get(`/forbidden_sources/${id}`, config)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
