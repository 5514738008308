export const enOperators = {
  addButtonOperator: 'Add operator',
  addOperator: 'Add operator',
  editOperator: 'Edit operator',
  copyOperator: 'Copy operator',

  confirmDialog: {
    title: 'Deleting a Operator',
    message: 'Do you really want to remove the Operator?',
  },

  id: 'ID',
  name: 'Name',
  alias: 'Alias',
  created_at: 'Created at',

  country: 'Country',
  additionalAliases: 'Additional aliases',
  addAlias: 'Add alias',

  save: 'Save',
  cancel: 'Cancel',
}

export const ruOperators = {
  addButtonOperator: 'Добавить оператора',
  addOperator: 'Добавление оператора',
  editOperator: 'Редактирование оператора',
  copyOperator: 'Скопировать оператора',

  confirmDialog: {
    title: 'Удаление оператора',
    message: 'Вы действительно хотите удалить оператора?',
  },

  country: 'Страна',
  additionalAliases: 'Дополнительные алиасы',
  addAlias: 'Добавить алиас',

  id: 'ID',
  name: 'Название',
  alias: 'Псевдоним',
  created_at: 'Дата создания',

  save: 'Сохранить',
  cancel: 'Отменить',
}
