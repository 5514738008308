<template>
  <div class="v-pagination">
    <div class="flex items-center justify-between flex-wrap gap-y-6">
      <div class="flex items-center">
        <span class="mr-2">{{ t('vTable.pageSize') }}</span>

        <select v-model="selectedPageSize" @change="updatePageSize" class="w-20 form-select sm:mt-0">
          <option v-for="item in optionsPageSize" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="flex items-center">
        <div class="mr-1" :class="{ 'cursor-pointer v-pagination__chevron': currentPage !== 1 }" @click="prev">
          <ChevronsLeftIcon class="w-6 h-6" />
        </div>

        <div class="flex items-center text-base select-none flex-nowrap">
          <div
            v-for="item in paginationInit(currentPage, totalPages)"
            :key="item"
            @click="changePage(item)"
            class="mx-1 px-2 sm:px-3 py-1 rounded-xl"
            :class="{
              'bg-theme-3 text-white font-bold': currentPage === item,
              'cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700': currentPage !== item && item !== '...',
            }"
          >
            {{ item }}
          </div>
        </div>

        <div class="ml-1" :class="{ 'cursor-pointer v-pagination__chevron': currentPage !== totalPages }" @click="next">
          <ChevronsRightIcon class="w-6 h-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { scrollTop } from '@/utils/scroll.js'

export default defineComponent({
  name: 'VPagination',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    optionsSize: {
      type: Array,
      default: () => [5, 15, 30, 100],
    },
  },

  setup(props, context) {
    const currentPage = ref(0)
    const totalPages = ref(null)
    const selectedPageSize = ref(15)
    const optionsPageSize = props.optionsSize

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    watch(
      () => props.pagination,
      pagination => {
        totalPages.value = pagination.last_page
        currentPage.value = pagination.current_page
        selectedPageSize.value = pagination.per_page
      },
      { deep: true, immediate: true }
    )

    const paginationInit = (currentPage, totalPages) => {
      const delta = 2
      const range = [1]
      const rangeWithDots = []
      let l

      if (totalPages <= 1) {
        return range
      }
      for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < totalPages && i > 1) {
          range.push(i)
        }
      }
      range.push(totalPages)
      for (const j of range) {
        if (l) {
          if (j - l === 2) {
            rangeWithDots.push(l + 1)
          } else if (j - l !== 1) {
            rangeWithDots.push('...')
          }
        }
        rangeWithDots.push(j)
        l = j
      }
      return rangeWithDots
    }

    const prev = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1
        context.emit('send-page', {
          page: currentPage.value,
          per_page: selectedPageSize.value,
        })

        scrollTop()
      }
    }

    const next = () => {
      if (currentPage.value <= totalPages.value - 1) {
        currentPage.value += 1
        context.emit('send-page', {
          page: currentPage.value,
          per_page: selectedPageSize.value,
        })

        scrollTop()
      }
    }

    const changePage = page => {
      if (page === currentPage.value) {
        return
      }

      if (typeof page === 'number') {
        currentPage.value = page
        context.emit('send-page', {
          page: currentPage.value,
          per_page: selectedPageSize.value,
        })

        scrollTop()
      }
    }

    const updatePageSize = () => {
      currentPage.value = 1
      context.emit('send-page', { per_page: selectedPageSize.value })
    }

    return {
      selectedPageSize,
      optionsPageSize,
      paginationInit,
      prev,
      next,
      changePage,
      updatePageSize,
      currentPage,
      totalPages,
      t,
    }
  },
})
</script>

<style lang="scss">
.v-pagination {
  &__chevron {
    transition: 0.5s;

    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transition: 0.1s;
      transform: scale(1);
    }
  }
}
</style>
