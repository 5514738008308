<template>
  <div class="v-add-campaign-form">
    <form @submit.prevent="handleSubmit">
      <BaseInput
        label="offerWall.name"
        name="name"
        :required="true"
        v-model="campaignForm.name"
        :errors="v$.name.$errors"
      />

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-8"
          label="offerWall.offer"
          :options="options.offers"
          :required="true"
          v-model="campaignForm.offer_id"
          :errors="v$.offer_id.$errors"
          :showId="true"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="offerWall.trafficSource"
          :options="options.sources"
          :required="true"
          v-model="campaignForm.source_id"
          :errors="v$.source_id.$errors"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-8"
          label="offerWall.callback"
          :options="options.callbacks"
          optionName="base_url"
          v-model="campaignForm.callback_id"
        />

        <div class="sm:w-2/4" />
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
          {{ t('offerWall.cancel') }}
        </button>
        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('offerWall.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'AddCampaignForm',
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()

    const campaignForm = ref({
      name: '',
      offer_id: props.offer.id,
      source_id: '',
      callback_id: '',
      enrollment_rule_id: props.offer.enrollment_rule?.id,
      trafficback_id: 1,
      payment_model_id: 1,
      domain_id: 3,
    })

    const options = ref({
      offers: [],
      sources: [],
      callbacks: [],
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        offer_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        source_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, campaignForm)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      if (!campaignForm.value.callback_id) {
        delete campaignForm.value.callback_id
      }

      const campaign = await store.dispatch('myCampaigns/addCampaign', campaignForm.value)

      if (campaign) {
        const errors = campaign.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('close-modal')
        context.emit('openLandingDialog', campaign.data.data.id)
      }
    }

    const generateCampaignTitle = async () => {
      let partnerName = ''
      let offerName = ''
      const date = new Date().toLocaleDateString('ru-RU')
      const token = store.state.auth?.accessToken

      const userId = await store.dispatch('auth/getUser', token)
      if (userId.data?.id) {
        const user = await store.dispatch('users/getUserById', userId.data?.id)

        if (user.data?.data) {
          partnerName = user.data?.data.name
        }
      }

      const offer = await store.dispatch('offerWall/getOfferById', campaignForm.value.offer_id)
      if (offer.data?.data) {
        offerName = `${offer.data?.data.name} (${offer.data?.data.id})`
      }

      campaignForm.value.name = `${partnerName} / ${offerName} / ${date}`
    }

    watch(
      () => campaignForm.value.offer_id,
      offerId => {
        generateCampaignTitle()
        setEnrollmentRule(offerId)
      }
    )

    const setEnrollmentRule = async offerId => {
      if (!offerId) {
        return
      }

      const offer = await store.dispatch('offerWall/getOfferById', campaignForm.value.offer_id)

      if (offer?.data) {
        campaignForm.value.enrollment_rule_id = offer.data.data.enrollment_rule?.id
      }
    }

    const getOffersOptions = async () => {
      const offers = await store.dispatch('offerWall/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }

    const getSourcesOptions = async () => {
      const sources = await store.dispatch('myTrafficSource/getTrafficSources', { full: false })
      options.value.sources = sources.data ? sources.data : []
    }

    const getCallbacksOptions = async () => {
      const callbacks = await store.dispatch('myTrafficCallbacks/getCallbacks', { full: false })
      options.value.callbacks = callbacks.data ? callbacks.data : []
    }

    generateCampaignTitle()

    getOffersOptions()
    getSourcesOptions()
    getCallbacksOptions()

    return {
      v$,
      campaignForm,
      options,
      handleSubmit,
      t,
    }
  },
})
</script>
