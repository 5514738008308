<template>
  <div class="v-permissions-table mt-1">
    <div v-if="loading" class="v-permissions-table__disabled-block">
      <LoadingIcon class="w-10 h-10" icon="tail-spin" />
    </div>

    <div class="v-permissions-table__all overflow-y-auto overflow-x-auto">
      <table class="table">
        <thead>
          <tr>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input border"
                  @click="selectAll('all')"
                  v-model="allSelectedLeft"
                />
              </div>
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in permissionList.all" :key="permission.id">
            <td v-if="permission.visible !== false">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input border"
                  :value="permission.id"
                  v-model="selectedList.all"
                />
              </div>
            </td>
            <td v-if="permission.visible !== false">
              <div class="flex items-center">
                <Tippy v-if="permission.description" :content="permission.description" class="flex items-center">
                  <InfoIcon class="w-4 h-4 mr-2 cursor-pointer text-theme-3" />
                </Tippy>

                {{ `${permission.controller}::${permission.action}` }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="v-permissions-table__between-container">
      <div>
        <button class="btn m-2" type="button" @click.prevent="addPermissions">
          <ArrowRightIcon class="w-5 h-5" />
        </button>
      </div>
      <div>
        <button class="btn m-2" type="button" @click.prevent="deletePermissions">
          <ArrowLeftIcon class="w-5 h-5" />
        </button>
      </div>
    </div>

    <div class="v-permissions-table__selected overflow-y-auto overflow-x-auto">
      <table class="table">
        <thead>
          <tr>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input border"
                  @click="selectAll('selected')"
                  v-model="allSelectedRight"
                />
              </div>
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in permissionList.selected" :key="permission.id">
            <td>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input border"
                  :value="permission.id"
                  v-model="selectedList.selected"
                />
              </div>
            </td>
            <td>
              <div class="flex items-center">
                <Tippy v-if="permission.description" :content="permission.description" class="flex items-center">
                  <InfoIcon class="w-4 h-4 mr-2 cursor-pointer text-theme-3" />
                </Tippy>

                {{ `${permission.controller}::${permission.action}` }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { errorHandler } from '@/api/utils/ErrorHandler'

export default defineComponent({
  name: 'PermissionsTable',
  props: {
    role: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()

    const permissionList = ref({
      all: [],
      selected: [],
    })

    const selectedList = ref({
      all: [],
      selected: [],
    })

    const selectedIds = ref([])
    const loading = ref(true)

    const allSelectedLeft = ref(false)
    const allSelectedRight = ref(false)

    const getPermissions = () => {
      store
        .dispatch('permissions/getPermissions')
        .then(result => {
          if (result.data?.data) {
            permissionList.value.all = result.data.data
          }
        })
        .finally(() => {
          hideSelectedPermissions()
          loading.value = false
        })
    }

    const addPermissions = async () => {
      loading.value = true

      if (selectedList.value.all.length) {
        const response = await store.dispatch('roles/addRolePermissions', {
          id: props.role.id,
          permissionsIds: selectedList.value.all,
        })

        if (errorHandler(response)) {
          loading.value = false
          return
        }
      }

      permissionList.value.all.forEach((record, index) => {
        const isSelected = selectedList.value.all.includes(record.id)

        if (isSelected) {
          permissionList.value.all[index] = { ...record, visible: false }
          permissionList.value.selected.push(record)
        }
      })

      permissionList.value.selected.sort((a, b) => {
        return a.id - b.id
      })

      updateSelectedIds()
      selectedList.value.all = []
      allSelectedLeft.value = false
      loading.value = false
    }

    const deletePermissions = async () => {
      loading.value = true

      if (selectedList.value.selected.length) {
        const response = await store.dispatch('roles/deleteRolePermissions', {
          id: props.role.id,
          permissionsIds: selectedList.value.selected,
        })

        if (errorHandler(response)) {
          loading.value = false
          return
        }
      }

      for (let i = permissionList.value.selected.length; i--; ) {
        const record = permissionList.value.selected[i]
        const isSelected = selectedList.value.selected.includes(record.id)

        if (isSelected) {
          permissionList.value.all.find((permission, index) => {
            if (permission.id === record.id) {
              permissionList.value.all[index] = { ...permission, visible: true }
            }
          })

          permissionList.value.selected.splice(i, 1)
        }
      }

      updateSelectedIds()
      selectedList.value.selected = []
      allSelectedRight.value = false
      loading.value = false
    }

    const getRolePermissions = () => {
      if (!props.role.id) {
        getPermissions()
        permissionList.value.selected = []
        loading.value = false
        return
      }

      store
        .dispatch('roles/getRolePermissions', props.role.id)
        .then(result => {
          if (result.data?.data) {
            permissionList.value.selected = result.data.data
          }
        })
        .finally(() => {
          updateSelectedIds()
          getPermissions()
        })
    }

    const hideSelectedPermissions = () => {
      permissionList.value.all.forEach((record, index) => {
        const isSelected = selectedIds.value.includes(record.id)

        if (isSelected) {
          permissionList.value.all[index] = { ...record, visible: false }
        }
      })
    }

    const updateSelectedIds = () => {
      selectedIds.value = []

      permissionList.value.selected.forEach(record => {
        selectedIds.value.push(record.id)
      })
    }

    const selectAll = table => {
      if (table === 'all') {
        selectedList.value.all = []

        if (!allSelectedLeft.value) {
          permissionList.value.all.forEach(record => {
            if (record.visible !== false) {
              selectedList.value.all.push(record.id)
            }
          })
        }
      }

      if (table === 'selected') {
        selectedList.value.selected = []

        if (!allSelectedRight.value) {
          permissionList.value.selected.forEach(record => {
            selectedList.value.selected.push(record.id)
          })
        }
      }
    }

    getRolePermissions()

    return {
      permissionList,
      selectedList,
      allSelectedLeft,
      allSelectedRight,
      loading,

      addPermissions,
      deletePermissions,
      selectAll,
    }
  },
})
</script>

<style lang="scss">
.v-permissions-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  max-height: 330px;
  height: 330px;

  &__disabled-block {
    position: absolute;
    height: 330px;
    width: 95%;
    border-radius: 0.375rem;
    background-color: white;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__all,
  &__selected {
    max-height: 335px;
    flex-grow: 1;
    flex-basis: 300px;
  }

  .table td {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-right: 0.1rem;
    border-bottom: 1px solid #eff1f3d2;
  }

  .table th {
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
    padding-right: 0.1rem;
  }

  &__between-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 8px 0 8px;
    border-right: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
  }
}
</style>
