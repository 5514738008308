import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useHandlerParams } from '@/hooks/useHandlerParams'

const state = {}

const actions = {
  async getOffers({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/offers`, config)
      return response
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getOfferById(_, id) {
    try {
      const response = await http.get(`/offers/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addOffer(_, params) {
    try {
      const response = await http.post(`/offers`, params)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateOffer(_, params) {
    try {
      const response = await http.put(`/offers/${params.id}`, params)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOffer(_, id) {
    try {
      const response = await http.delete(`/offers/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreOffer(_, id) {
    try {
      await http.put(`/offers/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  // Categories
  async getCategories() {
    try {
      const response = await http.get('/categories')
      return response.data.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  //  OPERATORS ACTIONS
  async getOperatorsByOffer(_, id) {
    try {
      const response = await http.get(`/offers/${id}/operators`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createOperatorToOffer(_, params) {
    try {
      const response = await http.post(`/offers/${params.offer_id}/operators/${params.operator_id}`, params.data)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async updateOperatorToOffer(_, params) {
    try {
      const response = await http.put(`/offers/${params.offer_id}/operators/${params.operator_id}`, params.data)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteOperatorFromOffer(_, params) {
    try {
      return await http.delete(`/offers/${params.offer_id}/operators/${params.operator_id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  // LANDINGS ACTIONS
  async getLandingsToOffer(_, id) {
    try {
      const response = await http.get(`/offers/${id}/landings`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async addLandingsToOffer(_, params) {
    try {
      const response = await http.post(`/offers/${params.id}/landings`, {
        landings: params.landingsIds,
      })

      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async deleteLandingsFromOffer(_, params) {
    try {
      const response = await http.delete(`/offers/${params.id}/landings`, {
        data: { landings: params.landingsIds },
      })

      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
