<template>
  <div>
    <header class="flex items-center">
      <label class="block mt-3.5 mb-1"> {{ t(title) }}</label>
      <base-checkbox v-if="isCheck" class="flex items-center ml-3" v-model="isSelectMode" />
    </header>
    <div v-if="isSelectMode">
      <TomSelect v-model="selected" class="w-full" multiple>
        <option
          v-for="option of options"
          :value="option.id"
          :key="option.id"
          class="p-3 text-sm shadow-sm border-gray-300 bg-no-repeat rounded cursor-default"
        >
          {{ option.name }}
        </option>
      </TomSelect>
    </div>

    <div v-else :class="{ 'flex sm:flex-row flex-wrap gap-y-1 gap-x-4 sm:gap-x-1': horizontal }">
      <div v-for="option of options" :key="option.id" class="form-check" :class="customClass">
        <input
          v-model="selected"
          type="checkbox"
          class="form-check-input"
          :value="option.id.toString()"
          :id="option.id + option.name"
        />
        <label class="form-check-label cursor-pointer select-none" :for="option.id + option.name" v-if="option.name">
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import BaseCheckbox from '@/global-components/base-checkbox/index.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: () => 'Список',
  },
  isCheck: {
    type: Boolean,
    default: () => false,
  },
  horizontal: {
    type: Boolean,
    default: () => false,
  },
  customClass: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])

const selected = ref([])
const isSelectMode = ref(false)

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

watch(
  () => props.modelValue,
  value => {
    selected.value = value
  },
  { immediate: true }
)

watch(
  () => selected.value,
  value => {
    emit('update:modelValue', value)
  }
)
</script>
