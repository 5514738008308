<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link :to="{ path: '/' }" class="-intro-x flex items-center pt-5 select-none">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-40"
              :src="require(`@/assets/images/logo.png`).default"
            />
            <!-- <span class="text-white text-lg ml-3">
              Ru<span class="font-medium">bick</span>
            </span> -->
          </router-link>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16 select-none"
              :src="require(`@/assets/images/register.png`).default"
            />
            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
              {{ t('auth.bigTextUp') }}
            </div>
            <div class="-intro-x text-white font-medium text-4xl leading-tight ">
              {{ t('auth.bigTextDown') }}
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">
              {{ t('auth.smallText') }}
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <form @submit.prevent="submitRegister" class=" h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <fieldset :disabled="loading" class="relative">
              <legend class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                {{ t('auth.register') }}
              </legend>
              <div
                v-if="loading"
                class="z-50 absolute inset-x-0 top-52 col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="tail-spin" class="w-8 h-8" />
                <div class="text-center text-xs mt-2"></div>
              </div>
              <div class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center">
                {{ t('auth.fullText') }}
              </div>
              <div class="intro-x mt-8">
                <BaseInput
                  label="auth.firstName"
                  v-model="formData.firstName"
                  :required="true"
                  :errors="v$.firstName.$errors"
                  iconLeft="UserIcon"
                />

                <BaseInput
                  :required="true"
                  label="auth.lastName"
                  v-model="formData.lastName"
                  :errors="v$.lastName.$errors"
                  iconLeft="UserIcon"
                />

                <BaseInput
                  type="email"
                  label="auth.email"
                  v-model="formData.email"
                  :required="true"
                  :errors="v$.email.$errors"
                  iconLeft="MailIcon"
                />

                <h3 v-if="errorMessage" class="text-theme-6">
                  {{ errorMessage }}
                </h3>

                <VFieldSelect
                  v-model="formData.messenger"
                  :required="true"
                  label="auth.chooseMessenger"
                  :options="[{ name: 'Telegram' }, { name: 'Skype' }]"
                  returnValue="name"
                  :hideSearch="true"
                  :errors="v$.messenger.$errors"
                />

                <BaseInput
                  v-model="formData.messenger_nickname"
                  label="auth.writeYourNickname"
                  name="nickname"
                  :disabled="!!!formData.messenger"
                  :required="true"
                  :errors="v$.messenger_nickname.$errors"
                  iconLeft="AtSignIcon"
                />

                <BaseInput
                  type="password"
                  label="auth.password"
                  :required="true"
                  v-model="formData.password"
                  :errors="v$.password.$errors"
                  iconLeft="LockIcon"
                />

                <!-- <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-2">
                  <div
                    class="col-span-3 h-full rounded"
                    :class="scopeSecurity >= 1 ? 'bg-red-500' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="col-span-3 h-full rounded"
                    :class="scopeSecurity >= 2 ? 'bg-red-500' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="col-span-3 h-full rounded"
                    :class="scopeSecurity >= 3 ? 'bg-green-500' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="col-span-3 h-full rounded dark:bg-dark-2"
                    :class="scopeSecurity >= 4 ? 'bg-green-500' : 'bg-gray-200'"
                  ></div>
                </div> -->

                <BaseInput
                  type="password"
                  :required="true"
                  label="auth.passwordConfirmation"
                  v-model="formData.password_confirmation"
                  :errors="v$.password_confirmation.$errors"
                  iconLeft="LockIcon"
                />
              </div>
              <!-- <div class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm">
                <base-checkbox v-model="formData.agreeEnvato" label="Я согласен с Envato" />
                <a class="text-theme-1 dark:text-theme-10 ml-1" href="">Privacy Policy</a>.
              </div> -->
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  @click="openLoginForm"
                  type="button"
                  class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 xl:mr-3 mb-3 xl:mt-0 align-top"
                >
                  <LogInIcon class="w-4 h-4 mr-2" />
                  {{ t('auth.logIn') }}
                </button>

                <button type="submit" class="btn btn-primary py-3 px-4 w-full xl:w-32 align-top">
                  {{ t('auth.registration') }}
                </button>
              </div>
            </fieldset>
          </div>
        </form>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default defineComponent({
  components: {
    DarkModeSwitcher,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const formData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      messenger: '',
      messenger_nickname: '',
      password: '',
      password_confirmation: '',
    })

    let errorMessage = ref(null)
    const loading = ref(false)

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        lastName: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        email: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
          email,
        },
        messenger: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        messenger_nickname: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        password: {
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
        password_confirmation: {
          sameAs: helpers.withMessage('Введенные пароли не совпадают', sameAs(formData.password)),
        },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const submitRegister = async () => {
      loading.value = true
      const validate = await v$.value.$validate()

      if (!validate) {
        loading.value = false
        return
      }

      let params = {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      }

      switch (formData.messenger) {
        case 'Telegram':
          params.telegram = formData.messenger_nickname
          break
        case 'Skype':
          params.skype = formData.messenger_nickname
          break
      }

      try {
        await store.dispatch('auth/sendRegisterParams', params)
        router.push({
          name: 'verify-email',
          params: { token: 'token' },
        })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          errorMessage.value = t('auth.mailHasAlreadyBeenSent')
        }
      } finally {
        loading.value = false
      }
    }

    const openLoginForm = () => {
      router.push({
        name: 'login',
      })
    }
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
    return {
      formData,
      submitRegister,
      openLoginForm,
      v$,
      errorMessage,
      loading,
      t,
    }
  },
})
</script>

<style scoped>
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
</style>
