import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import i18n from '@/i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { convertParamsForSend } from './convertParameters'
import { useCustomFilters } from './customFilters'

export const useQuery = () => {
  const { t } = i18n.global
  const store = useStore()
  const options = ref([])
  const customFilters = useCustomFilters()

  /* job response delay time in ms */
  const RESPONCE_DELAY_TIME = 400

  const run = async param => {
    try {
      const query = await getQueryById(param.queryId)
      const jobId = await getJobId(query)

      await startProcessGetJob(jobId, param.name)
    } catch (e) {
      console.error(e)
    }
  }

  const getQueryById = async id => {
    const query = await store.dispatch('reports/getQueryById', id)

    if (!query) {
      throw new Error('Request execution error getQueryById')
    }

    const errors = query.response?.data?.errors
    if (errors) {
      useErrorNotification(errors)
      throw new Error('Request execution error getQueryById')
    }

    return query
  }

  const getJobId = async query => {
    const params = query.options.parameters

    const response = await store.dispatch('reports/getQueryResult', {
      id: query.id,
      parameters: convertParamsForSend(params),
      apply_auto_limit: query.options.apply_auto_limit,
      max_age: 0,
    })

    if (!response) {
      throw new Error('Request execution error getQueryResult')
    }

    const errors = response.response?.data?.errors
    if (errors) {
      useErrorNotification(errors)
      throw new Error('Request execution error getQueryResult')
    }

    return response.job.id
  }

  const startProcessGetJob = async (jobId, name) => {
    const job = await store.dispatch('reports/getJobResult', jobId)

    let timeout = null

    if (job) {
      if (job.job.query_result_id) {
        clearTimeout(timeout)

        await generateDropdownOptions(job.job.query_result_id, name)

        return
      }

      if (job.job.error) {
        clearTimeout(timeout)
        useToast().error(t('query.toastiFy.error'), {
          closeOnClick: false,
        })
        return
      }

      timeout = setTimeout(() => {
        startProcessGetJob(jobId, name)
      }, RESPONCE_DELAY_TIME)
    } else {
      clearTimeout(timeout)
    }
  }

  const generateDropdownOptions = async (resultId = null, name) => {
    if (!resultId) {
      return
    }

    const result = await store.dispatch('reports/getReport', resultId)
    const rows = result.query_result.data.rows || null
    options.value[name] = rows ? rows : []

    customFilters.cloneOption(name, options, rows)
  }

  return {
    run,
    options,
  }
}
