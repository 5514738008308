<template>
  <div class="flex sm:flex-row flex-col mt-3 gap-6">
    <div class="lg:w-2/5 -intro-x">
      <ul class="flex flex-col w-full p-2 border-t border-gray-200 dark:border-dark-5">
        <li @click="activeTab = tabs.profile" class="my-1 cursor-pointer -intro-y">
          <span
            type="button"
            class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600"
            :class="{
              'bg-white text-gray-700 font-medium': activeTab === tabs.profile,
              'hover:bg-gray-100 hover:text-gray-700': activeTab !== tabs.profile,
            }"
          >
            <span class="flex items-center justify-center text-lg">
              <SettingsIcon class="h-6 w-6" />
            </span>
            <span class="ml-3">{{ t('sideMenu.settingProfile') }}</span>
          </span>
        </li>

        <li @click="activeTab = tabs.api" class="my-1 cursor-pointer -intro-y">
          <span
            type="button"
            class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600"
            :class="{
              'bg-white text-gray-700 font-medium': activeTab === tabs.api,
              'hover:bg-gray-100 hover:text-gray-700': activeTab !== tabs.api,
            }"
          >
            <span class="flex items-center justify-center text-lg">
              <DatabaseIcon class="h-6 w-6" />
            </span>
            <span class="ml-3">API</span>
          </span>
        </li>

        <li @click="activeTab = tabs.changePassword" class="my-1 cursor-pointer -intro-y">
          <span
            type="button"
            class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600"
            :class="{
              'bg-white text-gray-700 font-medium': activeTab === tabs.changePassword,
              'hover:bg-gray-100 hover:text-gray-700': activeTab !== tabs.changePassword,
            }"
          >
            <span class="flex items-center justify-center text-lg">
              <RepeatIcon class="h-6 w-6" />
            </span>
            <span class="ml-3">{{ t('sideMenu.changePassword') }}</span>
          </span>
        </li>
      </ul>
    </div>

    <div v-if="activeTab === tabs.profile" class="w-full intro-x">
      <div class="py-3 px-2">
        <h2 class="font-medium text-base">{{ t('sideMenu.settingProfile') }}</h2>
      </div>

      <div class="box p-5">
        <form @submit.prevent="submitProfile">
          <BaseInput type="fio" label="account.fio" iconLeft="UserIcon" v-model="user.name" disabled />

          <BaseInput type="email" label="account.email" iconLeft="MailIcon" v-model="user.email" disabled />

          <BaseInput type="phone" label="account.phone" iconLeft="PhoneIcon" v-model="formData.phone" />

          <BaseInput label="account.telegram" iconLeft="SendIcon" v-model="formData.telegram" />

          <BaseInput label="account.skype" v-model="formData.skype" />

          <VFieldSelect
            label="account.language"
            :options="translation.options"
            v-model="formData.language"
            :hideSearch="true"
          />

          <div class="flex items-center mt-6 justify-end">
            <button type="submit" class="btn btn-primary sm:px-5">
              <LoadingIcon v-if="showLoading" icon="oval" color="white" class="w-4 h-4 mr-1" />
              <SaveIcon v-if="!showLoading" class="w-4 h-4 mr-2" />
              <p>{{ t('account.saveButton') }}</p>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="activeTab === tabs.api" class="w-full intro-x">
      <div class="py-3 px-2">
        <h2 class="font-medium text-base">API</h2>
      </div>

      <div class="box p-5">
        <div v-if="haveAccessApi">
          <div class="mb-6">
            <span class="bg-green-200 text-green-700 text-xs font-medium px-3 py-1 rounded">
              {{ t('account.apiConnected') }}
            </span>
          </div>

          <div class="mb-4">
            <a class="text-blue-700 hover:underline" href="https://sd.api.sigmamobi.com/">
              https://sd.api.sigmamobi.com/
            </a>
          </div>

          <BaseInput label="account.token" v-model="token" disabled :inputGroup="true">
            <template #inputGroup>
              <div
                @click="copyToken(token)"
                class="input-group-text font-medium cursor-pointer hover:bg-gray-200 active:bg-gray-300"
              >
                <Tippy :content="t('account.copyToken')">
                  <CopyIcon class="w-4 h-4" />
                </Tippy>
              </div>
            </template>
          </BaseInput>
        </div>
        <div v-else>
          <div>
            <span class="bg-red-300 text-red-800 text-xs font-medium px-3 py-1 rounded">
              {{ t('account.apiDisabled') }}
            </span>
          </div>
          <div class="mt-6 text-base text-gray-600">{{ t('account.apiDisabledMessage') }}</div>
        </div>
      </div>
    </div>

    <div v-if="activeTab === tabs.changePassword" class="w-full intro-x">
      <div class="py-3 px-2">
        <h2 class="font-medium text-base">{{ t('sideMenu.changePassword') }}</h2>
      </div>

      <ChangePasswordForm :fromAccountProfile="true" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import { useGenerateBreadcrumbs } from '@/hooks/useGenerateBreadcrumbs'
import ChangePasswordForm from './ChangePasswordForm.vue'

export default defineComponent({
  name: 'AccountProfile',
  components: {
    ChangePasswordForm,
  },

  setup() {
    const { t, locale, availableLocales } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const store = useStore()
    const token = computed(() => store.state.auth.accessToken)
    const user = computed(() => store.state.auth.user)
    const translation = useTranslation()
    const toast = useToast()
    const route = useRoute()
    const tabs = {
      profile: 'profile',
      api: 'api',
      changePassword: 'changePassword',
    }
    const activeTab = ref('profile')
    const showLoading = ref(false)
    const haveAccessApi = ref(false)

    const formData = ref({
      phone: '',
      telegram: '',
      skype: '',
      language: 1,
    })

    const getProfile = async () => {
      const profile = await store.dispatch('users/getProfile')

      if (profile) {
        formData.value = {
          phone: profile.phone || '',
          telegram: profile.telegram || '',
          skype: profile.skype || '',
          language: translation.getLanguageId(profile.language) || '',
        }
      }
    }

    const getSelectedRole = async () => {
      const result = await store.dispatch('users/getUserRoles', user.value.id)
      if (result?.data.data.length) {
        result.data.data.forEach(role => {
          /*
           * API access for partners (ID: 13)
           * Роль для доступа к api subscription-dispatcher
           */
          if (role.id === 13) {
            haveAccessApi.value = true
          }
        })
      }
    }

    const submitProfile = async () => {
      showLoading.value = true
      store.dispatch('users/updateProfile', formData.value).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          showLoading.value = false
          return
        }
        toast.success(t('account.successSave'), {
          closeOnClick: false,
        })
        showLoading.value = false
      })
    }

    const copyToken = async token => {
      try {
        await navigator.clipboard.writeText(token)
        toast.success(t('account.copyTokenSuccess'), {
          timeout: 1500,
          closeOnClick: false,
        })
      } catch (e) {
        toast.error(t('vTable.copyErrorText'), {
          timeout: 1500,
          closeOnClick: false,
        })
      }
    }

    watch(
      () => formData.value.language,
      languageId => {
        locale.value = languageId === 1 ? 'ru' : 'en'
        useGenerateBreadcrumbs(route.matched)
      }
    )

    getProfile()
    getSelectedRole()

    return {
      t,
      formData,
      token,
      user,
      tabs,
      haveAccessApi,
      activeTab,
      translation,
      showLoading,
      availableLocales,
      submitProfile,
      copyToken,
    }
  },
})
</script>
