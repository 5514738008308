import { DEFAULT_TITLE, TITLE_DELIMITER } from '@/consts/consts.ts'
import i18n from '../i18n';

export const useGenerateBreadcrumbs = (route) => {
  const { t } = i18n.global;

  let titleName = ` ${t(DEFAULT_TITLE, { TITLE_DELIMITER })}`
  route.forEach(match => {
    const breadcrumb = match.meta.breadcrumb?.name

    if (breadcrumb) {
      titleName += ` ${TITLE_DELIMITER} ${t(breadcrumb)}`
    }
  })

  document.title = titleName
}