<template>
  <form @submit.prevent="handleSubmit">
    <BaseInput
      label="enrollmentRules.name"
      name="name"
      v-model="formData.name"
      :required="true"
      :errors="v$.name.$errors"
    />

    <div class="sm:flex">
      <div class="sm:w-1/2 sm:mr-6">
        <BaseInput
          label="enrollmentRules.percent"
          name="percent"
          v-model="formData.percent"
          :inputGroup="true"
          :errors="v$.percent.$errors"
        >
          <template #inputGroup>
            <div class="input-group-text font-medium select-none">%</div>
          </template>
        </BaseInput>
      </div>

      <div class="sm:w-1/2">
        <VFieldCheckbox
          label="enrollmentRules.isOnRebill"
          name="isOnRebill"
          v-model="formData.is_on_rebill"
          :returnNumber="true"
        />
      </div>
    </div>

    <div class="sm:flex">
      <div class="sm:w-2/4 sm:mr-6">
        <BaseInput
          v-model="formData.unsubscription_timeout"
          label="enrollmentRules.unsubscriptionTimeout"
          type="number"
          name="name"
          iconLeft="ClockIcon"
          :required="true"
          :errors="v$.unsubscription_timeout.$errors"
        />
      </div>

      <div class="sm:w-2/4">
        <BaseInput
          v-model="formData.subscriber_unique_period"
          label="enrollmentRules.subscriberUniquePeriod"
          type="number"
          name="subscriberUniquePeriod"
          iconLeft="PieChartIcon"
          :required="true"
          :errors="v$.subscriber_unique_period.$errors"
        />
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
        {{ t('enrollmentRules.cancel') }}
      </button>

      <button type="submit" class="btn btn-primary">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('enrollmentRules.save') }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { useStore } from 'vuex'
import { helpers, minValue, maxValue, required } from '@vuelidate/validators'
import { REQUIRED_MESSAGE, MIN_VALUE_MESSAGE, MAX_VALUE_MESSAGE } from '@/consts/validate.ts'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  name: 'EnrollmentRuleForm',
  props: {
    enrollmentRule: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()

    const formData = ref({
      id: props.enrollmentRule.id || '',
      name: props.enrollmentRule.name || '',
      is_on_rebill: props.enrollmentRule.is_on_rebill || 0,
      percent: props.enrollmentRule.percent ?? 100,
      unsubscription_timeout: props.enrollmentRule.unsubscription_timeout ?? '',
      subscriber_unique_period: props.enrollmentRule.subscriber_unique_period ?? '',
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        percent: {
          minValueValue: helpers.withMessage(`${t(MIN_VALUE_MESSAGE)} 0`, minValue(0)),
          maxValueValue: helpers.withMessage(`${t(MAX_VALUE_MESSAGE)} 100`, maxValue(100)),
        },
        unsubscription_timeout: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        subscriber_unique_period: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, formData)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()
      if (!validate) {
        return
      }

      if (!formData.value.percent) {
        formData.value.percent = 0
      }

      props.enrollmentRule.id ? updateEnrollmentRule() : createEnrollmentRule()
    }

    const updateEnrollmentRule = () => {
      store.dispatch('enrollmentRules/updateEnrollmentRule', formData.value).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close-modal')
      })
    }

    const createEnrollmentRule = () => {
      store.dispatch('enrollmentRules/createEnrollmentRule', formData.value).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close-modal')
      })
    }

    return {
      formData,
      handleSubmit,
      v$,
      t,
    }
  },
})
</script>
