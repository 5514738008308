import axios from 'axios'
import store from '@/store'

export const http = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_BASE_URL}`,
})

export const http_stat = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_STAT_URL}`,
})

export const http_redash = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_REDASH_URL}`,
})

export const http_redash_blob = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_REDASH_URL}`,
  responseType: 'blob',
})

export const http_auth = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_AUTH_URL}`,
})

const services = [http, http_stat, http_redash, http_auth, http_redash_blob]

services.forEach(service => {
  service.interceptors.request.use(
    function(config) {
      const token = store.state.auth.accessToken

      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        }
      }

      return config
    },

    function(error) {
      return Promise.reject(error)
    }
  )
})
