export const enCommon = {
  ok: 'Apply',
  cancel: 'Cancel',
  copy: 'Copy',

  /* Date */
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This week',
  lastWeek: 'Last week',
  thisMonth: 'This month',
  lastMonth: 'Last month',
  thisYear: 'This year',
  last7days: 'Last 7 days',
  last14days: 'Last 14 days',
  last30days: 'Last 30 days',
  last60days: 'Last 60 days',
  last90days: 'Last 90 days',

  /* Time */
  sec: 'sec.',
  min: 'min.',
  hour: 'h.',
  day: 'd.',

  /* Status */
  moderation: 'Moderating',
  active: 'Active',
  blocked: 'blocked',
}

export const ruCommon = {
  ok: 'Применить',
  cancel: 'Отмена',
  copy: 'Скопировать',

  /* Date */
  today: 'Сегодня',
  yesterday: 'Вчера',
  thisWeek: 'Текущая неделя',
  lastWeek: 'Предыдущая неделя',
  thisMonth: 'Текущий месяц',
  lastMonth: 'Предыдущий месяц',
  thisYear: 'Текущий год',
  last7days: 'Предыдущие 7 дней',
  last14days: 'Предыдущие 14 дней',
  last30days: 'Предыдущие 30 дней',
  last60days: 'Предыдущие 60 дней',
  last90days: 'Предыдущие 90 дней',

  /* Time */
  sec: 'сек.',
  min: 'мин.',
  hour: 'ч.',
  day: 'д.',

  /* Status */
  moderation: 'На модерации',
  active: 'Активный',
  blocked: 'Заблокирован',
}
