import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getNotifications(_, params) {
    const arrParams = []

    if (params.limit) {
      arrParams.push(`limit=${params.limit}`)
    }

    if (params.read === 0) {
      arrParams.push('read=0')
    }

    if (params.orderCreatedAt) {
      arrParams.push('order=DESC&order_by=created_at')
    }

    const strParams = arrParams.join('&')

    try {
      const response = await http.get(`/user_notifications${strParams ? `?${strParams}` : ''}`)
      return response?.data
    } catch (e) {
      console.error(e)
    }
  },

  async getNotificationsCount() {
    try {
      const response = await http.get('/user_notifications/counter')
      return response?.data.data
    } catch (e) {
      console.error(e)
    }
  },

  async setRead(_, id) {
    try {
      const response = await http.put(`/user_notifications/${id}/read`)
      return response
    } catch (error) {
      errorHandler(error)
    }
  },

  async setUnread(_, id) {
    try {
      const response = await http.put(`/user_notifications/${id}/unread`)
      return response
    } catch (error) {
      errorHandler(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
