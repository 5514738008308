<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addCampaign">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('campaign.addCampaignButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getCampaigns"
        @applyFilter=";(table.pagination.pageData.page = 1), getCampaigns()"
      >
        <BaseInput type="number" class="w-24" label="campaign.id" name="id" v-model="table.filter.id" />

        <BaseInput label="campaign.name" name="name" v-model="table.filter.name" />

        <BaseInput
          class="w-52"
          label="campaign.campaignHash"
          name="hash"
          iconLeft="HashIcon"
          v-model="table.filter.hash"
        />

        <VFieldSelect
          :options="options.offers"
          class="w-60"
          label="campaign.offer"
          :clearable="true"
          :showId="true"
          v-model="table.filter.offer"
        />

        <VFieldSelect
          v-model="table.filter.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          class="w-60"
          label="campaign.country"
          :clearable="true"
        />

        <VFieldSelect
          :options="options.users"
          class="w-52"
          label="campaign.owner"
          :clearable="true"
          v-model="table.filter.owner"
        />

        <VFieldSelect
          :options="options.users"
          class="w-52"
          label="campaign.author"
          :clearable="true"
          v-model="table.filter.author"
        />

        <VFieldSelect
          :options="options.paymentModels"
          class="w-32"
          label="campaign.paymentModel"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.model"
        />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.headers"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getCampaigns"
        @edit="editCampaign"
        @delete="deleteCampaign"
        @restore="restoreCampaign"
        @updateSorting="sort => ((table.sorting = sort), getCampaigns())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="showServiceDialog(slotProps.id)">
            <Tippy :content="t('campaign.serviceManagement')">
              <DollarSignIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showLandingsDialog(slotProps.id)">
            <Tippy :content="t('campaign.landingManagement')">
              <TrelloIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-orange-600" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyCampaignUrl(slotProps.record)">
            <Tippy :content="t('campaign.copyUrlCampaign')">
              <LinkIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-blue-500" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyCampaign(slotProps.id)">
            <Tippy :content="t('campaign.copyCampaign')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog.main"
      @close="closeCampaignForm"
      :textHeader="campaign.id ? t('campaign.editCampaign') : t('campaign.addCampaign')"
      :recordId="campaign.id"
    >
      <EditCampaignForm
        :campaign="campaign"
        @close="closeCampaignForm"
        @refresh="getCampaigns"
        @openLandingDialog="id => showLandingsDialog(id)"
      />
    </VDialog>

    <VDialog
      size="lg"
      :showDialog="showDialog.services"
      @close="showDialog.services = false"
      :textHeader="t('campaign.serviceManagement')"
      :recordId="campaign.id"
    >
      <CampaignServices :campaign="campaign" @close="showDialog.services = false" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.landing"
      @close="showDialog.landing = false"
      :textHeader="t('campaign.landingManagement')"
      :recordId="campaign.id"
    >
      <CampaignLandingsDialog :campaign="campaign" @close="showDialog.landing = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/hooks/useTranslation'
import EditCampaignForm from './EditCampaignForm.vue'
import CampaignServices from './CampaignServices.vue'
import CampaignLandingsDialog from './CampaignLandingsDialog.vue'

export default defineComponent({
  name: 'Campaigns',
  components: {
    EditCampaignForm,
    CampaignServices,
    CampaignLandingsDialog,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const toast = useToast()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const translation = useTranslation()

    const getMonitoring = inject('injectMonitoring')
    const getMonitoringCount = inject('injectMonitoringCount')

    const campaigns = ref([])
    const campaign = ref({})

    const showDialog = ref({
      main: false,
      services: false,
      landing: false,
    })

    const confirmDialog = ref(null)

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: route.query.name || '',
        hash: decodeURIComponent(route.query.hash || '') || '',
        offer: route.query.offer || '',
        country: route.query.country || '',
        owner: route.query.owner || '',
        author: route.query.author || '',
        model: route.query.model || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: getMonitoringCount.value.campaigns > 0 ? 'ASC' : 'DESC',
        order_by: getMonitoringCount.value.campaigns > 0 ? 'status_id' : 'created_at',
      },
      headers: {
        id: { label: t('campaign.id'), sortable: true, sort_name: 'id' },
        name: { label: t('campaign.title'), sortable: true, sort_name: 'name' },
        offer: t('campaign.offer'),
        domain: t('campaign.domain'),
        payment_model: t('campaign.paymentModel'),
        author: t('campaign.author'),
        created_at: { label: t('campaign.createdAt'), sortable: true, sort_name: 'created_at' },
        status: { label: t('campaign.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return campaigns.value.map(campaign => {
          return {
            id: campaign.id,
            name: campaign.name,
            offer: campaign.offer?.name,
            domain: campaign.domain?.id === 1 ? `${campaign.domain?.name} (Flagged)` : campaign.domain?.name,
            payment_model:
              campaign.owner_id && campaign.payment_model?.name === 'CPA'
                ? `${campaign.payment_model?.name} ${t('campaign.ransom')}`
                : campaign.payment_model?.name,
            author: campaign.author,
            created_at: new Date(campaign.created_at).toLocaleString() || '',
            status: campaign.status?.id,
            url: campaign.url,
            is_deleted: !!campaign.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const options = ref({
      offers: [],
      country: [],
      users: [],
      paymentModels: [],
    })

    const getCampaigns = async () => {
      const response = await store.dispatch('campaigns/getCampaigns', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response?.data) {
        response.data = await setAuthorName(response.data)
        campaigns.value = response.data
        table.value.pagination.meta = response.meta
      }
    }

    const setAuthorName = async campaign => {
      const authorIds = Array.from(campaign, ({ author_id }) => Number(author_id))
      const uniqAuthorIds = authorIds.filter((id, index) => authorIds.indexOf(id) === index)
      const users = await store.dispatch('users/getUsersName', uniqAuthorIds)

      return campaign.map(campaign => {
        const authorName = users[campaign.author_id] || ''

        return { ...campaign, author: authorName }
      })
    }

    const editCampaign = async id => {
      const response = await store.dispatch('campaigns/getCampaignById', id)

      if (response.data?.data) {
        router.push({ params: { id: id } })
        campaign.value = response.data?.data
        showDialog.value.main = true
      }
    }

    const deleteCampaign = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'campaign.confirmDialog.title',
        message: 'campaign.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('campaigns/deleteCampaign', id).then(() => {
          getCampaigns()
          getMonitoring()
        })
      }
    }

    const restoreCampaign = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'campaign.confirmRestoreDialog.title',
        message: 'campaign.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('campaigns/restoreCampaign', id).then(() => {
          getCampaigns()
          getMonitoring()
        })
      }
    }

    const addCampaign = () => {
      campaign.value = {}
      showDialog.value.main = true
    }

    const copyCampaignUrl = async campaign => {
      try {
        await navigator.clipboard.writeText(campaign.url || '')

        toast.success(t('campaign.campaignUrlMessage'), {
          closeOnClick: false,
        })
      } catch (error) {
        console.error(error)
        toast.error(t('campaign.copyUrlErrorMessage'), {
          closeOnClick: false,
        })
      }
    }

    const closeCampaignForm = () => {
      showDialog.value.main = false
      router.push({ params: { id: '' } })
    }

    const showLandingsDialog = id => {
      store.dispatch('campaigns/getCampaignById', id).then(result => {
        if (result.data?.data) {
          campaign.value = result.data.data
          showDialog.value.landing = true
        }
      })
    }

    const copyCampaign = id => {
      store.dispatch('campaigns/getCampaignById', id).then(response => {
        if (response.data) {
          response.data.data = {
            ...response.data.data,
            id: '',
            name: `${response.data.data.name} | COPY`,
          }

          campaign.value = response.data.data
          showDialog.value.main = true
        }
      })
    }

    const showServiceDialog = async id => {
      const response = await store.dispatch('campaigns/getCampaignById', id)

      if (response?.data) {
        campaign.value = response.data.data
        showDialog.value.services = true
      }
    }

    if (route.params.id) {
      editCampaign(route.params.id)
    }

    const getOffersOptions = async () => {
      const offers = await store.dispatch('offers/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }
    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getUsersOptions = async () => {
      const users = await store.dispatch('users/getUsers', { full: false })
      options.value.users = users.data.data ? users.data.data : []
    }
    const getPaymentModelOptions = async () => {
      const paymentModels = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModels = paymentModels.data ? paymentModels.data : []
    }

    getCampaigns()
    getOffersOptions()
    getCountriesOptions()
    getUsersOptions()
    getPaymentModelOptions()

    return {
      t,
      translation,
      campaigns,
      campaign,
      showDialog,
      confirmDialog,
      table,
      options,

      getCampaigns,
      addCampaign,
      editCampaign,
      deleteCampaign,
      restoreCampaign,
      copyCampaignUrl,
      copyCampaign,
      closeCampaignForm,
      showServiceDialog,

      showLandingsDialog,
    }
  },
})
</script>
