<template>
  <div class="mt-3">
    <form @submit.prevent="handleSubmit" class="sm:flex">
      <VFieldSelect
        v-model="form.callback_event"
        :options="options.callbacks"
        label="trafficCallback.event"
        class="sm:w-2/4 sm:mr-2"
        :required="true"
        :errors="v$.callback_event.$errors"
      />

      <button type="submit" class="btn btn-primary h-10 sm:mt-9 mt-5">
        <SaveIcon class="w-4 h-4 mr-1" />
        {{ t('trafficCallback.addEvent') }}
      </button>
    </form>

    <div class="mt-6 ml-1 font-medium">
      {{ t('trafficCallback.selectedEvents') }}
    </div>

    <VTable :columns="table.columns" :data="table.data" :hideButtons="['edit']" @delete="deleteCallbackEvent" />

    <div class="modal-footer mt-3">
      <button type="button" class="btn btn-outline-secondary" @click="$emit('close')">
        <XIcon class="w-4 h-4 mr-1" />
        {{ t('trafficCallback.close') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { helpers, required } from '@vuelidate/validators'
import { REQUIRED_MESSAGE } from '@/consts/validate.ts'
import useVuelidate from '@vuelidate/core'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'CallbackEventsForm',
  props: {
    callback: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const form = ref({
      callback_event: '',
    })

    const table = ref({
      columns: {
        id: t('trafficCallback.id'),
        name: t('trafficCallback.name'),
        description: t('trafficCallback.description'),
      },
      data: props.callback.callback_events,
    })

    const options = ref({
      callbacks: [],
    })

    const rules = computed(() => {
      return {
        callback_event: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
      }
    })

    const v$ = useVuelidate(rules, form)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()
      if (!validate) {
        return
      }

      const result = await store.dispatch('callbacks/addCallbackEvent', {
        callback_id: props.callback.id,
        callback_event_id: form.value.callback_event,
      })

      const errors = result.response?.data?.errors
      if (errors) {
        useErrorNotification(errors)
        return
      }

      await refreshCallbackEvents()
      updateDisabledOptions()
    }

    const deleteCallbackEvent = async id => {
      await store.dispatch('callbacks/deleteCallbackEvent', {
        callback_id: props.callback.id,
        callback_event_id: id,
      })

      await refreshCallbackEvents()
      updateDisabledOptions()
    }

    const refreshCallbackEvents = async () => {
      const callback = await store.dispatch('callbacks/getCallbackById', props.callback.id)
      if (callback.data) {
        table.value.data = callback.data.callback_events
      }

      form.value.callback_event = ''
      v$.value.$reset()
    }

    const getOptionsCallbackEvents = async () => {
      const callbacks = await store.dispatch('callbacks/getCallbackEvents')
      options.value.callbacks = callbacks.data ? callbacks.data : []
      updateDisabledOptions()
    }

    const updateDisabledOptions = () => {
      const selectedIds = table.value.data.map(el => el.id)

      options.value.callbacks.forEach(callback => {
        if (selectedIds.includes(callback.id)) {
          callback.disabled = true
        } else {
          callback.disabled = false
        }
      })
    }

    getOptionsCallbackEvents()

    return {
      t,
      v$,
      form,
      table,
      options,

      handleSubmit,
      deleteCallbackEvent,
    }
  },
})
</script>
