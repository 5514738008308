export const enQuery = {
  exportError: 'An export error has occurred',
  requests: 'General information',
  requestTrafficbacks: 'Request trafficbacks',
  requestLandingRedirects: 'Request landing redirects',
  requestContentRedirects: 'Request content redirects',
  providerEvents: 'Provider events',
  partnerEvents: 'Partner events',
  subscribers: 'Subscribers',
  subscribersLogEvents: 'Subscribers log events',
  receipts: 'Receipts',
  requestSubscriptions: 'Request subscriptions',
  runTime: 'Runtime',
  qty: 'Qty:',
  selectFilterOptions: 'Select filter options and click on the "Search" button',
  results: 'Results',

  toastiFy: {
    success: 'Testing was successful',
    error: 'Message from the database: Request validation error',
  },

  /* Side Menu */
  request_card: 'Request card',
  funnel_conversion: 'Funnel/Conversion',
  general: 'General',
  cohorts: 'Cohorts',
  statistics: 'Statistics',
  transitions: 'Transitions',
  general_with_tb: 'General',
  statistics_partner: 'Statistics (Partner)',
  trafficback_reasons: 'Trafficback reasons',
  subscriber_search: 'Subscriber search',
  service_subscriber_logs: 'Service subscriber logs',
  getting_content: 'Getting Content',
  landing_redirects: 'Landing redirects',
  notifications_to_partners: 'Notifications to partners',
  trafficback: 'Trafficback',
  internal_request_params: 'Internal request params',
  notificatiosn_to_providers: 'Notification from providers',
  accounts: 'Accounts',
  'event-statistic': 'Finance',

  /* Filter */
  all: 'All',
  'requests-created_at': 'Date',
  'receipts-created_at': 'Date',
  created_at: 'Date',
  company_filter: 'Campaign',
  country_filter: 'Country',
  group_by: 'Group by',
  request_param_filter: 'Request params',
  offer_filter: 'Offer',
  landing_filter: 'Landing',
  currency_filter: 'Currency ',
  request_owner_filter: 'User',
  request_user_filter: 'User',
  operator_filter: 'Operator',
  operators_filter: 'Operator',
  provider_filter: 'Provider',
  reason_filter: 'Trafficback reasons',
  'request-id_filter': 'Request ID',
  request_id_filter: 'Request ID',
  service_filter: 'Service',
  msisdn_filter: 'MSISDN',
  email_filter: 'Email',
  clickid_id_filter: 'Click ID',
  request_id: 'Request ID',
  event_filter: 'Events',
  'author-id_filter': 'User',
  callback_filter: 'Callback',
  'response-code_filter': 'Answer code',
  provider_event: 'Provider events',
  provider_id_filter: 'Provider',
}

export const ruQuery = {
  exportError: 'Произошла ошибка экспорта',
  requests: 'Общая информация',
  requestTrafficbacks: 'Возврат трафика',
  requestLandingRedirects: 'Перенаправление на лендинг',
  requestContentRedirects: 'Перенаправление на сервис',
  providerEvents: 'События провайдеров',
  partnerEvents: 'Уведомления партнерам',
  subscribers: 'Абоненты',
  subscribersLogEvents: 'События абонентов',
  receipts: 'Чеки',
  requestSubscriptions: 'Запросы к провайдеру',
  runTime: 'Время выполнения',
  qty: 'Кол-во:',
  selectFilterOptions: 'Выберите параметры фильтрации и нажмите на кнопку «Поиск»',
  results: 'Количество результатов',

  toastiFy: {
    success: 'Тестирование прошло успешно',
    error: 'Сообщение от базы данных: Ошибка валидации запроса',
  },

  /* Side Menu */
  request_card: 'Карточка запроса',
  funnel_conversion: 'Воронка/конверсия',
  general: 'General',
  cohorts: 'Когорты',
  statistics: 'Статистика',
  transitions: 'Переходы',
  general_with_tb: 'General',
  statistics_partner: 'Статистика (партнерская)',
  trafficback_reasons: 'Причины возврата трафика',
  subscriber_search: 'Поиск абонента',
  service_subscriber_logs: 'Логи абонентов сервисов',
  getting_content: 'Получение контента',
  landing_redirects: 'Редиректы на лендинги',
  notifications_to_partners: 'Уведомления партнерам',
  trafficback: 'Возвраты трафика',
  internal_request_params: 'Вн. параметры запросов',
  notificatiosn_to_providers: 'Уведомления провайдеров',
  accounts: 'Счета',
  'event-statistic': 'Финансы',

  /* Filter */
  all: 'Все',
  'requests-created_at': 'Дата',
  'receipts-created_at': 'Дата',
  created_at: 'Дата',
  company_filter: 'Кампания',
  country_filter: 'Странa',
  group_by: 'Группировать по',
  request_param_filter: 'Параметры запроса',
  offer_filter: 'Оффер',
  landing_filter: 'Лэндинг',
  currency_filter: 'Валюта',
  request_owner_filter: 'Пользователь',
  request_user_filter: 'Пользователь',
  operator_filter: 'Оператор',
  operators_filter: 'Оператор',
  provider_filter: 'Провайдер',
  reason_filter: 'Причины возврата',
  'request-id_filter': 'ID запроса',
  request_id_filter: 'ID запроса',
  service_filter: 'Сервис',
  msisdn_filter: 'MSISDN',
  email_filter: 'Email',
  clickid_id_filter: 'Click ID',
  request_id: 'ID запроса',
  event_filter: 'Событие',
  'author-id_filter': 'Пользователь',
  callback_filter: 'Callback',
  'response-code_filter': 'Код ответа',
  provider_event: 'Событие провайдера',
  provider_id_filter: 'Провайдер',
}
