import { isLocal, isDev } from '@/utils/stand.js'

export const QUERY_IDS = {
  /* Карточка запроса */
  request_card: isLocal() || isDev() ? 14 : 33,

  /* Статистика */
  statistics: isLocal() || isDev() ? 16 : 1,

  /* Финансы */
  finance: isLocal() || isDev() ? 23 : 51,

  /* Когорты */
  cohorts: isLocal() || isDev() ? 18 : 16,
}
