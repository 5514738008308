<template>
  <div class="box p-5 mt-3">
    <div class="mb-4 text-right">
      <button @click="showModal = !showModal" class="btn btn-success">
        <PlusIcon class="w-4 h-4 mr-1" />
        {{ t('paymentDetails.addRequisite') }}
      </button>
    </div>

    <VDialog
      size="sm"
      :showDialog="showModal"
      @close="showModal = !showModal"
      :textHeader="t('paymentDetails.addRequisite')"
    >
      <PaymentDetailsForm @close-modal="showModal = false" />
    </VDialog>

    <VTable :columns="columns" :data="data" :hideButtons="['edit', 'delete']" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import PaymentDetailsForm from '@/views/PaymentDetails/PaymentDetailsForm.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { PaymentDetailsForm },
  setup() {
    const showModal = ref(false)
    const documents = ref([])

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const columns = {
      method: t('paymentDetails.method'),
      currency: t('paymentDetails.currency'),
      sum: t('paymentDetails.sum'),
      payment_fee: t('paymentDetails.payment_fee'),
      requisite: t('paymentDetails.requisite'),
    }

    const data = computed(() => {
      return documents.value.map(document => {
        return {
          method: document.method,
          currency: document.currency,
          sum: document.sum,
          payment_fee: document.payment_fee,
          requisite: document.requisite,
        }
      })
    })

    return { documents, showModal, columns, data, t }
  },
})
</script>
