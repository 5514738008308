import { ref } from 'vue'
import i18n from '@/i18n'

const { t } = i18n.global

const time = ref({
  start: null,
  end: null,
})

export const runTime = () => {
  const start = () => {
    time.value.start = new Date().getTime()
  }

  const end = () => {
    time.value.end = new Date().getTime()
  }

  const getTime = () => {
    return `${t('query.runTime')} ${timeConversion(time.value.end - time.value.start)}`
  }

  return {
    start,
    end,
    getTime,
  }
}

const timeConversion = duration => {
  const portions = []

  const msInHour = 1000 * 60 * 60
  const hours = Math.trunc(duration / msInHour)
  if (hours > 0) {
    portions.push(`${hours} ${t('common.hour')}`)
    duration = duration - hours * msInHour
  }

  const msInMinute = 1000 * 60
  const minutes = Math.trunc(duration / msInMinute)
  if (minutes > 0) {
    portions.push(`${minutes} ${t('common.min')}`)
    duration = duration - minutes * msInMinute
  }

  const seconds = Math.trunc(duration / 1000)
  if (seconds > 0) {
    portions.push(`${seconds} ${t('common.sec')}`)
  }

  return portions.length ? portions.join(' ') : `1 ${t('common.sec')}`
}
