export const enBlackListSource = {
  // top button
  importBtn: 'Upload file',
  addBtn: 'Add source',

  // filter
  filterSource: 'Search by source',

  // main popup
  editTitle: 'Edit source',
  addTitle: 'Add source',
  cancel: 'Cancel',
  save: 'Save',

  // import popup
  importTitle: 'File upload',
  importMessage: 'Upload multiple sources to blacklist at once',
  selectFile: 'Select file',
  importInfo: 'Format of .csv file to upload and max. file size 10MB',
  download: 'Download',
  successUpload: 'You have successfully uploaded the file',
  failUpload: 'An error occurred while uploading the file',
  downloadTemplate: 'Template for importing data',

  errors: {
    notValidFormat: 'The selected file is not in .csv format',
    moreTenMb: 'The selected file exceeds the maximum size of 10MB',
  },

  // table
  id: 'ID',
  source: 'Source',
  reason: 'Reason',
  author: 'Author',
  createdAt: 'Date created',

  // confrim dialog
  confrimDialog: {
    title: 'Removing a source',
    message: 'Are you sure you want to remove the source?',
    okButton: 'Delete',
  },
}

export const ruBlackListSource = {
  // top button
  importBtn: 'Загрузить файл',
  addBtn: 'Добавить источник',

  // filter
  filterSource: 'Поиск по источнику',

  // main popup
  editTitle: 'Редактирование источника',
  addTitle: 'Добавление источника',
  cancel: 'Отмена',
  save: 'Сохранить',

  // import popup
  importTitle: 'Загрузка файла',
  importMessage: 'Загрузите сразу несколько источников в черный список',
  selectFile: 'Выбрать файл',
  importInfo: 'Формат файла .csv для загрузки и макс. размер файла 10MB',
  download: 'Загрузить',
  successUpload: 'Вы успешно загрузили файл',
  failUpload: 'Произошла ошибка при загрузке файла',
  downloadTemplate: 'Скачать пример файла для импорта данных',

  errors: {
    notValidFormat: 'Выбранный файл не .csv формата',
    moreTenMb: 'Выбранный файл превышает максимальный размер 10MB',
  },

  // table
  id: 'ID',
  source: 'Источник',
  reason: 'Причина',
  author: 'Автор',
  createdAt: 'Дата создания',

  // confrim dialog
  confrimDialog: {
    title: 'Удаление источника',
    message: 'Вы действительно хотите удалить источник?',
    okButton: 'Удалить',
  },
}
