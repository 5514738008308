export const enBlackListMsisdn = {
  // top button
  importBtn: 'Upload file',
  addBtn: 'Add number',

  // filter
  filterMsisdn: 'Search by MSISDN',

  // main popup
  addTitle: 'Adding a number',
  editTitle: 'Edit number',
  cancel: 'Cancel',
  save: 'Save',

  // import popup
  importTitle: 'File upload',
  importMessage: 'Upload several numbers to the blacklist at once',
  selectFile: 'Select file',
  importInfo: 'Format of .csv file to upload and max. file size 10MB',
  download: 'Download',
  successUpload: 'You have successfully uploaded the file',
  failUpload: 'An error occurred while uploading the file',
  downloadTemplate: 'Template for importing data',

  errors: {
    notValidFormat: 'The selected file is not in .csv format',
    moreTenMb: 'The selected file exceeds the maximum size of 10MB',
  },

  // table
  id: 'ID',
  msisdn: 'Number',
  reason: 'Reason',
  author: 'Author',
  createdAt: 'Date created',

  // confrim dialog
  confrimDialog: {
    title: 'Delete number',
    message: 'Are you sure you want to delete the number?',
    okButton: 'Delete',
  },
}

export const ruBlackListMsisdn = {
  // top button
  importBtn: 'Загрузить файл',
  addBtn: 'Добавить номер',

  // filter
  filterMsisdn: 'Поиск по MSISDN',

  // main popup
  editTitle: 'Редактирование номера',
  addTitle: 'Добавление номера',
  cancel: 'Отмена',
  save: 'Сохранить',

  // import popup
  importTitle: 'Загрузка файла',
  importMessage: 'Загрузите сразу несколько номеров в черный список',
  selectFile: 'Выбрать файл',
  importInfo: 'Формат файла .csv для загрузки и макс. размер файла 10MB',
  download: 'Загрузить',
  successUpload: 'Вы успешно загрузили файл',
  failUpload: 'Произошла ошибка при загрузке файла',
  downloadTemplate: 'Скачать пример файла для импорта данных',

  errors: {
    notValidFormat: 'Выбранный файл не .csv формата',
    moreTenMb: 'Выбранный файл превышает максимальный размер 10MB',
  },

  // table
  id: 'ID',
  msisdn: 'Номер',
  reason: 'Причина',
  author: 'Автор',
  createdAt: 'Дата создания',

  // confrim dialog
  confrimDialog: {
    title: 'Удаление номера',
    message: 'Вы действительно хотите удалить номер?',
    okButton: 'Удалить',
  },
}
