<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="text-right">
        <button type="button" @click="createTrafficbacks" class="btn btn-success">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('trafficBack.addTrafficBackButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getTrafficbacks"
        @applyFilter=";(table.pagination.pageData.page = 1), getTrafficbacks()"
      >
        <BaseInput type="number" class="w-24" label="trafficBack.id" name="id" v-model="table.filter.id" />

        <BaseInput label="trafficBack.name" name="name" v-model="table.filter.name" />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getTrafficbacks"
        @edit="openTrafficback"
        @delete="deleteTrafficback"
        @restore="restoreTrafficback"
        @updateSorting="sort => ((table.sorting = sort), getTrafficbacks())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="copyTrafficBack(slotProps.id)">
            <Tippy :content="t('trafficBack.copyTrafficBack')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog"
      @close="closeTrafficbackForm"
      :textHeader="trafficback.id ? t('trafficBack.editTrafficBack') : t('trafficBack.addTrafficBack')"
      :recordId="trafficback.id"
    >
      <TrafficBackForm @close="closeTrafficbackForm" :trafficback="trafficback" @refresh="getTrafficbacks" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, inject } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import TrafficBackForm from './TrafficBackForm.vue'

export default defineComponent({
  name: 'TrafficBack',
  components: {
    TrafficBackForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const confirmDialog = ref(null)
    const getMonitoring = inject('injectMonitoring')
    const getMonitoringCount = inject('injectMonitoringCount')

    const showDialog = ref(false)

    const trafficback = ref({})
    const trafficbacks = ref([])

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: getMonitoringCount.value.trafficBack > 0 ? 'ASC' : 'DESC',
        order_by: getMonitoringCount.value.trafficBack > 0 ? 'status_id' : 'created_at',
      },
      columns: {
        id: { label: t('trafficBack.id'), sortable: true, sort_name: 'id' },
        name: { label: t('trafficBack.name'), sortable: true, sort_name: 'name' },
        url: t('trafficBack.url'),
        author: t('trafficBack.author'),
        created_at: { label: t('trafficBack.created_at'), sortable: true, sort_name: 'created_at' },
        status: { label: t('trafficBack.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return trafficbacks.value.map(trafficback => {
          return {
            id: trafficback.id,
            name: trafficback.name,
            url: trafficback.url,
            author: trafficback.author,
            created_at: new Date(trafficback.created_at).toLocaleString() || '',
            status: trafficback.status?.id,
            is_deleted: !!trafficback.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getTrafficbacks = async () => {
      const response = await store.dispatch('trafficbacks/getTrafficbacks', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response.data) {
        response.data = await setAuthorName(response.data)
        trafficbacks.value = response.data
        table.value.pagination.meta = response.meta
      }
    }

    const openTrafficback = async id => {
      const response = await store.dispatch('trafficbacks/getTrafficbacksById', id)

      if (response.data) {
        router.push({ params: { id: id } })
        trafficback.value = response.data
        showDialog.value = true
      }
    }

    const copyTrafficBack = async id => {
      const response = await store.dispatch('trafficbacks/getTrafficbacksById', id)

      if (response.data) {
        response.data = {
          ...response.data,
          id: '',
          name: `${response.data.name} | COPY`,
        }

        trafficback.value = response.data
        showDialog.value = true
      }
    }

    const createTrafficbacks = () => {
      trafficback.value = {}
      showDialog.value = true
    }

    const deleteTrafficback = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'trafficBack.confirmDialog.title',
        message: 'trafficBack.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        await store.dispatch('trafficbacks/deleteTrafficbacks', id)
        getTrafficbacks()
        getMonitoring()
      }
    }

    const restoreTrafficback = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'trafficBack.confirmRestoreDialog.title',
        message: 'trafficBack.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('trafficbacks/restoreTrafficbacks', id).then(() => {
          getTrafficbacks()
          getMonitoring()
        })
      }
    }

    const setAuthorName = async trafficbacks => {
      const authorIds = Array.from(trafficbacks, ({ author_id }) => Number(author_id))
      const uniqAuthorIds = authorIds.filter((id, index) => authorIds.indexOf(id) === index)
      const users = await store.dispatch('users/getUsersName', uniqAuthorIds)

      return trafficbacks.map(source => {
        const authorName = users[source.author_id] || ''

        return { ...source, author: authorName }
      })
    }

    const closeTrafficbackForm = () => {
      showDialog.value = false
      router.push({ params: { id: '' } })
    }

    if (route.params.id) {
      openTrafficback(route.params.id)
    }

    getTrafficbacks()

    return {
      t,
      trafficbacks,
      trafficback,
      table,
      openTrafficback,
      copyTrafficBack,
      getTrafficbacks,
      createTrafficbacks,
      deleteTrafficback,
      restoreTrafficback,
      closeTrafficbackForm,
      showDialog,
      confirmDialog,
    }
  },
})
</script>
