import { http } from '@/api/http'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { errorHandler } from '@/api/utils/ErrorHandler'

const state = {}

const actions = {
  async getTrafficSources({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/sources`, config)
      return response.data
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }

      console.error(error)
    }
  },

  async getTrafficSourceById(_, id) {
    try {
      const response = await http.get(`/sources/${id}`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async updateTrafficSource(_, params) {
    try {
      const response = await http.put(`/sources/${params.id}`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async createTrafficSource(_, params) {
    try {
      const response = await http.post(`/sources`, params)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  async deleteTrafficSource(_, id) {
    try {
      await http.delete(`/sources/${id}`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  async restoreTrafficSource(_, id) {
    try {
      await http.put(`/sources/${id}/restore`)
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
