<template>
  <div class="mt-4">
    <div v-if="service.payment_model.name === 'CPA'">
      <CpaForm :service="service" :options="options" @close="$emit('close')" />
    </div>
    <div v-else>
      <RevShareForm :service="service" :options="options" @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import CpaForm from './CpaForm.vue'
import RevShareForm from './RevShareForm.vue'

export default defineComponent({
  components: { CpaForm, RevShareForm },
  name: 'EditControlsOperator',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const store = useStore()

    const options = ref({
      operators: [],
      currencies: [],
    })

    const getOptionsOperators = async () => {
      const operators = await store.dispatch('operators/getOperators', { full: false })
      options.value.operators = operators.data ? operators.data : []
    }

    const getOptionsCurrency = async () => {
      const currencies = await store.dispatch('currency/getCurrencies', { full: false })
      options.value.currencies = currencies.data ? currencies.data : []
    }

    getOptionsOperators()
    getOptionsCurrency()

    return {
      options,
    }
  },
})
</script>
