<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link :to="{ name: 'side-menu-dashboard-overview-1' }" tag="a" class="flex mr-auto select-none">
        <img src="@/assets/images/svg/logo_small.svg" />
      </router-link>

      <div class="text-center">
        <a href="javascript:;" data-toggle="modal" data-target="#mobile-modal-slide-over" @click="toggleMobileMenu">
          <BarChart2Icon class="w-8 h-8 text-white transform -rotate-90" />
        </a>
      </div>
    </div>

    <!-- BEGIN: Modal Content -->
    <div
      id="mobile-modal-slide-over"
      class="modal modal-slide-over"
      data-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;" @click="toggleMobileMenu">
            <XIcon class="w-8 h-8 text-white" />
          </a>

          <div class="modal-body">
            <ul>
              <!-- BEGIN: First Child -->
              <div v-for="(menu, menuKey) in menu" :key="menuKey">
                <li>
                  <a
                    href="javascript:;"
                    class="flex flex-row items-center px-2 py-3 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
                    :class="{
                      'menu--active': menu.active,
                      'menu--open': menu.activeDropdown,
                    }"
                    @click="linkTo(menu, router)"
                  >
                    <div class="mr-3">
                      <component :is="menu.icon" />
                    </div>
                    <div class="menu__title flex flex-row items-center">
                      {{ t(menu.title) }}

                      <div
                        v-if="!menu.activeDropdown && monitoring.isModeraitonPage(menu.pageName)"
                        class="ml-3 bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded animate-pulse"
                      >
                        {{ monitoring.count.value.all }}
                      </div>

                      <div
                        v-if="menu.subMenu.length"
                        class="ml-3"
                        :class="{ 'transform rotate-180': menu.activeDropdown }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Second Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="menu.subMenu && menu.activeDropdown">
                      <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                        <a
                          href="javascript:;"
                          class="flex flex-row items-center pl-4 py-2 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
                          :class="{ 'menu--active': subMenu.active }"
                          @click="linkTo(subMenu, router)"
                        >
                          <div class="mr-3">
                            <component v-if="subMenu.icon" :is="subMenu.icon" />
                            <ActivityIcon v-else />
                          </div>
                          <div class="flex flex-row items-center">
                            {{ t(subMenu.title) }}

                            <div
                              v-if="!!monitoring.getPageCount(subMenu.pageName)"
                              class="ml-3 bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded"
                            >
                              {{ monitoring.getPageCount(subMenu.pageName) }}
                            </div>
                            <div
                              v-if="subMenu.subMenu && subMenu.subMenu.length"
                              class="ml-3"
                              :class="{
                                'transform rotate-180': subMenu.activeDropdown,
                              }"
                            >
                              <ChevronDownIcon />
                            </div>
                          </div>
                        </a>
                        <!-- BEGIN: Third Child -->
                        <transition @enter="enter" @leave="leave">
                          <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                            <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                              <a
                                href="javascript:;"
                                class="flex flex-row items-center pl-6 py-2 rounded-lg text-gray-600 hover:text-gray-700 hover:bg-gray-100"
                                :class="{ 'menu--active': lastSubMenu.active }"
                                @click="linkTo(lastSubMenu, router)"
                              >
                                <div class="mr-3">
                                  <component v-if="lastSubMenu.icon" :is="lastSubMenu.icon" class="w-5 h-5" />
                                  <ZapIcon v-else />
                                </div>
                                <div class="menu__title flex flex-row items-center">
                                  {{ t(lastSubMenu.title) || lastSubMenu.title }}

                                  <div
                                    v-if="!!monitoring.getPageCount(lastSubMenu.pageName)"
                                    class="ml-3 bg-badge_side_menu text-green-800 text-xs font-medium px-1 rounded"
                                  >
                                    {{ monitoring.getPageCount(lastSubMenu.pageName) }}
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </transition>
                        <!-- END: Third Child -->
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Second Child -->
                </li>
              </div>
              <!-- END: First Child -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent } from 'vue'
import { activeMobileMenu, toggleMobileMenu, linkTo, enter, leave } from './index'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    monitoring: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const router = useRouter()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      router,
      linkTo,
      enter,
      leave,
      t,
    }
  },
})
</script>
