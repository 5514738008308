<template>
  <div>
    <div class="dropdown intro-x inline-block" data-placement="bottom" @click="getNotifications">
      <div class="dropdown-toggle cursor-pointer text-gray-600 hover:bg-gray-300 hover:text-gray-700 p-2 rounded-full">
        <BellIcon class="h-5 w-5" />

        <div
          v-if="countUnread"
          class="animate-pulse absolute top-1 bg-red-400 text-red-900 text-[0.60rem] leading-3 font-medium px-[4px] rounded-full select-none"
          :class="countUnread > 9 ? 'right-[1px]' : 'right-1.5'"
        >
          {{ countUnread > 9 ? '9+' : countUnread }}
        </div>
      </div>

      <div class="dropdown-menu w-max">
        <div class="dropdown-menu__content box mt-1 sm:min-w-[500px] sm:max-w-[500px] min-w-[90vw] max-w-[90vw]">
          <div class="flex items-center justify-between py-3 px-3 border-b">
            <span class="text-base text-gray-700">{{ t('notification.notifications') }}</span>
            <div>
              <span
                @click="toggleFilter()"
                class="text-xs font-medium px-2 py-1 rounded-lg mr-2 select-none"
                :class="
                  showUnread === ''
                    ? 'bg-blue-200 text-blue-600 cursor-default'
                    : 'bg-gray-200 text-gray-600 cursor-pointer'
                "
              >
                {{ t('notification.all') }}
              </span>

              <span
                @click="toggleFilter(0)"
                class="text-xs font-medium px-2 py-1 rounded-lg select-none"
                :class="
                  showUnread === 0
                    ? 'bg-blue-200 text-blue-600 cursor-default'
                    : 'bg-gray-200 text-gray-600 cursor-pointer'
                "
              >
                {{ t('notification.unread') }}
              </span>
            </div>
          </div>

          <div v-if="loading" class="flex justify-center py-8">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
          </div>
          <div v-else-if="!notifications.length" class="flex flex-row items-center justify-center py-8 text-gray-600">
            <SearchIcon class="w-5 h-5 mr-2" />
            <span>{{ t('notification.noData') }}</span>
          </div>
          <div v-else class="sm:max-h-[40vw] sm:overflow-y-auto">
            <ul class="flex flex-col w-full p-2">
              <li
                v-for="notifi in notifications"
                :key="notifi.id"
                class="my-1 hover:bg-gray-100 rounded-lg py-1.5 pl-2 pr-4 -intro-y"
              >
                <div href="#" class="flex flex-row items-center rounded-lg text-gray-600">
                  <span v-if="notifi.status_id === 1" class="bg-orange-100 rounded-full p-3">
                    <LockIcon v-if="notifi.type_id === 2" class="h-7 w-7 text-orange-500" />
                    <PlusIcon v-else class="h-7 w-7 text-orange-500" />
                  </span>
                  <span v-else-if="notifi.status_id === 2" class="bg-green-100 rounded-full p-3">
                    <UnlockIcon v-if="notifi.type_id === 2" class="h-7 w-7 text-green-600" />
                    <CheckIcon v-else class="h-7 w-7 text-green-600" />
                  </span>
                  <span v-else-if="notifi.status_id === 3" class="bg-red-100 rounded-full p-3">
                    <LockIcon v-if="notifi.type_id === 2" class="h-7 w-7 text-red-600" />
                    <XIcon v-else class="h-7 w-7 text-red-600" />
                  </span>

                  <div class="flex flex-col flex-wrap ml-3">
                    <span class="font-medium text-gray-700 text-sm">
                      {{ notification.getTitle(notifi) }}
                    </span>

                    <span class="text-xs mt-0.5">
                      {{ notification.getDescriptionEntity(notifi) }}

                      <router-link
                        type="span"
                        :to="{
                          name: notification.getEntityRouteName(notifi),
                          params: { id: notifi.entity_id },
                        }"
                        target="_blank"
                        class="text-blue-700 font-medium cursor-pointer hover:underline"
                      >
                        {{ notifi.name }}
                      </router-link>
                      {{ notification.getDescriptionStatus(notifi) }}
                    </span>

                    <span class="text-xs font-medium text-blue-700 mt-2">
                      <Tippy
                        v-if="notifi.convert_date_time"
                        :content="new Date(notifi.created_at).toLocaleString() || ''"
                      >
                        {{ notifi.convert_date_time }}
                      </Tippy>
                      <span v-else>{{ new Date(notifi.created_at).toLocaleString() || '' }}</span>
                    </span>
                  </div>

                  <div class="ml-auto pl-8">
                    <div
                      @click="toggleRead(notifi)"
                      class="h-3 w-3 bg-blue-500 rounded-full cursor-pointer"
                      :class="notifi.read ? 'bg-gray-400' : 'bg-blue-500'"
                    ></div>
                  </div>
                </div>
              </li>
            </ul>

            <router-link
              :to="{ name: 'notifications' }"
              @click="hide"
              class="flex flex-row items-center border-t hover:bg-blue-200 hover:bg-opacity-40 cursor-pointer justify-center py-3 text-gray-600 hover:text-blue-800"
            >
              <SearchIcon class="w-5 h-5 mr-2" />
              {{ t('notification.openAllNotifications') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useNotificationUtil } from '@/hooks/useNotification'

export default defineComponent({
  name: 'TopBarNotification',

  setup() {
    const store = useStore()
    const notification = useNotificationUtil()
    const countUnread = inject('injectCountUnread')
    const toggleRead = inject('injectToggleRead')

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const loading = ref(true)
    const notifications = ref({})
    const showUnread = ref('')

    const getNotifications = async () => {
      const response = await store.dispatch('notifications/getNotifications', {
        read: showUnread.value,
        limit: 5,
      })

      if (response) {
        notifications.value = response.data
        notification.setDateTime(notifications)
      }

      loading.value = false
    }

    /*
    / @type: '' - all, 0 - unread
    */
    const toggleFilter = (type = '') => {
      if (showUnread.value === type) {
        return
      }

      loading.value = true
      showUnread.value = type
      getNotifications()
    }

    const hide = () => {
      cash('#programmatically-dropdown').dropdown('hide')
    }

    getNotifications()

    return {
      t,
      loading,
      countUnread,
      notification,
      notifications,
      showUnread,
      toggleRead,
      getNotifications,
      toggleFilter,
      hide,
    }
  },
})
</script>
