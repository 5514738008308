<template>
  <div>
    <div v-if="loader" class="flex justify-center my-6">
      <LoadingIcon icon="tail-spin" class="w-8 h-8" />
    </div>
    <form v-else @submit.prevent="handleSubmit">
      <div v-for="(permission, index) in permissions" :key="index">
        <div v-if="permission.data.length">
          <ul class="flex flex-col w-full">
            <li class="mt-2 -intro-x">
              <span class="flex flex-row items-center text-gray-600">
                <span class="flex items-center justify-center text-lg">
                  <component :is="permission.icon" class="w-5 h-5 mr-2.5" />
                </span>

                <span class="font-medium text-sm my-2 uppercase">{{ permission.title }}</span>
              </span>
            </li>

            <li
              v-for="query in permission.data"
              :key="query.id"
              class="my-0.5 cursor-pointer -intro-x"
              @click="query.selected = !query.selected"
            >
              <div
                class="flex flex-row items-center h-14 px-4 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-100"
                :class="{
                  'bg-blue-100 border-blue-200 bg-opacity-30 hover:bg-blue-200 hover:bg-opacity-40': query.selected,
                }"
              >
                <BaseCheckbox class="mr-3" v-model="query.selected" />

                <span class="font-medium">{{ t(query.name) }}</span>

                <div class="flex flex-row items-center ml-auto">
                  <span
                    v-if="query.tags.includes('admin')"
                    class="bg-red-300 text-red-800 py-0.5 text-xs font-medium px-2 rounded"
                  >
                    ADMIN
                  </span>

                  <span class="ml-2 truncate bg-blue-200 text-blue-800 py-0.5 text-xs font-medium px-2 rounded">
                    ID: {{ query.id }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close-modal')">
          {{ t('role.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('role.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useErrorNotification } from '@/hooks/useErrorNotification'

export default defineComponent({
  name: 'RolesReports',
  props: {
    role: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const store = useStore()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const permissions = ref({
      reports: {
        icon: 'PieChartIcon',
        title: t('sideMenu.reports'),
        data: [],
      },
      statistics: {
        icon: 'BarChart2Icon',
        title: t('sideMenu.statistics'),
        data: [],
      },
      logs: {
        icon: 'DatabaseIcon',
        title: t('sideMenu.logs'),
        data: [],
      },
    })

    const loader = ref(true)
    const selectedIds = ref([])

    const getSelectedIds = async () => {
      const selected = await store.dispatch('reports/getPermissionsById', props.role.id)

      if (selected?.length) {
        selected.forEach(query => selectedIds.value.push(query.id))
      }
    }

    const getPermissions = async () => {
      const list = await store.dispatch('reports/getPermissions')

      await getSelectedIds()

      if (list?.length) {
        list.forEach(query => {
          const isReports = () => query.tags.includes('reports')
          const isStatistics = () => query.tags.includes('statistics')
          const isLogs = () => query.tags.includes('logs')

          const isSelectedId = () => selectedIds.value.includes(query.id)

          switch (true) {
            case isReports():
              permissions.value.reports.data.push({ ...query, selected: isSelectedId() })
              break
            case isStatistics():
              permissions.value.statistics.data.push({ ...query, selected: isSelectedId() })
              break
            case isLogs():
              permissions.value.logs.data.push({ ...query, selected: isSelectedId() })
              break
            default:
              permissions.value.reports.data.push({ ...query, selected: isSelectedId() })
          }
        })
      }

      loader.value = false
    }

    const handleSubmit = async () => {
      const list = [
        ...permissions.value.reports.data,
        ...permissions.value.statistics.data,
        ...permissions.value.logs.data,
      ]

      const actions = {
        deleteIds: [],
        addIds: [],
      }

      list.forEach(query => {
        const isDeleted = () => selectedIds.value.includes(query.id) && !query.selected
        const isAdded = () => !selectedIds.value.includes(query.id) && query.selected

        if (isDeleted()) {
          actions.deleteIds.push(query.id)
        }

        if (isAdded()) {
          actions.addIds.push(query.id)
        }
      })

      if (actions.deleteIds.length) {
        const response = await store.dispatch('reports/deletePermissions', {
          role_id: props.role.id,
          permissionsIds: actions.deleteIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      if (actions.addIds.length) {
        const response = await store.dispatch('reports/addPermissions', {
          role_id: props.role.id,
          permissionsIds: actions.addIds,
        })

        const errors = response.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
        }
      }

      context.emit('close-modal')
    }

    getPermissions()

    return {
      t,
      permissions,
      loader,
      handleSubmit,
    }
  },
})
</script>
