<template>
  <div class="box p-5 mt-3">
    <VTableFilter v-model="tableFilter" @resetFilter="getDomains" @applyFilter="getDomains">
      <BaseInput class="w-52" label="domain.name" name="domain-name" v-model="tableFilter.name" />

      <BaseInput class="w-52" label="domain.url" name="domain-url" v-model="tableFilter.url" />

      <GroupRadio
        class="sm:ml-2"
        v-model="tableFilter.active"
        :options="statusOptions"
        title="domain.status"
        :isCheck="false"
      />
    </VTableFilter>

    <VTable
      :columns="columns"
      :data="data"
      :paginationOptions="paginationOptions"
      @getData="getDomains"
      :hideButtons="['edit', 'delete']"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Domains',

  setup() {
    const store = useStore()
    const domains = ref([])
    const paginationOptions = ref([])

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const tableFilter = ref({
      name: '',
      url: '',
      active: '',
    })

    const columns = {
      id: t('domain.id'),
      name: t('domain.name'),
      created_at: t('domain.createdAt'),
    }

    const data = computed(() => {
      return domains.value.map(domain => {
        return {
          id: domain.id,
          name: domain.name,
          created_at: new Date(domain.created_at).toLocaleString() || '',
        }
      })
    })

    const getDomains = (params = {}) => {
      store.dispatch('domains/getDomains', { ...params, ...tableFilter.value }).then(result => {
        if (result?.data) {
          domains.value = result.data
          paginationOptions.value = result.meta
        }
      })
    }

    getDomains()

    return {
      tableFilter,
      paginationOptions,
      domains,
      columns,
      data,
      statusOptions: [
        { id: 1, name: t('consts.statusOptions.active') },
        { id: 0, name: t('consts.statusOptions.notActive') },
      ],

      getDomains,
      t,
    }
  },
})
</script>
