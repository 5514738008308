import { http } from '@/api/http'
import { errorHandler } from '@/api/utils/ErrorHandler'
import { useHandlerParams } from '@/hooks/useHandlerParams'
import { useErrorNotification } from '@/hooks/useErrorNotification'

const state = {}

const actions = {
  async getOffers({ dispatch }, params) {
    const config = await dispatch('buildAxiosConfigWithCredentials', null, {
      root: true,
    })

    config.params = useHandlerParams(params)

    try {
      const response = await http.get(`/cabinet/offers`, config)
      return response
    } catch (error) {
      const errors = error.response?.data?.errors
      errorHandler(error)
      if (errors) {
        useErrorNotification(errors)
      }
      console.error(error)
    }
  },

  async getOfferById(_, id) {
    try {
      const response = await http.get(`/cabinet/offers/${id}`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },

  //  OPERATORS ACTIONS
  async getOperatorsByOffer(_, id) {
    try {
      const response = await http.get(`/cabinet/offers/${id}/operators`)
      return response.data
    } catch (error) {
      errorHandler(error)
      console.error(error)
      return error
    }
  },

  // LANDINGS ACTIONS
  async getLandingsToOffer(_, id) {
    try {
      const response = await http.get(`/cabinet/offers/${id}/landings`)
      return response
    } catch (error) {
      errorHandler(error)
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
