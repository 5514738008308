<template>
  <div class="border-b border-gray-300 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-700 dark:text-gray-400">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VTabs',
})
</script>
