<template>
  <div class="v-additional-aliases">
    <hr class="mt-4" />

    <h4 class="text-lg font-medium mt-4">{{ t('operators.additionalAliases') }}</h4>

    <div class="sm:flex flex-row items-end">
      <div class="sm:w-2/5">
        <BaseInput label="operators.alias" name="alias" v-model="form.alias" />
      </div>

      <div class="sm:ml-6 mt-4">
        <button type="button" class="btn btn-success mr-2" :disabled="disabledAddButton" @click="addAlias">
          <PlusIcon class="w-3 h-3 mr-1" />
          {{ t('operators.addAlias') }}
        </button>

        <button type="button" class="btn btn-outline-secondary" @click="form.alias = ''">
          {{ t('operators.cancel') }}
        </button>
      </div>
    </div>

    <div class="flex flex-row flex-wrap gap-y-3 gap-x-4 mt-5">
      <div v-for="alias in form.aliases" :key="alias.id">
        <div class="bg-gray-100 hover:bg-gray-200 rounded py-1 px-3 flex flex-row items-center border border-gray-400">
          <span class="font-medium ml-1">{{ alias.alias }}</span>
          <Trash2Icon
            @click="deleteAlias(alias.id)"
            class="ml-3 w-6 h-6 rounded-full hover:bg-gray-400 hover:bg-opacity-50 active:bg-gray-400 text-theme-6 cursor-pointer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useErrorNotification } from '@/hooks/useErrorNotification'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AdditionalAliases',
  props: {
    operator: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore()

    const form = ref({
      aliases: props.operator.aliases || [],
      alias: '',
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const getOperatorAliases = () => {
      store.dispatch('operators/getOperator', props.operator.id).then(result => {
        form.value.aliases = result.data.aliases ? result.data.aliases : []
      })
    }

    const addAlias = () => {
      store
        .dispatch('operators/addOperatorAliases', {
          operator_id: props.operator.id,
          alias: form.value.alias,
        })
        .then(result => {
          const errors = result.response?.data?.errors

          if (errors) {
            useErrorNotification(errors)
            return
          }

          form.value.alias = ''
          getOperatorAliases()
        })
    }

    const deleteAlias = id => {
      store.dispatch('operators/deleteOperatorAliases', id).then(() => {
        getOperatorAliases()
      })
    }

    const disabledAddButton = computed(() => {
      return !form.value.alias?.replace(/\s/g, '')
    })

    return {
      form,
      addAlias,
      deleteAlias,
      disabledAddButton,
      t,
    }
  },
})
</script>
