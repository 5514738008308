<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addOffer">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('offer.addOfferButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getOffers"
        @applyFilter=";(table.pagination.pageData.page = 1), getOffers()"
      >
        <BaseInput type="number" class="w-24" label="offer.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-50" label="offer.name" name="name" v-model="table.filter.name" />

        <VFieldSelect
          label="offer.country"
          class="w-48"
          v-model="table.filter.country"
          :options="options.country"
          :optionName="translation.getCountryOptionName()"
          :clearable="true"
        />

        <VFieldSelect
          :options="options.paymentModels"
          class="w-32"
          label="offer.paymentModel"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.model"
        />

        <VFieldSelect
          :options="options.flowTypes"
          class="w-36"
          label="offer.flowType"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.flow_type"
        />

        <VFieldSelect
          :options="options.privacyType"
          class="w-36"
          label="offer.privacyType"
          :clearable="true"
          :hideSearch="true"
          v-model="table.filter.privacy"
        />

        <VFieldSelect
          v-model="table.filter.category"
          :options="options.categorys"
          optionName="en_name"
          label="offer.category"
          :clearable="true"
          class="w-52"
        />

        <VFieldSelect
          :options="options.providers"
          class="w-56"
          label="offer.provider"
          :clearable="true"
          v-model="table.filter.provider"
        />

        <VFieldSelect
          v-model="table.filter.operator"
          :options="options.operators"
          label="offer.operator"
          class="w-52"
          :clearable="true"
        />

        <GroupRadio
          class="sm:ml-2"
          v-model="table.filter.active"
          :options="options.status"
          title="offer.isActive"
          :isCheck="false"
        />

        <VFieldCheckbox label="offer.isCustom" v-model="table.filter.custom" />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :columns="table.headers"
        :data="table.data"
        :sort="table.sorting"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getOffers"
        @edit="editOffer"
        @delete="deleteOffer"
        @restore="restoreOffer"
        @updateSorting="sort => ((table.sorting = sort), getOffers())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="showServiceControl(slotProps.id)">
            <Tippy :content="t('offer.serviceManagement')">
              <ServerIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showLandingControl(slotProps.id)">
            <Tippy :content="t('offer.landingManagement')">
              <TrelloIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-35" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyOffer(slotProps.id)">
            <Tippy :content="t('offer.copyOffer')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-33" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog.main"
      @close="showDialog.main = false"
      :textHeader="offer.id ? t('offer.editOffer') : t('offer.addOffer')"
      :recordId="offer.id"
    >
      <EditOffersForm @close-modal="showDialog.main = false" :offer="offer" @refresh="getOffers" />
    </VDialog>

    <VDialog
      size="lg"
      :showDialog="showDialog.services"
      @close="showDialog.services = false"
      :textHeader="t('offer.serviceManagement')"
      :recordId="offer.id"
    >
      <ServiceControlForm :offer="offer" @close="showDialog.services = false" />
    </VDialog>

    <VDialog
      size="sm"
      :showDialog="showDialog.landing"
      @close="showDialog.landing = false"
      :textHeader="t('offer.landingManagement')"
      :recordId="offer.id"
    >
      <OffersLandings :offer="offer" @close-modal="showDialog.landing = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useTranslation } from '@/hooks/useTranslation'
import { useRoute } from 'vue-router'
import EditOffersForm from './EditOffersForm.vue'
import ServiceControlForm from './controlService/ServiceControlForm.vue'
import OffersLandings from './OffersLandings.vue'

export default defineComponent({
  name: 'Offers',
  components: {
    EditOffersForm,
    ServiceControlForm,
    OffersLandings,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const translation = useTranslation()

    const offers = ref([])
    const offer = ref({})

    const showDialog = ref({
      main: false,
      services: false,
      landing: false,
    })

    const confirmDialog = ref(null)

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        country: route.query.country || '',
        model: route.query.model || '',
        flow_type: route.query.flow_type || '',
        privacy: route.query.privacy || '',
        category: route.query.category || '',
        provider: route.query.provider || '',
        operator: route.query.operator || '',
        active: route.query.active || '',
        custom: route.query.custom || 0,
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      headers: {
        id: { label: t('offer.id'), sortable: true, sort_name: 'id' },
        name: { label: t('offer.name'), sortable: true, sort_name: 'name' },
        payment_model: t('offer.paymentModel'),
        country: t('offer.country'),
        privacy_type: t('offer.privacyType'),
        category: t('offer.category'),
        created_at: { label: t('offer.createdAt'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return offers.value.map(offer => {
          return {
            id: offer.id,
            name: offer.name,
            payment_model: offer.payment_model?.name,
            country: translation.getCountry(offer.country),
            privacy_type: offer.privacy_type?.name,
            category: offer.category?.en_name,
            created_at: new Date(offer.created_at).toLocaleString() || '',
            is_deleted: !!offer.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const options = ref({
      country: [],
      paymentModels: [],
      flowTypes: [],
      privacyType: [],
      categorys: [],
      providers: [],
      operators: [],
      status: [
        { id: 1, name: t('consts.statusOptions.active') },
        { id: 0, name: t('consts.statusOptions.notActive') },
      ],
    })

    const getOffers = () => {
      store
        .dispatch('offers/getOffers', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(result => {
          if (result.data?.data) {
            offers.value = result.data.data
            table.value.pagination.meta = result.data.meta
          }
        })
    }

    const addOffer = () => {
      offer.value = {}
      showDialog.value.main = true
    }

    const editOffer = id => {
      store.dispatch('offers/getOfferById', id).then(result => {
        if (result.data?.data) {
          offer.value = result.data?.data
          showDialog.value.main = true
        }
      })
    }

    const deleteOffer = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'offer.confirmDialog.title',
        message: 'offer.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('offers/deleteOffer', id).then(() => {
          getOffers()
        })
      }
    }

    const restoreOffer = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'offer.confirmRestoreDialog.title',
        message: 'offer.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('offers/restoreOffer', id).then(() => {
          getOffers()
        })
      }
    }

    const showServiceControl = id => {
      store.dispatch('offers/getOfferById', id).then(result => {
        if (result.data?.data) {
          offer.value = result.data?.data
          showDialog.value.services = true
        }
      })
    }

    const showLandingControl = id => {
      store.dispatch('offers/getOfferById', id).then(result => {
        if (result.data?.data) {
          offer.value = result.data?.data
          showDialog.value.landing = true
        }
      })
    }

    const copyOffer = async id => {
      const response = await store.dispatch('offers/getOfferById', id)
      let data = response.data?.data

      if (data) {
        data = {
          ...data,
          id: '',
          name: `${data.name} | COPY`,
        }

        offer.value = data
        showDialog.value.main = true
      }
    }

    const getCountriesOptions = async () => {
      const countries = await store.dispatch('countries/getCountries', { full: false })
      options.value.country = countries.data ? countries.data : []
    }
    const getPaymentModelOptions = async () => {
      const paymentModels = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentModels = paymentModels.data ? paymentModels.data : []
    }
    const getFlowTypesOptions = async () => {
      const flowTypes = await store.dispatch('flowTypes/getFlowTypes')
      options.value.flowTypes = flowTypes.data ? flowTypes.data : []
    }
    const getPrivacyTypesOptions = async () => {
      const privacys = await store.dispatch('offerPrivacyTypes/getOfferPrivacyTypes')
      options.value.privacyType = privacys.data ? privacys.data : []
    }
    const getCategoriesOptions = async () => {
      const categories = await store.dispatch('offers/getCategories')
      options.value.categorys = categories ? categories : []
    }
    const getProvidersOptions = async () => {
      const providers = await store.dispatch('providers/getProviders')
      options.value.providers = providers.data ? providers.data : []
    }
    const getOptionsOperators = async () => {
      const operators = await store.dispatch('operators/getOperators', { full: false })
      options.value.operators = operators.data ? operators.data : []
    }

    getOffers()

    // Dropdown options
    getCountriesOptions()
    getPaymentModelOptions()
    getFlowTypesOptions()
    getPrivacyTypesOptions()
    getCategoriesOptions()
    getProvidersOptions()
    getOptionsOperators()

    return {
      t,
      translation,
      offers,
      offer,
      showDialog,
      confirmDialog,
      table,
      options,

      getOffers,
      addOffer,
      editOffer,
      deleteOffer,
      restoreOffer,

      showServiceControl,
      showLandingControl,
      copyOffer,
    }
  },
})
</script>
