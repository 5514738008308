<template>
  <div class="v-edit-campaign-form">
    <form @submit.prevent="handleSubmit">
      <BaseInput
        label="campaign.name"
        name="name"
        :required="true"
        v-model="campaignForm.name"
        :errors="v$.name.$errors"
      />

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-6"
          label="campaign.offer"
          :required="true"
          :disabled="!!campaign.id"
          :options="options.offers"
          :showId="true"
          v-model="campaignForm.offer_id"
          :errors="v$.offer_id.$errors"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="campaign.trafficSource"
          :required="true"
          :options="options.sources"
          v-model="campaignForm.source_id"
          :errors="v$.source_id.$errors"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-6"
          label="campaign.domain"
          :required="true"
          :options="options.domains"
          v-model="campaignForm.domain_id"
          :errors="v$.domain_id.$errors"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="campaign.trafficBack"
          :required="true"
          :options="options.trafficbacks"
          v-model="campaignForm.trafficback_id"
          :errors="v$.trafficback_id.$errors"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-6"
          label="campaign.author"
          v-model="campaignForm.author_id"
          :required="true"
          :options="options.users"
          :errors="v$.author_id.$errors"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="campaign.ownerСallback"
          :options="options.callbacks"
          optionName="base_url"
          :clearable="true"
          v-model="campaignForm.callback_id"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-2/4 sm:mr-6"
          label="campaign.owner"
          v-model="campaignForm.owner_id"
          :options="options.users"
        />

        <VFieldSelect
          class="sm:w-2/4"
          label="campaign.investorСallback"
          v-model="campaignForm.owner_callback_id"
          :options="options.callbacks"
          :disabled="!campaignForm.owner_id"
          :key="campaignForm.owner_id"
          :clearable="true"
          optionName="base_url"
        />
      </div>

      <div class="sm:flex">
        <VFieldSelect
          class="sm:w-1/2 sm:mr-6"
          label="campaign.paymentModel"
          :disabled="!!campaignForm.id"
          :hideSearch="true"
          :required="true"
          v-model="campaignForm.payment_model_id"
          :options="options.paymentsModel"
          :errors="v$.payment_model_id.$errors"
        />

        <VFieldSelect
          v-model="campaignForm.enrollment_rule_id"
          label="campaign.enrollmentsRule"
          :options="options.enrollmentsRule"
          class="sm:w-1/2"
        />
      </div>

      <div class="sm:flex">
        <div class="sm:w-1/2 sm:mr-6">
          <BaseInput
            label="campaign.caps"
            name="caps"
            :required="true"
            v-model="campaignForm.caps"
            :errors="v$.caps.$errors"
          />
        </div>

        <VFieldSelect
          v-model="campaignForm.redirect_campaign_id"
          label="campaign.redirectCampaign"
          :options="options.campaigns"
          :showId="true"
          class="sm:w-1/2"
          :clearable="true"
        />
      </div>

      <GroupCheckbox
        v-model="campaignForm.blacklists"
        :options="options.blacklists"
        title="campaign.blacklist"
        :horizontal="true"
        customClass="mr-8"
      />

      <div class="sm:flex">
        <div class="sm:w-2/4 flex items-end sm:mr-6" v-if="campaign.id">
          <div class="w-[100%]">
            <BaseInput label="campaign.campaignUrl" v-model="campaignUrl" :disabled="true" />
          </div>
          <Tippy :content="t('campaign.copyUrlCampaign')">
            <button type="button" class="btn btn-linkedin ml-1 h-10" @click="copyCampaignUrl">
              <CopyIcon class="w-5 h-5" />
            </button>
          </Tippy>
        </div>

        <div class="sm:w-2/4">
          <GroupRadio
            radioGroupClass="flex flex-wrap gap-y-3 gap-x-5 sm:gap-x-4"
            v-model="campaignForm.status_id"
            :options="options.statuses"
            title="campaign.status"
          />
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary mr-2" @click="$emit('close')">
          {{ t('campaign.cancel') }}
        </button>

        <button type="submit" class="btn btn-primary">
          <SaveIcon class="w-4 h-4 mr-1" />
          {{ t('campaign.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useErrorNotification } from '@/hooks/useErrorNotification'
import useVuelidate from '@vuelidate/core'
import { helpers, minValue, required } from '@vuelidate/validators'
import { MIN_VALUE_MESSAGE, REQUIRED_MESSAGE } from '@/consts/validate.ts'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'EditCampaignForm',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })

    const store = useStore()
    const toast = useToast()
    const getMonitoring = inject('injectMonitoring')

    const campaignForm = ref({
      id: props.campaign.id || '',
      name: props.campaign.name || '',
      offer_id: props.campaign.offer?.id || '',
      source_id: props.campaign.source?.id || '',
      author_id: props.campaign.author_id || store.state.auth.user.id || '',
      callback_id: props.campaign.callback?.id || null,
      trafficback_id: props.campaign.trafficback?.id || '',
      domain_id: props.campaign.domain?.id || '3',
      payment_model_id: props.campaign.payment_model?.id || '',
      enrollment_rule_id: props.campaign.enrollment_rule?.id || null,
      redirect_campaign_id: props.campaign.redirect_campaign_id || '',
      owner_id: props.campaign.owner_id || '',
      owner_callback_id: props.campaign.owner_callback?.id || null,
      caps: props.campaign.id ? props.campaign.caps : 0,
      blacklists: props.campaign.blacklists?.list || [],
      status_id: props.campaign.status?.id || 1,
    })

    const campaignUrl = props.campaign.url

    const options = ref({
      offers: [],
      sources: [],
      callbacks: [],
      trafficbacks: [],
      domains: [],
      paymentsModel: [],
      enrollmentsRule: [],
      campaigns: [],
      users: [],
      currency: [],
      blacklists: [],
      statuses: [],
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        offer_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        source_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        trafficback_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        domain_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        payment_model_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        author_id: { required: helpers.withMessage(t(REQUIRED_MESSAGE), required) },
        caps: {
          minValueValue: helpers.withMessage(`${t(MIN_VALUE_MESSAGE)} 0`, minValue(0)),
          required: helpers.withMessage(t(REQUIRED_MESSAGE), required),
        },
      }
    })

    const v$ = useVuelidate(rules, campaignForm)

    const handleSubmit = async () => {
      const validate = await v$.value.$validate()

      if (!validate) {
        return
      }

      const data = dataPreparation()

      props.campaign.id ? updateCampaign(data) : addCampaign(data)
      getMonitoring()
    }

    const dataPreparation = () => {
      let data = cloneDeep(campaignForm.value)

      if (!data.owner_id) {
        data.owner_id = null
      }

      if (!data.callback_id) {
        data.callback_id = null
      }

      if (!data.owner_callback_id) {
        data.owner_callback_id = null
      }

      if (!data.enrollment_rule_id) {
        data.owner_callback_id = null
      }

      return data
    }

    const addCampaign = data => {
      store.dispatch('campaigns/addCampaign', data).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
        context.emit('openLandingDialog', result.data.data.id)
      })
    }

    const updateCampaign = data => {
      store.dispatch('campaigns/updateCampaign', data).then(result => {
        const errors = result.response?.data?.errors
        if (errors) {
          useErrorNotification(errors)
          return
        }

        context.emit('refresh')
        context.emit('close')
      })
    }

    const copyCampaignUrl = async () => {
      try {
        await navigator.clipboard.writeText(campaignUrl)

        toast.success(t('campaign.campaignUrlMessage'), {
          closeOnClick: false,
        })
      } catch (error) {
        console.error(error)
        toast.error(t('campaign.copyUrlErrorMessage'), {
          closeOnClick: false,
        })
      }
    }

    watch(
      () => campaignForm.value.offer_id,
      offerId => setEnrollmentRule(offerId)
    )

    const setEnrollmentRule = async offerId => {
      if (!offerId) {
        return
      }

      const offer = await store.dispatch('offers/getOfferById', campaignForm.value.offer_id)

      if (offer?.data) {
        campaignForm.value.enrollment_rule_id = offer.data.data.enrollment_rule?.id
      }
    }

    const getOffersOptions = async () => {
      const offers = await store.dispatch('offers/getOffers', { full: false })
      options.value.offers = offers.data.data ? offers.data.data : []
    }
    const getSourcesOptions = async () => {
      const sources = await store.dispatch('trafficSource/getTrafficSources', { full: false })
      options.value.sources = sources.data ? sources.data : []
    }
    const getCallbacksOptions = async () => {
      const callbacks = await store.dispatch('callbacks/getCallbacks', { full: false })
      options.value.callbacks = callbacks.data ? callbacks.data : []
    }
    const getTrafficbacksOptions = async () => {
      const trafficbacks = await store.dispatch('trafficbacks/getTrafficbacks', { full: false })
      options.value.trafficbacks = trafficbacks.data ? trafficbacks.data : []
    }
    const getDomainsOptions = async () => {
      const domains = await store.dispatch('domains/getDomains', { full: false })

      let mainDomainIndex = null

      Object.values(domains.data).forEach((domain, index) => {
        if (!domain?.id) {
          return
        }
        if (domain.id === 1) {
          domain.name = `${domain.name} (flagged)`
        }
        if (domain.id === 3) {
          mainDomainIndex = index
        }
      })
      ;[domains.data[0], domains.data[mainDomainIndex]] = [domains.data[mainDomainIndex], domains.data[0]]

      options.value.domains = domains.data ? domains.data : []
    }
    const getPaymentsModelOptions = async () => {
      const paymentsModel = await store.dispatch('paymentModels/getPaymentModels')
      options.value.paymentsModel = paymentsModel.data ? paymentsModel.data : []
    }
    const getUsersOptions = async () => {
      const users = await store.dispatch('users/getUsers', { full: false })
      options.value.users = users.data.data ? users.data.data : []
    }
    const getOptionsCurrency = async () => {
      const currency = await store.dispatch('currency/getCurrencies', { full: false })
      options.value.currency = currency.data ? currency.data : []
    }
    const getStatusesOptions = async () => {
      const statuses = await store.dispatch('statuses/getStatuses')
      options.value.statuses = statuses ? statuses : []
    }
    const getEnrollmentsRuleOptions = async () => {
      const enrollmentsRule = await store.dispatch('enrollmentRules/getEnrollmentRules', { full: false })
      options.value.enrollmentsRule = enrollmentsRule.data ? enrollmentsRule.data : []
    }
    const getCampaignsOptions = async () => {
      const campaigns = await store.dispatch('campaigns/getCampaigns', { full: false })
      options.value.campaigns = campaigns.data ? campaigns.data : []
    }
    const getBlacklistTypesOptions = async () => {
      const blacklists = await store.dispatch('campaigns/getBlacklistTypes')
      options.value.blacklists = blacklists.data ? blacklists.data : []
    }

    // Dropdown options
    getOffersOptions()
    getSourcesOptions()
    getCallbacksOptions()
    getTrafficbacksOptions()
    getDomainsOptions()
    getPaymentsModelOptions()
    getUsersOptions()
    getOptionsCurrency()
    getStatusesOptions()
    getEnrollmentsRuleOptions()
    getCampaignsOptions()
    getBlacklistTypesOptions()

    return {
      v$,
      campaignForm,
      options,
      campaignUrl,
      handleSubmit,
      copyCampaignUrl,
      t,
    }
  },
})
</script>
