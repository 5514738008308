import { useStore } from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import { useGenerateBreadcrumbs } from '@/hooks/useGenerateBreadcrumbs'
import { getRubickRoutes } from './rubikRoutes'

import SideMenu from '../layouts/side-menu/Main.vue'
import Login from '../views/login/Main.vue'
import Register from '../views/register/Main.vue'
import Forgot from '../views/forgot-password/index.vue'
import ErrorPage from '../views/error-page/Main.vue'
import ChangePassword from '../views/change-password/Main.vue'
import AccountProfile from '../views/SettingProfile/AccountProfile.vue'
import ChangePasswordForm from '../views/SettingProfile/ChangePasswordForm.vue'
import PaymentDetails from '../views/PaymentDetails/PaymentDetails.vue'
import TrafficSource from '../views/TrafficSource/TrafficSource.vue'
import TrafficCallback from '../views/TrafficCallback/TrafficCallback.vue'
import TrafficBack from '../views/TrafficBack/TrafficBack.vue'
import Domains from '../views/Domains/Domains.vue'
import OfferWall from '../views/OfferWall/OfferWall.vue'
import MyTrafficSource from '../views/MyTrafficSource/MyTrafficSource.vue'
import VerifyEmail from '../views/verify-email/index.vue'
import Providers from '../views/Providers/Providers.vue'
import Operators from '../views/Operators/Operators.vue'
import Landings from '../views/landings/Landings.vue'
import Services from '../views/services/Services.vue'
import Query from '../views/Queries/Query.vue'
import Offers from '../views/offers/Offers.vue'
import EnrollmentRules from '../views/EnrollmentRules/EnrollmentRules.vue'
import Prelandings from '../views/Prelandings/Prelandings.vue'
import Users from '../views/Users/Users.vue'
import Roles from '../views/Roles/Roles.vue'
import Campaigns from '../views/Campaigns/Campaigns.vue'
import MyCampaigns from '../views/MyCampaigns/MyCampaigns.vue'
import i18n from '@/i18n'

const { locale } = i18n.global
const store = useStore()
const rubickRoutes = getRubickRoutes()
let profile = null

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true,
      breadcrumb: { name: 'auth.authorization' },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      isPublic: true,
      breadcrumb: { name: 'auth.register' },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot,
    meta: {
      isPublic: true,
      breadcrumb: { name: 'auth.recoverPassword' },
    },
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage,
  },
  {
    path: '/reset_password/:token',
    name: 'side-menu-change-password',
    component: ChangePassword,
    meta: {
      isPublic: true,
    },
  },

  {
    path: '/verify_email/:token',
    name: 'verify-email',
    component: VerifyEmail,
    meta: {
      isPublic: true,
    },
  },

  // SIDE-MENU: MODERATION
  {
    path: '/moderation',
    name: 'moderation',
    component: SideMenu,
    meta: { breadcrumb: { name: 'sideMenu.moderation' } },
    children: [
      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: { breadcrumb: { name: 'sideMenu.users' } },
      },
      {
        path: 'campaigns/:id(\\d+)?',
        name: 'campaigns',
        component: Campaigns,
        meta: { breadcrumb: { name: 'sideMenu.campaigns' } },
      },
      {
        path: 'traffic-source/:id(\\d+)?',
        name: 'traffic-source',
        component: TrafficSource,
        meta: { breadcrumb: { name: 'sideMenu.trafficSource' } },
      },
      {
        path: 'traffic-callback/:id(\\d+)?',
        name: 'traffic-callback',
        component: TrafficCallback,
        meta: { breadcrumb: { name: 'sideMenu.trafficCallback' } },
      },
      {
        path: 'traffic-back/:id(\\d+)?',
        name: 'traffic-back',
        component: TrafficBack,
        meta: { breadcrumb: { name: 'sideMenu.trafficBack' } },
      },
      {
        path: 'domains',
        name: 'domains',
        component: Domains,
        meta: { breadcrumb: { name: 'sideMenu.domains' } },
      },
      {
        path: 'access-management/offers',
        name: 'access-management-offers',
        component: () => import('../views/AccessManagement/AccessManagement.vue'),
        meta: { breadcrumb: { name: 'sideMenu.accessManagement' } },
      },
      {
        path: 'access-management/landings',
        name: 'access-management-landings',
        component: () => import('../views/AccessManagement/AccessManagement.vue'),
        meta: { breadcrumb: { name: 'sideMenu.accessManagement' } },
      },
    ],
  },

  // SIDE-MENU: SETTINGS
  {
    path: '/settings',
    name: 'settings',
    component: SideMenu,
    meta: { breadcrumb: { name: 'sideMenu.settings' } },
    children: [
      {
        path: 'operators',
        name: 'operators',
        component: Operators,
        meta: { breadcrumb: { name: 'sideMenu.operators' } },
      },
      {
        path: 'providers',
        name: 'providers',
        component: Providers,
        meta: { breadcrumb: { name: 'sideMenu.providers' } },
      },
      {
        path: 'offers',
        name: 'offers',
        component: Offers,
        meta: { breadcrumb: { name: 'sideMenu.offers' } },
      },
      {
        path: 'enrollment-rules',
        name: 'enrollment-rules',
        component: EnrollmentRules,
        meta: { breadcrumb: { name: 'sideMenu.enrollmentRules' } },
      },
      {
        path: 'landings',
        name: 'landings',
        component: Landings,
        meta: { breadcrumb: { name: 'sideMenu.landings' } },
      },
      {
        path: 'prelanding',
        name: 'prelanding',
        component: Prelandings,
        meta: { breadcrumb: { name: 'sideMenu.prelanding' } },
      },
      {
        path: 'services',
        name: 'services',
        component: Services,
        meta: { breadcrumb: { name: 'sideMenu.services' } },
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
        meta: { breadcrumb: { name: 'sideMenu.roles' } },
      },
      {
        path: 'blacklist/msisdn',
        name: 'blacklist-msisdn',
        component: () => import('../views/BlackListMsisdn/Msisdn.vue'),
        meta: { breadcrumb: { name: 'sideMenu.msisdn' } },
      },
      {
        path: 'blacklist/header',
        name: 'blacklist-header',
        component: () => import('../views/BlackListHeader/Header.vue'),
        meta: { breadcrumb: { name: 'sideMenu.xRequestedWith' } },
      },
      {
        path: 'blacklist/referer',
        name: 'blacklist-referer',
        component: () => import('../views/BlackListReferer/Referer.vue'),
        meta: { breadcrumb: { name: 'sideMenu.referer' } },
      },
      {
        path: 'blacklist/ip',
        name: 'blacklist-ip',
        component: () => import('../views/BlackListIpAddress/IpAddress.vue'),
        meta: { breadcrumb: { name: 'sideMenu.ipAddress' } },
      },
      {
        path: 'blacklist/useragent',
        name: 'blacklist-useragent',
        component: () => import('../views/BlackListUserAgent/UserAgent.vue'),
        meta: { breadcrumb: { name: 'sideMenu.userAgent' } },
      },
      {
        path: 'blacklist/source',
        name: 'blacklist-source',
        component: () => import('../views/BlackListSource/Source.vue'),
        meta: { breadcrumb: { name: 'sideMenu.source' } },
      },
      {
        path: 'countries',
        name: 'countries',
        component: () => import('../views/Countries/Countries.vue'),
        meta: { breadcrumb: { name: 'sideMenu.countries' } },
      },
      {
        path: 'payouts-history/offers',
        name: 'payouts-history-offers',
        component: () => import('../views/ArchiveOfferRules/ArchiveOfferRules.vue'),
        meta: { breadcrumb: { name: 'sideMenu.archiveOfferRules' } },
      },
      {
        path: 'payouts-history/campaign',
        name: 'payouts-history-campaign',
        component: () => import('../views/ArchiveCampaignRules/ArchiveCampaignRules.vue'),
        meta: { breadcrumb: { name: 'sideMenu.archiveCampaignRules' } },
      },
    ],
  },

  // SIDE-MENU: CABINET
  {
    path: '/cabinet',
    name: 'cabinet',
    component: SideMenu,
    children: [
      {
        path: 'campaigns/:id(\\d+)?',
        name: 'my-campaigns',
        component: MyCampaigns,
        meta: { breadcrumb: { name: 'sideMenu.myCampaigns' } },
      },
      {
        path: 'traffic-sources/:id(\\d+)?',
        name: 'my-traffic-source',
        component: MyTrafficSource,
        meta: { breadcrumb: { name: 'sideMenu.myTrafficSource' } },
      },
      {
        path: 'callbacks/:id(\\d+)?',
        name: 'my-traffic-callback',
        component: () => import('../views/MyTrafficCallback/MyTrafficCallback.vue'),
        meta: { breadcrumb: { name: 'sideMenu.myTrafficCallback' } },
      },
      {
        path: 'traffic-backs/:id(\\d+)?',
        name: 'my-traffic-back',
        component: () => import('../views/MyTrafficBack/MyTrafficBack.vue'),
        meta: { breadcrumb: { name: 'sideMenu.myTrafficBack' } },
      },
      // {
      //   path: 'my-domains',
      //   name: 'my-domains',
      //   component: () => import('../views/MyDomains/MyDomains.vue'),
      //   meta: { breadcrumb: { name: 'sideMenu.myDomains' } },
      // },
    ],
  },

  // SIDE-MENU: REPORTS
  {
    path: '/reports',
    name: 'reports',
    component: SideMenu,
    meta: { breadcrumb: { name: 'sideMenu.reports' } },
    children: [
      {
        path: 'query/:id(\\d+)',
        name: 'reports-query',
        component: Query,
      },
    ],
  },

  // SIDE-MENU: STATISTICS
  {
    path: '/statistics',
    name: 'statistics',
    component: SideMenu,
    meta: { breadcrumb: { name: 'sideMenu.statistics' } },
    children: [
      {
        path: 'query/:id(\\d+)',
        name: 'stat-query',
        component: Query,
      },
    ],
  },

  // SIDE-MENU: LOGS
  {
    path: '/logs',
    name: 'logs',
    component: SideMenu,
    meta: { breadcrumb: { name: 'sideMenu.logs' } },
    children: [
      {
        path: 'query/:id(\\d+)',
        name: 'logs-query',
        component: Query,
      },
    ],
  },

  {
    path: '/',
    component: SideMenu,
    children: [
      // {
      //   path: '/',
      //   name: 'dashboards',
      //   component: () => import('../views/Dashboards/Dashboards.vue'),
      //   meta: { breadcrumb: { name: 'sideMenu.dashboards' } },
      // },

      {
        path: '/',
        name: 'side-menu-dashboard-overview-1',
        meta: { breadcrumb: { name: 'sideMenu.myOfferWall' } },
        component: OfferWall,
      },
      {
        path: 'offer-wall/:id(\\d+)?',
        name: 'offer-wall',
        component: OfferWall,
        meta: { breadcrumb: { name: 'sideMenu.myOfferWall' } },
      },
      {
        path: 'landing-wall/:id(\\d+)?',
        name: 'landing-wall',
        component: () => import('../views/LandingWall/LandingWall.vue'),
        meta: { breadcrumb: { name: 'sideMenu.landingWall' } },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('../views/Notifications/Notifications.vue'),
        meta: { breadcrumb: { name: 'sideMenu.notifications' } },
      },
      {
        path: '/account-profile',
        name: 'account-profile',
        component: AccountProfile,
        meta: { breadcrumb: { name: 'sideMenu.settingProfile' } },
      },
      {
        path: '/payment-details',
        name: 'payment-details',
        component: PaymentDetails,
        meta: { breadcrumb: { name: 'sideMenu.paymentDetails' } },
      },
      {
        path: '/change-password',
        name: 'change-password',
        component: ChangePasswordForm,
        meta: { breadcrumb: { name: 'sideMenu.changePassword' } },
      },
    ],
  },

  rubickRoutes.topMenu,
  rubickRoutes.simpleMenu,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // @feat: when changing the parameters of the router.push(), the page scrolled up
    if (to.name === from.name) {
      return false
    }
    return savedPosition || { left: 0, top: 0, behavior: 'smooth' }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next()
  } else {
    const isAuthenticated = await store.dispatch('auth/isAuthenticated')
    if (isAuthenticated) {
      await setProfileLanguage()
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach(to => {
  useGenerateBreadcrumbs(to.matched)
})

const setProfileLanguage = async () => {
  if (profile) {
    return
  }

  profile = await store.dispatch('users/getProfile')
  if (profile) {
    locale.value = profile.language === 'en-EN' ? 'en' : 'ru'
  }
}

export default router
