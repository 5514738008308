<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="flex justify-end">
        <button class="btn btn-success" @click="addProvider">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('provider.addProviderButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getProviders"
        @applyFilter=";(table.pagination.pageData.page = 1), getProviders()"
      >
        <BaseInput type="number" class="w-24" label="provider.id" name="id" v-model="table.filter.id" />

        <BaseInput class="w-52" label="provider.name" name="name" v-model="table.filter.name" />

        <VFieldCheckbox label="baseCheckbox.showDeleted" v-model="table.filter.deleted" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :sort="table.sorting"
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :clickableRow="true"
        @getData="getProviders"
        @edit="onEditProvider"
        @delete="deleteProvider"
        @restore="restoreProvider"
        @updateSorting="sort => ((table.sorting = sort), getProviders())"
        @updatePageData="data => (table.pagination.pageData = data)"
      >
        <template v-slot:buttons="slotProps">
          <button type="button" class="mr-1" @click.stop="showOperatorAlias(slotProps.id)">
            <Tippy :content="t('provider.operatorAlias')">
              <HeadphonesIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-31" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showCallbackEvents(slotProps.id)">
            <Tippy :content="t('provider.callbackEvents')">
              <BellIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-33" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showTrafficbackReasons(slotProps.id)">
            <Tippy :content="t('provider.trafficbackReasons')">
              <SendIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-32" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="showCallbackResponce(slotProps.id)">
            <Tippy :content="t('provider.callbackResponce')">
              <MessageSquareIcon class="w-9 h-9 rounded-2xl hover:bg-gray-200 active:bg-gray-300 p-1 text-theme-34" />
            </Tippy>
          </button>

          <button type="button" class="mr-1" @click.stop="copyProvider(slotProps.id)">
            <Tippy :content="t('provider.copyProvider')">
              <CopyIcon class="w-9 h-9 rounded-xl hover:bg-gray-200 active:bg-gray-300 p-1 text-green-700" />
            </Tippy>
          </button>
        </template>
      </VTable>
    </div>

    <VDialog
      :showDialog="showDialog.provider"
      @close="showDialog.provider = false"
      :textHeader="provider.id ? t('provider.editProvider') : t('provider.addProvider')"
      :recordId="provider.id"
    >
      <EditProviderForm :provider="provider" @close="showDialog.provider = false" @refresh="getProviders" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.operatorAlias"
      @close="showDialog.operatorAlias = false"
      :textHeader="t('provider.operatorAlias')"
      :recordId="provider.id"
    >
      <OperatorAlias :provider="provider" @close="showDialog.operatorAlias = false" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.callbackEvents"
      @close="showDialog.callbackEvents = false"
      :textHeader="t('provider.callbackEvents')"
      :recordId="provider.id"
    >
      <CallbackEvents :provider="provider" @close="showDialog.callbackEvents = false" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.trafficbackReasons"
      @close="showDialog.trafficbackReasons = false"
      :textHeader="t('provider.trafficbackReasons')"
      :recordId="provider.id"
    >
      <TrafficbackReasons :provider="provider" @close="showDialog.trafficbackReasons = false" />
    </VDialog>

    <VDialog
      :showDialog="showDialog.callbackResponce"
      @close="showDialog.callbackResponce = false"
      :textHeader="t('provider.callbackResponce')"
      :recordId="provider.id"
    >
      <CallbackResponce :provider="provider" @close="showDialog.callbackResponce = false" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import EditProviderForm from './EditProviderForm.vue'
import OperatorAlias from './OperatorAlias.vue'
import CallbackEvents from './CallbackEvents.vue'
import TrafficbackReasons from './TrafficbackReasons.vue'
import CallbackResponce from './CallbackResponce.vue'

export default defineComponent({
  name: 'Providers',
  components: {
    EditProviderForm,
    OperatorAlias,
    CallbackEvents,
    TrafficbackReasons,
    CallbackResponce,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const route = useRoute()
    const store = useStore()
    const provider = ref({})
    const providers = ref([])
    const confirmDialog = ref(null)

    const showDialog = ref({
      provider: false,
      operatorAlias: false,
      callbackEvents: false,
      trafficbackReasons: false,
      callbackResponce: false,
    })

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
        deleted: route.query.deleted ? '1' : false,
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('provider.id'), sortable: true, sort_name: 'id' },
        name: { label: t('provider.name'), sortable: true, sort_name: 'name' },
        alias: t('provider.alias'),
        created_at: { label: t('provider.created_at'), sortable: true, sort_name: 'created_at' },
      },
      data: computed(() => {
        return providers.value.map(provider => {
          return {
            id: provider.id,
            name: provider.name,
            alias: provider.alias,
            created_at: new Date(provider.created_at).toLocaleString() || '',
            is_deleted: !!provider.deleted_at,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getProviders = () => {
      store
        .dispatch('providers/getProviders', {
          ...table.value.pagination.pageData,
          ...table.value.filter,
          ...table.value.sorting,
        })
        .then(res => {
          providers.value = res.data
          table.value.pagination.meta = res.meta
        })
    }

    const addProvider = () => {
      provider.value = {}
      showDialog.value.provider = true
    }

    const onEditProvider = id => {
      store.dispatch('providers/getProvider', id).then(result => {
        provider.value = result.data
        showDialog.value.provider = true
      })
    }

    const copyProvider = async id => {
      const response = await store.dispatch('providers/getProvider', id)

      if (response.data) {
        response.data = {
          ...response.data,
          id: '',
          name: `${response.data.name} | COPY`,
        }

        provider.value = response.data
        showDialog.value.provider = true
      }
    }

    const deleteProvider = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'provider.confirmDialog.title',
        message: 'provider.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        store.dispatch('providers/deleteProvider', id).then(() => {
          getProviders()
        })
      }
    }

    const restoreProvider = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'provider.confirmRestoreDialog.title',
        message: 'provider.confirmRestoreDialog.message',
      })

      if (dialog) {
        store.dispatch('providers/restoreProvider', id).then(() => {
          getProviders()
        })
      }
    }

    const showOperatorAlias = async id => {
      store.dispatch('providers/getProvider', id).then(result => {
        provider.value = result.data
        showDialog.value.operatorAlias = true
      })
    }

    const showCallbackEvents = async id => {
      store.dispatch('providers/getProvider', id).then(result => {
        provider.value = result.data
        showDialog.value.callbackEvents = true
      })
    }

    const showTrafficbackReasons = async id => {
      store.dispatch('providers/getProvider', id).then(result => {
        provider.value = result.data
        showDialog.value.trafficbackReasons = true
      })
    }

    const showCallbackResponce = async id => {
      store.dispatch('providers/getProvider', id).then(result => {
        provider.value = result.data
        showDialog.value.callbackResponce = true
      })
    }

    getProviders()

    return {
      store,
      showDialog,
      providers,
      provider,
      confirmDialog,
      table,
      getProviders,
      addProvider,
      onEditProvider,
      copyProvider,
      deleteProvider,
      restoreProvider,
      showOperatorAlias,
      showCallbackEvents,
      showTrafficbackReasons,
      showCallbackResponce,
      t,
    }
  },
})
</script>
