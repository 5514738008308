export const enEnrollmentRules = {
  addEnrollmentRuleButton: 'Add enrollment rule',
  copyEnrollmentRule: 'Copy enrollment rule',

  // Table
  id: 'ID',
  name: 'Name',
  isOnRebill: 'Rebil',
  unsubscriptionTimeout: 'Unsubscription timeout',
  subscriberUniquePeriod: 'Subscriber unique period',
  author: 'Author',
  createdAt: 'Created at',
  yes: 'Yes',
  no: 'No',

  confirmDialog: {
    title: 'Deleting a enrollment rule',
    message: 'Do you really want to remove the enrollment rule?',
  },

  confirmRestoreDialog: {
    title: 'Restore a enrollment rule',
    message: 'Do you really want to restore the enrollment rule?',
  },

  // Dialog
  addEnrollmentRule: 'Add enrollment rule',
  editEnrollmentRule: 'Edit enrollment rule',
  percent: 'Percent',
  save: 'Save',
  cancel: 'Cancel',
}

export const ruEnrollmentRules = {
  addEnrollmentRuleButton: 'Добавить правило выкупа',
  copyEnrollmentRule: 'Скопировать правило выкупа',

  // Table
  id: 'ID',
  name: 'Название',
  isOnRebill: 'Ребил',
  unsubscriptionTimeout: 'Время ожидания отписки',
  subscriberUniquePeriod: 'Уникальный период подписки',
  author: 'Автор',
  createdAt: 'Дата создания',
  yes: 'Да',
  no: 'Нет',

  confirmDialog: {
    title: 'Удаление правила выкупа',
    message: 'Вы действительно хотите удалить правило выкупа?',
  },

  confirmRestoreDialog: {
    title: 'Восстановление правила выкупа',
    message: 'Вы действительно хотите восстановить правило выкупа?',
  },

  // Dialog
  addEnrollmentRule: 'Добавление правила выкупа',
  editEnrollmentRule: 'Редактирование правила выкупа',
  percent: 'Процент',
  save: 'Сохранить',
  cancel: 'Отменить',
}
