export const useHandlerParams = params => {
  if (!params) {
    return {}
  }

  let result = {}

  const isExistPage = 'page' in params
  const full = 'full' in params

  if (!isExistPage && !full) {
    result['page'] = 1
  }

  if (full) {
    result['full'] = 0
  }

  Object.entries(params).forEach(([key, value]) => {
    if (key && value) {
      switch (typeof value) {
        case 'boolean':
          result[key] = Number(value)
          break
        default:
          result[key] = value
      }
    }
  })

  return result
}
