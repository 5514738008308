<template>
  <div>
    <div class="box p-4 mt-3">
      <div class="text-right">
        <button type="button" @click="createSource" class="btn btn-success">
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ t('trafficSource.addSourceButton') }}
        </button>
      </div>

      <VTableFilter
        v-model="table.filter"
        @resetFilter="getSources"
        @applyFilter=";(table.pagination.pageData.page = 1), getSources()"
      >
        <BaseInput type="number" class="w-24" label="trafficSource.id" name="id" v-model="table.filter.id" />

        <BaseInput label="trafficSource.name" name="name" v-model="table.filter.name" />
      </VTableFilter>
    </div>

    <div class="box p-4 mt-3">
      <VTable
        :columns="table.columns"
        :data="table.data"
        :paginationOptions="table.pagination.meta"
        :sort="table.sorting"
        :clickableRow="true"
        @getData="getSources"
        @edit="showSourceForm"
        @delete="deleteSource"
        @updateSorting="sort => ((table.sorting = sort), getSources())"
        @updatePageData="data => (table.pagination.pageData = data)"
      />
    </div>

    <VDialog
      :showDialog="showModal"
      @close="closeSourceForm"
      :textHeader="source.id ? t('trafficSource.editSource') : t('trafficSource.addSource')"
      :recordId="source.id"
      :status="source.status?.id"
    >
      <TrafficSourceForm @close="closeSourceForm" @refresh="getSources" :source="source" />
    </VDialog>

    <VConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import TrafficSourceForm from './MyTrafficSourceForm.vue'

export default defineComponent({
  name: 'MyTrafficSource',
  components: {
    TrafficSourceForm,
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const confirmDialog = ref(null)

    const sources = ref([])
    const source = ref({})

    const showModal = ref(false)

    const table = ref({
      filter: {
        id: route.query.id || '',
        name: decodeURIComponent(route.query.name || '') || '',
      },
      sorting: {
        order: 'DESC',
        order_by: 'created_at',
      },
      columns: {
        id: { label: t('trafficSource.id'), sortable: true, sort_name: 'id' },
        name: { label: t('trafficSource.name'), sortable: true, sort_name: 'name' },
        created_at: { label: t('trafficSource.created_at'), sortable: true, sort_name: 'created_at' },
        status: { label: t('trafficCallback.status'), type: 'status', sortable: true, sort_name: 'status_id' },
      },
      data: computed(() => {
        return sources.value.map(source => {
          return {
            id: source.id,
            name: source.name,
            created_at: new Date(source.created_at).toLocaleString() || '',
            status: source.status?.id,
          }
        })
      }),
      pagination: {
        meta: [],
        pageData: {},
      },
    })

    const getSources = async () => {
      const response = await store.dispatch('myTrafficSource/getTrafficSources', {
        ...table.value.pagination.pageData,
        ...table.value.filter,
        ...table.value.sorting,
      })

      if (response) {
        sources.value = response.data
        table.value.pagination.meta = response.meta
      }
    }

    const createSource = () => {
      source.value = {
        variables_map: [{ name: 'clickid', value: '' }],
      }
      showModal.value = true
    }

    const showSourceForm = async id => {
      const response = await store.dispatch('myTrafficSource/getTrafficSourceById', id)

      if (response) {
        router.push({ params: { id: id } })
        source.value = response.data
        showModal.value = true
      }
    }

    const closeSourceForm = () => {
      showModal.value = false
      router.push({ params: { id: '' } })
    }

    const deleteSource = async id => {
      const dialog = await confirmDialog.value.onShowDialog({
        title: 'trafficSource.confirmDialog.title',
        message: 'trafficSource.confirmDialog.message',
        confirmClassBtn: 'btn-danger',
        okButton: 'vTable.delete',
      })

      if (dialog) {
        await store.dispatch('myTrafficSource/deleteTrafficSource', id)
        getSources()
      }
    }

    if (route.params.id) {
      showSourceForm(route.params.id)
    }

    getSources()

    return {
      t,
      table,
      sources,
      source,
      confirm,
      createSource,
      showSourceForm,
      closeSourceForm,
      deleteSource,
      getSources,
      confirmDialog,
      showModal,
    }
  },
})
</script>
